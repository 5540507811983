import React, { forwardRef, useEffect } from 'react';
import { Portal } from '~/components/shared/Portal/Portal';
import { Transition, TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import ReactFocusLock from 'react-focus-lock';
import Overlay from '~/components/shared/Modal/Overlay';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { Size, theme } from '~/styles/themes';
import Group from '~/components/shared/Layout/Group';
import useScrollLock from '~/hooks/useScrollLock';
import { MediaQueries } from '~/constants/constVars';
import { QitUiProvider } from '@qred/qit-ui';
import { ComponentsLibraryProvider } from '@qred/components-library';
import Stack from '../Layout/Stack';

export const ModalTransitionDuration = 150;

export interface ModalProps extends React.ComponentPropsWithoutRef<'div'> {
  onClose?: () => void;
  opened: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  zIndex?: number;
  id?: string;
  size?: Size;
  fullScreen?: boolean;
  fixedHeaderAndFooter?: boolean;
  hasOwnFooter?: boolean;
  filledBackground?: boolean;
  heightAuto?: boolean;
  showBackButton?: boolean;
  hideCloseButton?: boolean;
}

const getFullScreenStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  max-height: 100vh;
  overflow-y: auto;
`;

const ModalContainer = styled.div.attrs(
  (props: { state: TransitionStatus; zIndex: number }) => props
)`
  position: fixed;
  z-index: ${(props) => props.zIndex};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition-property: opacity;
  transition-duration: ${ModalTransitionDuration}ms;
  ${(props) =>
    props.state === 'entered' &&
    `
            opacity: 1;
          `}
  ${(props) =>
    props.state === 'exited' &&
    `
            opacity: 0;
          `}
`;

const BackButtonIcon = styled(Icon)`
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  z-index: 21;
  position: absolute;
  left: 1.5em;
  top: 1.5em;
  svg {
    color: ${theme.colors.dark};
  }

  svg:hover:not([disabled]) {
    color: ${theme.colors.hoverDark};
  }
`;

const CloseButtonIcon = styled(Icon)`
  cursor: pointer;
  align-self: flex-end;
  width: 1.5em;
  height: 1.5em;
  z-index: 21;

  svg {
    color: ${theme.colors.dark};
  }

  svg:hover:not([disabled]) {
    color: ${theme.colors.hoverDark};
  }
`;

const FocusLock = styled(ReactFocusLock)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModal = styled.div<{
  size?: Size;
  fullScreen: boolean;
  heightAuto?: boolean;
}>`
  position: relative;
  z-index: 21;
  margin-top: auto;
  margin-bottom: auto;
  background-color: ${theme.colors.white};
  display: flex;
  height: ${(props) =>
    props.fullScreen || props.heightAuto ? 'auto' : '80vh'};
  flex-direction: column;
  border-radius: 0.2em;

  width: ${(props) => {
    if (props.fullScreen) {
      return '100vw';
    }
    if (props.size) {
      return theme.modal.width[props.size];
    }
    return undefined;
  }};
  max-width: ${(props) => (props.fullScreen ? '100vw' : '95vw')};

  ${(props) =>
    props.fullScreen
      ? undefined
      : css`
          margin-left: calc(var(--removed-scroll-width, 0px) * -1);
        `}

  ${(props) => props.fullScreen && getFullScreenStyles}
`;

const ModalHeader = styled(Stack)<{
  fixedHeaderAndFooter?: boolean;
  filledBackground?: boolean;
  hasContent?: boolean;
  showBackButton?: boolean;
}>`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.hasContent ? '1.5em' : '1.5em 1.5em 0.5em')};
  ${(props) =>
    props.showBackButton &&
    css`
      padding-left: 3em;
      padding-right: 3em;
    `}
  justify-content: ${(props) => {
    if (props.fixedHeaderAndFooter) {
      return 'space-evenly';
    }
    return 'space-between';
  }};
  ${(props) =>
    props.fixedHeaderAndFooter &&
    css`
      width: 100%;
      top: 0;
      left: 0;
      position: sticky;
    `}
  ${(props) =>
    props.filledBackground &&
    props.hasContent &&
    css`
      box-shadow: 1px 4px 18px 5px rgba(102, 99, 99, 0.11);
      -webkit-box-shadow: 1px 4px 18px 5px rgba(102, 99, 99, 0.11);
      -moz-box-shadow: 1px 4px 18px 5px rgba(102, 99, 99, 0.11);
      z-index: 1;
      background-color: ${theme.colors.white};
    `};
`;

const HeaderContent = styled(Group)<{ fixedHeaderAndFooter?: boolean }>`
  margin-bottom: ${(props) => (props.fixedHeaderAndFooter ? 0 : 1)}em;
`;

const Body = styled.div<{
  fullScreen?: boolean;
  fixedHeaderAndFooter?: boolean;
  hasFooter?: boolean;
}>`
  padding: 1em 1.5em;
  ${(props) =>
    props.fixedHeaderAndFooter &&
    css`
      margin-bottom: 6em;
    `};
  overflow: auto;
`;

export const ModalFooter = styled.div<{
  fixedHeaderAndFooter?: boolean;
  filledBackground?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0;
  ${(props) =>
    props.filledBackground &&
    css`
      box-shadow: 1px -5px 18px -5px rgba(102, 99, 99, 0.11);
      -webkit-box-shadow: 1px -5px 18px -5px rgba(102, 99, 99, 0.11);
      -moz-box-shadow: 1px -5px 18px -5px rgba(102, 99, 99, 0.11);
      z-index: 1;
      background-color: ${theme.colors.white};
    `};
  ${(props) =>
    props.fixedHeaderAndFooter &&
    css`
      width: 100%;
      padding: 1em 0;
      background-color: ${theme.colors.white};
      bottom: 0;
      left: 0;
      position: absolute;
      @media ${MediaQueries.smallScreenPortrait} {
        position: fixed;
      }
    `}
`;

export const ModalFooterContent = styled.div`
  width: 80%;
`;

const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const {
    onClose = () => null,
    opened,
    zIndex = 200,
    children,
    header,
    footer,
    size,
    fullScreen = false,
    fixedHeaderAndFooter,
    hasOwnFooter,
    filledBackground,
    heightAuto,
    showBackButton,
    hideCloseButton,
  } = props;

  const [, setScrollLock] = useScrollLock();

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onClose]);

  return (
    <Portal zIndex={zIndex}>
      <QitUiProvider>
        <ComponentsLibraryProvider>
          <Transition
            in={opened}
            timeout={ModalTransitionDuration}
            onEntered={() => setScrollLock(true)}
            onExit={() => setScrollLock(false)}
            unmountOnExit
          >
            {(state) => (
              <ModalContainer state={state} zIndex={zIndex} ref={ref}>
                <FocusLock autoFocus={false}>
                  <StyledModal
                    fullScreen={fullScreen}
                    size={size}
                    heightAuto={heightAuto}
                  >
                    <ModalHeader
                      fixedHeaderAndFooter={fixedHeaderAndFooter}
                      filledBackground={filledBackground}
                      hasContent={!!header}
                      showBackButton={showBackButton}
                      spacing="sm"
                    >
                      {showBackButton ? (
                        <BackButtonIcon
                          src={SvgSrc.ChevronLeft}
                          onClick={onClose}
                        />
                      ) : (
                        !hideCloseButton && (
                          <CloseButtonIcon
                            src={SvgSrc.Times}
                            onClick={onClose}
                          />
                        )
                      )}
                      {header && (
                        <HeaderContent
                          justify="center"
                          fixedHeaderAndFooter={fixedHeaderAndFooter}
                        >
                          {header}
                        </HeaderContent>
                      )}
                    </ModalHeader>
                    <Body
                      fullScreen={fullScreen}
                      fixedHeaderAndFooter={fixedHeaderAndFooter}
                      hasFooter={hasOwnFooter || !!footer}
                    >
                      {children}
                    </Body>
                    {footer && (
                      <ModalFooter
                        fixedHeaderAndFooter={fixedHeaderAndFooter}
                        filledBackground={filledBackground}
                      >
                        <ModalFooterContent>{footer}</ModalFooterContent>
                      </ModalFooter>
                    )}
                  </StyledModal>
                  <Overlay onMouseDown={onClose} zIndex={20} />
                </FocusLock>
              </ModalContainer>
            )}
          </Transition>
        </ComponentsLibraryProvider>
      </QitUiProvider>
    </Portal>
  );
});

export default Modal;
