import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiGetKlarnaKosmaAccountsInfo,
  apiInitKlarnaKosmaTransactions,
} from '~/services/api/klarnaKosma';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import { RootState } from '~/store/types/sharedTypes';
import useTranslate from '~/hooks/useTranslate';
import { Button } from '@qred/components-library';
import { KlarnaStatus } from '~/enums';
import {
  appendAccountsInfo,
  updateForm,
} from '~/store/slices/loanOnboardingOffer.slice';

interface KlarnaKosmaButtonProps {
  setKlarnaStatus: (klarnaStatus: KlarnaStatus) => void;
}

const KlarnaKosmaButton = ({ setKlarnaStatus }: KlarnaKosmaButtonProps) => {
  const [klarnaIsLoading, setKlarnaIsLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    onboardingOffer: { overview },
    intl: { market, language },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();

  const startKlarnaFlow = async () => {
    setKlarnaIsLoading(true);
    try {
      const response = await apiInitKlarnaKosmaTransactions(
        market,
        language,
        overview.clientId
      );

      const {
        client_token: clientToken,
        session_id: sessionId,
        flow_id: flowId,
      } = response.data;

      window.XS2A.startFlow(clientToken, {
        onFinished: async () => {
          try {
            const res = await apiGetKlarnaKosmaAccountsInfo(
              market,
              sessionId,
              flowId,
              overview.applicationUuid
            );
            setKlarnaIsLoading(false);
            dispatch(appendAccountsInfo(res.accounts));
            dispatch(updateForm({ accountNumber: '' }));
            setKlarnaStatus(KlarnaStatus.COMPLETED);
          } catch (err) {
            setKlarnaIsLoading(false);
            dispatch(
              pushToSentryAction(err, 'apiStoreKlarnaKosmaAccounts', {
                organizationNumber: overview.companyNumber,
              })
            );
          }
        },
        onError: (err) => {
          setKlarnaStatus(KlarnaStatus.ERROR);
          setKlarnaIsLoading(false);
          dispatch(
            pushToSentryAction(err, 'processKlarnaKosmaAccountsFlow flow', {
              organizationNumber: overview.companyNumber,
            })
          );
        },
        onAbort: () => {
          setKlarnaStatus(KlarnaStatus.ERROR);
          setKlarnaIsLoading(false);
        },
      });
    } catch (error) {
      setKlarnaStatus(KlarnaStatus.ERROR);
      setKlarnaIsLoading(false);
      dispatch(
        pushToSentryAction(error, 'apiInitKlarnaKosmaAccounts', {
          organizationNumber: overview.companyNumber,
        })
      );
    }
  };

  return (
    <Button
      onClick={startKlarnaFlow}
      variant="secondary"
      fullWidth={mobile}
      rightIcon="Klarna"
      isLoading={klarnaIsLoading}
      disabled={klarnaIsLoading}
    >
      {translate('OnboardingOffer.KlarnaKosmaButtonInAccountSelection')}
    </Button>
  );
};

export default KlarnaKosmaButton;
