import React, { createContext, useState } from 'react';
import {
  accountNumberValidator,
  beneficialOwnerPercentageValidator,
  clearingNumberValidator,
  digitValidator,
  emailValidator,
  emptyFieldValidator,
  emptyListValidator,
  fullNameValidator,
  percentageShareFormatValidator,
  percentageValidator,
  personalNumberValidator,
  turnoverValidator,
  zipCodeValidator,
} from '~/helpers/validators.helper';
import {
  validateLoanAmount,
  validateOrgNumber,
  validatePhoneNumber,
} from '@qred/shared-component-library/src/validators';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { CountryCode } from '~/enums';
import { removeNonDigits } from '~/helpers/formatters.helper';
import { normalizeOrgNumberHelper } from '~/helpers/normalizeOrgNumber.helper';

export enum FormStatus {
  IDLE = 100,
  SUBMITTED,
  SUBMITTING,
  COMPLETED,
}

interface ValidationErrors {
  [key: string]: boolean;
}

export interface SimpleEvent {
  target: {
    name: string;
    value:
      | string
      | number
      | any[]
      | readonly string[]
      | undefined
      | boolean
      | Date;
    dataset: DOMStringMap;
  };
}

export const ValidationContext = createContext({
  onChangeHOC: (event: SimpleEvent) => {},
  setFormStatus: (status: FormStatus) => {},
  removePropertyFromValidationErrors: (name: string) => {},
  addPropertyToValidationErrors: (name: string) => {},
  manuallyInvalidateForm: (isValid: boolean) => {},
  formStatus: FormStatus.IDLE,
  isFormValid: true,
  validationErrors: {} as ValidationErrors,
  isFormManuallyInvalidated: false,
});

ValidationContext.displayName = 'ValidationContext';

/**
 * All validators go here in order of importance for each type.
 * If a field has no validation to its name then no validation is run on that field.
 * It is important that translation ids match the validator mapper keys
 */
const validatorMapper = {
  Required: [emptyFieldValidator],
  Email: [emailValidator],
  LoanAmount: [
    (val: any = '', market: CountryCode) => {
      const value = removeNonDigits(val.toString());
      // When a RLC has an existing loan we need to validate the remaing loan amount.
      const isValid = validateLoanAmount(value, market);
      return !isValid;
    },
  ],
  OrgNumber: [
    (val: any, market: CountryCode) => {
      const isValidFormat = validateOrgNumber(
        normalizeOrgNumberHelper(val, market),
        market
      );
      return !isValidFormat;
    },
  ],
  Phone: [
    (val: any, market: CountryCode) => {
      const isValid = validatePhoneNumber(val, market);
      return !isValid;
    },
  ],
  ApplicationReasonOther: [emptyFieldValidator],
  AccountNumber: [emptyFieldValidator, accountNumberValidator],
  ClearingNumber: [emptyFieldValidator, clearingNumberValidator],
  FirstAndLastNameOwner: [emptyFieldValidator],
  FullName: [fullNameValidator],
  PersonalNumber: [emptyFieldValidator, personalNumberValidator],
  OwnerType: [emptyFieldValidator],
  LoanPurpose: [emptyFieldValidator],
  PercentageShare: [emptyFieldValidator, percentageValidator],
  PercentageShareFormat: [emptyFieldValidator, percentageShareFormatValidator],
  BeneficialOwnerPercentage: [
    emptyFieldValidator,
    beneficialOwnerPercentageValidator,
  ],
  CardPurpose: [emptyListValidator],
  TurnoverAmount: [turnoverValidator],
  Zip: [zipCodeValidator],
  HouseNumber: [emptyFieldValidator, digitValidator],
  HouseNumberAddition: [
    (value: string) => {
      const isValid = value !== 'Select';
      return !isValid;
    },
  ],
  DateOfBirth: [emptyFieldValidator],
};

export type ValidationType = keyof typeof validatorMapper;

const validateField = (event: SimpleEvent, market: CountryCode) => {
  const validationType = event.target.dataset.validationType as ValidationType;
  const { value } = event.target;
  const validators = validatorMapper[validationType];
  if (!validators) return false;

  return validators.some((validator) => validator(value, market));
};

export interface WithValidationProps {
  /**
   * If the input is "uncontrolled" this function should be called in each change handler with the event object
   *  passed as its only argument. For control component it is not needed because InputField effect takes care of that.
   */
  onChangeHOC: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFormStatus: (status: FormStatus) => void;
  isFormValid: boolean;
  formStatus: FormStatus;
}

/**
 * For this solution to work correctly names of the inputs should be unique across the wrapped component
 * @param WrappedComponent
 */
const withValidation = <P,>(
  WrappedComponent: React.FC<P & WithValidationProps>
) => {
  const Component = (props: P) => {
    const { market } = useSelector((state: RootState) => state.intl);

    const [errors, setErrors] = useState<ValidationErrors>({});
    const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE);
    const [isFormManuallyInvalidated, setIsFormManuallyInvalidated] = useState(
      false
    );

    // Derived state
    const isFormValid =
      !Object.values(errors).some((i: boolean) => i) &&
      !isFormManuallyInvalidated;

    const onChangeHOC = (event: SimpleEvent) => {
      const { validationType } = event.target.dataset;
      if (validationType) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [event.target.name]: validateField(event, market),
        }));
      }
    };

    const setFormStatus = (formStatus: FormStatus) => {
      setStatus(formStatus);
      if (formStatus === FormStatus.SUBMITTED) {
        // setTimeout will ensure that all components has added .has-error class to their element before we try to find them.
        setTimeout(() => {
          const errorElement = document.querySelector('.has-error');
          if (errorElement) {
            errorElement.scrollIntoView({
              behavior: 'smooth',
              inline: 'nearest',
              block: 'nearest',
            });
          }
        });
      }
    };

    /**
     * There are rare cases that a validation is run on an input field, but user might change their mind and change the
     * option they had selected before and consequently those field are no longer visible. This method is needed to remove
     * corresponing properties from the object otherwise the form remains invalid.
     * @param name
     */
    const removePropertyFromValidationErrors = (name: string) => {
      setErrors((prevErrors) => {
        const clonedErrors = { ...prevErrors };
        delete clonedErrors[name];
        return clonedErrors;
      });
    };

    const addPropertyToValidationErrors = (name: string) => {
      setErrors((prevErrors) => {
        const clonedErrors = { ...prevErrors, [name]: true };
        return clonedErrors;
      });
    };

    const manuallyInvalidateForm = (isValid: boolean) => {
      setIsFormManuallyInvalidated(isValid);
    };

    // TODO: Investigate if rendering WrappedComponent in a form element would mess up the css design
    return (
      <ValidationContext.Provider
        value={{
          onChangeHOC,
          setFormStatus,
          removePropertyFromValidationErrors,
          addPropertyToValidationErrors,
          manuallyInvalidateForm,
          formStatus: status,
          isFormValid,
          validationErrors: errors,
          isFormManuallyInvalidated,
        }}
      >
        <WrappedComponent
          {...props}
          onChangeHOC={onChangeHOC}
          setFormStatus={setFormStatus}
          formStatus={status}
          isFormValid={isFormValid}
          isFormManuallyInvalidated={isFormManuallyInvalidated}
        />
      </ValidationContext.Provider>
    );
  };

  return Component;
};

export default withValidation;
