import {
  GetCompanyLoanApplicationActiveStageResponseDto,
  GetCompanyLoansResponseDto,
} from '~/store/apis/dtos/companies/companies.dto';
import {
  formatValueWithLocalisedCurrency,
  formatValueWithLocalisedDate,
} from '@qred/fe-cp-formatting-helpers';
import { CountryCode } from '~/enums';
import { LoanOverview } from '~/models/companies/loans';
import { ApplicationStage } from '~/models/companies/application';
import { getCustomerStep } from '~/helpers/applications.helper';

export const getCompanyLoansMapped = (
  response: GetCompanyLoansResponseDto[],
  market: CountryCode
): LoanOverview[] =>
  response.map(
    ({ id, contractNumber, status, amount, term, startDate, closedDate }) => ({
      id,
      contractNumber,
      status,
      amount: formatValueWithLocalisedCurrency(amount, market),
      term,
      startDate: {
        label: formatValueWithLocalisedDate(startDate, market),
        value: new Date(startDate),
      },
      closedDate: {
        label: formatValueWithLocalisedDate(closedDate, market),
        value: new Date(closedDate),
      },
      isActive: ['Open_Active', 'Open_Late', 'Open_In_Collection'].includes(
        status
      ),
    })
  );

export const getCompanyLoanApplicationActiveStageMapped = (
  response: GetCompanyLoanApplicationActiveStageResponseDto,
  market: CountryCode
): ApplicationStage => {
  return {
    activeStep: getCustomerStep(response.status),
    applicationDate: formatValueWithLocalisedDate(
      response.applicationDate,
      market
    ),
    confirmDate: formatValueWithLocalisedDate(response.confirmDate, market),
    id: response.id,
    isAdditionalSigners: response.status === 'WAIT_SIGNER_ACCEPT',
    isConfirmed: response.status === 'FIN_CONFIRMED',
    isDeclined: response.status === 'FIN_REJECTED',
    isExpired: response.status === 'FIN_EXPIRED',
    numberOfCompletedSignatures: response.numberOfCompletedSignatures,
    numberOfSigners: response.numberOfSigners,
    offerDate: formatValueWithLocalisedDate(response.offerDate, market),
    rejectDate: formatValueWithLocalisedDate(response.rejectDate, market),
    uploadDocumentsStep: [
      CountryCode.SE,
      CountryCode.DK,
      CountryCode.NO,
    ].includes(market)
      ? response.amount >= 100000
      : response.amount >= 10000,
    uuid: response.uuid,
  };
};
