import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/store';
import {
  FileButton,
  Stack,
  Button,
  MaterialIcon,
  Body,
} from '@qred/components-library';
import { IOnboardingFile } from '~/interfaces/Onboarding';
import useTranslate from '~/hooks/useTranslate';
import Dropzone from 'react-dropzone';
import { DropZoneContainer } from '~/pages/Onboarding/components/UploadDocuments/components/UploadDocumentsModalBody.styled';
import { formatSize } from '~/helpers/formatters.helper';

interface IDocumentUpload {
  allowedFileExtensionsDisplayed: string[];
  maxFiles: number;
  maxSize: number;
  onUploadFiles: (uploadedFiles: File[] | IOnboardingFile[]) => void;
}

const DocumentUpload: React.FC<IDocumentUpload> = ({
  allowedFileExtensionsDisplayed,
  maxFiles,
  maxSize,
  onUploadFiles,
}) => {
  const {
    matchedMedia: { mobile, smallScreen },
  } = useSelector((state: RootState) => state);
  const t = useTranslate();

  return (
    <Stack spacing={'md'}>
      {mobile ? (
        <FileButton
          multiple
          onChange={onUploadFiles}
          accept={allowedFileExtensionsDisplayed.join(', ')}
        >
          {(props) => (
            <Button {...props} variant="secondary" leftIcon="upload">
              {t('Onboarding.Upload')}
            </Button>
          )}
        </FileButton>
      ) : (
        <Dropzone
          onDrop={onUploadFiles}
          accept={{ 'text/plain': allowedFileExtensionsDisplayed }}
        >
          {({ getRootProps, getInputProps }) => (
            <DropZoneContainer {...getRootProps()}>
              <input {...getInputProps()} />
              <Stack align="center">
                <MaterialIcon
                  src="upload_file"
                  color="secondary.600"
                  size="40"
                />
                <Stack spacing="xs">
                  <Body size="md">
                    {smallScreen
                      ? t('Onboarding.UploadFilesDropZoneEnabledClick')
                      : t('Onboarding.UploadFilesDropZoneEnabledDragAndClick')}
                  </Body>
                  <Body size="md">
                    {t('Onboarding.UploadFilesRules', {
                      maxFiles,
                      maxSize: formatSize(maxSize),
                    })}
                  </Body>
                </Stack>
              </Stack>
            </DropZoneContainer>
          )}
        </Dropzone>
      )}

      <Body size="md">
        {t('Onboarding.UploadFilesAcceptedFormatsText', {
          acceptedFormats: allowedFileExtensionsDisplayed.join(', '),
          maxFiles,
        })}
      </Body>
    </Stack>
  );
};

export default DocumentUpload;
