import React from 'react';
import {
  Banner,
  Body,
  Group,
  Label,
  Link,
  Loader,
  Stack,
} from '@qred/components-library';
import OTPVerificationInput from './AdditionalSignerOTPInput';
import { useDispatch, useSelector } from 'react-redux';
import { regenerateOTP } from '~/store/actions/additionalSignerActions';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { OTPErrorType } from '~/enums';
import useTranslate from '~/hooks/useTranslate';

const AdditionalSignerOTPInputSection = () => {
  const {
    intl: { market },
    additionalSigner: { apiStatus, otpError, timerCompleted },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const translate = useTranslate();

  const onClickResendCode = () => {
    dispatch(regenerateOTP(market));
  };

  return (
    <>
      <Stack spacing={'sm'}>
        <Label>{translate('AdditionalSigner.VerificationCode')}</Label>
        <OTPVerificationInput />
        {otpError === OTPErrorType.INVALID_OTP && (
          <Banner
            opened
            withCloseButton={false}
            state="critical"
            message={translate('AdditionalSigner.InvalidCodeSent')}
          />
        )}
      </Stack>
      <Group align="center" spacing="xs" mt={4}>
        <Body size="sm">
          {timerCompleted
            ? translate('AdditionalSigner.CodeExpiredShort')
            : translate('AdditionalSigner.NoCodeText')}
        </Body>

        <Link size="sm" onClick={onClickResendCode}>
          {apiStatus.regenerateOTP === ApiStatus.Started
            ? (translate('AdditionalSigner.CodeSentText') as string)
            : (translate('AdditionalSigner.Resend') as string)}
        </Link>

        {apiStatus.regenerateOTP === ApiStatus.Started && (
          <Loader size={'xs'} />
        )}
      </Group>
    </>
  );
};

export default AdditionalSignerOTPInputSection;
