import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  ArrangementProps,
  BaseArrangement,
} from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';

const ButtonBase = styled.button<{
  href?: string;
  disabled?: boolean;
  smallSize?: boolean;
  fullWidth?: boolean;
  target?: string;
  borderRadius?: number;
  wide?: boolean;
  textColor?: string;
}>`
  width: 100%;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '2'}em;
  border-style: solid;
  border-width: 0.125em;
  font-weight: 400;
  font-family: inherit;
  font-style: normal;
  padding: 0.85em ${(props) => (props.wide ? '3' : '1.75')}em;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  outline: 0;
  font-size: ${(props) => (props.smallSize ? '0.8' : '1')}em;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  @media (orientation: landscape) {
    min-width: 50%;
    width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')};
    margin: 0 auto;
    display: block;
  }

  &:focus:not([disabled]) {
    border: 0.125em solid ${theme.colors.primaryGreen};
  }

  transition: all 0.2s ease-in-out;
  svg {
    color: inherit;
    vertical-align: middle;
  }

  &:hover:not([disabled]) {
    border: 0.125em solid ${theme.colors.hoverGreen};
  }
`;

const FilledButton = styled(ButtonBase)`
  background-color: ${(props) =>
    props.disabled ? theme.colors.secondaryGray : theme.colors.primaryGreen};
  color: ${(props) =>
    props.disabled ? theme.colors.primaryGray : theme.colors.white};
  border-color: ${(props) =>
    props.disabled ? theme.colors.secondaryGray : theme.colors.primaryGreen};
  &:hover:not([disabled]) {
    background-color: ${theme.colors.hoverGreen};
  }
`;

const OutlinedButton = styled(ButtonBase)`
  background-color: ${(props) =>
    props.disabled ? theme.colors.primaryGray : 'transparent'};
  color: ${(props) =>
    props.disabled ? theme.colors.secondaryGray : theme.colors.secondary};
  border-color: ${(props) =>
    props.disabled ? theme.colors.secondaryGray : theme.colors.secondary};
`;

const NoBorderButton = styled(ButtonBase)`
  background-color: ${(props) =>
    props.disabled ? theme.colors.primaryGray : 'transparent'};
  border-color: transparent;
  border-radius: 0.5em;
  color: ${(props) => {
    if (props.disabled) {
      return theme.colors.secondaryGray;
    }
    return props.textColor || theme.colors.secondary;
  }};

  &:focus:not([disabled]) {
    border-color: transparent;
  }

  &:hover:not([disabled]) {
    border-color: transparent;
    color: ${theme.colors.secondaryHover};
  }
`;

const SecondaryButton = styled(ButtonBase)`
  background-color: ${(props) =>
    props.disabled ? `${theme.colors.secondary}40` : theme.colors.secondary};
  border-radius: 0.5em;
  border-color: ${(props) =>
    props.disabled ? 'transparent' : theme.colors.secondary};

  &:hover:not([disabled]) {
    border-color: transparent;
    background-color: #2c5d60;
  }
  &:focus:not([disabled]) {
    border-color: transparent;
    background-color: #2c5d60;
  }
`;

const SecondaryInvertedButton = styled(ButtonBase)`
  background-color: transparent;
  border-radius: 0.5em;
  border-color: ${(props) => props.textColor || theme.colors.secondary};
  color: ${(props) => props.textColor || theme.colors.secondary};

  ${(props) =>
    props.disabled &&
    css`
      border-color: ${theme.colors.newSecondaryGray};
      color: ${theme.colors.newSecondaryGray};
    `}

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: ${theme.colors.secondaryHover};
    border-color: ${theme.colors.secondaryHover};
  }
`;

const TertiaryButton = styled(ButtonBase)`
  background-color: ${theme.colors.document};
  border-radius: 0.5em;
  border-color: transparent;
  font-weight: 600;
  color: ${(props) =>
    props.disabled ? theme.colors.newSecondaryGray : theme.colors.secondary};

  &:hover:not([disabled]) {
    border-color: transparent;
    background-color: ${theme.colors.secondaryLightGray};
  }
`;

const TextButton = styled(ButtonBase)`
  border: none;
  background-color: transparent;
  padding: 0;
  color: ${theme.colors.accent};
  line-height: 1.3em;
  width: unset;

  @media (orientation: landscape) {
    margin: 0;
    min-width: unset;
  }

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    color: ${theme.colors.secondary};
    border: none;
  }
`;

export enum ButtonType {
  Filled,
  Outlined,
  NoBorder,
  Secondary,
  SecondaryInverted,
  Tertiary,
  Text,
}

export interface ButtonProps {
  type: ButtonType;
  link?: string;
  openInNewTab?: boolean;
  smallSize?: boolean;
  disabled?: boolean;
  fullWidth?: boolean; // used for buttons that we want to take up the whole width of their parent even in landscape mode
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  id?: string;
  borderRadius?: number;
  wide?: boolean;
  dataCy?: string;
  textColor?: string;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps & ArrangementProps> = (props) => {
  const ref = useRef<HTMLButtonElement>(null);

  // Safari does not give button focus on click, this is necessary to have consistent behaviour on all browsers
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.disabled) {
      e.preventDefault();
      return;
    }
    if (ref && ref.current) {
      ref.current.focus();
    }
    if (props.onClick) props.onClick(e);
  };

  const buttonComponents = {
    [ButtonType.Filled]: FilledButton,
    [ButtonType.Outlined]: OutlinedButton,
    [ButtonType.NoBorder]: NoBorderButton,
    [ButtonType.Secondary]: SecondaryButton,
    [ButtonType.SecondaryInverted]: SecondaryInvertedButton,
    [ButtonType.Tertiary]: TertiaryButton,
    [ButtonType.Text]: TextButton,
  };

  const ButtonComponent = buttonComponents[props.type];

  return (
    <BaseArrangement mt={props.mt} mb={props.mb} mr={props.mr} ml={props.ml}>
      <ButtonComponent
        data-cy={props.dataCy}
        as={props.link ? 'a' : 'button'}
        target={props.openInNewTab ? '_blank' : '_self'}
        href={props.link}
        onClick={onClick}
        disabled={props.disabled}
        smallSize={props.smallSize}
        fullWidth={props.fullWidth}
        borderRadius={props.borderRadius}
        textColor={props.textColor}
        wide={props.wide}
        id={props.id}
        ref={ref}
      >
        {props.children}
      </ButtonComponent>
    </BaseArrangement>
  );
};

export default Button;
