import { StepConfigItem } from '~/Interfaces';
import { CountryCode, FlowTypes } from '~/enums';
import CompanySelector from './CompanySelector/CompanySelector';
import CompanySelectorFooter from './CompanySelector/components/CompanySelectorFooter';
import CompanySelectorHeader from './CompanySelector/components/CompanySelectorHeader';
import ConnectToBank from './ConnectToBank/ConnectToBank';
import ConnectToBankFooter from './ConnectToBank/components/ConnectToBankFooter';
import ConnectToBankHeader from './ConnectToBank/components/ConnectToBankHeader';
import { UploadDocuments } from './UploadDocuments/UploadDocuments';
import { UploadDocumentsFooter } from './UploadDocuments/components/UploadDocumentsFooter';
import { UploadDocumentsHeader } from './UploadDocuments/components/UploadDocumentsHeader';
import ConnectToEskat from './ConnectToEskat/ConnectToEskat';
import ConnectToEskatFooter from './ConnectToEskat/components/ConnectToEskatFooter';
import FinancialInformation from './FinancialInformation/FinancialInformation';
import FinancialInformationHeader from './FinancialInformation/components/FinancialInformationHeader';
import LoanPurpose from './LoanPurpose/LoanPurpose';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import { PersonalInformationDE } from './PersonalInformation/PersonalInformationDE';
import PersonalInformationHeader from './PersonalInformation/components/PersonalInformationHeader';
import SelectGuarantor from './SelectGuarantor/SelectGuarantor';
import SelectGuarantorHeader from './SelectGuarantor/components/SelectGuarantorHeader';
import SelectGuarantorNetherlandsFooter from './SelectGuarantor/components/SelectGuarantorNetherlandsFooter';
import BankConnection from './BankConnection/BankConnectionStep';
import BankConnectionHeader from './BankConnection/components/BankConnectionStepHeader';
import { BankConnectionFooter } from './BankConnection/components/BankConnectionStepFooter';
import { Checkpoint } from './Checkpoint/Checkpoint';
import { CheckpointFooter } from './Checkpoint/CheckpointFooter';
import { ApplicationOverview } from './ApplicationOverview/ApplicationOverview';
import { ApplicationOverviewFooter } from './ApplicationOverview/ApplicationOverviewFooter';
import { marketHasCheckpointStep } from '~/constants/markets';

const CompanySelectorStep: StepConfigItem = {
  stepContent: [CompanySelector],
  customHeader: CompanySelectorHeader,
  customFooter: CompanySelectorFooter,
  name: 'company_selector_step',
};

const PersonalInformationStep: StepConfigItem = {
  stepContent: [PersonalInformation],
  customHeader: PersonalInformationHeader,
  name: 'personal_information_step',
};

const PersonalInformationStepDE: StepConfigItem = {
  stepContent: [PersonalInformationDE],
  customHeader: PersonalInformationHeader,
  name: 'personal_information_step',
};

const LoanPurposeStep: StepConfigItem = {
  stepContent: [LoanPurpose],
  header: {
    title: 'Onboarding.SelectPurposeTitle',
    description: 'Onboarding.SelectPurposeDescription',
  },
  name: 'loan_purpose_step',
};

const CheckpointStep: StepConfigItem = {
  stepContent: [Checkpoint],
  customFooter: CheckpointFooter,
  stickyFooter: false,
  fullHeight: false,
  name: 'checkpoint_step',
  hideInProgressStepper: true,
};

const ApplicationOverviewStep: StepConfigItem = {
  header: {
    title: 'Onboarding.ApplicationOverviewTitle',
    description: 'Onboarding.ApplicationOverviewDescription',
  },
  stepContent: [ApplicationOverview],
  customFooter: ApplicationOverviewFooter,
  name: 'application_overview_step',
  hideInProgressStepper: true,
};

const SelectGuarantorStep: StepConfigItem = {
  stepContent: [SelectGuarantor],
  customHeader: SelectGuarantorHeader,
  name: 'guarantor_selector_step',
};

const SelectGuarantorNetherlandsStep: StepConfigItem = {
  stepContent: [SelectGuarantor],
  customHeader: SelectGuarantorHeader,
  customFooter: SelectGuarantorNetherlandsFooter,
  name: 'guarantor_selector_step',
};

const KlarnaKosmaBankIntegrationStep: StepConfigItem = {
  stepContent: [ConnectToBank],
  customHeader: ConnectToBankHeader,
  customFooter: ConnectToBankFooter,
  name: 'connect_to_bank_through_klarna_kosma_step',
};

const BankConnectionStep: StepConfigItem = {
  stepContent: [BankConnection],
  customHeader: BankConnectionHeader,
  customFooter: BankConnectionFooter,
  name: 'connect_to_bank_step',
};

const FinancialInformationStep: StepConfigItem = {
  stepContent: [FinancialInformation],
  customHeader: FinancialInformationHeader,
  name: 'financial_information_step',
};

const UploadDocumentsStep: StepConfigItem = {
  stepContent: [UploadDocuments],
  customHeader: UploadDocumentsHeader,
  customFooter: UploadDocumentsFooter,
  name: 'upload_documents_step',
};

const EskatStep: StepConfigItem = {
  stepContent: [ConnectToEskat],
  customFooter: ConnectToEskatFooter,
  header: {
    title: 'Onboarding.TaxReport',
    description: 'Onboarding.EskatText',
  },
  name: 'connect_to_eskat_step',
};

export const stepConfigPerMarketFlowType: Record<
  CountryCode,
  Record<FlowTypes, StepConfigItem[]>
> = {
  [CountryCode.SE]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.SE]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.SE]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
  },
  [CountryCode.FI]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.FI]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.FI]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
  },
  [CountryCode.NO]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.NO]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.NO]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
  },
  [CountryCode.DK]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.DK]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      EskatStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.DK]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      EskatStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
  },
  [CountryCode.BE]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.BE]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.BE]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
  },
  [CountryCode.NL]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.NL]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorNetherlandsStep,
      FinancialInformationStep,
      BankConnectionStep,
      UploadDocumentsStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      ...(marketHasCheckpointStep[CountryCode.NL]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorNetherlandsStep,
      FinancialInformationStep,
      BankConnectionStep,
      UploadDocumentsStep,
    ],
  },
  [CountryCode.DE]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStepDE,
      ...(marketHasCheckpointStep[CountryCode.DE]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStepDE,
      ...(marketHasCheckpointStep[CountryCode.DE]
        ? [CheckpointStep, ApplicationOverviewStep]
        : []),
      SelectGuarantorStep,
      FinancialInformationStep,
      BankConnectionStep,
    ],
  },
};
