import { CampaignType, CountryCode } from '~/enums';
import { formatAmount } from '~/helpers/formatters.helper';
import Table, {
  CellData,
} from '~/pages/OnboardingOffer/components/PaymentCalculatorTable';
import React, { useEffect, useMemo, useState } from 'react';
import useTranslate, { useCampaignTypeTranslate } from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import Select from '~/components/shared/Select/Select';
import useDateFormatter from '~/hooks/useDateFormatter';
import { Body, Container, H6, Stack } from '@qred/components-library';
import { SvgSrc } from '~/components/shared/Icon/Icon';

const PaymentCalculatorModal: React.FC<{
  amount: number;
  loanPeriod: number;
  monthlyFee: number;
  campaignType: CampaignType | null;
  campaignDiscount?: number | null;
  campaignPrincipalFreeMonths?: number | null;
  campaignInterestRateDiscount?: number | null;
}> = (props) => {
  const [loanPeriod, setLoanPeriod] = useState(1);
  const { market } = useSelector((state: RootState) => state.intl);
  const formatDate = useDateFormatter();
  const translate = useTranslate();
  const [tableData, footers, savedAmount] = useMemo(() => {
    const _tableData: Array<Array<CellData>> = [];
    const defaultAmortization = Math.round(props.amount / props.loanPeriod);
    const defaultMonthlyFee = props.monthlyFee;
    let totalMonthlyFee = 0;
    let totalToPay = 0;
    let totalSavedAmount = 0;
    let totalAmortization = 0;

    for (let index = 1; index <= props.loanPeriod; index += 1) {
      // These values are recalculated for each row based on the selected loan period and campaign type
      let amortization = defaultAmortization;
      let monthlyFee = defaultMonthlyFee;
      let monthlySavedAmount = 0;
      let commissionDiscountInfo;

      // Step 1: applying campaign conditions on amortization and monthlyFee values
      if (props.campaignType) {
        switch (props.campaignType) {
          case CampaignType.FirstCommissionFree:
            if (index === 1) {
              monthlyFee = 0;
            }
            break;
          case CampaignType.FirstMonthFree:
            if (index === 1) {
              amortization = 0;
              monthlyFee = 0;
            } else {
              amortization = Math.round(props.amount / (props.loanPeriod - 1));
            }
            break;
          case CampaignType.CustomCommissionDiscount:
            if (index === 1 && props.campaignDiscount) {
              monthlyFee = Math.max(monthlyFee - props.campaignDiscount, 0); // In case discount number is bigger than monthly fee
            }
            break;
          case CampaignType.AmortizationFreeMonths:
            if (props.campaignPrincipalFreeMonths) {
              if (index <= props.campaignPrincipalFreeMonths) {
                amortization = 0;
              } else {
                amortization = Math.round(
                  props.amount /
                    (props.loanPeriod - props.campaignPrincipalFreeMonths)
                );
              }
            }
            break;
          case CampaignType.MonthlyInterestRateDiscount:
            if (
              props.campaignPrincipalFreeMonths &&
              props.campaignInterestRateDiscount
            ) {
              if (index <= props.campaignPrincipalFreeMonths) {
                const discountPercentage =
                  props.campaignInterestRateDiscount / 100;
                const discountAmount = monthlyFee * discountPercentage;
                monthlyFee -= discountAmount;
                if (index <= loanPeriod)
                  commissionDiscountInfo = `(-${props.campaignInterestRateDiscount}%)`;
              }
            }
            break;
          default:
            break;
        }
      }

      // Step 2: early payment conditions on amortization and monthlyFee values
      // This step takes precedence over step 1
      if (index === loanPeriod) {
        amortization = props.amount - totalAmortization;
      } else if (index > loanPeriod) {
        amortization = 0;
        monthlySavedAmount = monthlyFee;
        monthlyFee = 0;
      }

      const toPay = amortization + monthlyFee;

      // Must be a new Date object every time otherwise setting month on the same object will give incorrect results if month is bigger than 12
      const paymentDate = new Date();
      paymentDate.setMonth(new Date().getMonth() + index);

      const rowData: Array<CellData> = [
        { value: index },
        { value: formatDate(paymentDate) },
        { value: formatAmount(amortization) },
        {
          value: commissionDiscountInfo
            ? `${formatAmount(monthlyFee)} ${commissionDiscountInfo}`
            : formatAmount(monthlyFee),
        },
        { value: formatAmount(toPay), isFullWidthCell: true },
      ];

      totalToPay += toPay;
      totalMonthlyFee += monthlyFee;
      totalSavedAmount += monthlySavedAmount;
      totalAmortization += amortization;

      _tableData.push(rowData);
    }

    const _footers = [
      { value: '', isEmptyCell: true },
      { value: translate('Total') as string, isTotalLabel: true },
      { value: formatAmount(props.amount) },
      { value: formatAmount(totalMonthlyFee) },
      { value: formatAmount(totalToPay) },
    ];

    return [_tableData, _footers, totalSavedAmount];
  }, [props.amount, props.monthlyFee, props.campaignType, loanPeriod]);

  const generateLoanPeriodDropdownValues = (period: number) => {
    const values = [];
    for (let index = 1; index <= period; index += 1) {
      values.push({
        label: translate('PaymentCalculator.DropdownItem', {
          itemCount: index,
        }) as string,
        value: index,
      });
    }

    return values;
  };

  const loanPeriodOptions = useMemo(() => {
    const values = [];
    for (let index = 1; index <= props.loanPeriod; index += 1) {
      values.push({
        label: translate('PaymentCalculator.DropdownItem', {
          itemCount: index,
        }) as string,
        value: index,
      });
    }

    return values;
  }, [props.loanPeriod]);

  const headers = [
    { value: translate('Installment') as string, iconSrc: SvgSrc.ChartPie },
    { value: translate('DueDate') as string, iconSrc: SvgSrc.Clock },
    { value: translate('Amortization') as string, iconSrc: SvgSrc.ChartArea },
    { value: translate('MonthlyFee') as string, iconSrc: SvgSrc.Coins },
    { value: translate('ToPay') as string, iconSrc: SvgSrc.MoneyBillAlt },
  ];

  const campaignTypeTranslate = useCampaignTypeTranslate(
    props.campaignType,
    props.campaignPrincipalFreeMonths,
    props.campaignInterestRateDiscount
  );

  useEffect(() => {
    setLoanPeriod(props.loanPeriod);
  }, [props.loanPeriod]);

  return (
    <Stack spacing="sm" mt={24}>
      {props.campaignType && (
        <H6>
          {translate('PaymentCalculator.CampaignApplied')} -{' '}
          {campaignTypeTranslate}
        </H6>
      )}
      {market !== CountryCode.NO && (
        <Body size="lg">
          {translate('PaymentCalculator.HeaderExplanation')}
        </Body>
      )}
      <Stack spacing="sm">
        <Container m={0} maw={'22em'}>
          <Select
            name="LoanPeriod"
            options={loanPeriodOptions}
            onChange={(v) => setLoanPeriod(Number(v?.value))}
            defaultValue={loanPeriodOptions[loanPeriodOptions.length - 1]}
          />
        </Container>
        {loanPeriod < props.loanPeriod && (
          <Body size="lg">
            {translate('PaymentCalculator.YouSave', {
              amount: formatAmount(savedAmount),
            })}
          </Body>
        )}
      </Stack>
      <Stack spacing={'sm'}>
        <Table center data={tableData} headers={headers} footers={footers} />
        <Body size="sm">
          {translate('PaymentCalculator.FooterExplanation')}
        </Body>
      </Stack>
    </Stack>
  );
};

export default PaymentCalculatorModal;
