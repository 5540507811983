import { ColorTheme, styledPolymorphic } from '@qred/components-library';
import styled from 'styled-components';

export const Container = styled('div')`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 16px;
  row-gap: 5px;
`;

export const IconWrapper = styled('div')`
  display: grid;
  grid-template-rows: 30px minmax(18px, 1fr);
  justify-items: center;
`;

export const ItemWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const Line = styledPolymorphic<
  'div',
  {
    $isCompleted?: boolean;
  }
>()(
  (_styled) => _styled`
    width: 1px;
    background: ${(props) =>
      props.$isCompleted
        ? props.theme.fn.themeColor('Main/Primary/800')
        : props.theme.fn.themeColor('Main/Grey/Alpha/OnLight/40%')};
`
);

export const Bullet = styledPolymorphic<
  'div',
  {
    bg: ColorTheme;
  }
>()(
  (_styled) => _styled`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    align-self: center;
    margin-top: -4px;
    background: ${(props) => props.bg};
  `
);
