import React, { useState } from 'react';
import CalendarHeader from '~/components/shared/DatePicker/CalendarHeader';
import { getDecadeRange } from '~/helpers/utils';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { theme } from '~/styles/themes';

export function formatYear(year: number, format: string) {
  return dayjs(new Date(year, 1, 1)).format(format);
}

interface YearPickerProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
  value: number;

  onChange(value: number): void;

  minYear?: number;
  maxYear?: number;
  nextDecadeLabel?: string;
  previousDecadeLabel?: string;
  preventFocus?: boolean;
  yearLabelFormat?: string;
}

const YearPickerControls = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const MonthPickerControl = styled.button`
  flex: 0 0 25%;
  height: 2.5em;
  width: 2em;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-size: 0.7em;
  border-radius: 0.2em;
  color: ${theme.colors.dark};

  &:hover {
    background-color: ${theme.colors.backdrop};
  }

  &:disabled {
    pointer-events: none;
    color: ${theme.colors.secondaryGray};
  }

  &[data-active] {
    background-color: ${theme.colors.secondary};
    color: white;
  }
`;

const YearPicker: React.FC<YearPickerProps> = ({
  value,
  onChange,
  minYear,
  maxYear,
  nextDecadeLabel,
  previousDecadeLabel,
  preventFocus,
  yearLabelFormat = 'YYYY',
  ...others
}) => {
  const [decade, setDecade] = useState(value);
  const range = getDecadeRange(decade);

  const years = range.map((year) => (
    <MonthPickerControl
      key={year}
      onClick={() => onChange(year)}
      disabled={year < minYear! || year > maxYear!}
      data-active={year === value || undefined}
      onMouseDown={(event) => preventFocus && event.preventDefault()}
    >
      {formatYear(year, yearLabelFormat)}
    </MonthPickerControl>
  ));

  return (
    <div {...others}>
      <CalendarHeader
        label={`${formatYear(range[0], yearLabelFormat)} – ${formatYear(
          range[range.length - 1],
          yearLabelFormat
        )}`}
        hasPrevious={typeof minYear === 'number' ? minYear < range[0] : true}
        hasNext={
          typeof maxYear === 'number' ? maxYear > range[range.length - 1] : true
        }
        onNext={() => setDecade((current) => current + 10)}
        onPrevious={() => setDecade((current) => current - 10)}
        nextLevelDisabled
        nextLabel={nextDecadeLabel}
        previousLabel={previousDecadeLabel}
        preventFocus={preventFocus}
      />
      <YearPickerControls data-cy="date_picker_year">
        {years}
      </YearPickerControls>
    </div>
  );
};

export default YearPicker;
