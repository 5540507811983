import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { StepConfigItem } from '~/Interfaces';
import {
  ApplicationInfoLevel,
  ClientType,
  CountryCode,
  FlowTypes,
} from '~/enums';
import { stepConfigPerMarketFlowType } from '~/pages/Onboarding/components/stepConfigs';
import { RootState } from '../types/sharedTypes';
import { posthog } from 'posthog-js';
import { applicationCanHaveGuarantors } from '~/helpers/onboarding.helper';
import { marketHasCheckpointStep } from '~/constants/markets';

export const selectStepConfig = createSelector(
  [
    (state: RootState) => state.onboardingApplication.flowType,
    (state: RootState) => state.onboardingApplication.form.clientType,
    (state: RootState) => state.onboardingApplication.form.bankIntegration,
    (state: RootState) => state.onboardingApplication.form.bankConnected,
    (state: RootState) => state.intl.market,
    (state: RootState) => state.onboardingApplication.form.companyTypeCategory,
    (state: RootState) => state.onboardingApplication.form.guarantors,
    (state: RootState) => state.onboardingApplication.apiStatus.guarantors,
    (state: RootState) =>
      state.onboardingApplication.form.choseToBoostApplication,
    (state: RootState) => state.onboardingApplication.infoLevel,
  ],
  (
    flowType,
    clientType,
    bankIntegration,
    bankConnected,
    market,
    companyTypeCategory,
    guarantors,
    guarantorsApiStatus,
    choseToBoostApplication,
    infoLevel
  ) => {
    console.group('Application flow');

    const isKlarnaKosmaDead = Boolean(
      posthog.isFeatureEnabled('Onboarding_IsKlarnaKosmaDead')
    );
    const marketHasEnableBankingFeature = Boolean(
      posthog.isFeatureEnabled('Onboarding_MarketHasEnableBankingFeature')
    );
    console.log('isKlarnaKosmaDead', isKlarnaKosmaDead);
    console.log('marketHasEnableBankingFeature', marketHasEnableBankingFeature);

    const stepConfig = stepConfigPerMarketFlowType[market]?.[flowType]?.filter(
      (step: StepConfigItem) => {
        let hideStep = false;

        switch (step.name) {
          case 'company_selector_step':
          case 'loan_purpose_step':
            hideStep =
              marketHasCheckpointStep[market] &&
              infoLevel !== ApplicationInfoLevel.PRE_BASIC;
            break;
          case 'personal_information_step':
            hideStep =
              (clientType === ClientType.RLC &&
                flowType === FlowTypes.Authenticated &&
                market !== CountryCode.NL) ||
              (marketHasCheckpointStep[market] &&
                infoLevel !== ApplicationInfoLevel.PRE_BASIC);
            break;
          case 'application_overview_step':
            hideStep = Boolean(choseToBoostApplication);
            break;
          case 'guarantor_selector_step':
            hideStep =
              !applicationCanHaveGuarantors(
                market,
                companyTypeCategory,
                guarantors,
                guarantorsApiStatus
              ) ||
              (marketHasCheckpointStep[market] && !choseToBoostApplication);
            break;
          case 'connect_to_eskat_step':
            hideStep =
              marketHasCheckpointStep[market] && !choseToBoostApplication;
            break;
          case 'connect_to_bank_through_klarna_kosma_step': {
            const successfullyConnectedToBankThroughKlarnaKosma = Boolean(
              bankIntegration
            );
            hideStep =
              isKlarnaKosmaDead ||
              marketHasEnableBankingFeature ||
              successfullyConnectedToBankThroughKlarnaKosma ||
              (marketHasCheckpointStep[market] && !choseToBoostApplication);
            console.log(
              'connect_to_bank_through_klarna_kosma_step is shown:',
              !hideStep
            );
            break;
          }
          case 'upload_documents_step':
            hideStep =
              Boolean(bankConnected) ||
              (marketHasCheckpointStep[market] && !choseToBoostApplication);
            break;
          case 'financial_information_step':
            hideStep =
              marketHasCheckpointStep[market] && !choseToBoostApplication;
            break;
          case 'connect_to_bank_step':
            hideStep =
              !marketHasEnableBankingFeature ||
              (marketHasCheckpointStep[market] && !choseToBoostApplication);
            console.log('connect_to_bank_step is shown:', !hideStep);
            break;
          default:
            break;
        }

        return !hideStep;
      }
    );
    console.groupEnd();
    return stepConfig || [];
  }
);

export const useLoanOnboardingApplicationSelectors = () => ({
  stepConfig: useSelector(selectStepConfig),
});
