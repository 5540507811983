import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body, H5, H7, Label } from '@qred/components-library';
import { numberOfBusinessDaysFromToday } from '~/helpers/getNextWorkingDay.helper';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import Group from '~/components/shared/Layout/Group';
import Stack from '~/components/shared/Layout/Stack';
import Button, { ButtonType } from '~/components/shared/Button/button';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import PaymentCalculatorModal from '~/pages/OnboardingOffer/components/PaymentCalculator/PaymentCalculatorModal';
import useModals from '~/hooks/useModals';
import Divider from '~/components/shared/Divider/Divider';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import useDateFormatter from '~/hooks/useDateFormatter';
import { useOnboardingOfferSelectors } from '~/store/selectors/onboardingOffer.selector';
import PersonalLiability from '../PersonalLiability/PersonalLiability';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { BottomContent, SummaryHeader } from './ContractSummary.styled';
import { useCurrencyFormatter } from '~/hooks/formatter/useCurrencyFormatter';

const ContractSummary = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const modals = useModals();

  const formatDate = useDateFormatter();
  const currencyFormatter = useCurrencyFormatter();

  const {
    onboardingOffer: { form, overview },
    intl: { market },
    matchedMedia,
  } = useSelector((state: RootState) => state);

  const {
    monthlyAmortization,
    monthlyFee,
    monthlyPayment,
    loanAmount,
  } = useOnboardingOfferSelectors();

  const payoutDate = new Date(numberOfBusinessDaysFromToday(market, 1));

  const handleOpenPaymentScheduleModal = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'loan_offer_payment_plan_open',
        stepActionNumber: 0.1,
      })
    );

    modals.openModal({
      header: <H7>{translate('PaymentCalculator.PaymentSchedule')}</H7>,
      children: (
        <PaymentCalculatorModal
          amount={loanAmount}
          loanPeriod={form.loanPeriod}
          monthlyFee={monthlyFee}
          campaignType={overview.campaignType}
          campaignDiscount={overview.campaignDiscount}
          campaignPrincipalFreeMonths={overview.campaignPrincipalFreeMonths}
          campaignInterestRateDiscount={overview.campaignInterestRateDiscount}
        />
      ),
      footer: (
        <Button type={ButtonType.Outlined} onClick={modals.closeAll}>
          {translate('Ok')}
        </Button>
      ),
      fullScreen: matchedMedia.smallScreenPortrait,
      fixedHeaderAndFooter: matchedMedia.smallScreenPortrait,
      filledBackground: true,
      size: 'xl',
    });
  };

  return (
    <Stack spacing="lg" grow>
      <SummaryHeader>
        <Stack align="center" spacing="md">
          <Stack spacing="sm">
            <Label size="sm">{translate('OnboardingOffer.QredPaysYou')}</Label>
            <H5>{currencyFormatter.format(form.payoutAmount)}</H5>
          </Stack>
          <Body size="md">
            {formatDate(payoutDate, {
              month: 'long',
              day: '2-digit',
              year: 'numeric',
            })}
          </Body>
        </Stack>
      </SummaryHeader>

      <Stack justify="space-between">
        {!!overview.currentLoanAmountLeft && (
          <Stack spacing="xs">
            <Group justify="space-between">
              <Body size="lg">{translate('TotalLoan')}</Body>
              <Body size="lg">{currencyFormatter.format(loanAmount)}</Body>
            </Group>
            <Group justify="space-between">
              <Body size="lg">{translate('OnboardingOffer.LoanTerms')}</Body>
              <Body size="lg">
                {`${form.loanPeriod} ${translate('Months')}`}
              </Body>
            </Group>
          </Stack>
        )}

        <Stack spacing="sm">
          <Stack spacing="xs">
            <Group justify="space-between">
              <Body size="lg">{translate('MonthlyAmortization')}</Body>
              <Body size="lg">
                {currencyFormatter.format(monthlyAmortization)}
              </Body>
            </Group>
            <Group justify="space-between">
              <Body size="lg">{translate('MonthlyFee')}</Body>
              <Body size="lg">{currencyFormatter.format(monthlyFee)}</Body>
            </Group>
          </Stack>
          <Group justify="space-between">
            <H7>{translate('OnboardingOffer.YouPayQred')}</H7>
            <H7>{currencyFormatter.format(monthlyPayment)}</H7>
          </Group>
        </Stack>
      </Stack>
      <Stack>
        <Button
          type={ButtonType.Tertiary}
          onClick={handleOpenPaymentScheduleModal}
        >
          <Group align="center" justify="center" spacing="sm">
            <Icon src={SvgSrc.CalendarDay} size={ComponentSize.Small} />
            {translate('ViewPaymentSchedule')}
          </Group>
        </Button>
        <Divider />
      </Stack>

      <BottomContent spacing="sm">
        <PersonalLiability />
        <TermsAndConditions />
      </BottomContent>
    </Stack>
  );
};

export default ContractSummary;
