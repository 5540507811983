import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { FlowTypes } from '~/enums';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { formatNameHelper } from '~/helpers/formatName.helper';

const PersonalInformationHeader = () => {
  const {
    onboardingApplication: { form, flowType },
  } = useSelector((state: RootState) => state);
  const t = useTranslate();

  const title = t('Onboarding.PersonalInformationTitle') as string;

  const description = useMemo(() => {
    let translationKey = '';
    let values: {} = {};

    if (flowType === FlowTypes.Authenticated && form.applicant.fullName) {
      translationKey = 'Onboarding.PersonalInformationDescriptionWithName';
      values = { name: formatNameHelper(form.applicant.fullName) };
    } else {
      translationKey = 'Onboarding.PersonalInformationDescription';
    }

    return t(translationKey, values) as string;
  }, [flowType, form.applicant.fullName]);

  return (
    <StepHeader
      title={title}
      description={description}
      shouldTranslate={false}
    />
  );
};

export default PersonalInformationHeader;
