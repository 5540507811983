import React from 'react';
import styled from 'styled-components';
import { Size, theme } from '~/styles/themes';

interface StackProps {
  align?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyContent'];
  spacing?: Size;
  grow?: boolean;
  alignContent?: React.CSSProperties['alignContent'];
  wrap?: React.CSSProperties['flexWrap'];
}

const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align ?? 'stretch'};
  justify-content: ${(props) => props.justify ?? 'top'};
  gap: ${(props) => theme.spacing[props.spacing ?? 'md']};
  flex-grow: ${(props) => (props.grow ? 1 : 0)};
  align-content: ${(props) => props.alignContent ?? 'initial'};
  flex-wrap: ${(props) => props.wrap ?? 'initial'};
`;

export default Stack;
