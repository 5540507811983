import { Body, H7 } from '@qred/components-library';
import React from 'react';
import {
  CustomerReviewItemWrapper,
  Img,
} from './CustomerReviewsSection.styled';

interface CustomerReviewItemProps {
  title: string;
  text: string;
  imgSrc: string;
}

const CustomerReviewItem = ({
  title,
  text,
  imgSrc,
}: CustomerReviewItemProps) => {
  const onImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = 'default-image.webp';
  };

  return (
    <CustomerReviewItemWrapper>
      <Img src={imgSrc} onError={onImageError} />
      <H7>{title}</H7>
      <Body size="lg">“{text}“</Body>
    </CustomerReviewItemWrapper>
  );
};

export default CustomerReviewItem;
