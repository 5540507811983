import React, { useEffect, useRef } from 'react';
import { StepConfigItem } from '~/Interfaces';
import { StepContent } from '../Stepper.styled';
import StepHeader from './StepHeader';

interface StepProps {
  currentStepConfig: StepConfigItem;
  lockStep?: boolean;
  footerHeight: number;
  currentStep: number;
  extraStepContent?: React.ReactNode;
}

const Step = ({
  currentStepConfig,
  lockStep,
  currentStep,
  footerHeight,
  extraStepContent,
}: StepProps) => {
  const stepContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (stepContentRef) {
      stepContentRef.current?.scrollTo(0, 0);
    }
  }, [currentStep, stepContentRef]);

  return (
    <>
      <StepContent
        $lockStep={lockStep}
        ref={stepContentRef}
        $footerHeight={footerHeight}
        data-cy="step_content"
      >
        {currentStepConfig.customHeader ? (
          <currentStepConfig.customHeader />
        ) : (
          <StepHeader
            title={currentStepConfig.header?.title}
            description={currentStepConfig.header?.description}
            overline={currentStepConfig.header?.overline}
          />
        )}

        {currentStepConfig.stepContent.map((Component: React.FC) => (
          <Component key={Component.name} />
        ))}
        {extraStepContent}
      </StepContent>
    </>
  );
};

export default Step;
