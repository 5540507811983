import axios from 'axios';
import { myQredApi } from '../../_baseApi/myQredApi.base.api';
import { downloadFile, generatePdfHelper } from '~/helpers/generatePdf.helper';
import {
  GetActiveLoanRequestDto,
  GetActiveLoanResponseDto,
  GetDownloadInvoicesRequestDto,
  GetDownloadInvoicesResponseDto,
  GetLoanAgreementRequestDto,
  GetLoanAgreementResponseDto,
  GetLoanCurrentInvoiceRequestDto,
  GetLoanDocumentDownloadRequestDto,
  GetLoanInvoicesRequestDto,
  GetLoanInvoicesResponseDto,
  GetLoanStatementRequestDto,
  GetLoanStatementResponseDto,
  GetLoanTerminationLetterRequestDto,
  GetLoanTerminationLetterResponseDto,
  PatchPaymentMethodRequestDto,
  PostAmoFreeMonthsRequestDto,
} from '~/store/apis/dtos/loan/loans.dto';
import { accessToken } from '~/store/apis/utils/accessToken';
import { generateParams } from '~/store/apis/utils/generateParams';
import { PaginationResponse } from '~/store/apis/types/Pagination';
import {
  getLoanActiveMapped,
  getLoanCurrentInvoiceMapped,
  getLoanInvoicesMapped,
  getLoanStatementMapped,
} from '~/store/apis/endpoints/myQredApi/loan/loans.mapper';
import { Invoice } from '~/models/loans/invoice';
import { Statement } from '~/models/loans/statement';
import { GetCompanyLoansResponseDto } from '~/store/apis/dtos/companies/companies.dto';
import { getCompanyLoansMapped } from '~/store/apis/endpoints/myQredApi/companies/companies.mapper';
import { notifications } from '@qred/components-library';
import { Loan } from '~/models/loans/loan';

export const loansApi = myQredApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoanActive: builder.query<Loan, GetActiveLoanRequestDto>({
      async queryFn({ companyId, market }, _api, _extraOptions, baseQuery) {
        const loansRes = await baseQuery({
          url: `/company/v1/companies/${companyId}/loans`,
        });

        if (loansRes.error) {
          return { error: loansRes.error };
        }

        const loans = loansRes.data as GetCompanyLoansResponseDto[];

        const mappedLoans = getCompanyLoansMapped(loans, market);

        const activeLoan = mappedLoans.find((loan) => loan.isActive);

        if (!activeLoan) {
          return { data: (null as unknown) as Loan };
        }

        const loanRes = await baseQuery({
          url: `/loan/v1/loans/${activeLoan.id}`,
        });

        if (loanRes.error) {
          if (loanRes.error.status !== 404) {
            notifications.show({
              state: 'critical',
              autoClose: false,
              message: 'Something went wrong',
            });
          }
          return { error: loanRes.error };
        }

        const loan = loanRes.data as GetActiveLoanResponseDto;

        if (!loan) {
          return { data: (null as unknown) as Loan };
        }

        const mappedLoan = getLoanActiveMapped(loan, market);

        return { data: mappedLoan };
      },
      providesTags: (_result, _error, arg) => [
        { type: 'ActiveLoan', id: arg.companyId },
      ],
    }),
    getLoanDownloadDocument: builder.query<
      any,
      GetLoanDocumentDownloadRequestDto
    >({
      async queryFn({ loanId, documentType, ...rest }) {
        const token = await accessToken.get();
        try {
          const res = await axios.get(
            `${
              import.meta.env.VITE_MYQRED_API_URL
            }/loan/v1/loans/${loanId}/download/${documentType}?${generateParams(
              rest
            )}`,
            {
              responseType: 'blob',
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          downloadFile(`${documentType}.pdf`, res.data, 'application/pdf');
          return { data: res.data };
        } catch (error) {
          return { error: error };
        }
      },
    }),
    getLoanStatement: builder.query<Statement, GetLoanStatementRequestDto>({
      query: ({ market: _market, loanId, ...rest }) =>
        `/loan/v1/loans/${loanId}/statement?${generateParams(rest)}`,
      providesTags: (_result, _error, arg) => [
        { type: 'LoanStatement', id: arg.loanId },
      ],
      transformResponse: (response: GetLoanStatementResponseDto, _meta, arg) =>
        getLoanStatementMapped(response, arg.market),
    }),
    getLoanCurrentInvoice: builder.query<
      Invoice | undefined,
      GetLoanCurrentInvoiceRequestDto
    >({
      query: ({ market: _market, loanId, ...rest }) =>
        `/loan/v1/loans/${loanId}/invoices?${generateParams(rest)}`,
      providesTags: (_result, _error, arg) => [
        { type: 'LoanInvoices', id: arg.loanId },
      ],
      transformResponse: (
        response: PaginationResponse<GetLoanInvoicesResponseDto>,
        _meta,
        arg
      ) => getLoanCurrentInvoiceMapped(response, arg.market),
    }),
    getLoanInvoices: builder.query<
      PaginationResponse<Invoice>,
      GetLoanInvoicesRequestDto
    >({
      query: ({ market: _market, loanId, ...rest }) =>
        `/loan/v1/loans/${loanId}/invoices?${generateParams(rest)}`,
      providesTags: (_result, _error, arg) => [
        { type: 'LoanInvoices', id: arg.loanId },
      ],
      transformResponse: (
        response: PaginationResponse<GetLoanInvoicesResponseDto>,
        _meta,
        arg
      ) => getLoanInvoicesMapped(response, arg.market),
    }),
    getLoanAgreement: builder.query<unknown, GetLoanAgreementRequestDto>({
      async queryFn({ loanId }, _api, _extraOptions, baseQuery) {
        const result = await baseQuery({
          url: `/loan/v1/loans/${loanId}/loan-agreement`,
        });
        const data = result.data as GetLoanAgreementResponseDto;

        try {
          const res = await axios.get(data.url, {
            responseType: 'blob',
          });
          downloadFile('credit-agreement.pdf', res.data, 'application/pdf');
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: (_result, _error, arg) => [
        { type: 'LoanAgreement', id: arg.loanId },
      ],
    }),
    getLoanPaymentPlan: builder.query<unknown, GetLoanAgreementRequestDto>({
      async queryFn({ loanId }, _api, _extraOptions, baseQuery) {
        const result = await baseQuery({
          url: `/loan/v1/loans/${loanId}/loan-payment-plan`,
        });
        const data = result.data as GetLoanAgreementResponseDto;
        try {
          const res = await axios.get(data.url, {
            responseType: 'blob',
          });

          downloadFile('payment-plan.pdf', res.data, 'application/pdf');
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: (_result, _error, arg) => [
        { type: 'LoanPaymentPlan', id: arg.loanId },
      ],
    }),
    getLoanDownloadInvoices: builder.query<
      GetDownloadInvoicesResponseDto,
      GetDownloadInvoicesRequestDto
    >({
      async queryFn({ loanId, ...rest }, _api, _extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `/loan/v1/loans/${loanId}/download/invoices?${generateParams(
            rest
          )}`,
        });
        if (response.error) {
          return { error: response.error };
        }
        const data = response.data as GetDownloadInvoicesResponseDto;
        generatePdfHelper(
          data.content,
          'invoice',
          rest.activeCompanyName,
          data.format
        );

        return { data };
      },
    }),
    postAmoFreeMonth: builder.mutation<unknown, PostAmoFreeMonthsRequestDto>({
      query: ({ loanId, ...rest }) => ({
        url: `/loan/v1/loans/${loanId}/amo-free-month`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: ['ActiveLoan'],
    }),
    updatePaymentMethod: builder.mutation<
      unknown,
      PatchPaymentMethodRequestDto
    >({
      query: ({ loanId, ...rest }) => ({
        url: `/loan/v1/loans/${loanId}/payment-method`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['ActiveLoan'],
    }),
    getLoanTerminationLetter: builder.query<
      unknown,
      GetLoanTerminationLetterRequestDto
    >({
      async queryFn({ loanId }, _api, _extraOptions, baseQuery) {
        const result = await baseQuery({
          url: `/loan/v1/loans/${loanId}/loan-termination-letter`,
        });
        const data = result.data as GetLoanTerminationLetterResponseDto;
        try {
          const res = await axios.get(data.url, {
            responseType: 'blob',
          });
          downloadFile('termination-letter.pdf', res.data, 'application/pdf');
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useLazyGetLoanDownloadDocumentQuery,
  useGetLoanActiveQuery,
  useGetLoanInvoicesQuery,
  useGetLoanCurrentInvoiceQuery,
  useLazyGetLoanInvoicesQuery,
  useLazyGetLoanStatementQuery,
  useLazyGetLoanAgreementQuery,
  useLazyGetLoanPaymentPlanQuery,
  useLazyGetLoanDownloadInvoicesQuery,
  usePostAmoFreeMonthMutation,
  useUpdatePaymentMethodMutation,
  useLazyGetLoanTerminationLetterQuery,
} = loansApi;
