import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';
import { FlexContentPlacement } from '~/enums';
import { MediaQueries } from '~/constants/constVars';
import {
  ArrangementProps,
  BaseArrangement,
  ComponentSize,
} from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import AlertBox, { AlertColor } from '../AlertBox/AlertBox';
import Icon, { SvgSrc } from '../Icon/Icon';

const CardContainer = styled(BaseArrangement)<{
  hasAlertBox?: boolean;
  grow?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.hasAlertBox ? 0 : '')};
  height: inherit;
  min-height: inherit;
  max-height: inherit;
  ${(props) =>
    props.grow &&
    css`
      flex-grow: 1;
    `}
`;

const CardContent = styled.div<{
  noPadding?: boolean;
  clickable?: boolean;
  hasAlertBox?: boolean;
  fullHeight?: boolean;
  justifyContent?: FlexContentPlacement;
  isOnboarding?: boolean;
  grow?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.fullHeight ? '100%' : 'inherit')};
  background-color: ${theme.colors.white};
  border-radius: 0.2em;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'inherit'};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
  padding: ${(props) => (props.noPadding ? 0 : '1em')};
  transition: all 0.2s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.18);
  ${(props) =>
    props.grow &&
    css`
      flex-grow: 1;
    `}

  ${(props) =>
    props.hasAlertBox
      ? css`
          padding-top: ${props.noPadding ? '1em' : '2em'};
        `
      : undefined};

  ${(props) =>
    props.isOnboarding &&
    css`
      padding: 0;
      @media ${MediaQueries.smallScreenPortrait} {
        border: none;
        border-radius: 0;
      }
    `}

  &:hover .enlargeOnHover {
    transform-origin: center center;
    transform: scale(1.4);
    color: ${theme.colors.secondaryGray};
    transition: all 0.3s ease-in-out;
  }
`;

const ClickableIcon = styled.div`
  position: absolute;
  right: 0.6em;
  top: 50%;
  transform: translateY(-50%);
`;

interface Props {
  alertSvgSrc?: IconProp;
  alertColor?: AlertColor;
  alertMessage?: string; // Alert box is redered only if there is a message
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  dataTestId?: string;
  clickable?: boolean;
  hideClickableIcon?: boolean;
  noPadding?: boolean;
  fullHeight?: boolean;
  isOnboarding?: boolean;
  justifyContent?: FlexContentPlacement;
  grow?: boolean;
  children?: React.ReactNode;
}

const Card: React.FC<Props & ArrangementProps> = (props) => (
  <CardContainer
    data-testid={props.dataTestId}
    hasAlertBox={!!props.alertMessage}
    mt={props.mt}
    mb={props.mb}
    mr={props.mr}
    ml={props.ml}
    grow={props.grow}
  >
    {props.alertMessage ? (
      <AlertBox
        message={props.alertMessage}
        color={props.alertColor}
        svgSrc={props.alertSvgSrc}
      />
    ) : null}
    <CardContent
      onClick={props.onClick}
      noPadding={props.noPadding}
      clickable={props.clickable}
      hasAlertBox={!!props.alertMessage}
      fullHeight={props.fullHeight}
      justifyContent={props.justifyContent}
      isOnboarding={props.isOnboarding}
      grow={props.grow}
    >
      {props.children}
      {props.clickable && !props.hideClickableIcon && (
        <ClickableIcon>
          <Icon
            className="enlargeOnHover"
            src={SvgSrc.ChevronRight}
            size={ComponentSize.Medium}
          />
        </ClickableIcon>
      )}
    </CardContent>
  </CardContainer>
);

export default Card;
