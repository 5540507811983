import React from 'react';
import { useSelector } from 'react-redux';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { RootState } from '~/store/types/sharedTypes';

const FinancialInformationHeader = () => {
  const {
    onboardingApplication: { form },
  } = useSelector((state: RootState) => state);

  const hasBankIntegrationOrFilesUploaded =
    form.bankIntegration || form.bankFilesUploaded;

  const title = 'Onboarding.FinancialDetailsTitle';
  const description = hasBankIntegrationOrFilesUploaded
    ? 'Onboarding.HasCompletedBankIntegrationTurnoverDescription'
    : 'Onboarding.TurnoverDescription';

  return <StepHeader title={title} description={description} />;
};

export default FinancialInformationHeader;
