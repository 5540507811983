import React, { FC } from 'react';
import {
  AppShell as CLAppShell,
  useWindowProperties,
} from '@qred/components-library';
import SideBar from './SideBar';
import Header from './Header';
import { AppShellContextProvider } from '~/components/shared/Navigation/AppShell.context';
import { useAppShell } from '~/components/shared/Navigation/hooks/useAppShell';
import { useAccount } from '~/hooks/useAccount';

interface AppShellProps {
  children: React.ReactNode;
}

const AppShell: FC<AppShellProps> = ({ children }) => {
  const { isMobile, isTablet, isDesktop } = useWindowProperties();
  const { selectedCompany } = useAccount();

  const { headerTitle, setHeaderTitle } = useAppShell({
    headerTitle: selectedCompany.name,
  });
  const responsivePadding = isMobile ? 32 : 48;
  const bottomPadding = isDesktop ? 60 : responsivePadding;
  return (
    <AppShellContextProvider value={{ headerTitle, setHeaderTitle }}>
      <CLAppShell
        header={<Header />}
        navbar={<SideBar />}
        fixed
        mainProps={{
          style: {
            paddingBottom: bottomPadding,
          },
        }}
      >
        {isTablet && <Header keepDesktopStyle />}
        {children}
      </CLAppShell>
    </AppShellContextProvider>
  );
};

export default AppShell;
