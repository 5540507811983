import { OwnerTypeEnum, SignerType } from '~/enums';
import {
  getMarketHasAddress,
  getMarketOnlyAllowsBoardMemberAsGuarantor,
} from '~/constants/markets';
import moment from 'moment';
import { personalNumbersAreTheSame } from './utils';
import { parseMarket } from './market.helper';
import {
  formatBankAccountNumber,
  formatPersonalNumber,
} from './formatters.helper';
import {
  ICardOnboardingOfferApplicant,
  ICardOnboardingOfferState,
} from '~/interfaces/CardOnboardingOffer';
import {
  CompanyTypeCategory,
  IKlarnaAccount,
  IOnboardingAddressFields,
  IOnboardingAMLData,
  IOnboardingBeneficialOwner,
  IOnboardingPerson,
  IOnboardingSigner,
  IOpenBankingAccount,
} from '~/interfaces/Onboarding';
import {
  ILoanOnboardingApplicationForm,
  ILoanOnboardingApplicationGuarantor,
} from '~/interfaces/LoanOnboardingApplication';
import {
  ILoanOnboardingOfferApplicant,
  ILoanOnboardingOfferForm,
  ILoanOnboardingOfferState,
} from '~/interfaces/LoanOnboardingoffer';
import { AnyAction } from '@reduxjs/toolkit';
import { ApiStatus, AppDispatch } from '~/store/types/sharedTypes';
import { TLoanOnboardingOfferSliceActions } from '~/store/slices/loanOnboardingOffer.slice';
import { TCardOnboardingOfferSliceActions } from '~/store/slices/cardOnboardingOffer.slice';
import { CountryCode } from '@qred/shared-enums';

export const buildStreetValue = ({
  streetName,
  houseNumber,
  houseNumberAddition,
}: IOnboardingAddressFields) => {
  if (
    houseNumberAddition === 'NoAdditionalHouseNumber' ||
    !houseNumberAddition
  ) {
    return `${streetName} ${houseNumber}`.trim();
  }
  return `${streetName} ${houseNumber} ${houseNumberAddition}`.trim();
};

export const transformGuarantorAddress = ({
  streetName,
  houseNumber,
  houseNumberAddition,
  zipCode,
  city,
  market,
}: IOnboardingAddressFields & { market?: CountryCode }) => {
  if (market === CountryCode.DE) {
    return `${streetName || ''} ${houseNumber || ''}${
      houseNumberAddition || ''
    }, ${zipCode || ''}, ${city || ''}`.trim();
  }
  if (
    houseNumberAddition === 'NoAdditionalHouseNumber' ||
    !houseNumberAddition
  ) {
    return `${streetName} ${houseNumber} ${zipCode} ${city}`.trim();
  }
  return `${streetName} ${houseNumber} ${houseNumberAddition} ${zipCode} ${city}`.trim();
};

/**
 *
 * @param addressResponseHouseNumberAdditions
 * @returns empty array if only item is empty string
 */
export const houseNumberAdditionsDropdownValuesHelper = (
  addressResponseHouseNumberAdditions: string[]
) => {
  if (!addressResponseHouseNumberAdditions.filter((x) => x).length) {
    return [];
  }

  const addressResponseDropdownValuesArray = [
    'Select',
    ...addressResponseHouseNumberAdditions,
  ].map((x) => (x === '' ? 'NoAdditionalHouseNumber' : x));

  return addressResponseDropdownValuesArray;
};

/**
 *
 * @param addressResponseHouseNumberAdditions
 * @returns empty array if only item is empty string
 */
export const houseNumberAdditionsDropdownValuesHelperV2 = (
  addressResponseHouseNumberAdditions: string[]
) => {
  if (
    !addressResponseHouseNumberAdditions.filter(
      (houseNumberAddition) => houseNumberAddition
    ).length
  ) {
    return [];
  }

  const addressResponseDropdownValuesArray = addressResponseHouseNumberAdditions.map(
    (houseNumberAddition) =>
      houseNumberAddition === ''
        ? 'NoAdditionalHouseNumber'
        : houseNumberAddition
  );

  return addressResponseDropdownValuesArray;
};

/**
 * Compares names and returns true if they match
 * Function tries to match even if middle names are missing.
 * E.g. "John Doe" and "John Doe Smith" would match.
 * They do not have to be in the same order, unless both names are the same length.
 * E.g. "John Doe" and "Doe John" would not match match.
 * @param name1
 * @param name2
 * @returns boolean
 */
export const compareNames = (
  name1: string | null | undefined,
  name2: string | null | undefined
): boolean => {
  const names1: string[] = (name1 ?? '').toLowerCase().trim().split(' ');
  const names2: string[] = (name2 ?? '').toLowerCase().trim().split(' ');

  if (names1.length === names2.length) {
    return names1.join(' ') === names2.join(' ');
  }

  const [moreNames, lessNames]: [string[], string[]] =
    names1.length > names2.length ? [names1, names2] : [names2, names1];

  return lessNames.every((part: string) => moreNames.includes(part));
};

export const personsAreTheSame = (
  person1: IOnboardingPerson,
  person2: IOnboardingPerson
) => {
  const {
    personalNumber: personalNumber1,
    dateOfBirth: dateOfBirth1,
    yearOfBirth: yearOfBirth1,
  } = person1;
  const {
    personalNumber: personalNumber2,
    dateOfBirth: dateOfBirth2,
    yearOfBirth: yearOfBirth2,
  } = person2;
  const name1 = person1.fullName ?? person1.name;
  const name2 = person2.fullName ?? person2.name;

  if (personalNumber1 && personalNumber2) {
    return personalNumbersAreTheSame(personalNumber1, personalNumber2);
  }

  const namesMatch = compareNames(name1, name2);

  if (dateOfBirth1 && dateOfBirth2 && name1 && name2) {
    const date1 = moment(dateOfBirth1).format('YYYY-MM-DD');
    const date2 = moment(dateOfBirth2).format('YYYY-MM-DD');

    return date1 === date2 && namesMatch;
  }

  if (yearOfBirth1 && yearOfBirth2 && name1 && name2) {
    return yearOfBirth1 === yearOfBirth2 && namesMatch;
  }

  if (name1 && name2) {
    return namesMatch;
  }

  return false;
};

export const personAlreadyExistsInList = (
  personList: IOnboardingPerson[],
  person: IOnboardingPerson
) => personList.some((listPerson) => personsAreTheSame(listPerson, person));

/**
 *
 * @param form
 * @returns true if guarantor is manuallyAdded, else false
 */
export const hasManuallyAddedAGuarantor = (
  form: ILoanOnboardingApplicationForm
) =>
  form.guarantors.some(
    (guarantor: ILoanOnboardingApplicationGuarantor) => guarantor.manuallyAdded
  );

export const getGuarantorContentByMarket = (
  form: ILoanOnboardingApplicationForm,
  market: CountryCode
) => {
  const hasGuarantors = form.guarantors.length > 0;

  const marketOnlyAllowsBoardMemberAsGuarantor = getMarketOnlyAllowsBoardMemberAsGuarantor(
    market
  );

  let translationKey = '';
  if (hasGuarantors && marketOnlyAllowsBoardMemberAsGuarantor) {
    translationKey = 'AddAdditionalBoardMembersTextBoardMembersExist';
  } else if (!hasGuarantors && marketOnlyAllowsBoardMemberAsGuarantor) {
    translationKey = 'AddAdditionalBoardMembersTextBoardMembersNotExist';
  } else {
    translationKey = 'AddAdditionalGuarantorsText';
  }
  return translationKey;
};

export const getGuarantorText = (
  guarantor?: ILoanOnboardingApplicationGuarantor
) => {
  let translationKey = '';
  if (!guarantor) {
    translationKey = 'AddNewGuarantor';
  } else if (guarantor.manuallyAdded) {
    translationKey = 'EditGuarantor';
  } else if (guarantor.email && guarantor.phone) {
    translationKey = 'EditContactInformation';
  } else {
    translationKey = 'AddContactInformation';
  }
  return translationKey;
};

export const isBeneficialOwner = (ownership: string) => Number(ownership) > 25;

export const getOwnerType = (
  applicantOwnershipPercentage: string,
  additionalOwners: IOnboardingBeneficialOwner[]
) => {
  // Applicant owns more than 75% (76% or more)
  if (Number(applicantOwnershipPercentage) > 75) {
    return OwnerTypeEnum.MoreThan75;
  }

  const allOwnerPercentages = [
    applicantOwnershipPercentage,
    ...additionalOwners.map((owner) => owner.percentage),
  ];

  // At least 1 owner owns more than 25%
  if (
    allOwnerPercentages.some((ownerPercentage) =>
      isBeneficialOwner(ownerPercentage)
    )
  ) {
    return OwnerTypeEnum.MorePersonsOwn25;
  }

  return OwnerTypeEnum.NonOneOwn25;
};

export const setAMLData = (
  formData: URLSearchParams,
  amlData: IOnboardingAMLData & {
    address?: IOnboardingAddressFields | null;
  },
  prefix: string,
  suffix: string
) => {
  const market = parseMarket(window.__QRED_MARKET__);
  amlData.countryOfResidence &&
    formData.set(
      `${prefix}residence_country_code${suffix}`,
      amlData.countryOfResidence
    );
  amlData.countryOfTaxResidence &&
    formData.set(
      `${prefix}tax_residence_country_code${suffix}`,
      amlData.countryOfTaxResidence
    );
  amlData.isPEP !== undefined &&
    formData.set(`${prefix}is_pep${suffix}`, amlData.isPEP ? '1' : '0');

  if (market && getMarketHasAddress(market) && amlData.address) {
    formData.set(`${prefix}street${suffix}`, buildStreetValue(amlData.address));
    amlData.address.zipCode &&
      formData.set(`${prefix}zip_code${suffix}`, amlData.address.zipCode);
    amlData.address.city &&
      formData.set(`${prefix}city${suffix}`, amlData.address.city);
  }
};

const setOwner = (
  owner: Omit<IOnboardingBeneficialOwner, 'id'>,
  formData: URLSearchParams,
  id: string
) => {
  const market = parseMarket(window.__QRED_MARKET__);

  const nonOneOwn25 = formData.get('owner_type') === OwnerTypeEnum.NonOneOwn25;

  formData.set(`owner_block_${id}`, id);
  formData.set(`owner_block_name_${id}`, owner.fullName);
  owner.isAlternativeBeneficialOwner &&
    formData.set(`owner_block_is_alternative_${id}`, '1');
  owner.personalNumber &&
    market &&
    formData.set(
      `owner_block_personal_no_${id}`,
      formatPersonalNumber(owner.personalNumber, market)
    );

  owner.dateOfBirth &&
    formData.set(
      `owner_block_date_of_birth_${id}`,
      moment(owner.dateOfBirth).format('YYYY-MM-DD')
    );

  owner.yearOfBirth &&
    formData.set(
      `owner_block_date_of_birth_${id}`,
      moment(owner.yearOfBirth).format('YYYY-MM-DD')
    );

  // TODO: once BO accepts undefined percent for ABOs, change this to only set prop if UBO
  formData.set(
    `owner_block_percent_${id}`,
    owner.isAlternativeBeneficialOwner || nonOneOwn25 || !owner.percentage
      ? '0'
      : owner.percentage
  );

  setAMLData(formData, owner, 'owner_block_', `_${id}`);
};

const setSigner = (
  signer: IOnboardingSigner,
  formData: URLSearchParams,
  id: string | number
) => {
  formData.append('signer_block', id.toString());
  formData.set(`signer_email_${id}`, signer.email || '');
  formData.set(`signer_phone_${id}`, signer.phone || '');
  setAMLData(formData, signer, 'signer_', `_${id}`);
};

export const buildPostOfferFormData = (
  data: Omit<ILoanOnboardingOfferForm, 'payoutAmount'> & { amount: number },
  offerInterface: string
) => {
  const market = parseMarket(window.__QRED_MARKET__);
  const formData = new URLSearchParams();
  formData.set('amount', String(data.amount));
  formData.set('client_id', String(data.clientId));
  formData.set('term', String(data.loanPeriod));
  market &&
    formData.set(
      'client_account',
      formatBankAccountNumber(data.accountNumber, market)
    );
  formData.set('invoice_email', data.invoiceEmail);
  formData.set('loan_purpose', data.purposeOfLoan);
  formData.set('loan_purpose_manual', data.loanPurposeManual);
  formData.set('account', JSON.stringify(data.account));
  formData.set(
    'open_banking_account',
    data.openBankingAccount ? JSON.stringify(data.openBankingAccount) : 'null'
  );
  formData.set(
    'owner_type',
    getOwnerType(data.applicant.percentage, data.owners)
  );
  formData.set('need_paper_invoice', data.regularMailForInvoices ? '1' : '0');
  formData.set('is_terms_approved', data.offerIsAgreed ? '1' : '0');
  formData.set(
    'is_personal_guarantee_approved',
    data.personalLiabilityIsAgreed ? '1' : '0'
  );
  formData.set('is_document_approved', data.offerIsAgreed ? '1' : '0');
  formData.set('interface', offerInterface);
  formData.set(
    'is_direct_debit_enabled',
    data.isDirectDebitEnabled ? '1' : '0'
  );

  const allSigners = [...data.signers, ...data.guarantors];
  allSigners.forEach((signer) => {
    setSigner(signer, formData, signer.signerId);
  });

  let allOwners = [...data.owners];

  const applicantInOwnersList = allOwners.find((owner) =>
    personsAreTheSame(owner, data.applicant)
  );

  if (applicantInOwnersList) {
    // make sure that we put the applicant first in the list
    allOwners = allOwners.filter(
      (owner) => applicantInOwnersList.id !== owner.id
    );
    allOwners = [applicantInOwnersList, ...allOwners];
  } else {
    allOwners = [data.applicant, ...allOwners];
  }

  allOwners.forEach((owner, index) => {
    const indexString = (index + 1).toString().padStart(2, 'O');
    setOwner(owner, formData, indexString);
  });

  return formData;
};

export const showRadioInputDependantOnQuestionId = (questionId: number) => {
  const questionIdHasRadioButtonAnswers = [103, 107];
  const shouldShowRadioInputOption = questionIdHasRadioButtonAnswers.includes(
    questionId
  );
  return shouldShowRadioInputOption;
};

export const getKYCQuestionTranslationDependantOnQuestionId = (
  questionId: number
) => {
  const questionMapping: { [key: number]: string } = {
    100: 'OnboardingOffer.KYCQuestion100',
    101: 'OnboardingOffer.KYCQuestion101',
    102: 'OnboardingOffer.KYCQuestion102',
    103: 'OnboardingOffer.KYCQuestion103',
    104: 'OnboardingOffer.KYCQuestion104',
    105: 'OnboardingOffer.KYCQuestion105',
    106: 'OnboardingOffer.KYCQuestion106',
    107: 'OnboardingOffer.KYCQuestion107',
  };

  return questionMapping[questionId];
};

export const validateAMLFields = ({
  countryOfResidence,
  countryOfTaxResidence,
  isPEP,
}: IOnboardingAMLData) => {
  let isValid = false;
  if (countryOfResidence && countryOfTaxResidence && isPEP !== undefined) {
    isValid = true;
  }
  return isValid;
};

export const validateOfferApplicant = (
  applicant: ICardOnboardingOfferApplicant | IOnboardingBeneficialOwner
) => validateAMLFields(applicant);

export const validateOfferSigner = (signer: IOnboardingSigner) => {
  const { email, phone } = signer;
  const isValid = email && phone && validateAMLFields(signer);

  return isValid;
};

export const validateOfferGuarantor = (guarantor: IOnboardingSigner) => {
  const { email, phone } = guarantor;
  let isValid = false;
  if (email && phone) {
    isValid = true;
  }
  return isValid;
};

export const validateFetchedBeneficialOwner = (
  beneficialOwner: IOnboardingBeneficialOwner
) => {
  const market = parseMarket(window.__QRED_MARKET__);

  const {
    fullName,
    personalNumber,
    percentage,
    countryOfTaxResidence,
    isPEP,
    dateOfBirth,
    yearOfBirth,
    address,
  } = beneficialOwner;
  let isValid = false;

  switch (market) {
    case CountryCode.SE:
      if (
        fullName &&
        personalNumber &&
        percentage &&
        validateAMLFields(beneficialOwner)
      ) {
        isValid = true;
      }
      break;
    case CountryCode.FI:
      if (
        fullName &&
        percentage &&
        dateOfBirth &&
        validateAMLFields(beneficialOwner)
      ) {
        isValid = true;
      }
      break;
    case CountryCode.DK:
      if (fullName && percentage && validateAMLFields(beneficialOwner)) {
        isValid = true;
      }
      break;
    case CountryCode.NO:
      if (
        fullName &&
        percentage &&
        yearOfBirth &&
        countryOfTaxResidence &&
        isPEP !== undefined
      ) {
        isValid = true;
      }
      break;
    case CountryCode.DE:
      if (
        fullName &&
        percentage &&
        dateOfBirth &&
        address &&
        validateAMLFields(beneficialOwner)
      ) {
        isValid = true;
      }
      break;
    default:
      break;
  }
  return isValid;
};

export const getFullName = (firstName: string, secondName: string) => {
  const fullName = `${firstName} ${secondName}`.trim();
  return fullName;
};

/**
 * Check if the AML data is different between two persons.
 */
const AMLDataIsDifferent = (
  person1: IOnboardingAMLData,
  person2: IOnboardingAMLData
) => {
  const isDifferent =
    person1.countryOfResidence !== person2.countryOfResidence ||
    person1.countryOfTaxResidence !== person2.countryOfTaxResidence ||
    person1.isPEP !== person2.isPEP;

  return isDifferent;
};

/**
 * Extracts the AML data from a person.
 */
const extractAMLData = ({
  countryOfTaxResidence,
  countryOfResidence,
  isPEP,
}: IOnboardingAMLData): IOnboardingAMLData => ({
  countryOfTaxResidence,
  countryOfResidence,
  isPEP,
});

/**
 * Whenever an owner is updated, we need to check if the same person is in the
 * list of signers and/or the applicant and update it there as well.
 */
const handleBeneficialOwnerChange = (
  beneficialOwner: IOnboardingBeneficialOwner,
  state: ICardOnboardingOfferState | ILoanOnboardingOfferState,
  dispatch: AppDispatch,
  actions: TLoanOnboardingOfferSliceActions | TCardOnboardingOfferSliceActions
) => {
  const matchingSigner = state.form.signers.find((s) =>
    personsAreTheSame(s, beneficialOwner)
  );
  if (matchingSigner && AMLDataIsDifferent(matchingSigner, beneficialOwner)) {
    dispatch(
      actions.updateSigner({
        ...matchingSigner,
        ...extractAMLData(beneficialOwner),
      })
    );
  }

  const matchingApplicant =
    personsAreTheSame(state.form.applicant, beneficialOwner) &&
    state.form.applicant;
  if (
    matchingApplicant &&
    (AMLDataIsDifferent(matchingApplicant, beneficialOwner) ||
      matchingApplicant.percentage !== beneficialOwner.percentage)
  ) {
    dispatch(
      actions.updateApplicant({
        ...extractAMLData(beneficialOwner),
        percentage: beneficialOwner.percentage,
      })
    );
  }
};

const handleBeneficialOwnerRemoved = (
  beneficialOwner: IOnboardingBeneficialOwner,
  state: ICardOnboardingOfferState | ILoanOnboardingOfferState,
  dispatch: AppDispatch,
  actions: TLoanOnboardingOfferSliceActions | TCardOnboardingOfferSliceActions
) => {
  const matchingApplicant =
    personsAreTheSame(state.form.applicant, beneficialOwner) &&
    state.form.applicant;
  if (matchingApplicant) {
    dispatch(
      actions.updateApplicant({
        percentage: '0',
      })
    );
  }
};

/**
 * Whenever a signer is updated, we need to check if the same person is in the list of owners,
 * and then update it there as well.
 */
const handleSignerChange = (
  signer: IOnboardingSigner,
  state: ICardOnboardingOfferState | ILoanOnboardingOfferState,
  dispatch: AppDispatch,
  actions: TLoanOnboardingOfferSliceActions | TCardOnboardingOfferSliceActions
) => {
  const matchingOwner = state.form.owners.find((o) =>
    personsAreTheSame(o, signer)
  );
  if (matchingOwner && AMLDataIsDifferent(matchingOwner, signer)) {
    dispatch(
      actions.updateAdditionalOwner({
        ...matchingOwner,
        ...extractAMLData(signer),
      })
    );
  }
};

/**
 * Whenever the applicant is updated, we need to check if the same person is in the list of owners,
 * and then update it there as well.
 */
const handleApplicantChange = (
  applicant: ILoanOnboardingOfferApplicant | ICardOnboardingOfferApplicant,
  state: ICardOnboardingOfferState | ILoanOnboardingOfferState,
  dispatch: AppDispatch,
  actions: TLoanOnboardingOfferSliceActions | TCardOnboardingOfferSliceActions
) => {
  const matchingOwner = state.form.owners.find((o) =>
    personsAreTheSame(o, applicant)
  );
  if (matchingOwner && AMLDataIsDifferent(matchingOwner, applicant)) {
    dispatch(
      actions.updateAdditionalOwner({
        ...matchingOwner,
        ...extractAMLData(applicant),
      })
    );
  }
};

/**
 * Whenever the list of owners is updated, we need to check each owner against the applicant and signers
 * and update them if they match.
 */
const handleBeneficialOwnersListChange = (
  beneficialOwners: IOnboardingBeneficialOwner[],
  state: ICardOnboardingOfferState | ILoanOnboardingOfferState,
  dispatch: AppDispatch,
  actions: TLoanOnboardingOfferSliceActions | TCardOnboardingOfferSliceActions
) => {
  const ownerMatchingApplicant = beneficialOwners.find((o) =>
    personsAreTheSame(o, state.form.applicant)
  );
  if (
    ownerMatchingApplicant &&
    (AMLDataIsDifferent(ownerMatchingApplicant, state.form.applicant) ||
      ownerMatchingApplicant.percentage !== state.form.applicant.percentage)
  ) {
    dispatch(actions.updateApplicant(ownerMatchingApplicant));
  }

  const signers = state.form.signers ?? [];
  beneficialOwners.forEach((o) => {
    const matchingSigner = signers.find((s) => personsAreTheSame(s, o));
    if (matchingSigner && AMLDataIsDifferent(matchingSigner, o)) {
      dispatch(
        actions.updateSigner({
          ...matchingSigner,
          ...extractAMLData(o),
        })
      );
    }
  });
};

/**
 * Syncs the AML/KYC data between the applicant and the owners/signers for both card and loan offer flows.
 * @param action { type, payload } where type can be 'onboardingOffer/*' or 'cardOnboardingOffer/*' where * can be any of the following:
 * - addAdditionalOwner
 * - updateAdditionalOwner
 * - updateSigner
 * - updateApplicant
 * - setBeneficialOwners
 * - removeAdditionalOwnerById
 * and payload can be any of the following:
 * - IOnboardingBeneficialOwner
 * - IOnboardingSigner
 * - ILoanOnboardingOfferApplicant
 * - ICardOnboardingOfferApplicant
 * - IOnboardingBeneficialOwner[]
 * - string
 */
export const syncOnboardingOfferPersons = (
  action: AnyAction,
  state: ICardOnboardingOfferState | ILoanOnboardingOfferState,
  originalState: ICardOnboardingOfferState | ILoanOnboardingOfferState,
  dispatch: AppDispatch,
  actions: TLoanOnboardingOfferSliceActions | TCardOnboardingOfferSliceActions
) => {
  // Pick out the last part of the action type, e.g. 'addAdditionalOwner' or 'updateAdditionalOwner'
  const [caseReducer] = action.type.split('/').reverse();

  switch (caseReducer) {
    case 'addAdditionalOwner':
    case 'updateAdditionalOwner': {
      const owner: IOnboardingBeneficialOwner = action.payload;
      handleBeneficialOwnerChange(owner, state, dispatch, actions);
      break;
    }
    case 'updateSigner': {
      const signer: IOnboardingSigner = action.payload;
      // We only care about signer with type Signer, not Guarantor. Guarantors and owners are not sharing any data.
      if (signer.type === SignerType.Signer) {
        handleSignerChange(signer, state, dispatch, actions);
      }
      break;
    }
    case 'updateApplicant': {
      const applicant:
        | ILoanOnboardingOfferApplicant
        | ICardOnboardingOfferApplicant = action.payload;
      handleApplicantChange(applicant, state, dispatch, actions);
      break;
    }
    case 'setBeneficialOwners': {
      const beneficialOwners: IOnboardingBeneficialOwner[] = action.payload;
      if (beneficialOwners.length) {
        handleBeneficialOwnersListChange(
          beneficialOwners,
          state,
          dispatch,
          actions
        );
      }
      break;
    }
    case 'removeAdditionalOwnerById': {
      const removedOwnerId: string = action.payload;
      const removedOwner = originalState.form.owners.find(
        (owner) => owner.id === removedOwnerId
      );
      if (removedOwner) {
        handleBeneficialOwnerRemoved(removedOwner, state, dispatch, actions);
      }
      break;
    }
    default:
      break;
  }
};

export const getBankAccountNumber = (
  account: IKlarnaAccount,
  market: CountryCode
): string => {
  if (market === CountryCode.SE) {
    return `${account.bank_code || ''}${account.account_number || ''}`;
  }
  if (account.iban) {
    return account.iban;
  }
  return '';
};

export const getOpenBankingAccountNumber = (
  account: IOpenBankingAccount
): string => {
  switch (account.market) {
    case CountryCode.SE:
    case CountryCode.NO:
    case CountryCode.DK:
      return account.bban;
    case CountryCode.FI:
    case CountryCode.BE:
    case CountryCode.NL:
    case CountryCode.DE:
      return account.iban;
  }
};

/**
 * We only get the account number from the API, so we need to check if that account number
 * exists in the list of bank accounts they have previously given us.
 * - If it does, we set that as the default bank account ("account" property in the form).
 * - If it doesn't, we set the account number as the default account number.
 * @param bankAccounts list of bank accounts previously given by the user through Klarna Kosma
 * @param accountNumber This + the bankClearing is the only value we get from backend, even if they selected an account through Klarna which has a lot more information
 * @param bankClearing This + the accountNumber is the only value we get from backend, even if they selected an account through Klarna which has a lot more information
 * @returns { defaultBankAccount, defaultAccountNumber } only one of them should be set
 * - if defaultBankAccount is set, it will be autoselecting one of the accounts in the list.
 * - if defaultAccountNumber is set, it will be in the text input field for the manually entered account number.
 */
export const getDefaultBankAccountForLoanOffer = (
  bankAccounts: IKlarnaAccount[],
  accountNumber: string | null
) => {
  const accountFoundInKlarnaList = accountNumber
    ? bankAccounts?.find((account) => {
        const matchingIban = account.iban === accountNumber;
        const matchingAccountNumber = account.account_number === accountNumber;
        const matchingClearingAndAccountNumber =
          account.bank_code &&
          account.account_number &&
          account.bank_code + account.account_number === accountNumber;

        return (
          matchingIban ||
          matchingAccountNumber ||
          matchingClearingAndAccountNumber
        );
      })
    : null;

  if (accountFoundInKlarnaList) {
    return {
      defaultBankAccount: accountFoundInKlarnaList,
      defaultAccountNumber: '',
    };
  } else if (bankAccounts?.length === 1 && !accountNumber) {
    return {
      defaultBankAccount: bankAccounts[0],
      defaultAccountNumber: '',
    };
  }

  return { defaultBankAccount: {}, defaultAccountNumber: accountNumber };
};

export const applicationCanHaveGuarantors = (
  market: CountryCode,
  companyTypeCategory: CompanyTypeCategory | undefined,
  guarantors: ILoanOnboardingApplicationGuarantor[],
  guarantorsApiStatus: ApiStatus
) => {
  const isSoleTraderNotInBE =
    companyTypeCategory === CompanyTypeCategory.SOLE_TRADER &&
    market !== CountryCode.BE;

  const hasNoGuarantorsInDE =
    market === CountryCode.DE &&
    !guarantors.length &&
    guarantorsApiStatus === ApiStatus.Completed;

  return !isSoleTraderNotInBE && !hasNoGuarantorsInDE;
};
