import React from 'react';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { Body, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';

const ConnectToBankHeader = () => {
  const t = useTranslate();

  const title = t('Onboarding.BankIntegrationTitle') as string;
  let descriptionList = [
    'Onboarding.BankIntegrationText1',
    'Onboarding.BankIntegrationText2',
  ];

  const description = (
    <Stack>
      {descriptionList.map((text) => (
        <Body key={text} size="lg">
          {t(text)}
        </Body>
      ))}
    </Stack>
  );

  return (
    <StepHeader
      title={title}
      description={description}
      shouldTranslate={false}
    />
  );
};

export default ConnectToBankHeader;
