import {
  Body,
  Group,
  H8,
  Card,
  Link,
  useTheme,
  Label,
  useWindowProperties,
} from '@qred/components-library';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormStatus, ValidationContext } from '~/components/hoc/withValidation';
import Stepper from '~/components/shared/Stepper/Stepper';
import { FlowTypes, StepStatus } from '~/enums';
import { getCookieValue, areSameCompanies } from '~/helpers/utils';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import useTranslate from '~/hooks/useTranslate';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { useLoanOnboardingApplicationSelectors } from '~/store/selectors/loanOnboardingApplication.selector';
import {
  clearOnboardingState,
  goToPreviousStep,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { OnboardingStepsContainer } from '~/styles/OnboardingBaseStyledComponents';
import OnboardingNavbar from '../OnboardingNavbar/OnboardingNavbar';
import OnboardingAppShell from '~/components/shared/OnboardingAppShell/OnboardingAppShell';
import { TopFixedContainer } from './TopFixedContainer';
import useGoToNextStep from './useGoToNextStep';
import useSubmit from './useSubmit';
import { useHistory } from 'react-router-dom';
import { marketAndLanguagePerReferrerUrl } from '~/pages/Login/constants';
import { useCurrencyFormatter } from '~/hooks/formatter/useCurrencyFormatter';
import { getMarketHasCompanyCreditCheck } from '~/constants/markets';

const Steps = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const history = useHistory();

  const [selectedCompanyHasOffer, setSelectedCompanyHasOffer] = useState(false);

  const validationContext = useContext(ValidationContext);

  const publicToken = getCookieValue('publicToken');
  const onSubmit = useSubmit();
  const onNextStepClick = useGoToNextStep();
  const {
    marketRequiresLoginBeforeSubmittingApplication,
  } = useOnboardingMarketConfig();
  const theme = useTheme();
  const { isMobile } = useWindowProperties();

  const {
    onboardingApplication: {
      form,
      flowType,
      currentStep,
      overview,
      apiStatus: applicationApiStatus,
    },
    matchedMedia,
    klarnaKosma: { transactionFlow },
    intl: { market },
  } = useSelector((state: RootState) => state);
  const formatter = useCurrencyFormatter();
  const { stepConfig } = useLoanOnboardingApplicationSelectors();

  const nextStep = stepConfig?.[currentStep];
  const isLastBasicStep = nextStep?.name === 'checkpoint_step';
  const isLastStep = stepConfig.length === currentStep;

  const applicationApiRequestInProgress = [
    applicationApiStatus.application,
    applicationApiStatus.patch,
    applicationApiStatus.post,
  ].some((apiStatus) => apiStatus === ApiStatus.Started);
  const currentStepConfig = stepConfig[currentStep - 1];
  const showProgressStepperDots =
    !selectedCompanyHasOffer && !currentStepConfig.hideInProgressStepper;

  useEffect(() => {
    if (!form.sessionId) {
      const sessionId = `${Math.random()}${Date.now()}`.replace('0.', '');
      dispatch(updateForm({ sessionId }));
    }
  }, [dispatch, form.sessionId]);

  useEffect(() => {
    dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Started }));
    validationContext.setFormStatus(FormStatus.IDLE);
  }, [currentStepConfig.name, dispatch]);

  useEffect(() => {
    const selectedCompany = overview.clients.find((client) =>
      areSameCompanies(
        form.organizationNumber || form.connectId,
        client.company_no || client.connect_id
      )
    );
    setSelectedCompanyHasOffer(!!selectedCompany?.has_offer_to_sign);
  }, [form.organizationNumber, form.connectId, overview.clients]);

  const onBackButtonClick = () => {
    if (
      transactionFlow.status === 'inProgress' ||
      applicationApiRequestInProgress
    )
      return;

    dispatch(pushToGtmOnboardingAction({ actionName: 'back_button_pressed' }));
    const referrerUrl = document.referrer && new URL(document.referrer);
    const shouldGoBackInBrowserHistory =
      currentStep === 1 &&
      referrerUrl &&
      referrerUrl?.origin &&
      marketAndLanguagePerReferrerUrl[referrerUrl.origin];

    if (shouldGoBackInBrowserHistory) {
      history.goBack();
    } else {
      dispatch(goToPreviousStep());
      dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Edit }));
    }
  };

  useEffect(() => {
    const userIsPastFirstStep = currentStep > 1;
    if (flowType === FlowTypes.Manual && !publicToken && userIsPastFirstStep) {
      dispatch(clearOnboardingState());
    }
  }, [currentStep, dispatch, flowType, publicToken]);

  const extraFooterContent = useMemo(() => {
    let _extraFooterContent = [];
    if (
      isLastStep &&
      marketRequiresLoginBeforeSubmittingApplication &&
      flowType === FlowTypes.Manual
    ) {
      _extraFooterContent.push(
        <Body
          color="Text/Secondary"
          size="sm"
          display="block"
          align="left"
          key="SubmissionIdentificationExplanation"
        >
          {t('Onboarding.SubmissionIdentificationExplanation')}
        </Body>
      );
    }

    if (
      getMarketHasCompanyCreditCheck(market) &&
      currentStepConfig.name === 'personal_information_step'
    ) {
      _extraFooterContent.push(
        <Body
          color="Text/Secondary"
          size="sm"
          display="block"
          align="left"
          key="CreditCheckExplanation"
        >
          {t('Onboarding.MarketingConsent', {
            next: `"${t('Onboarding.Next')}"`,
            email: (
              <a href="mailto:privacy@qred.com">
                <Link size="sm">privacy@qred.com</Link>
              </a>
            ),
          })}
        </Body>
      );
    }

    if (isLastBasicStep && validationContext.isFormValid) {
      _extraFooterContent.push(
        <Body
          color="Text/Secondary"
          size="sm"
          display="block"
          align="left"
          key="ApplicationProcessingConsent"
        >
          {t('Onboarding.ApplicationProcessingConsent')}
        </Body>
      );
    }

    return <>{_extraFooterContent.map((item) => item)}</>;
  }, [
    validationContext.isFormValid,
    isLastBasicStep,
    isLastStep,
    currentStepConfig,
    marketRequiresLoginBeforeSubmittingApplication,
  ]);

  const isCheckpointStep = currentStepConfig.name === 'checkpoint_step';
  const shouldShowBackButton = !isCheckpointStep;
  const shouldShowNavbar = shouldShowBackButton || showProgressStepperDots;

  return (
    <OnboardingAppShell
      background={
        isCheckpointStep
          ? `url(/qrayon22_large.png) no-repeat center / contain`
          : undefined
      }
      backgroundOpacity={'0.1'}
      backgroundColor={
        isCheckpointStep && !isMobile
          ? theme.fn.themeColor('Page/Option_2_(Green)')
          : undefined
      }
    >
      {shouldShowNavbar && (
        <TopFixedContainer
          style={{ maxWidth: '800px', width: '100%' }}
          topFixedDisabled={!matchedMedia.smallScreenPortrait}
        >
          <OnboardingNavbar
            onBackClick={onBackButtonClick}
            showBackButton={shouldShowBackButton}
            progressStepperDots={
              showProgressStepperDots
                ? {
                    steps: stepConfig.map((step) => ({
                      name: step.name,
                      hideInProgressStepper: step.hideInProgressStepper,
                    })),
                    currentStep,
                  }
                : undefined
            }
          />
        </TopFixedContainer>
      )}
      <OnboardingStepsContainer>
        <Group p={0} w="100%" h={'100%'} direction="column" spacing={0}>
          {stepConfig.length && (
            <Stepper
              currentStepConfig={currentStepConfig}
              stepIsLoading={applicationApiRequestInProgress}
              currentStep={currentStep}
              extraStepContent={
                isLastBasicStep &&
                form.amount &&
                validationContext.isFormValid ? (
                  <Card p={12} color="Main/Grey/Alpha/OnLight/5%" mt={'auto'}>
                    <Group justify={'space-between'}>
                      <Label size="lg">
                        {t('Onboarding.ApplicationAmount')}
                      </Label>
                      <H8>
                        {formatter.format(
                          Number(form.amount.replace(/\D/g, ''))
                        )}
                      </H8>
                    </Group>
                  </Card>
                ) : undefined
              }
              footerCTALabel={
                isLastStep || isLastBasicStep
                  ? 'Onboarding.Submit'
                  : 'Onboarding.Next'
              }
              footerCTAAction={isLastStep ? onSubmit : onNextStepClick}
              extraFooterContent={extraFooterContent}
              fixedFooter={!isCheckpointStep}
            />
          )}
        </Group>
      </OnboardingStepsContainer>
    </OnboardingAppShell>
  );
};

export default Steps;
