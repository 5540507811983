import React, { useContext, useEffect } from 'react';
import { fetchKYCQuestionsAndAnswers } from '~/store/actions/cardOnboardingActions';
import { useDispatch, useSelector } from 'react-redux';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import {
  Body,
  Button,
  Group,
  Label,
  Radio,
  Stack,
} from '@qred/components-library';
import { ValidationContext } from '~/components/hoc/withValidation';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { theme } from '~/styles/themes';
import useTranslate from '~/hooks/useTranslate';
import {
  getKYCQuestionTranslationDependantOnQuestionId,
  showRadioInputDependantOnQuestionId,
} from '~/helpers/onboarding.helper';
import { KycQuestionnaireQuestionNames } from '~/enums';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';
import { IOnboardingKYCQuestionnaireAnswer } from '~/interfaces/Onboarding';
import { updateKYCAnswers } from '~/store/slices/cardOnboardingOffer.slice';

const KYCQuestionnaire = () => {
  const validationContext = useContext(ValidationContext);

  const dispatch = useDispatch();
  const {
    cardOnboardingOffer: {
      form: { kycQuestionnaireQuestions, kycQuestionnaireAnswers },
      apiStatus,
    },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (kycQuestionnaireQuestions.length === 0) {
      dispatch(fetchKYCQuestionsAndAnswers());
    }
  }, [dispatch, kycQuestionnaireQuestions]);

  const t = useTranslate();

  const shouldShowHandleCashExtentQuestion = (
    questionnaireAnswers: IOnboardingKYCQuestionnaireAnswer[]
  ) => {
    const handleCashQuestionId = kycQuestionnaireQuestions.find(
      (question) =>
        question.name === KycQuestionnaireQuestionNames.handleCashQuestion ||
        question.name === KycQuestionnaireQuestionNames.handleCashQuestionNew
    )?.id;
    return questionnaireAnswers
      .find((answer) => answer.questionId === handleCashQuestionId)
      ?.answers?.some((answer) => answer?.answer === 'YES');
  };

  const filteredKYCQuestionnaireQuestions = shouldShowHandleCashExtentQuestion(
    kycQuestionnaireAnswers
  )
    ? kycQuestionnaireQuestions
    : kycQuestionnaireQuestions.filter(
        (question) =>
          question.name !==
            KycQuestionnaireQuestionNames.handleCashToWhatExtentQuestion &&
          question.name !==
            KycQuestionnaireQuestionNames.handleCashPercentageTurnoverQuestion
      );

  useEffect(() => {
    if (
      kycQuestionnaireAnswers.length !==
        filteredKYCQuestionnaireQuestions.length ||
      apiStatus.fetchKycQuestions === ApiStatus.Started
    ) {
      validationContext.manuallyInvalidateForm(true);
    }

    return () => {
      validationContext.manuallyInvalidateForm(false);
    };
  }, [kycQuestionnaireAnswers, filteredKYCQuestionnaireQuestions]);

  const onHandleKYCQuestionAndAnswerChange = (value: string, index: number) => {
    if (value.includes('_')) {
      const splitAnswer = value.split('_');

      const answer = splitAnswer[0];
      const answerId = Number(splitAnswer[1]);
      const questionId = Number(splitAnswer[2]);

      dispatch(
        updateKYCAnswers({
          answers: [{ answer, answerId }],
          questionId,
        })
      );

      dispatch(
        pushToGtmCardOnboardingAction({
          actionName: `Question ${index + 1} / ${
            filteredKYCQuestionnaireQuestions.length
          } answered/changed`,
        })
      );
    }
  };

  const isAnswerSelected = (questionId: number, answerId: number) => {
    const foundQuestion = kycQuestionnaireAnswers.find(
      (currentQuestion) => currentQuestion.questionId === questionId
    );
    return foundQuestion
      ? foundQuestion.answers.some(
          (currentAnswer) => currentAnswer.answerId === answerId
        )
      : false;
  };

  const getAnswerDetails = (
    questionId: number,
    questionnaireAnswers: IOnboardingKYCQuestionnaireAnswer[]
  ) => {
    const foundQuestion = questionnaireAnswers.find(
      (question) => question.questionId === questionId
    );
    if (foundQuestion) {
      const foundQuestionObject = foundQuestion.answers[0];
      return `${foundQuestionObject.answer}_${foundQuestionObject.answerId}_${questionId}`;
    }
    return '';
  };

  return (
    <Stack spacing={'xxl'}>
      {apiStatus.fetchKycQuestions === ApiStatus.Started ? (
        <SpinnerCircular color={theme.colors.secondaryGray} />
      ) : (
        filteredKYCQuestionnaireQuestions.map((question, index) => (
          <Stack spacing={'sm'} key={question.id}>
            {index < 3 && (
              <Label size="lg">
                {t('OnboardingOffer.Question')} {index + 1}/
                {kycQuestionnaireQuestions.length - 1}
              </Label>
            )}

            <Stack spacing={'lg'}>
              <Body size="lg">
                {t(getKYCQuestionTranslationDependantOnQuestionId(question.id))}
              </Body>

              {showRadioInputDependantOnQuestionId(question.id) ? (
                <Radio.Group
                  onChange={(value) =>
                    onHandleKYCQuestionAndAnswerChange(value, index)
                  }
                  value={getAnswerDetails(question.id, kycQuestionnaireAnswers)}
                >
                  {question?.answers?.map((answer) => (
                    <Radio
                      key={answer.id}
                      label={answer.name}
                      value={`${answer.name}_${answer.id}_${question.id}`}
                    />
                  ))}
                </Radio.Group>
              ) : (
                <Group direction="row">
                  <Button
                    size="md"
                    onClick={() =>
                      onHandleKYCQuestionAndAnswerChange(
                        `YES_${question.answers[0].id}_${question.id}`,
                        index
                      )
                    }
                    variant="secondary"
                    style={{ flexGrow: mobile ? 1 : 0 }}
                    data-active={isAnswerSelected(
                      question.id,
                      question.answers[0].id
                    )}
                  >
                    {t('Yes')}
                  </Button>
                  <Button
                    size="md"
                    onClick={() =>
                      onHandleKYCQuestionAndAnswerChange(
                        `NO_${question.answers[1].id}_${question.id}`,
                        index
                      )
                    }
                    variant="secondary"
                    style={{ flexGrow: mobile ? 1 : 0 }}
                    data-active={isAnswerSelected(
                      question.id,
                      question.answers[1].id
                    )}
                  >
                    {t('No')}
                  </Button>
                </Group>
              )}
            </Stack>
          </Stack>
        ))
      )}
    </Stack>
  );
};

export default KYCQuestionnaire;
