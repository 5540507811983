import { Body } from '@qred/components-library';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { useCurrencyFormatter } from '~/hooks/formatter/useCurrencyFormatter';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';

const createBoldAmount = (
  amount: number,
  currencyFormatter: ReturnType<typeof useCurrencyFormatter>
) => (
  <Body size="lg" weight={600}>
    {currencyFormatter.format(amount)}
  </Body>
);

const LoanOfferAdjustmentHeader = () => {
  const { overview } = useSelector((state: RootState) => state.onboardingOffer);
  const currencyFormatter = useCurrencyFormatter();
  const translate = useTranslate();

  const description = useMemo(() => {
    let translationKey = '';
    let values: {
      amount?: React.ReactNode;
      maxPayoutAmount?: React.ReactNode;
      currentLoanAmountLeft?: string;
    } = {};

    switch (overview.type) {
      case 'FIRST_LOAN':
      case 'REPEATED_LOAN':
        if (overview.amountCannotBeChanged) {
          translationKey =
            'OnboardingOffer.LoanOfferAdjustmentDescriptionNewLoanCannotBeChanged';
          values = {
            amount: createBoldAmount(
              overview.defaultPayoutAmount,
              currencyFormatter
            ),
          };
        } else {
          translationKey =
            'OnboardingOffer.LoanOfferAdjustmentDescriptionNewLoanCanBeChanged';
          values = {
            maxPayoutAmount: createBoldAmount(
              overview.maxPayoutAmount,
              currencyFormatter
            ),
          };
        }
        break;
      case 'CREDIT_LIMIT_CHANGE':
        if (overview.amountCannotBeChanged) {
          if (overview.maxPayoutAmount === 0) {
            translationKey =
              'OnboardingOffer.LoanOfferAdjustmentDescriptionPaymentPlan';
          } else {
            translationKey =
              'OnboardingOffer.LoanOfferAdjustmentDescriptionCreditLimitChangeCannotBeChanged';
            values = {
              amount: createBoldAmount(
                overview.defaultPayoutAmount,
                currencyFormatter
              ),
              currentLoanAmountLeft: currencyFormatter.format(
                overview.currentLoanAmountLeft ?? 0
              ),
            };
          }
        } else {
          translationKey =
            'OnboardingOffer.LoanOfferAdjustmentDescriptionCreditLimitChangeCanBeChanged';
          values = {
            maxPayoutAmount: createBoldAmount(
              overview.maxPayoutAmount,
              currencyFormatter
            ),
            currentLoanAmountLeft: currencyFormatter.format(
              overview.currentLoanAmountLeft ?? 0
            ),
          };
        }
        break;
    }

    return (
      <Body size="lg" data-testid="description">
        {translate(translationKey, values)}
      </Body>
    );
  }, [overview]);

  const title = useMemo(() => {
    let translationKey = '';
    if (overview.type === 'FIRST_LOAN' || overview.type === 'REPEATED_LOAN') {
      translationKey = 'OnboardingOffer.LoanOfferAdjustmentTitleNewLoan';
    }

    if (overview.type === 'CREDIT_LIMIT_CHANGE') {
      translationKey =
        overview.maxPayoutAmount === 0
          ? 'OnboardingOffer.LoanOfferAdjustmentTitlePaymentPlan'
          : 'OnboardingOffer.LoanOfferAdjustmentTitleCreditLimitChange';
    }

    return translate(translationKey) as string;
  }, [overview]);

  return (
    <StepHeader
      title={title}
      description={description}
      shouldTranslate={false}
    />
  );
};

export default LoanOfferAdjustmentHeader;
