import constVars from '~/constants/constVars';
import dayjs from 'dayjs';
import { removeSpecialCharacters } from './formatters.helper';
import { parseMarket } from './market.helper';
import moment from 'moment';
import { CountryCode } from '@qred/shared-enums';

export const getAuth0Audience = () => {
  switch (import.meta.env.MODE) {
    case 'production':
      return constVars.AUTH_PROD.AUDIENCE;
    case 'internal-prod':
      return constVars.AUTH_INTERNAL_PROD.AUDIENCE;
    case 'test':
    case 'development':
      return constVars.AUTH_TEST.AUDIENCE;
    default:
      throw new Error('Invalid environment variable is being used!');
  }
};

export function getCookieValue(cname: string) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  const correctCookie = cookies.find(
    (cookie) => cookie.trim().indexOf(name) === 0
  );
  return correctCookie
    ? correctCookie.trim().substring(name.length, correctCookie.length)
    : null;
}

export function randomId() {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0].toString(36).slice(2, 11);
}

export type FirstDayOfWeek = 'sunday' | 'monday';

export interface DayModifiers {
  selected: boolean;
  disabled: boolean;
  weekend: boolean;
  outside: boolean;
}

export function getStartOfWeek(
  date: Date,
  firstDayOfWeek: FirstDayOfWeek = 'monday'
) {
  const value = new Date(date);
  const day = value.getDay() || 7;
  const isSunday = firstDayOfWeek === 'sunday';

  const clampToFirstDay = isSunday ? day : day - 1;

  if ((isSunday && day !== 0) || day !== 1) {
    value.setHours(-24 * clampToFirstDay);
  }

  return value;
}

export function getEndOfWeek(
  date: Date,
  firstDayOfWeek: FirstDayOfWeek = 'monday'
) {
  const value = new Date(date);
  const day = value.getDay();
  const isSunday = firstDayOfWeek === 'sunday';

  const clampToLastDay = 7 - (isSunday ? day + 1 : day);

  if ((isSunday && day !== 6) || day !== 0) {
    value.setDate(value.getDate() + clampToLastDay);
  }

  return value;
}

export function getMonthDays(
  month: Date,
  firstDayOfWeek: FirstDayOfWeek = 'monday'
): Date[][] {
  const currentMonth = month.getMonth();
  const startOfMonth = new Date(month.getFullYear(), currentMonth, 1);
  const endOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);
  const endDate = getEndOfWeek(endOfMonth, firstDayOfWeek);
  const date = getStartOfWeek(startOfMonth, firstDayOfWeek);
  const weeks: Date[][] = [];

  while (date <= endDate) {
    const days: Date[] = [];

    for (let i = 0; i < 7; i += 1) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    weeks.push(days);
  }

  return weeks;
}

export function getWeekdaysNames(
  locale: string,
  firstDayOfWeek: FirstDayOfWeek = 'monday',
  format = 'dd'
) {
  const names: string[] = [];
  const date = getStartOfWeek(new Date(), firstDayOfWeek);
  for (let i = 0; i < 7; i += 1) {
    names.push(dayjs(date).locale(locale).format(format));
    date.setDate(date.getDate() + 1);
  }

  return names;
}

export function getDecadeRange(year: number) {
  const rounded = year - (year % 10) - 1;
  const range: number[] = [];
  for (let i = 0; i < 12; i += 1) {
    const rangeYear = rounded + i;
    range.push(rangeYear);
  }

  return range;
}

export function getMonthsNames(locale: string, format = 'MMM') {
  const names: string[] = [];
  const date = new Date(2021, 0, 1);

  for (let i = 0; i < 12; i += 1) {
    names.push(dayjs(date).locale(locale).format(format));
    date.setMonth(date.getMonth() + 1);
  }

  return names;
}

export const range = (low: number, high: number) => {
  const list = [];
  for (let i = low; i <= high; i += 1) {
    list.push(i);
  }
  return list;
};

export const getMarketBasedOnHostname = (): CountryCode | null => {
  // when running localhost, either manually or with cypress, we need to
  // get the market from an env variable (manual localhost) or local storage (cypress)
  // as the hostname will be localhost
  if (window.location.hostname === 'localhost') {
    if (import.meta.env.VITE_CYPRESS) {
      return parseMarket(localStorage.getItem('qredTestMarket'));
    }

    return parseMarket(import.meta.env.VITE_LOCALHOST_MARKET);
  }

  const [topLevelDomain, domain] = window.location.hostname
    .split('.')
    .reverse();

  if (domain === 'qeld') {
    return CountryCode.NL;
  }

  return parseMarket(topLevelDomain);
};

/**
 *
 * @param org1 company number or connect id
 * @param org2 company number or connect id
 * @returns
 */
export const areSameCompanies = (
  org1?: string | null,
  org2?: string | null
) => {
  if (!org1 || !org2) {
    return false;
  }

  return org1?.replace(/\D/g, '') === org2?.replace(/\D/g, '');
};

export const formatPersonalNumberForComparison = (pn: string) => {
  const market = (window.__QRED_MARKET__ || 'se').toUpperCase();
  // some markets have letters in the personal number that could be lower or uppercase
  let formattedPn = pn.toLowerCase();
  if (market === CountryCode.SE) {
    // remove '-'
    formattedPn = removeSpecialCharacters(formattedPn);
    // make YYYY -> YY
    formattedPn =
      formattedPn.length === 12 ? formattedPn.substring(2) : formattedPn;
  }
  if (market === CountryCode.DK) {
    // remove '-'
    formattedPn = removeSpecialCharacters(formattedPn);
  }
  return formattedPn;
};

export const personalNumbersAreTheSame = (pn1?: string, pn2?: string) => {
  if (!pn1 || !pn2) return false;

  return (
    formatPersonalNumberForComparison(pn1) ===
    formatPersonalNumberForComparison(pn2)
  );
};

/**
 * @param timestamp - The unix timestamp in milliseconds to calculate the time left until.
 * @returns {string} - The time left until the given timestamp, in the format 'mm:ss'.
 */
export const calculateTimeLeft = (timestamp: number) => {
  const differenceInMs = timestamp - Date.now();
  if (differenceInMs < 0) return '';
  return moment(differenceInMs).format('mm:ss');
};

/**
 * Generate an array of values for a slider.
 * @param min minimum value
 * @param max maximum value
 * @param step step between values
 * @returns an array of values
 */
export const generateSliderValues = (
  min: number,
  max: number,
  step: number
) => {
  const length = Math.ceil((max - min) / step);
  const values = Array.from({ length }, (_, i) => min + i * step);
  values.push(max);
  return values;
};

/**
 * Find the closest value in an array to a target value.
 * @param arr array of numbers
 * @param target target number
 * @returns the closest number in the array to the target number
 */
export const findClosestValueInArray = (arr: number[], target: number) => {
  return arr.reduce(
    (prev, curr) =>
      Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev,
    arr[0]
  );
};

export const isEmptyObject = (obj: Object): boolean =>
  Object.keys(obj).length === 0;

export const truncateFileName = (fileName: string, maxLength: number) => {
  return fileName.length > maxLength
    ? `${fileName.substring(0, maxLength)}...`
    : fileName;
};
