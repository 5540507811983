import {
  Banner,
  Body,
  Controller,
  H8,
  Input,
  Label,
  Loader,
  Radio,
  Stack,
  useForm,
  z,
} from '@qred/components-library';
import debounce from 'lodash.debounce';
import React, { useCallback, useContext, useEffect } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationContext } from '~/components/hoc/withValidation';
import { AddressSearch } from '~/components/shared/AddressSearch/AddressSearch';
import { FormErrorBanner } from '~/components/shared/FormErrorBanner/FormErrorBanner';
import { PostcodeAddress } from '~/hooks/usePostcode';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import { useLazyGetCompanyInfoBySearchQuery } from '~/store/apis/endpoints/onboardingUnauthenticatedApi/companySearch/companySearch.api';
import {
  resetCompanyInForm,
  setIsCompanyTypeCategoryRequested,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';

export const CompanySearchGermany = () => {
  const dispatch = useDispatch();
  const { market } = useSelector((state: RootState) => state.intl);
  const { companyName, connectId, companyAddress } = useSelector(
    (state: RootState) => state.onboardingApplication.form
  );
  const validationContext = useContext(ValidationContext);
  const t = useTranslate();

  const CompanySearchSchema = z
    .object({
      companyName: z
        .string()
        .trim()
        .min(1, { message: 'ValidationErrors.Required' }),
      companyAddress: z
        .string()
        .trim()
        .min(1, { message: 'ValidationErrors.Required' }),
      selectedAddress: z
        .object({
          city: z.string(),
          street: z.string(),
          zipCode: z.string(),
        })
        .nullable(),
    })
    .refine(
      (data) => {
        if (data.selectedAddress === null && data.companyAddress !== '') {
          return false;
        }

        return true;
      },
      {
        message: 'ValidationErrors.NotSelectedFromAddressSearchResults',
        path: ['companyAddress'],
      }
    );

  type CompanySearchForm = z.infer<typeof CompanySearchSchema>;

  const {
    setValue,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<CompanySearchForm>({
    schema: CompanySearchSchema,
    defaultValues: {
      companyName: '',
      companyAddress: '',
      selectedAddress: companyAddress || null,
    },
    mode: 'onTouched',
  });

  const fieldValues = watch();

  const [
    getCompanyInfoBySearch,
    { data: companySearchResults, isFetching, isUninitialized, isError },
  ] = useLazyGetCompanyInfoBySearchQuery();

  const handleSelectedAddress = (selectedAddress: PostcodeAddress | null) => {
    if (selectedAddress) {
      const {
        postcode,
        street,
        buildingNumber,
        locality,
      } = selectedAddress.address;

      const fullStreet = `${street} ${buildingNumber ?? ''}, ${postcode}`;

      setValue(
        'selectedAddress',
        {
          city: locality,
          street: fullStreet,
          zipCode: postcode,
        },
        { shouldValidate: true }
      );

      setValue('companyAddress', `${locality}, ${fullStreet}`, {
        shouldValidate: true,
      });
    }
  };

  const handleSelectedCompany = (companyConnectId: string | undefined) => {
    const targetCompany = companySearchResults?.find(
      (company) => company.connectId === companyConnectId
    );

    if (targetCompany) {
      dispatch(
        updateForm({
          organizationNumber: targetCompany.companyNumber,
          companyName: targetCompany.companyName,
          vatNumber: targetCompany.vatNumber,
          connectId: targetCompany.connectId,
          companyAddress: targetCompany.address
            ? {
                street: targetCompany.address.street,
                zipCode: targetCompany.address.postcode,
                city: targetCompany.address.city,
              }
            : undefined,
          companyTypeCategory: targetCompany.companyTypeCategory,
          companyType: targetCompany.companyType,
        })
      );
      if (targetCompany.companyTypeCategory) {
        dispatch(setIsCompanyTypeCategoryRequested(true));
      }
    }
  };

  const getCompanyInfoBySearchDebounced = useCallback(
    debounce(
      (name: string, address: string) =>
        getCompanyInfoBySearch({ searchInput: name, market, address }, true),
      1000
    ),
    []
  );

  const onChangeCompanyName = (
    field: ControllerRenderProps<CompanySearchForm, 'companyName'>,
    value: string
  ) => {
    field.onChange(value);
    dispatch(resetCompanyInForm());
  };

  const onChangeCompanyAddress = (
    field: ControllerRenderProps<CompanySearchForm, 'companyAddress'>,
    value: string
  ) => {
    setValue('selectedAddress', null);
    field.onChange(value);
    dispatch(resetCompanyInForm());
  };

  useEffect(() => {
    if (
      fieldValues.companyName.trim() &&
      fieldValues.companyAddress.trim() &&
      fieldValues.selectedAddress
    ) {
      getCompanyInfoBySearchDebounced(
        fieldValues.companyName,
        fieldValues.companyAddress
      );
    }
  }, [
    fieldValues.companyName,
    fieldValues.companyAddress,
    fieldValues.selectedAddress,
  ]);

  useEffect(() => {
    if (!connectId) {
      validationContext.addPropertyToValidationErrors('companySelectorStep');
    } else {
      validationContext.removePropertyFromValidationErrors(
        'companySelectorStep'
      );
    }

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'companySelectorStep'
      );
    };
  }, [connectId]);

  return (
    <Stack>
      <Stack spacing={'xs'}>
        <Input.Group orientation="vertical">
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <Input
                label={t('Onboarding.SelectCompanySearchByCompanyNameLabel')}
                onChange={(event) =>
                  onChangeCompanyName(field, event.target.value)
                }
                onBlur={field.onBlur}
                error={
                  errors.companyName?.message && t(errors.companyName.message)
                }
              />
            )}
          />
          <Controller
            name="companyAddress"
            control={control}
            render={({ field }) => (
              <AddressSearch
                label={t('Onboarding.SelectCompanySearchByCompanyAddressLabel')}
                handleResult={handleSelectedAddress}
                onChange={(value) => onChangeCompanyAddress(field, value)}
                onBlur={field.onBlur}
                error={
                  errors.companyAddress?.message &&
                  t(errors.companyAddress.message)
                }
              />
            )}
          />
        </Input.Group>
        <FormErrorBanner errors={errors} />
      </Stack>

      {connectId && isUninitialized && (
        <Stack mt="md">
          <H8>Selected company:</H8>
          <Radio.Group direction="column" value={connectId}>
            <Radio
              label={companyName}
              hint={
                companyAddress &&
                `${companyAddress.street}, ${companyAddress.zipCode}, ${companyAddress.city}`
              }
              value={connectId}
            />
          </Radio.Group>
        </Stack>
      )}

      {!isError && !isFetching && companySearchResults && isValid && (
        <Stack mt="md">
          <H8>
            {t('Onboarding.SelectCompanySearchByNameAndAddressSearchResults', {
              count: companySearchResults.length,
            })}
          </H8>
          <Radio.Group
            direction="column"
            onChange={handleSelectedCompany}
            value={connectId}
          >
            {companySearchResults.map((result) => {
              return (
                <Radio
                  key={result.connectId}
                  label={result.companyName}
                  hint={
                    !!result.address &&
                    `${result.address.street}, ${result.address.postcode},
                      ${result.address.city}`
                  }
                  value={result.connectId}
                />
              );
            })}
          </Radio.Group>
        </Stack>
      )}

      {isError && isValid && (
        <Stack mt="md">
          <H8>
            {t('Onboarding.SelectCompanySearchByNameAndAddressSearchResults', {
              count: 0,
            })}
          </H8>
          <Body size={'lg'}>
            {t(
              'Onboarding.SelectCompanySearchByNameAndAddressNoSearchResultsFound'
            )}
          </Body>
          <Banner
            message={
              <Stack spacing="xs">
                <Label size="lg">
                  {t('Onboarding.NeedCompanyRegistrationTitle')}
                </Label>
                <Body size="lg">
                  {t('Onboarding.NeedCompanyRegistrationDescription')}
                </Body>
              </Stack>
            }
            state="informative"
            opened={true}
            withCloseButton={false}
          />
        </Stack>
      )}

      {isFetching && (
        <Stack align="center" justify="center" mt={'md'}>
          <Loader />
        </Stack>
      )}
    </Stack>
  );
};
