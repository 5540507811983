import React from 'react';
import {
  Button,
  Box,
  Divider,
  Stack,
  useWindowProperties,
  Body,
  Link,
} from '@qred/components-library';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import {
  openQredCardGeneralTermsPage,
  openQredCardPricesPage,
} from '~/services/links';
import { sendSignersCardOfferData } from '~/store/actions/additionalSignerActions';

export const CardAdditionalSignerFooter = () => {
  const dispatch = useDispatch();

  const {
    intl: { market, language },
    additionalSigner: {
      apiStatus: { postAdditionalSignerCardApplication },
    },
  } = useSelector((state: RootState) => state);
  const translate = useTranslate();
  const { isMobile } = useWindowProperties();

  const onConfirmClick = () => {
    dispatch(sendSignersCardOfferData());
  };

  return (
    <Box>
      <Divider thickness={1} color="neutral.16" />
      <Stack spacing={'md'} align="center" p={isMobile ? 'md' : 'xxl'}>
        <Body size="sm">
          {translate('CreditCardTermsAndConditionsCard.Text3', {
            generalTermLink: (
              <Link
                size="sm"
                onClick={() => openQredCardGeneralTermsPage(market, language)}
              >
                {translate('CreditCardTermsAndConditionsCard.Link1').toString()}
              </Link>
            ),
            qredCardPricesLink: (
              <Link
                size="sm"
                onClick={() => openQredCardPricesPage(market, language)}
              >
                {translate(
                  'CreditCardTermsAndConditionsCard.Link2Legacy'
                ).toString()}
              </Link>
            ),
          })}
        </Body>
        <Button
          fullWidth={isMobile}
          onClick={onConfirmClick}
          size="md"
          isLoading={postAdditionalSignerCardApplication === ApiStatus.Started}
        >
          {translate('ConfirmTermsCard.ConfirmAgreement')}
        </Button>
      </Stack>
    </Box>
  );
};
