import {
  Badge,
  Body,
  Group,
  H6,
  MaterialIcon,
  Stack,
} from '@qred/components-library';
import React from 'react';
import { Timeline } from './Timeline';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import { applicationCanHaveGuarantors } from '~/helpers/onboarding.helper';
import { useSelector } from 'react-redux';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';

export const Checkpoint = () => {
  const t = useTranslate();
  const {
    intl: { market },
    onboardingApplication: {
      form: { companyTypeCategory, guarantors },
      apiStatus: { guarantors: guarantorsApiStatus },
    },
  } = useSelector((state: RootState) => state);
  const { marketHasEskat } = useOnboardingMarketConfig();

  const _applicationCanHaveGuarantors = applicationCanHaveGuarantors(
    market,
    companyTypeCategory,
    guarantors,
    guarantorsApiStatus
  );

  const items: React.JSX.Element[] = [
    <Stack key="basic_application_sent" spacing="sm" mt={3}>
      <H6>{t('Onboarding.CheckpointTitle')}</H6>
      <Body size="lg" mb="xxl">
        {t('Onboarding.CheckpointDescription')}
      </Body>
    </Stack>,

    ...(_applicationCanHaveGuarantors
      ? [
          <Group
            key="guarantors_step"
            align="center"
            justify="space-between"
            w="100%"
          >
            <Body>{t('Onboarding.CheckpointGuarantorsStep')}</Body>
            <Badge color="Main/Grey/300" size="sm">
              {t('Onboarding.CheckpointIncompleteStep')}
            </Badge>
          </Group>,
        ]
      : []),

    ...(marketHasEskat
      ? [
          <Group
            key="eskat_step"
            align="center"
            justify="space-between"
            w="100%"
          >
            <Body>{t('Onboarding.CheckpointEskatStep')}</Body>
            <Badge color="Main/Grey/300" size="sm">
              {t('Onboarding.CheckpointIncompleteStep')}
            </Badge>
          </Group>,
        ]
      : []),

    <Group
      key="turnover_steps"
      align="center"
      justify="space-between"
      w="100%"
      pb={40}
    >
      <Body>{t('Onboarding.CheckpointTurnoverStep')}</Body>
      <Badge color="Main/Grey/300" size="sm">
        {t('Onboarding.CheckpointIncompleteStep')}
      </Badge>
    </Group>,

    <Stack key="boosted_application" mt={3}>
      <H6>{t('Onboarding.CheckpointBoostedApplicationStep')}</H6>
      <Body size="lg">
        {t('Onboarding.CheckpointBoostedApplicationStepDescription')}
      </Body>
      <Stack m="sm">
        <Group align="center">
          <MaterialIcon
            src="star"
            bg="Container/Option_6_(Light_Green)"
            size={28}
          />
          <Body size="lg">
            {t('Onboarding.CheckpointBoostedApplicationStepDescriptionUSP1')}
          </Body>
        </Group>
        <Group align="center">
          <MaterialIcon
            src="speed"
            bg="Container/Option_6_(Light_Green)"
            size={28}
          />
          <Body size="lg">
            {t('Onboarding.CheckpointBoostedApplicationStepDescriptionUSP2')}
          </Body>
        </Group>
        <Group align="center">
          <MaterialIcon
            src="rocket_launch"
            bg="Container/Option_6_(Light_Green)"
            size={28}
          />
          <Body size="lg">
            {t('Onboarding.CheckpointBoostedApplicationStepDescriptionUSP3')}
          </Body>
        </Group>
      </Stack>
    </Stack>,
  ];

  return <Timeline items={items} completedIndex={1} />;
};
