export const posthogActionNames = {
  sidebar: {
    COMPANY_ITEM: 'company_item',
    SWITCH_COMPANY: 'switch_company',
    LOG_OUT: 'clicked_log_out',
    QRED_VISA: 'qred_visa',
    SELL_YOUR_INVOICES: 'sell_your_invoices',
    BECOME_A_TESTER: 'become_a_tester',
    CHANGE_LANGUAGE: 'language_menu',
  },
  dashboard: {
    STEPPER_LOAN_OFFER_EXPIRED: 'offer_expired',
    STEPPER_LOAN_DECISION: 'decision',
    STEPPER_PAY_OUT: 'pay_out',
    STEPPER_REVIEW: 'review',
    STEPPER_OFFER: 'offer',
    STEPPER_CONFIRMATION: 'confirmation',
    STEPPER_UPLOAD_DOCUMENTS: 'upload_documents',
    STEPPER_UPLOAD_DOCUMENTS_BUTTON_CLICKED: 'upload_documents_button_clicked',
    STEPPER_UPLOAD_DOCUMENTS_MODAL: 'upload_documents_modal_opened',
    STEPPER_UPLOAD_DOCUMENTS_MODAL_CLOSED: 'upload_documents_modal_closed',
    STEPPER_UPLOAD_DOCUMENTS_MODAL_SUBMIT: 'upload_documents_modal_submit',
    APPLY_FOR_LOAN: 'apply_now',
    APPLY_FOR_LOAN_INCREASE: 'apply_loan_increase',
    DOWNLOAD_INVOICE: 'download_invoice',
    DOWNLOAD_DOCUMENTS: 'download_documents',
    PREVIOUS_LOAN_DOWNLOAD_INVOICE: 'previous_loan_download_invoice',
    PREVIOUS_LOAN_DOWNLOAD_ALL_INVOICES: 'previous_loan_download_all_invoices',
    FINAL_INVOICE: 'final_invoice',
    PAID_INVOICE: 'paid_invoices',
    UPCOMING_INVOICES: 'upcoming_invoices',
    QRED_VISA: 'qred_visa',
    DOWNLOAD_TERMINATION_LETTER: 'download_termination_letter',
  },
  profile: {
    SAVE_PROFILE: 'save',
    EDIT_PROFILE: 'edit',
    CANCEL_PROFILE: 'cancel',
    UPDATE_PHONE_NUMBER: 'updates_PhoneNumber',
    UPDATE_EMAIL: 'updates_Email',
  },
  faq: {
    CATEGORY_CLICKED: 'category_clicked',
    QUESTION_CLICKED: 'question_clicked',
  },
  document: {
    DOWNLOAD: 'download',
    UPLOAD_DOCUMENT_SINGLE_BUTTON_CLICKED:
      'upload_document_single_button_clicked',
    UPLOAD_DOCUMENT_MULTIPLE_BUTTON_CLICKED:
      'upload_document_multiple_button_clicked',
    UPLOAD_DOCUMENT_SINGLE_SUBMIT_BUTTON_CLICKED:
      'upload_document_single_submit_button_clicked',
    UPLOAD_DOCUMENT_MULTIPLE_SUBMIT_BUTTON_CLICKED:
      'upload_document_multiple_submit_button_clicked',
    UPLOAD_DOCUMENT_SINGLE_FILE_BUTTON_CLICKED:
      'upload_document_single_file_button_clicked',
    UPLOAD_DOCUMENT_MULTIPLE_FILE_BUTTON_CLICKED:
      'upload_document_multiple_file_button_clicked',
    UPLOAD_DOCUMENTS_REPLACE_FILE_BUTTON_CLICKED:
      'upload_documents_replace_file_button_clicked',
    UPLOAD_DOCUMENTS_SINGLE_MODAL_CLOSED:
      'upload_documents_single_modal_closed',
    UPLOAD_DOCUMENTS_MULTIPLE_MODAL_CLOSED:
      'upload_documents_multiple_modal_closed',
    UPLOAD_DOCUMENTS_MULTIPLE_DONE_BUTTON_CLICKED:
      'upload_documents_multiple_done_button_clicked',
    UPLOAD_DOCUMENTS_SINGLE_OK_BUTTON_CLICKED:
      'upload_documents_single_ok_button_clicked',
  },
  loanIncrease: {
    APPLY: 'apply_loan_increase',
  },
  invoices: {
    DOWNLOAD_ALL: 'download_all_invoices',
    DOWNLOAD_INVOICE: 'download_invoice',
    SWITCH_CONTRACTS: 'switch_contracts',
    FINAL_INVOICE: 'final_invoice',
    SET_UP_SEPADD: 'set_up_sepadd',
  },
  account: {
    APPLY_FOR_LOAN: 'apply_now',
  },
};
