import { usePostHog } from 'posthog-js/react';
import React from 'react';
import { useSelector } from 'react-redux';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { useGetBankAccountsQuery } from '~/store/apis/endpoints/onboardingApi/onboarding.api';
import { RootState } from '~/store/types/sharedTypes';

const BankInformationHeader = () => {
  const { bankAccounts, clientId } = useSelector(
    (state: RootState) => state.onboardingOffer.overview
  );
  const postHog = usePostHog();
  const marketHasEnableBankingFeature = Boolean(
    postHog.isFeatureEnabled('Onboarding_MarketHasEnableBankingFeature')
  );

  const { data: openBankingAccounts } = useGetBankAccountsQuery(
    { clientId },
    {
      skip: !clientId || !marketHasEnableBankingFeature,
    }
  );

  const title = 'OnboardingOffer.ConfirmBankAccountTitle';
  const hasBankAccountsViaKlarnaKosma = Boolean(bankAccounts?.length);
  const hasBankAccountsViaOpenBanking = Boolean(openBankingAccounts?.length);

  const hasBankAccounts =
    hasBankAccountsViaKlarnaKosma || hasBankAccountsViaOpenBanking;
  const description = hasBankAccounts
    ? 'OnboardingOffer.ConfirmBankAccountDescriptionIfExist'
    : 'OnboardingOffer.ConfirmBankAccountDescriptionIfNotExist';

  return <StepHeader title={title} description={description} />;
};

export default BankInformationHeader;
