import { Body, H4, Stack, useWindowProperties } from '@qred/components-library';
import { numberFormatter } from '@qred/fe-cp-formatting-helpers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { LabelValueGrid } from '~/components/shared/Layout/LabelValueGrid';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import constVars from '~/constants/constVars';
import { formatAmount } from '~/helpers/formatters.helper';
import useTranslate from '~/hooks/useTranslate';
import { fetchAdditionalSignerCardApplication } from '~/store/actions/additionalSignerActions';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { CardAdditionalSignerFooter } from './CardAdditionalSignerFooter';

export const CardAdditionalSigner = () => {
  const dispatch = useDispatch();

  const {
    intl: { market },
    additionalSigner: { additionalSignerCardApplicationOffer, apiStatus },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();
  const history = useHistory();

  const { isMobile } = useWindowProperties();

  const formatter = numberFormatter(market);

  useEffect(() => {
    dispatch(fetchAdditionalSignerCardApplication(market));
  }, []);

  useEffect(() => {
    if (apiStatus.fetchAdditionalSignerCardApplication === ApiStatus.Failed) {
      history.push(constVars.ROUTE_SIGNER_ERROR);
    }
  }, [apiStatus.fetchAdditionalSignerCardApplication]);

  const cardDetailsLabelAndValue = [
    {
      label: translate('CreditCardDetailsCard.CompanyName') as string,
      value: additionalSignerCardApplicationOffer.companyName,
    },
    {
      label: translate('CreditCardDetailsCard.ApprovedCredit') as string,
      value: formatAmount(
        additionalSignerCardApplicationOffer.offeredCreditLimit
      ),
    },
    {
      label: translate('CreditCardDetailsCard.MonthlyInterest') as string,
      value: `${formatter(additionalSignerCardApplicationOffer.interest)} %`,
    },
    {
      label: translate('CreditCardDetailsCard.YearlyMonthlyFee') as string,
      value: formatAmount(0),
    },
    {
      label: translate('CreditCardDetailsCard.PlasticCardFee') as string,
      value: formatAmount(0),
    },
    {
      label: translate('OnboardingCardOffer.BenefitsCashback') as string,
      value: '1 %',
    },
  ];

  return additionalSignerCardApplicationOffer.cardApplicationUuid ? (
    <>
      <Stack p={'xxl'} spacing={'xxl'}>
        <Stack>
          <H4>{translate('ConfirmOffer')}</H4>
          <Body size="lg">
            {translate('OnboardingCardOffer.ConfirmOfferDescription')}
          </Body>
        </Stack>
        <LabelValueGrid items={cardDetailsLabelAndValue} isMobile={isMobile} />
      </Stack>
      <CardAdditionalSignerFooter />
    </>
  ) : (
    <SpinnerCircular />
  );
};
