import { LoanPurposes } from '~/enums';
import useTranslate from './useTranslate';

export const useLoanPurposeTranslationMapper = () => {
  const t = useTranslate();

  const selectedPurposeOfLoan = {
    [LoanPurposes.Marketing]: t('LoanPurposeMarketing') as string,
    [LoanPurposes.Hiring]: t('LoanPurposeStaff') as string,
    [LoanPurposes.Expansion]: t('LoanPurposeExpansion') as string,
    [LoanPurposes.Equipment]: t('LoanPurposeEquipment') as string,
    [LoanPurposes.RepayOtherLoan]: t('LoanPurposeDebt') as string,
    [LoanPurposes.Inventory]: t('LoanPurposeInventory') as string,
    [LoanPurposes.Working]: t('LoanPurposeWorkingCapital') as string,
    [LoanPurposes.Other]: t('LoanPurposeOther') as string,
  };

  return selectedPurposeOfLoan;
};
