import React from 'react';
import {
  Button,
  notifications,
  Stack,
  Body,
  Link,
  Box,
  Divider,
  useWindowProperties,
} from '@qred/components-library';
import { confirmContractAction } from '~/store/actions/additionalSignerActions';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { logToSentry } from '~/helpers/loggers.helper';
import useTermsAndConditionsModal from '~/hooks/useTermsAndConditionsModal';

const LoanAdditionalSignerFooter = () => {
  const {
    additionalSigner: {
      additionalSignerLoanApplicationOffer: { granter },
      apiStatus: { postAdditionalSignerLoanApplication },
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const translate = useTranslate();
  const { openTermsAndConditionsModal } = useTermsAndConditionsModal();
  const { isMobile } = useWindowProperties();

  const onConfirmLoanClick = () => {
    dispatch(confirmContractAction());
  };

  const openModal = () => {
    if (granter) {
      openTermsAndConditionsModal(granter);
    } else {
      notifications.show({
        state: 'critical',
        message: translate('SomethingWentWrong'),
        opened: true,
      });
      logToSentry(
        new Error('Granter is missing for application'),
        'GranterIsMissingForApplication'
      );
    }
  };

  return (
    <Box>
      <Divider thickness={1} color="neutral.16" />
      <Stack spacing={'md'} align="center" p={isMobile ? 'md' : 'xxl'}>
        <Body size="sm">
          {translate('ConfirmTermsCard.UserAgreementText')}
          <Link size="sm" onClick={openModal} ml={'xs'}>
            {translate('ConfirmTermsCard.UserAgreementLink')}
          </Link>
        </Body>
        <Button
          fullWidth={isMobile}
          onClick={onConfirmLoanClick}
          size="md"
          isLoading={postAdditionalSignerLoanApplication === ApiStatus.Started}
        >
          {translate('ConfirmTermsCard.ConfirmAgreement')}
        </Button>
      </Stack>
    </Box>
  );
};

export default LoanAdditionalSignerFooter;
