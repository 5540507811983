import React from 'react';
import Button, { ButtonType } from '~/components/shared/Button/button';
import {
  FontWeights,
  Typography,
} from '~/components/shared/Typography/Typography';
import styled from 'styled-components';
import useTranslate from '~/hooks/useTranslate';
import { openMailToQredSupport, openQredHomePage } from '~/services/links';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { ContactEmails, MarketValues } from '~/constants/markets';
import { Link } from '@qred/components-library';

const TitleContainer = styled.div`
  padding: 0 1em;
  margin: auto;
`;

const TextContainer = styled.div`
  padding: 1em;
`;

const ButtonContainer = styled.div`
  padding: 1em;
`;

const Separator = styled.div`
  height: 1em;
`;

const ThankYouCardContentContractSigned = () => {
  const translate = useTranslate();
  const { market, language } = useSelector((state: RootState) => state.intl);

  const ClickHereTag = (
    <Link onClick={() => openMailToQredSupport(market)}>
      {ContactEmails[market.toUpperCase() as keyof MarketValues<string>]}
    </Link>
  );

  return (
    <>
      <TitleContainer>
        <Typography
          centerAlign
          lineHeight={1.5}
          fontSize={1.2}
          fontWeight={FontWeights.Thick}
        >
          {translate('ThankYouCardContentCardOffer.Title')}
        </Typography>
      </TitleContainer>
      <TextContainer>
        <Typography centerAlign lineHeight={2} fontSize={0.8} element="p">
          {translate('ThankYouCardContentCardOffer.Text1')}
        </Typography>
        <Separator />
        <Typography centerAlign lineHeight={2} fontSize={0.8} element="p">
          {translate('ThankYouCardContentCardOffer.Text3', {
            link: ClickHereTag,
          })}
        </Typography>
      </TextContainer>
      <ButtonContainer>
        <Button
          type={ButtonType.Filled}
          onClick={() => openQredHomePage(market, language)}
        >
          {translate('ThankYouCardContentCardOffer.ButtonText')}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default ThankYouCardContentContractSigned;
