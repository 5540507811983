import React from 'react';
import styled from 'styled-components';
import Popup from '~/components/shared/Popup/Popup';
import Image from '~/components/shared/Image/Image';
import useTranslate from '~/hooks/useTranslate';
import { Body, Center } from '@qred/components-library';

const ImageContainer = styled.div`
  width: 6em;
  margin: auto;
`;

const RedirectFromEskatModal: React.FC = () => {
  const translate = useTranslate();

  return (
    <Popup
      isOpen
      onOkButtonClick={() => window.location.assign('https://www.qred.com')}
      buttonText="Continue"
    >
      <ImageContainer>
        <Image src="/rocket.png" />
      </ImageContainer>
      <Center>
        <Body size="lg">{translate('RedirectFromEskatModal.Text1')}</Body>
      </Center>
    </Popup>
  );
};

export default RedirectFromEskatModal;
