import { cleanObjectWithUndefinedProperties } from './cleanObjectWithUndefinedProperties';

export const generateParams = (params: Record<string, any>) => {
  cleanObjectWithUndefinedProperties(params);

  const _params = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((item) => _params.append(key, item));
    } else {
      _params.append(key, value);
    }
  }

  return _params.toString();
};
