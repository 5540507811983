import React from 'react';
import { Stack } from '@qred/components-library';
import SelectPaymentMethod from './components/SelectPaymentMethod';
import { useOnboardingOfferSelectors } from '~/store/selectors/onboardingOffer.selector';
import SelectPayoutAccount from './components/SelectPayoutAccount';

const BankInformation = () => {
  const { hasSepaDirectDebitFeature } = useOnboardingOfferSelectors();

  return (
    <Stack spacing={'xxl'}>
      <SelectPayoutAccount />

      {hasSepaDirectDebitFeature && <SelectPaymentMethod />}
    </Stack>
  );
};

export default BankInformation;
