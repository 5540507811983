import { Dispatch } from 'redux';
import { CountryCode, GlobalErrorType } from '~/enums';
import { companyIsSoleTrader } from '~/constants/markets';
import {
  apiGetCardOnboardingOffer,
  apiGetOnboardingKYCQuestionsAndAnswers,
  apiPostCardOnboardingOffer,
  apiPostOnboardingKYCAnswers,
} from '~/services/api/onboarding';
import { PostCardOfferCallback } from '~/types/types';
import { cardOnboardingOfferDTOMappers } from '~/mappers/onboarding/DTO/cardOnboardingOffer';
import { ApiStatus, AppThunk, GetStateFunc } from '../types/sharedTypes';
import {
  setApiStatus,
  setBeneficialOwners,
  updateForm,
  updateOverview,
} from '../slices/cardOnboardingOffer.slice';
import { pushToSentryAction } from './sentryActions';
import { toggleGlobalErrorOn } from '../slices/globalError.slice';

export const fetchCardOfferData = (clientId: number): AppThunk => async (
  dispatch: Dispatch<any>,
  getState: GetStateFunc
) => {
  dispatch(setApiStatus({ offer: ApiStatus.Started }));
  const { cardOnboardingOffer, intl } = getState();

  if (!cardOnboardingOffer) return;
  try {
    const res = await apiGetCardOnboardingOffer(clientId, intl.market);
    if (res && !res.message) {
      const mappedOffer = cardOnboardingOfferDTOMappers.offerFromDTO(res);

      dispatch(updateOverview(mappedOffer));
      dispatch(
        updateForm({
          clientId,
          applicationReasons: mappedOffer.applicationReasons,
          signers: mappedOffer.additionalSigners || [],
          applicant: {
            ...mappedOffer.applicant,
            percentage: companyIsSoleTrader(
              intl.market,
              mappedOffer.companyType
            )
              ? '100'
              : '',
          },
        })
      );
      dispatch(setBeneficialOwners(mappedOffer.beneficialOwners));
      dispatch(setApiStatus({ offer: ApiStatus.Completed }));
    } else {
      dispatch(setApiStatus({ offer: ApiStatus.Failed }));
    }
  } catch (err: any) {
    dispatch(
      pushToSentryAction(err, 'apiGetCardOnboardingOffer', {
        clientId,
      })
    );
    dispatch(setApiStatus({ offer: ApiStatus.Failed }));
  }
};

export const postCardOnboardingOffer = (
  {
    applicationUuid,
    market,
    clientId,
    isPublic,
    apiVersion,
  }: {
    applicationUuid: string;
    market: CountryCode;
    clientId: number;
    isPublic: boolean;
    apiVersion?: string;
  },
  postCardOfferCallback: PostCardOfferCallback
): AppThunk => async (dispatch, getState: GetStateFunc) => {
  const {
    cardOnboardingOffer: { form, overview, apiStatus },
    intl,
  } = getState();
  if (apiStatus.post === ApiStatus.Started) return;
  dispatch(setApiStatus({ post: ApiStatus.Started }));
  const transformedOfferDetails = cardOnboardingOfferDTOMappers.formAndOverviewToPostOfferDTO(
    form,
    overview,
    intl.market
  );

  try {
    const response = await apiPostCardOnboardingOffer({
      data: transformedOfferDetails,
      applicationUuid,
      market,
      clientId,
      apiVersion,
    });

    postCardOfferCallback(response);
  } catch (err: any) {
    dispatch(
      pushToSentryAction(err, 'apiPostCardOfferV2UserData', {
        clientId,
        public: isPublic,
      })
    );
    dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    dispatch(setApiStatus({ post: ApiStatus.Failed }));
    // TODO: Implement GTM event logging for error
    // dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Error }));
  }
};

export const fetchKYCQuestionsAndAnswers = (): AppThunk => (
  dispatch,
  getState: GetStateFunc
): void => {
  dispatch(setApiStatus({ fetchKycQuestions: ApiStatus.Started }));
  const { market } = getState().intl;

  apiGetOnboardingKYCQuestionsAndAnswers(market)
    .then((data) => {
      if (data) {
        dispatch(updateForm({ kycQuestionnaireQuestions: data.questions }));
      }
      dispatch(setApiStatus({ fetchKycQuestions: ApiStatus.Completed }));
    })
    .catch((err: any) => {
      dispatch(
        pushToSentryAction(err, 'apiGetOnboardingKYCQuestionsAndAnswers', {
          product: 'onboarding',
        })
      );
      dispatch(setApiStatus({ fetchKycQuestions: ApiStatus.Failed }));
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    });
};

export const postKYCAnswers = (clientId: number): AppThunk => async (
  dispatch,
  getState: GetStateFunc
): Promise<void> => {
  dispatch(setApiStatus({ postKycAnswers: ApiStatus.Started }));
  const { market } = getState().intl;
  const { kycQuestionnaireAnswers } = getState().cardOnboardingOffer.form;

  try {
    await apiPostOnboardingKYCAnswers(
      market,
      clientId,
      kycQuestionnaireAnswers
    );
    dispatch(setApiStatus({ postKycAnswers: ApiStatus.Completed }));
  } catch (error) {
    dispatch(
      pushToSentryAction(error, 'apiPostOnboardingKYCAnswers', {
        product: 'onboarding',
      })
    );
    dispatch(setApiStatus({ postKycAnswers: ApiStatus.Failed }));
    dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
  }
};
