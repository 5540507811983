import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '~/components/shared/CheckBox/CheckBox';
import { Body } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';
import { RootState } from '~/store/types/sharedTypes';
import { onChangeInputEvent } from '~/types/types';

const PersonalLiability = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { form } = useSelector((state: RootState) => state.onboardingOffer);

  const onChange = (event: onChangeInputEvent) => {
    const { checked } = event.target;
    dispatch(updateForm({ personalLiabilityIsAgreed: checked }));
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'personal_liability_change',
        stepActionNumber: 0.2,
      })
    );
  };

  return (
    <Checkbox
      checked={form.personalLiabilityIsAgreed}
      dataCy="onboarding_offer_liability_consent_checkbox"
      name="personalLiabilityIsAgreed"
      onChange={onChange}
      validationType="Required"
    >
      <Body size="sm">{translate('OnboardingOffer.LiabilityConsent')}</Body>
    </Checkbox>
  );
};

export default PersonalLiability;
