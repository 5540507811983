import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { ValidationContext } from '~/components/hoc/withValidation';
import {
  Center,
  Group,
  Link,
  Loader,
  MaterialIcon,
  Stack,
} from '@qred/components-library';
import KlarnaKosmaBankAccountList from './KlarnaKosmaBankAccountList';
import AccountNumberInput from './AccountNumberInput';
import { isEmptyObject } from '~/helpers/utils';
import { useGetBankAccountsQuery } from '~/store/apis/endpoints/onboardingApi/onboarding.api';
import BankConnection from '~/components/shared/BankConnection/BankConnection';
import OpenBankingAccountList from './OpenBankingAccountList';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';
import { BankConnectionResult } from '~/enums';
import useTranslate from '~/hooks/useTranslate';
import { usePostHog } from 'posthog-js/react';
import { getOpenBankingAccountNumber } from '~/helpers/onboarding.helper';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';

const SelectPayoutAccount = () => {
  const validationContext = useContext(ValidationContext);
  const [bankConnectionError, setBankConnectionError] = useState(false);
  const translate = useTranslate();
  const {
    onboardingOffer: { overview, form },
  } = useSelector((state: RootState) => state);
  const postHog = usePostHog();
  const marketHasEnableBankingFeature = Boolean(
    postHog.isFeatureEnabled('Onboarding_MarketHasEnableBankingFeature')
  );
  const {
    data: openBankingAccounts,
    isFetching: isFetchingBankAccounts,
    isError: fetchingBankAccountsFailed,
    refetch,
  } = useGetBankAccountsQuery(
    { clientId: overview.clientId },
    {
      skip: !overview.clientId || !marketHasEnableBankingFeature,
    }
  );
  const useEnableBankingFeature =
    marketHasEnableBankingFeature && !fetchingBankAccountsFailed;
  const dispatch = useDispatch();
  const [showAddNewAccount, setShowAddNewAccount] = useState(false);
  const showManualAccountInputField =
    form.accountNumber !== null ||
    !useEnableBankingFeature ||
    fetchingBankAccountsFailed;

  const [
    showButtonToSwitchToManualAccountInputField,
    setShowButtonToSwitchToManualAccountInputField,
  ] = useState(false);

  const hasBankAccountsViaKlarnaKosma = Boolean(overview.bankAccounts?.length);
  const hasBankAccountsViaOpenBanking = Boolean(openBankingAccounts?.length);
  const hasBankAccounts =
    hasBankAccountsViaKlarnaKosma || hasBankAccountsViaOpenBanking;

  useEffect(() => {
    if (
      isEmptyObject(form.account) &&
      !form.accountNumber &&
      !form.openBankingAccount
    ) {
      validationContext.addPropertyToValidationErrors('MissingAccount');
    }

    return () => {
      validationContext.removePropertyFromValidationErrors('MissingAccount');
    };
  }, [form.account, form.accountNumber, form.openBankingAccount]);

  useEffect(() => {
    if (
      !isFetchingBankAccounts &&
      (!hasBankAccounts || form.accountNumber !== null)
    ) {
      setShowAddNewAccount(true);
    }
  }, [isFetchingBankAccounts, hasBankAccounts, form.accountNumber]);

  const handleAccountSelected = (value: string) => {
    setShowAddNewAccount(false);
    const selectedAccount = openBankingAccounts?.find(
      (account) => getOpenBankingAccountNumber(account) === value
    );
    if (!selectedAccount) return;

    dispatch(
      updateForm({
        account: {},
        accountNumber: null,
        openBankingAccount: selectedAccount,
      })
    );
  };

  const handleAddNewAccount = () => {
    dispatch(
      updateForm({
        account: {},
        accountNumber: useEnableBankingFeature ? null : '',
        openBankingAccount: null,
      })
    );

    setShowAddNewAccount(true);
  };

  const onBankConnectionResult = (result: BankConnectionResult) => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: `bank_connection_${result}`,
      })
    );
    if (
      result === BankConnectionResult.Error ||
      result === BankConnectionResult.Cancelled
    ) {
      setShowButtonToSwitchToManualAccountInputField(true);
    }

    if (result === BankConnectionResult.Success) {
      setShowAddNewAccount(false);
      refetch();
    }
  };

  const onClickVerifyWithBank = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'switch_to_verify_with_bank_button_pressed',
      })
    );
    dispatch(updateForm({ accountNumber: null }));
  };

  const onClickFillInAccountManually = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'switch_to_fill_in_account_manually_button_pressed',
      })
    );
    dispatch(updateForm({ accountNumber: '' }));
    setBankConnectionError(false);
  };

  if (isFetchingBankAccounts) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack spacing={'xl'}>
      {useEnableBankingFeature && (
        <>
          {hasBankAccountsViaOpenBanking ? (
            <OpenBankingAccountList
              showAddNewAccount={showAddNewAccount}
              handleAccountSelected={handleAccountSelected}
              handleAddNewAccount={handleAddNewAccount}
            />
          ) : (
            <>
              {hasBankAccountsViaKlarnaKosma && (
                <KlarnaKosmaBankAccountList
                  setShowAddNewAccount={setShowAddNewAccount}
                  showAddNewAccount={showAddNewAccount}
                />
              )}
            </>
          )}

          {showAddNewAccount && (
            <>
              {showManualAccountInputField ? (
                <>
                  <AccountNumberInput />
                  <Group spacing={'sm'}>
                    <Link size="lg" onClick={onClickVerifyWithBank} data-record>
                      {translate(
                        'OnboardingOffer.BankInformationVerifyWithBankButtonText'
                      )}
                    </Link>
                    <MaterialIcon src="east" />
                  </Group>
                </>
              ) : (
                <>
                  <BankConnection
                    clientId={overview.clientId}
                    setError={setBankConnectionError}
                    allowCancel={false}
                    onResult={onBankConnectionResult}
                    hasError={bankConnectionError}
                    showPsuTypeLabel
                  />
                  {showButtonToSwitchToManualAccountInputField && (
                    <Group spacing={'sm'}>
                      <Link
                        size="lg"
                        onClick={onClickFillInAccountManually}
                        data-record
                      >
                        {translate(
                          'OnboardingOffer.BankInformationFillInManuallyButtonText'
                        )}
                      </Link>
                      <MaterialIcon src="east" />
                    </Group>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {!useEnableBankingFeature && (
        <>
          {hasBankAccountsViaKlarnaKosma && (
            <KlarnaKosmaBankAccountList
              setShowAddNewAccount={setShowAddNewAccount}
              showAddNewAccount={showAddNewAccount}
            />
          )}

          {showAddNewAccount && showManualAccountInputField && (
            <AccountNumberInput />
          )}
        </>
      )}
    </Stack>
  );
};

export default SelectPayoutAccount;
