import { EndpointName } from '~/store/middlewares/rtkErrorNotifier/rtkErrorNotifier.type';

export const isRtkErrorNotifierExcludedEndpoint = (
  endpointName: EndpointName
) => {
  const rtkErrorNotifierExcludedEndpoints: EndpointName[] = [
    'getCompanyLoanApplicationActiveStage',
    'postAuth',
    'getClientData',
    'getSessionStatus',
    'getBankAccounts',
    'updatePaymentMethod',
    'getAspsps',
    'postCompanyUpload',
  ];

  return rtkErrorNotifierExcludedEndpoints.includes(endpointName);
};

export const isSentryNotifierExcludedEndpoint = (
  endpointName: EndpointName
) => {
  const sentryNotifierExcludedEndpoints: EndpointName[] = [
    'getCompanyLoanApplicationActiveStage',
  ];

  return sentryNotifierExcludedEndpoints.includes(endpointName);
};
