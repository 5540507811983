import { CountryCode } from '~/enums';
import { countryNames } from '~/constants/markets';
import { LoginMethod, loginMethods } from '~/helpers/authentication.helper';
import { Language } from '~/store/types/sharedTypes';
import { IconKey } from '@qred/components-library';

export const ActiveLoginMarkets: Record<CountryCode, boolean> = {
  SE: true,
  DK: true,
  BE: true,
  FI: true,
  NO: true,
  NL: true,
  // TODO: (DE) Add German login methods
  DE: true,
};

interface MarketAndLanguage {
  marketValue: CountryCode;
  language: Language;
}

export const marketAndLanguagePerReferrerUrl: Record<
  string,
  MarketAndLanguage
> = {
  'https://www.qred.se': {
    marketValue: CountryCode.SE,
    language: Language.Swedish,
  },
  'https://en.qred.se': {
    marketValue: CountryCode.SE,
    language: Language.English,
  },
  'https://www.qred.dk': {
    marketValue: CountryCode.DK,
    language: Language.Danish,
  },
  'https://en.qred.dk': {
    marketValue: CountryCode.DK,
    language: Language.English,
  },
  'https://www.qred.fi': {
    marketValue: CountryCode.FI,
    language: Language.Finnish,
  },
  'https://en.qred.fi': {
    marketValue: CountryCode.FI,
    language: Language.English,
  },
  'https://www.qred.no': {
    marketValue: CountryCode.NO,
    language: Language.Norwegian,
  },
  'https://en.qred.no': {
    marketValue: CountryCode.NO,
    language: Language.English,
  },
  'https://www.qred.be': {
    marketValue: CountryCode.BE,
    language: Language.DutchBE,
  },
  'https://fr.qred.be': {
    marketValue: CountryCode.BE,
    language: Language.French,
  },
  'https://en.qred.be': {
    marketValue: CountryCode.BE,
    language: Language.English,
  },
  'https://www.qeld.com': {
    marketValue: CountryCode.NL,
    language: Language.Dutch,
  },
  'https://en.qeld.com': {
    marketValue: CountryCode.NL,
    language: Language.Dutch,
  },
  'https://www.qred.de': {
    marketValue: CountryCode.DE,
    language: Language.German,
  },
  'https://en.qred.de': {
    marketValue: CountryCode.DE,
    language: Language.English,
  },
};

export interface LanguageOption {
  languageLabel: string;
  value: Language;
  countryLabel?: string;
}

export const getCountryLabelForLanguage = (
  marketLanguages: LanguageOption[],
  language: Language
) =>
  marketLanguages.find((lang) => lang.value === language)?.countryLabel || '';

export interface LoginMarket {
  defaultLabel?: string;
  value: CountryCode;
  loginMethods: LoginMethod[];
  iconSrc: IconKey;
  languages: LanguageOption[];
}

type ArrayMinimumOne<T> = {
  0: T;
} & Array<T>;

/**
 * First item for each country should always be the default language for that country.
 */
const languages: Record<CountryCode, ArrayMinimumOne<LanguageOption>> = {
  [CountryCode.SE]: [
    {
      languageLabel: 'SV',
      value: Language.Swedish,
      countryLabel: countryNames[CountryCode.SE][Language.Swedish],
    },
    {
      languageLabel: 'EN',
      value: Language.English,
      countryLabel: countryNames[CountryCode.SE][Language.English],
    },
  ],
  [CountryCode.DK]: [
    {
      languageLabel: 'DA',
      value: Language.Danish,
      countryLabel: countryNames[CountryCode.DK][Language.Danish],
    },
    {
      languageLabel: 'EN',
      value: Language.English,
      countryLabel: countryNames[CountryCode.DK][Language.English],
    },
  ],
  [CountryCode.FI]: [
    {
      languageLabel: 'FI',
      value: Language.Finnish,
      countryLabel: countryNames[CountryCode.FI][Language.Finnish],
    },
    {
      languageLabel: 'EN',
      value: Language.English,
      countryLabel: countryNames[CountryCode.FI][Language.English],
    },
  ],
  [CountryCode.BE]: [
    {
      languageLabel: 'NL',
      value: Language.DutchBE,
      countryLabel: countryNames[CountryCode.BE][Language.DutchBE],
    },
    {
      languageLabel: 'FR',
      value: Language.French,
      countryLabel: countryNames[CountryCode.BE][Language.French],
    },
    {
      languageLabel: 'EN',
      value: Language.English,
      countryLabel: countryNames[CountryCode.BE][Language.English],
    },
  ],
  [CountryCode.NO]: [
    {
      languageLabel: 'NO',
      value: Language.Norwegian,
      countryLabel: countryNames[CountryCode.NO][Language.Norwegian],
    },
    {
      languageLabel: 'EN',
      value: Language.English,
      countryLabel: countryNames[CountryCode.NO][Language.English],
    },
  ],
  [CountryCode.NL]: [
    {
      languageLabel: 'NL',
      value: Language.Dutch,
      countryLabel: countryNames[CountryCode.NL][Language.Dutch],
    },
  ],
  [CountryCode.DE]: [
    {
      languageLabel: 'DE',
      value: Language.German,
      countryLabel: countryNames[CountryCode.DE][Language.German],
    },
    {
      languageLabel: 'EN',
      value: Language.English,
      countryLabel: countryNames[CountryCode.DE][Language.English],
    },
  ],
};

const allLoginMarkets: LoginMarket[] = [
  {
    defaultLabel: countryNames[CountryCode.SE][Language.Swedish],
    value: CountryCode.SE,
    iconSrc: 'FlagSweden',
    languages: languages[CountryCode.SE],
    loginMethods: loginMethods[CountryCode.SE],
  },
  {
    defaultLabel: countryNames[CountryCode.DK][Language.Danish],
    value: CountryCode.DK,
    iconSrc: 'FlagDenmark',
    languages: languages[CountryCode.DK],
    loginMethods: loginMethods[CountryCode.DK],
  },
  {
    defaultLabel: countryNames[CountryCode.FI][Language.Finnish],
    value: CountryCode[CountryCode.FI],
    iconSrc: 'FlagFinland',
    languages: languages[CountryCode.FI],
    loginMethods: loginMethods[CountryCode.FI],
  },
  {
    defaultLabel: countryNames[CountryCode.BE][Language.DutchBE],
    value: CountryCode.BE,
    iconSrc: 'FlagBelgium',
    languages: languages[CountryCode.BE],
    loginMethods: loginMethods[CountryCode.BE],
  },
  {
    defaultLabel: countryNames[CountryCode.NO][Language.Norwegian],
    value: CountryCode.NO,
    iconSrc: 'FlagNorway',
    languages: languages[CountryCode.NO],
    loginMethods: loginMethods[CountryCode.NO],
  },
  {
    defaultLabel: countryNames[CountryCode.NL][Language.Dutch],
    value: CountryCode.NL,
    iconSrc: 'FlagNetherlands',
    languages: languages[CountryCode.NL],
    loginMethods: loginMethods[CountryCode.NL],
  },
  {
    defaultLabel: countryNames[CountryCode.DE][Language.German],
    value: CountryCode.DE,
    iconSrc: 'FlagGermany',
    languages: languages[CountryCode.DE],
    loginMethods: loginMethods[CountryCode.DE],
  },
];

/**
 * filter out inactive markets and markets without login methods and sort by label
 */
export const loginMarkets = allLoginMarkets
  .filter(
    (market) => ActiveLoginMarkets[market.value] && market.loginMethods.length
  )
  .sort((a, b) => {
    if (!a.defaultLabel || !b.defaultLabel) return 0;
    if (a.defaultLabel < b.defaultLabel) {
      return -1;
    }
    if (a.defaultLabel > b.defaultLabel) {
      return 1;
    }
    return 0;
  });

export const getMarketByValue = (value: CountryCode | null) => {
  if (!value) return null;
  return (
    loginMarkets.find(
      (availableLoginMarket) => availableLoginMarket.value === value
    ) || null
  );
};
