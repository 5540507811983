import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { PhoneNumberPlaceholders } from '~/constants/markets';
import { onChangeInputEvent, onFocusSelectEvent } from '~/types/types';
import { Button, Stack, TextInput } from '@qred/components-library';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import {
  validatePhoneNumber,
  validateEmail,
} from '@qred/shared-component-library/src/validators';
import { SignerType } from '~/enums';

import AMLFields from '../AMLFields/AMLFields';
import { IOnboardingSigner } from '~/interfaces/Onboarding';
import { updateSigner } from '~/store/slices/loanOnboardingOffer.slice';

interface SignerProps {
  signer: IOnboardingSigner;
  onBlur: (name: onChangeInputEvent | onFocusSelectEvent | string) => void;
}

const Signer = ({ signer, onBlur }: SignerProps) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const {
    intl: { market },
    matchedMedia: { mobile },
    onboardingOffer: {
      form: { signers, guarantors },
      overview,
    },
  } = useSelector((state: RootState) => state);

  const formMethods = useForm<IOnboardingSigner>({
    defaultValues: signer,
    mode: 'onTouched',
  });

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = formMethods;

  /**
   * useEffect to trigger validation on email and phone fields
   * if they are pre-filled. If any of the fields are pre-filled but not valid,
   * the user will see the error message immediately.
   */
  useEffect(() => {
    signer.email && trigger('email');
    signer.phone && trigger('phone');
  }, [trigger, signer]);

  const saveSigner: SubmitHandler<IOnboardingSigner> = (formData) => {
    dispatch(updateSigner(formData));

    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'edit_signer_save_button_pressed',
      })
    );
  };

  /**
   * Check if the email already exists on other signers or the applicant
   */
  const emailAlreadyExists = (_email: string) => {
    const otherSigners = [...signers, ...guarantors].filter(
      (_signer) => _signer.signerId !== signer.signerId
    );
    const usedEmails = [
      ...otherSigners.map((_signer) => _signer.email),
      overview.signers.find((_signer) => _signer.type === SignerType.Applicant)
        ?.email,
      overview.email,
    ];

    return usedEmails.includes(_email);
  };

  /**
   * Check if the phone number already exists on other signers or the applicant
   */
  const phoneAlreadyExists = (_phone: string) => {
    const otherSigners = [...signers, ...guarantors].filter(
      (_signer) => _signer.signerId !== signer.signerId
    );
    const usedPhones = [
      ...otherSigners.map((_signer) => _signer.phone),
      overview.signers.find((_signer) => _signer.type === SignerType.Applicant)
        ?.phone,
      overview.phone,
    ];

    return usedPhones.includes(_phone);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(saveSigner)}>
        <Stack spacing="md">
          <TextInput
            inputMode="tel"
            data-cy="signers_step_phone"
            label={translate('Phone') as string}
            placeholder={PhoneNumberPlaceholders[market]}
            {...register('phone', {
              required: translate('ValidationErrors.Required') as string,
              validate: (value) => {
                const phoneIsValid = validatePhoneNumber(value, market);
                if (!phoneIsValid) {
                  return translate('ValidationErrors.Phone') as string;
                }

                if (value && phoneAlreadyExists(value)) {
                  return translate(
                    'ValidationErrors.PhoneAlreadyUsed'
                  ) as string;
                }

                return true;
              },
              onBlur,
            })}
            error={errors.phone?.message}
          />
          <TextInput
            inputMode="email"
            data-cy="signers_step_email"
            type="email"
            label={translate('Email') as string}
            placeholder={translate('Onboarding.EmailPlaceholder') as string}
            {...register('email', {
              required: translate('ValidationErrors.Required') as string,
              validate: (value) => {
                const emailIsValid = validateEmail(value);
                if (!emailIsValid) {
                  return translate('ValidationErrors.Email') as string;
                }

                if (value && emailAlreadyExists(value)) {
                  return translate(
                    'ValidationErrors.EmailAlreadyUsed'
                  ) as string;
                }

                return true;
              },

              onBlur,
            })}
            error={errors.email?.message}
          />
          {signer.type === SignerType.Signer && (
            <AMLFields trackFieldChange={onBlur} />
          )}
        </Stack>
        <Stack align="center">
          <Button
            mt="2em"
            variant="secondary"
            data-cy="onboarding_offer_save_signer_button"
            fullWidth={mobile}
            size="md"
            type="submit"
            disabled={isSubmitting || isSubmitSuccessful}
          >
            {translate('Save')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default Signer;
