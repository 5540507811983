import React from 'react';
import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';

const Wrapper = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  @media ${MediaQueries.smallScreen} {
    width: 100%;
  }
  // We want buttons in a ButtonGroup always have 100% width
  & [class*='ButtonBase'] {
    width: 100%;
  }
`;

const ButtonGroup: React.FC<{
  children?: React.ReactNode;
}> = (props) => (
  <Wrapper>
    <Container>{props.children}</Container>
  </Wrapper>
);

export default ButtonGroup;
