import { styledPolymorphic } from '@qred/components-library';
import React from 'react';
import { StepConfigItem } from '~/Interfaces';

const Dot = styledPolymorphic<'div', { $step: number; $currentStep: number }>()(
  (styled) => styled`
  width: 0.8em;
  height: 0.8em;
  background-color: ${({ $currentStep, $step, theme }) => {
    if ($step < $currentStep) {
      return theme.fn.themeColor('Main/Primary/1000');
    }

    if ($step === $currentStep) {
      return theme.fn.themeColor('Main/Primary/800');
    }

    return theme.fn.themeColor('Main/Grey/Alpha/OnLight/20%');
  }};
  border-radius: 50%;
  margin: 0 0.5em;
`
);

const DotsContainer = styledPolymorphic()(
  (styled) => styled`
  display: flex;
  flex-direction: row;
  align-items: center;
`
);

const ProgressStepperContainer = styledPolymorphic()(
  (styled) => styled`
  position: relative;
`
);

interface Props {
  currentStep: number;
  steps: Pick<StepConfigItem, 'name' | 'hideInProgressStepper'>[];
}

const ProgressStepperDots: React.FC<Props> = ({ currentStep, steps }) => {
  return (
    <ProgressStepperContainer>
      <DotsContainer>
        {steps.map((step, index) =>
          step.hideInProgressStepper ? null : (
            <Dot key={step.name} $step={index} $currentStep={currentStep - 1} />
          )
        )}
      </DotsContainer>
    </ProgressStepperContainer>
  );
};

export default ProgressStepperDots;
