import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { ClientType } from '~/enums';
import { areSameCompanies } from '~/helpers/utils';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import {
  resetCompanyInForm,
  setClientId,
  setCompanyIsNew,
  updateApplicantData,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import { BadgeWrapper } from '../CompanySelector.styled';
import { ILoanOnboardingApplicationClient } from '~/interfaces/LoanOnboardingApplication';
import { Badge, Group, Radio } from '@qred/components-library';

interface CompanySelectorRadioButtonsProps {
  clients: ILoanOnboardingApplicationClient[];
}

const CompanySelectorRadioButtons = ({
  clients,
}: CompanySelectorRadioButtonsProps) => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const {
    form,
    companyIsNew,
    currentApplicationOrganizationIdentifier,
  } = useSelector((state: RootState) => state.onboardingApplication);

  const updateOrganizationInRedux = (org: ILoanOnboardingApplicationClient) => {
    const isRLC = org.active;

    dispatch(
      updateForm({
        organizationNumber: org.company_no ?? undefined,
        companyType: org.company_type ?? undefined,
        clientType: isRLC ? ClientType.RLC : ClientType.NLC,
        companyName: org.company_name ?? undefined,
        connectId: org.connect_id ?? undefined,
        companyTypeCategory: org.company_type_category ?? undefined,
        vatNumber: org.vat_number ?? undefined,
        companyAddress: {
          street: org.street ?? '',
          city: org.city ?? '',
          zipCode: org.zip_code ?? '',
        },
      })
    );

    dispatch(
      updateApplicantData({
        email: isRLC && org.email ? org.email : '',
        phone: isRLC && org.phone ? org.phone : '',
      })
    );
  };

  useEffect(() => {
    if (clients.length && !form.organizationNumber && !form.connectId) {
      updateOrganizationInRedux(clients[0]);
    }
  }, [clients]);

  useEffect(() => {
    if (
      !areSameCompanies(
        form.organizationNumber || form.connectId,
        currentApplicationOrganizationIdentifier
      )
    ) {
      dispatch(updateForm({ companyName: '' }));
    }
  }, [companyIsNew]);

  const onChange = (company: {
    company_no?: string | null;
    connectId?: string | null;
  }) => {
    if (companyIsNew) {
      dispatch(setCompanyIsNew(false));
    }

    if (
      areSameCompanies(
        form.organizationNumber || form.connectId,
        company.company_no || company.connectId
      )
    ) {
      return;
    }

    const selectedClient = clients.find(
      (client: ILoanOnboardingApplicationClient) =>
        areSameCompanies(
          client.company_no || client.connect_id,
          company.company_no || company.connectId
        )
    );

    if (selectedClient) {
      updateOrganizationInRedux(selectedClient);
      if (selectedClient.client_id) {
        dispatch(setClientId(selectedClient.client_id));
      }
      dispatch(
        pushToGtmOnboardingAction({
          stepActionNumber: 0.1,
          actionName: 'company_change',
        })
      );
    }
  };

  const handleNewCompany = () => {
    dispatch(setCompanyIsNew(true));
    dispatch(resetCompanyInForm());
    dispatch(
      updateApplicantData({
        email: '',
        phone: '',
      })
    );
    dispatch(
      pushToGtmOnboardingAction({
        stepActionNumber: 0.1,
        actionName: 'company_change',
      })
    );
  };

  return (
    <Radio.Group
      direction="column"
      dataCy="onboarding_company_selector_checkbox"
      value={
        companyIsNew ? 'company_new' : form.organizationNumber || form.connectId
      }
    >
      {clients.map((company: ILoanOnboardingApplicationClient) => (
        <Radio
          key={company.company_no || company.connect_id}
          value={company.company_no || company.connect_id || undefined}
          onClick={() => onChange(company)}
          label={
            <Group>
              {company.company_name}
              {company.application_uuid &&
                (company.has_inprogress_application ||
                  company.has_offer_to_sign) && (
                  <BadgeWrapper>
                    <Badge
                      color={
                        company.has_offer_to_sign
                          ? 'success.500'
                          : 'warning.500'
                      }
                    >
                      {company.has_offer_to_sign
                        ? t('Onboarding.ActiveOffer')
                        : t('Onboarding.ActiveApplication')}
                    </Badge>
                  </BadgeWrapper>
                )}
            </Group>
          }
          hint={company.company_no}
        />
      ))}
      <Radio
        value="company_new"
        onClick={handleNewCompany}
        dataCy="onboarding_company_selector_checkbox_new_company"
        label={t('Onboarding.NewCompany')}
      />
    </Radio.Group>
  );
};

export default CompanySelectorRadioButtons;
