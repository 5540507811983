import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Size, theme } from '~/styles/themes';
import clsx from 'clsx';

interface GroupProps {
  align?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyContent'];
  spacing?: Size;
  grow?: boolean;
  direction?: 'row' | 'column';
  children: React.ReactNode;
  wrap?: React.CSSProperties['flexWrap'];
}

const StyledGroup = styled.div<GroupProps & { count: number }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  gap: ${(props) => theme.spacing[props.spacing!]};
  flex-wrap: ${(props) => props.wrap || 'wrap'};

  .child {
    box-sizing: border-box;
    max-width: ${(props) =>
      props.grow &&
      ` calc(${100 / props.count}% - ${
        +theme.spacing[props.spacing!].replace('em', '') -
        +theme.spacing[props.spacing!].replace('em', '') / props.count
      }px) `};
    ${(props) =>
      props.grow &&
      css`
        flex-grow: 1;
      `}
  }
`;

const Group = forwardRef<HTMLDivElement, GroupProps>((props, ref) => {
  const { children, spacing = 'md', direction = 'row', ...rest } = props;
  const filterFalsyChildren = (childrenToFilter: React.ReactNode) =>
    (React.Children.toArray(childrenToFilter) as React.ReactElement[]).filter(
      Boolean
    );

  const filteredChildren = filterFalsyChildren(children);

  const items = filteredChildren.map((child) => {
    if (typeof child === 'object' && child !== null && 'props' in child) {
      return React.cloneElement(child, {
        className: clsx('child', child.props?.className),
      });
    }

    return child;
  });

  return (
    <StyledGroup
      {...rest}
      ref={ref}
      count={filteredChildren.length}
      spacing={spacing}
      direction={direction}
    >
      {items}
    </StyledGroup>
  );
});

export default Group;
