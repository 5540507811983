import React from 'react';
import Image from '~/components/shared/Image/Image';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { openAppStore, openGooglePlay } from '~/services/links';
import { LanguageValues } from '~/constants/markets';
import {
  MainOnboardingContainer,
  OnboardingStepsContainer,
} from '~/styles/OnboardingBaseStyledComponents';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import {
  Body,
  Card,
  Divider,
  Group,
  Stack,
  useWindowProperties,
} from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { Container } from './StoreButtonAndQrCodeView.styled';

const AppStoreBadges: LanguageValues = {
  SV: 'app-store-badge-swedish.svg',
  EN: 'app-store-badge-english.svg',
  FI: 'app-store-badge-finnish.svg',
  DA: 'app-store-badge-danish.svg',
  NO: 'app-store-badge-norwegian.svg',
  FR: 'app-store-badge-french.svg',
  NL_BE: 'app-store-badge-dutch.svg',
  NL: 'app-store-badge-dutch.svg',
  // TODO: Add German app store badge
  DE: 'app-store-badge-german.svg',
};

const GooglePlayStoreBadges: LanguageValues = {
  SV: 'google-play-badge-swedish.png',
  EN: 'google-play-badge-english.png',
  FI: 'google-play-badge-finnish.png',
  DA: 'google-play-badge-danish.png',
  NO: 'google-play-badge-norwegian.png',
  FR: 'google-play-badge-french.png',
  NL_BE: 'google-play-badge-dutch.png',
  NL: 'google-play-badge-dutch.png',
  // TODO: Add German google play badge
  DE: 'google-play-badge-german.png',
};

const GooglePlayQRCode: LanguageValues = {
  SV: 'all_google_play.png',
  EN: 'all_google_play.png',
  FI: 'all_google_play.png',
  DA: 'all_google_play.png',
  NO: 'all_google_play.png',
  FR: 'all_google_play.png',
  NL_BE: 'all_google_play.png',
  NL: 'all_google_play.png',
  DE: 'all_google_play.png',
};
const AppleStoreQRCode: LanguageValues = {
  SV: 'app_store_qr_code_swedish.png',
  EN: 'app_store_qr_code_english.png',
  FI: 'app_store_qr_code_finish.png',
  DA: 'app_store_qr_code_english.png',
  NO: 'app_store_qr_code_english.png',
  FR: 'app_store_qr_code_english.png',
  NL_BE: 'app_store_qr_code_english.png',
  NL: 'app_store_qr_code_english.png',
  DE: 'app_store_qr_code_english.png',
};
interface IStoreButtonAndQrCodeView {
  title: string;
  description1: string;
  description2?: string;
}
const StoreButtonAndQrCodeView: React.FC<IStoreButtonAndQrCodeView> = ({
  title,
  description1,
  description2,
}) => {
  const {
    intl: { language },
  } = useSelector((state: RootState) => state);

  const { isMobile } = useWindowProperties();

  const t = useTranslate();

  const QredAppStoreBadge = () => (
    <Container onClick={() => openAppStore(language)}>
      <Image src={AppStoreBadges[language]} />
    </Container>
  );
  const QredAppGooglePlayStoreBadge = () => (
    <Container onClick={() => openGooglePlay()}>
      <Image src={GooglePlayStoreBadges[language]} />
    </Container>
  );
  const QRCodeGooglePlay = () => (
    <Container>
      <Image src={GooglePlayQRCode[language]} />
    </Container>
  );
  const QRCodeAppleStore = () => (
    <Container>
      <Image src={AppleStoreQRCode[language]} />
    </Container>
  );

  const description = (
    <Stack>
      <Body size="lg">{t(description1)}</Body>
      {description2 && <Body size="lg">{description2}</Body>}
    </Stack>
  );

  return (
    <MainOnboardingContainer>
      <OnboardingStepsContainer $justify={'center'}>
        <Card border={!isMobile}>
          <Stack>
            <StepHeader
              title={t(title) as string}
              description={description}
              shouldTranslate={false}
            />
            <Group justify="space-evenly">
              <Stack>
                <QRCodeAppleStore />
                <Group spacing={'xs'} justify="space-around" align="center">
                  <Divider
                    thickness={1}
                    style={{ flex: 1 }}
                    color="textLight.50"
                  />
                  {t('OnboardingCardOffer.Or')}
                  <Divider
                    thickness={1}
                    style={{ flex: 1 }}
                    color="textLight.50"
                  />
                </Group>
                <QredAppStoreBadge />
              </Stack>
              <Stack>
                <QRCodeGooglePlay />
                <Group spacing={'xs'} justify="space-around" align="center">
                  <Divider
                    thickness={1}
                    style={{ flex: 1 }}
                    color="textLight.50"
                  />
                  {t('OnboardingCardOffer.Or')}
                  <Divider
                    thickness={1}
                    style={{ flex: 1 }}
                    color="textLight.50"
                  />
                </Group>
                <QredAppGooglePlayStoreBadge />
              </Stack>
            </Group>
          </Stack>
        </Card>
      </OnboardingStepsContainer>
    </MainOnboardingContainer>
  );
};

export default StoreButtonAndQrCodeView;
