import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getMarketHasAddGuarantorButtonCopy,
  getMarketHasAddress,
  getMarketHasPersonalNumber,
  getMarketsHasAddGuarantorButtonNonFooter,
  getMarketOnlyHasEmailLogin,
  getMarketRequiresLoginBeforeSubmittingApplication,
  getMarketShouldShowCreditCheckCopyTooltip,
  getMarketHasGuarantorSubheader,
  getMarketShouldAskApplicantForFullName,
  getMarketShouldShowAccountNumberInputFieldByDefault,
  getMarketHasRoaring,
  getMarketRequiresPersonalNumberForFetchedOwners,
  getMarketRequiresDateOfBirthForFetchedOwners,
  getMarketRequiresYearOfBirthForFetchedOwners,
  getMarketRequiresCountryOfResidenceForFetchedOwners,
  getMarketHasFetchedOwnershipSharesAsInteger,
  getMarketHasFetchedOwnershipSharesAsRanges,
  getMarketHasMultipleProducts,
  getMarketHasMandatoryTurnover,
  getMarketHasTrustpilot,
  getMarketOnlyAllowsIbanAccountNumbers,
  getMarketHasEskat,
} from '~/constants/markets';
import { RootState } from '~/store/types/sharedTypes';

const useOnboardingMarketConfig = () => {
  const { market } = useSelector((state: RootState) => state.intl);

  return useMemo(
    () => ({
      marketHasPersonalNumber: getMarketHasPersonalNumber(market),
      marketHasAddress: getMarketHasAddress(market),
      marketShouldAskApplicantForFullName: getMarketShouldAskApplicantForFullName(
        market
      ),
      marketHasMandatoryTurnover: getMarketHasMandatoryTurnover(market),
      marketsHasAddGuarantorButtonNonFooter: getMarketsHasAddGuarantorButtonNonFooter(
        market
      ),
      marketHasAddGuarantorButtonCopy: getMarketHasAddGuarantorButtonCopy(
        market
      ),
      marketOnlyHasEmailLogin: getMarketOnlyHasEmailLogin(market),
      marketRequiresLoginBeforeSubmittingApplication: getMarketRequiresLoginBeforeSubmittingApplication(
        market
      ),
      marketShouldShowCreditCheckCopyTooltip: getMarketShouldShowCreditCheckCopyTooltip(
        market
      ),
      marketHasGuarantorSubheader: getMarketHasGuarantorSubheader(market),
      marketShouldShowAccountNumberInputFieldByDefault: getMarketShouldShowAccountNumberInputFieldByDefault(
        market
      ),
      marketHasRoaring: getMarketHasRoaring(market),
      marketRequiresPersonalNumberForFetchedOwners: getMarketRequiresPersonalNumberForFetchedOwners(
        market
      ),
      marketRequiresDateOfBirthForFetchedOwners: getMarketRequiresDateOfBirthForFetchedOwners(
        market
      ),
      marketRequiresYearOfBirthForFetchedOwners: getMarketRequiresYearOfBirthForFetchedOwners(
        market
      ),
      marketRequiresCountryOfResidenceForFetchedOwners: getMarketRequiresCountryOfResidenceForFetchedOwners(
        market
      ),
      marketHasFetchedOwnershipSharesAsInteger: getMarketHasFetchedOwnershipSharesAsInteger(
        market
      ),
      marketHasFetchedOwnershipSharesAsRanges: getMarketHasFetchedOwnershipSharesAsRanges(
        market
      ),
      marketHasMultipleProducts: getMarketHasMultipleProducts(market),
      marketHasTrustpilot: getMarketHasTrustpilot(market),
      marketOnlyAllowsIban: getMarketOnlyAllowsIbanAccountNumbers(market),
      marketHasEskat: getMarketHasEskat(market),
    }),
    [market]
  );
};

export default useOnboardingMarketConfig;
