import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CountryCode } from '~/enums';
import { defaultLanguagePerMarket } from '~/helpers/market.helper';
import { intlInitialState } from '../initialState';
import { Language } from '../types/sharedTypes';

const intlSlice = createSlice({
  name: 'intl',
  initialState: intlInitialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
      state.languageIsSet = true;
      localStorage.setItem('qredLanguage', action.payload);
    },
    setMarket: (state, action: PayloadAction<CountryCode>) => {
      state.market = action.payload;
      state.marketIsSet = true;
      localStorage.setItem('qredMarket', action.payload);
      window.__QRED_MARKET__ = action.payload.toUpperCase();
    },
  },
});

export const { setLanguage, setMarket } = intlSlice.actions;

export default intlSlice.reducer;

export const defaultLanguageToMarket = (market: CountryCode) => {
  const language = defaultLanguagePerMarket[market];

  if (!language) {
    throw new Error('Invalid market');
  }

  return setLanguage(language);
};
