import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '~/store/apis/baseQuery/baseQuery';

export const tagTypes = [
  'Account',
  'AccountFileExtensions',
  'LoanDocument',
  'CompanyLoans',
  'CompanyLoanApplicationActiveStage',
  'ActiveLoan',
  'LoanInvoices',
  'LoanStatement',
  'LoanAgreement',
  'LoanPaymentPlan',
  'AccountStatements',
  'LoanApplication',
];
export const myQredApi = createApi({
  reducerPath: 'myQredApi',
  tagTypes: tagTypes,
  endpoints: () => ({}),
  baseQuery: baseQuery(import.meta.env.VITE_MYQRED_API_URL),
});
