import React from 'react';
import { ContactEmails } from '~/constants/markets';
import { RootState } from '~/store/types/sharedTypes';
import { useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import StoreButtonAndQrCodeView from './StoreButtonAndQrCodeView/StoreButtonAndQrCodeView';
import { Link } from '@qred/components-library';

const ThankyouCardWaitingAdditionalSigner = () => {
  const { market } = useSelector((state: RootState) => state.intl);
  const translate = useTranslate();

  const QredSupportLink = () => (
    <a href={`mailto:${ContactEmails[market]}`}>
      <Link>{ContactEmails[market]}</Link>
    </a>
  );

  return (
    <StoreButtonAndQrCodeView
      title={'OnboardingCardOffer.youAreAllSet'}
      description1={
        'OnboardingCardOffer.ThankyouCardAdditionalSignerDescription1'
      }
      description2={
        translate(
          'OnboardingCardOffer.ThankyouCardAdditionalSignerDescription2',
          {
            qredSupportEmail: <QredSupportLink />,
          }
        ) as string
      }
    />
  );
};

export default ThankyouCardWaitingAdditionalSigner;
