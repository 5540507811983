import React from 'react';
import {
  Stack,
  Input,
  DatePicker,
  useForm,
  z,
  Button,
  Group,
  Controller,
  DateValue,
  useModals,
  Body,
} from '@qred/components-library';
import dayjs from 'dayjs';
import useTranslate from '~/hooks/useTranslate';
import { validatePhoneNumber } from '@qred/shared-component-library/src/validators';
import { AddressSearch } from '~/components/shared/AddressSearch/AddressSearch';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store';
import { ILoanOnboardingApplicationGuarantor } from '~/interfaces/LoanOnboardingApplication';
import { PostcodeAddress } from '~/hooks/usePostcode';
import { ControllerRenderProps } from 'react-hook-form';
import { updateForm } from '~/store/slices/onboardingApplication.slice';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { FormErrorBanner } from '~/components/shared/FormErrorBanner/FormErrorBanner';

interface Props {
  initialGuarantor: ILoanOnboardingApplicationGuarantor;
}

export const EditGuarantorModalDE = ({ initialGuarantor }: Props) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const modals = useModals();
  const DEFAULT_DATE = new Date(
    new Date().setFullYear(new Date().getFullYear() - 18)
  );

  const {
    intl: { market },
    onboardingApplication: { form },
  } = useSelector((state: RootState) => state);

  const {
    fullName,
    dateOfBirth,
    phone,
    email,
    hasAddressFromReport,
    hasDateOfBirthFromReport,
    addressFields,
  } = initialGuarantor;

  const initialAddressValue = addressFields
    ? `${addressFields.city}, ${addressFields.streetName} ${
        addressFields.houseNumber || ''
      }${addressFields.houseNumberAddition || ''}`.trim()
    : undefined;

  const GuarantorSchema = z
    .object({
      dateOfBirth: z
        .date()
        .optional()
        .refine((value) => value || hasDateOfBirthFromReport, {
          message: 'ValidationErrors.Required',
        }),
      mobilePhone: z
        .string()
        .trim()
        .min(1, { message: 'ValidationErrors.Required' })
        .refine((value) => validatePhoneNumber(value, market), {
          message: 'ValidationErrors.Phone',
        }),
      email: z
        .string()
        .trim()
        .min(1, { message: 'ValidationErrors.Required' })
        .email({ message: 'ValidationErrors.Email' }),
      addressFieldValue: z
        .string()
        .refine((value) => value.length || hasAddressFromReport, {
          message: 'ValidationErrors.Required',
        }),
      selectedAddress: z
        .object({
          city: z.string(),
          streetName: z.string(),
          houseNumber: z.string().optional(),
          houseNumberAddition: z.string().optional(),
          zipCode: z.string(),
        })
        .nullable(),
    })
    .refine(
      (data) => {
        return data.selectedAddress !== null || hasAddressFromReport;
      },
      {
        message: 'ValidationErrors.NotSelectedFromAddressSearchResults',
        path: ['addressFieldValue'],
      }
    );

  type GuarantorInformationForm = z.infer<typeof GuarantorSchema>;

  const {
    setValue,
    watch,
    trigger,
    control,
    register,
    formState: { errors, isValid },
  } = useForm<GuarantorInformationForm>({
    schema: GuarantorSchema,
    defaultValues: {
      mobilePhone: phone || '',
      email: email || '',
      addressFieldValue: initialAddressValue || '',
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : undefined,
      selectedAddress: addressFields ?? null,
    },
    mode: 'onTouched',
  });

  const handleSelectedAddress = (selectedAddress: PostcodeAddress | null) => {
    if (selectedAddress) {
      const {
        locality,
        postcode,
        street,
        buildingNumber,
        buildingNumberAddition,
      } = selectedAddress.address;

      const fullStreet = `${street} ${buildingNumber || ''}${
        buildingNumberAddition || ''
      }`.trim();

      setValue(
        'selectedAddress',
        {
          city: locality,
          houseNumber: buildingNumber ? buildingNumber.toString() : undefined,
          streetName: street,
          zipCode: postcode,
          houseNumberAddition: buildingNumberAddition || undefined,
        },
        { shouldValidate: true }
      );

      setValue('addressFieldValue', `${locality}, ${fullStreet}`, {
        shouldValidate: true,
      });
    }
  };

  const onChangeAddressFieldValue = (
    field: ControllerRenderProps<GuarantorInformationForm, 'addressFieldValue'>,
    value: string
  ) => {
    setValue('selectedAddress', null);
    field.onChange(value);
  };

  const onChange = (
    field: keyof GuarantorInformationForm,
    value: string | undefined | DateValue
  ) => {
    setValue(field, value || '');
    trigger(field);
  };

  const fieldValues = watch();

  const onBlur = (field: keyof GuarantorInformationForm) => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: `modal_edit_guarantor_${field}_change`,
      })
    );
    setValue(field, fieldValues[field], { shouldDirty: true });
    trigger(field);
  };

  const handleSave = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'edited_guarantor_save_pressed',
      })
    );
    dispatch(
      updateForm({
        guarantors: form.guarantors.map(
          (formGuarantor: ILoanOnboardingApplicationGuarantor) => {
            if (formGuarantor.guarantorId === initialGuarantor.guarantorId) {
              const updatedGuarantor: ILoanOnboardingApplicationGuarantor = {
                ...formGuarantor,
                ...(!hasDateOfBirthFromReport && {
                  dateOfBirth:
                    fieldValues.dateOfBirth &&
                    dayjs(fieldValues.dateOfBirth).format('YYYY-MM-DD'),
                }),
                ...(!hasAddressFromReport &&
                  fieldValues.selectedAddress && {
                    addressFields: fieldValues.selectedAddress,
                  }),

                phone: fieldValues.mobilePhone,
                email: fieldValues.email,
                isChecked: true,
              };
              return updatedGuarantor;
            }
            return formGuarantor;
          }
        ),
      })
    );

    modals.closeModal('editGuarantor-modal');
  };

  return (
    <Stack>
      <Body size="md">
        {t(`Onboarding.AddAdditionalGuarantorsModalHeader`)}
      </Body>

      <Stack spacing={'xs'}>
        <Input.Group orientation="vertical">
          <Input
            label={t('FullName')}
            disabled={true}
            defaultValue={fullName}
          />
          {!hasDateOfBirthFromReport && (
            <DatePicker
              {...register('dateOfBirth')}
              label={t('DateOfBirth')}
              error={
                errors.dateOfBirth?.message && t(errors.dateOfBirth?.message)
              }
              onBlur={() => {
                onBlur('dateOfBirth');
              }}
              onChange={(value) => {
                onChange('dateOfBirth', value);
              }}
              maxDate={new Date()}
              value={fieldValues.dateOfBirth as Date}
              defaultDate={DEFAULT_DATE}
            />
          )}
          <Input
            {...register('mobilePhone')}
            label={t('MobilePhone')}
            error={
              errors.mobilePhone?.message && t(errors.mobilePhone?.message)
            }
            value={fieldValues.mobilePhone}
          />
          <Input
            {...register('email')}
            label={t('Email')}
            error={errors.email?.message && t(errors.email?.message)}
            value={fieldValues.email}
          />
          {!hasAddressFromReport && (
            <Controller
              name="addressFieldValue"
              control={control}
              render={({ field, fieldState }) => (
                <AddressSearch
                  label={t('Onboarding.ApplicantAddress')}
                  initialValue={initialAddressValue}
                  handleResult={handleSelectedAddress}
                  onChange={(value) => onChangeAddressFieldValue(field, value)}
                  onBlur={field.onBlur}
                  error={
                    fieldState.error?.message && t(fieldState.error?.message)
                  }
                />
              )}
            />
          )}
        </Input.Group>
        <FormErrorBanner errors={errors} />
      </Stack>

      <Group justify="flex-end">
        <Button
          variant="tertiary"
          onClick={() => {
            modals.closeModal('editGuarantor-modal');
          }}
          size="md"
        >
          {t('Cancel')}
        </Button>
        <Button disabled={!isValid} onClick={handleSave} size="md">
          {t('Save')}
        </Button>
      </Group>
    </Stack>
  );
};
