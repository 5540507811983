import {
  Header as CLHeader,
  Group,
  QredLogo,
  useWindowProperties,
} from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import MainMenu from '~/components/shared/Menu/MainMenu';
import { CountryCode } from '~/enums';
import { RootState } from '~/store/types/sharedTypes';
import { onboardingNavbarHeight } from '~/styles/index.css';

const OnboardingHeader = () => {
  const {
    intl: { market },
  } = useSelector((state: RootState) => state);
  const isQeld = market === CountryCode.NL;
  const { isMobile } = useWindowProperties();

  return (
    <CLHeader bg="transparent" keepDesktopStyle h={onboardingNavbarHeight}>
      <Group
        justify="space-between"
        px={isMobile ? '16px' : '120px'}
        miw="240px"
        h="100%"
        style={{ alignItems: 'center' }} // using the "align" prop from Group component causes issues with the children inside also getting "text-align: center", which is not what we want
      >
        <QredLogo width={65} qeld={isQeld} />
        <MainMenu showText={!isMobile} />
      </Group>
    </CLHeader>
  );
};

export default OnboardingHeader;
