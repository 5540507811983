import { Auth0ConnectionKey, CountryCode } from '~/enums';

export interface LoginMethod {
  id?: string;
  label: string; // leave empty to use the "GetStarted" translation key
  connectionName: string;
  secondary?: boolean;
  showOnLaunchViewPage?: boolean;
}

const env =
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'internal-prod'
    ? 'production'
    : 'notprod';

const CONNECTION_NAMES = {
  notprod: {
    [Auth0ConnectionKey.SE_BANKID]: 'SE-BankID-Signicat',
    [Auth0ConnectionKey.DK_MITID]: 'DK-MitID-Signicat',
    [Auth0ConnectionKey.FI_FTN]: 'FI-FTN-Signicat',
    [Auth0ConnectionKey.BE_EMAIL]: 'myqred-login-BE-test',
    [Auth0ConnectionKey.NL_EMAIL]: 'myqred-login-NL-test',
    [Auth0ConnectionKey.NO_BANKID]: 'NO-BankID-Signicat',
    [Auth0ConnectionKey.BE_ITSME]: 'BE-itsme-Signicat-Test',
    [Auth0ConnectionKey.BE_BEID]: 'BE-beid-Signicat-Test',
    [Auth0ConnectionKey.NL_IDIN]: 'NL-iDIN-Signicat-Test',
    [Auth0ConnectionKey.DE_EMAIL]: 'myqred-login-DE-test',
  },
  production: {
    [Auth0ConnectionKey.SE_BANKID]: 'SE-BankId-Signicat',
    [Auth0ConnectionKey.DK_MITID]: 'DK-MitID-Signicat',
    [Auth0ConnectionKey.FI_FTN]: 'FI-FTN-Signicat',
    [Auth0ConnectionKey.BE_EMAIL]: 'myqred-login-BE-prod',
    [Auth0ConnectionKey.NL_EMAIL]: 'myqred-login-NL-prod',
    [Auth0ConnectionKey.NO_BANKID]: 'NO-BankID-Signicat',
    [Auth0ConnectionKey.BE_ITSME]: 'BE-itsme-Signicat-Prod',
    [Auth0ConnectionKey.BE_BEID]: 'BE-beid-Signicat-Prod',
    [Auth0ConnectionKey.NL_IDIN]: 'NL-iDIN-Signicat-Prod',
    [Auth0ConnectionKey.DE_EMAIL]: 'myqred-login-DE-prod',
  },
};

export const getConnection = (connectionKey: Auth0ConnectionKey) => {
  return CONNECTION_NAMES[env][connectionKey];
};

export const loginMethods: Record<CountryCode, Array<LoginMethod>> = {
  [CountryCode.SE]: [
    {
      label: 'BankID',
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.SE_BANKID],
    },
  ],
  [CountryCode.FI]: [
    {
      label: '',
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.FI_FTN],
    },
  ],
  [CountryCode.DK]: [
    {
      label: 'MitID',
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.DK_MITID],
    },
  ],
  [CountryCode.NO]: [
    {
      label: 'BankID',
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.NO_BANKID],
    },
  ],
  [CountryCode.NL]: [
    {
      label: 'iDIN',
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.NL_IDIN],
    },
    {
      label: 'email',
      secondary: true,
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.NL_EMAIL],
    },
  ],
  [CountryCode.BE]: [
    {
      label: 'itsme',
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.BE_ITSME],
    },
    {
      label: '.beID',
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.BE_BEID],
    },
    {
      label: 'email',
      secondary: true,
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.BE_EMAIL],
    },
  ],
  [CountryCode.DE]: [
    {
      label: '',
      connectionName: CONNECTION_NAMES[env][Auth0ConnectionKey.DE_EMAIL],
    },
  ],
};

export const onboardingLoginMethods: Record<CountryCode, Array<LoginMethod>> = {
  [CountryCode.SE]: [],
  [CountryCode.FI]: [],
  [CountryCode.DK]: [],
  [CountryCode.NO]: [],
  [CountryCode.NL]: [],
  [CountryCode.BE]: [],
  [CountryCode.DE]: [],
};
