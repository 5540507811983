import React from 'react';
import { useSelector } from 'react-redux';
import { ContactNumbers, ContactNumbersFormatted } from '~/constants/markets';
import { RootState } from '~/store/types/sharedTypes';
import {
  BodyContainer,
  ComponentSize,
  MainContainer,
  PageFooter,
} from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import Button, { ButtonType } from '../Button/button';
import Icon, { SvgSrc } from '../Icon/Icon';
import PageHeader from '../PageHeader/PageHeader';
import PageTitle from '../PageTitle/PageTitle';
import {
  ComponentsLibraryProvider,
  QredLogo,
  Stack,
} from '@qred/components-library';
import { CountryCode } from '@qred/shared-enums';

const ErrorFallback = () => {
  const market = useSelector((state: RootState) => state.intl.market);
  const isQeld = market === CountryCode.NL;

  return (
    <ComponentsLibraryProvider>
      <MainContainer>
        <PageHeader hideAllMenus>
          <Stack>
            <QredLogo qeld={isQeld} />
            <PageTitle mb={6}>Something went wrong!</PageTitle>
          </Stack>
        </PageHeader>
        <BodyContainer>
          <Button
            mb={1}
            type={ButtonType.Filled}
            link={`tel:${ContactNumbers[market]}`}
          >
            <Icon
              src={SvgSrc.Phone}
              size={ComponentSize.Small}
              mr={0.5}
              color={theme.colors.white}
            />
            {ContactNumbersFormatted[market]}
          </Button>
        </BodyContainer>
        <PageFooter />
      </MainContainer>
    </ComponentsLibraryProvider>
  );
};

export default ErrorFallback;
