import {
  Center,
  Group,
  Icon,
  Menu,
  Navbar,
  QredLogo,
  Typography,
  useMobileSidebar,
  useModals,
} from '@qred/qit-ui';
import { Stack } from '@qred/components-library';
import { CountryCode, StepStatus } from '~/enums';
import constVars from '@qred/shared-constants';
import React, { useCallback, useState } from 'react';
import { openBecomeTesterPage, openQredCardPage } from '~/services/links';
import { pushToGtmAction } from '~/store/actions/gtmActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '~/store/types/sharedTypes';
import { getMarketLanguageLabels } from '~/constants/markets';
import useTranslate from '~/hooks/useTranslate';
import { LanguageItem } from '~/components/shared/Sidebar/LanguageItem';
import { SwitchCompanyButton } from '~/components/shared/Sidebar/SwitchCompanyButton';
import useLogout from '~/hooks/useLogout';
import { logoutButton } from '~/constants/cy/components/menu-items';
import {
  sidebarBecomeTesterLink,
  sidebarChangeLanguageButton,
  sidebarHelpButton,
  sidebarHomeButton,
  sidebarLogo,
  sidebarProfileButton,
  sidebarQredVisaLink,
} from '~/constants/cy/components/sidebar';

const SidebarContent = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const logout = useLogout();
  const location = useLocation();
  const history = useHistory();
  const { toggle: toggleSidebar } = useMobileSidebar();
  const [languageSelectorOpened, setLanguageSelectorOpened] = useState(false);
  const { market, language } = useSelector((state: RootState) => state.intl);

  const { companies } = useSelector((state: RootState) => state.user);
  const languages = getMarketLanguageLabels(market);
  const modals = useModals();
  const selectedLanguageInfo = languages.find((l) => l.value === language);

  const showQredCard = market === CountryCode.SE || market === CountryCode.FI;
  const showExploreSection = showQredCard;
  const showQeldLogo = market === CountryCode.NL;

  const onOpenQredVisaPage = useCallback(() => {
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'qredVisa_button',
      })
    );
    openQredCardPage(market, language);
  }, [market, language]);

  const onLogoutConfirmed = () => {
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'confirm_logout',
        stepStatus: StepStatus.Finished,
      })
    );
    logout();
  };

  const onLogout = () => {
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'open_logout_confirmation_modal_button',
      })
    );
    modals.openConfirmModal({
      children: (
        <Typography weight={600} my="1em">
          {t('Sidebar.ConfirmLogoutModalSentence')}
        </Typography>
      ),
      labels: { confirm: t('Yes'), cancel: t('Cancel') },
      onConfirm: () => onLogoutConfirmed(),
    });
  };

  const onProfileClicked = useCallback(() => {
    toggleSidebar();
    history.push(`/${constVars.ROUTE_PROFILE}`);
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'profile_page_button',
      })
    );
  }, [history]);

  const onHomeClicked = useCallback(() => {
    toggleSidebar();
    history.push('/');
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'home_page_button',
      })
    );
  }, [history]);

  const onHelpClicked = useCallback(() => {
    toggleSidebar();
    history.push(`/${constVars.ROUTE_CONTACT}`);
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'help_page_button',
      })
    );
  }, [history]);

  const onOpenBecomeTesterPage = useCallback(() => {
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'become_tester_button',
      })
    );
    openBecomeTesterPage(market, language);
  }, [market, language]);
  return (
    <>
      <Navbar.Section px="2em" mb="2.4em">
        <Center>
          <QredLogo
            width="90"
            qeld={showQeldLogo}
            dataCy={sidebarLogo}
            onClick={onHomeClicked}
          />
        </Center>
      </Navbar.Section>
      <Navbar.Section grow={!showExploreSection}>
        <Stack spacing="sm">
          <Navbar.Link
            onClick={onHomeClicked}
            dataCy={sidebarHomeButton}
            active={location.pathname === '/'}
          >
            {t('Home')}
          </Navbar.Link>
          <Navbar.Link
            onClick={onProfileClicked}
            dataCy={sidebarProfileButton}
            active={location.pathname.startsWith(`/${constVars.ROUTE_PROFILE}`)}
          >
            {t('Profile')}
          </Navbar.Link>
          <Navbar.Link
            onClick={onHelpClicked}
            dataCy={sidebarHelpButton}
            active={location.pathname === `/${constVars.ROUTE_CONTACT}`}
          >
            {t('Help')}
          </Navbar.Link>
        </Stack>
      </Navbar.Section>
      {showExploreSection && (
        <Navbar.Section grow mt="2em">
          <Stack spacing="sm">
            <Navbar.Title>{t('Dashboard.Explore')}:</Navbar.Title>
            {showQredCard && (
              <Navbar.Link
                onClick={onOpenQredVisaPage}
                external
                dataCy={sidebarQredVisaLink}
              >
                {t('QredCard')}
              </Navbar.Link>
            )}
            <Navbar.Link
              onClick={onOpenBecomeTesterPage}
              external
              dataCy={sidebarBecomeTesterLink}
            >
              {t('Dashboard.BecomeATester')}
            </Navbar.Link>
          </Stack>
        </Navbar.Section>
      )}
      <Navbar.Section>
        <Stack spacing="sm">
          {languages.length > 1 && (
            <Menu onChange={setLanguageSelectorOpened}>
              <Menu.Target>
                <Navbar.Link
                  icon={selectedLanguageInfo?.icon}
                  dataCy={sidebarChangeLanguageButton}
                >
                  <Group spacing="sm">
                    {selectedLanguageInfo?.label.long}
                    <Icon
                      src={languageSelectorOpened ? 'ChevronUp' : 'ChevronDown'}
                      size="sm"
                    />
                  </Group>
                </Navbar.Link>
              </Menu.Target>
              <Menu.Dropdown>
                {languages
                  .filter(({ value }) => value !== language)
                  .map((_language) => (
                    <LanguageItem {..._language} key={_language.value} />
                  ))}
              </Menu.Dropdown>
            </Menu>
          )}
          {companies.length > 1 && <SwitchCompanyButton />}
          <Navbar.Link
            icon="ArrowRightFromBracket"
            dataCy={logoutButton}
            onClick={onLogout}
          >
            {t('Dashboard.SignOut')}
          </Navbar.Link>
        </Stack>
      </Navbar.Section>
    </>
  );
};

export default SidebarContent;
