import React from 'react';
import { Body, Link } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import {
  openQredCardGeneralTermsPage,
  openQredCardPricesPage,
} from '~/services/links';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { ConfirmTermsAndConditionsPricelistTextStyle } from './TermsAndConditions.styled';

export const TermsAndConditions = () => {
  const translate = useTranslate();
  const {
    intl: { market, language },
  } = useSelector((state: RootState) => state);

  return (
    <ConfirmTermsAndConditionsPricelistTextStyle>
      <Body size="sm">
        {translate('CreditCardTermsAndConditionsCard.Text3', {
          generalTermLink: (
            <Link
              size="sm"
              onClick={() => openQredCardGeneralTermsPage(market, language)}
            >
              {translate('CreditCardTermsAndConditionsCard.Link1').toString()}
            </Link>
          ),
          qredCardPricesLink: (
            <Link
              size="sm"
              onClick={() => openQredCardPricesPage(market, language)}
            >
              {translate(
                'CreditCardTermsAndConditionsCard.Link2Legacy'
              ).toString()}
            </Link>
          ),
        })}
      </Body>
    </ConfirmTermsAndConditionsPricelistTextStyle>
  );
};
