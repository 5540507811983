import { Button, Label, Menu } from '@qred/components-library';
import React, { useState } from 'react';
import constVars from '~/constants/constVars';
import { posthogActionNames } from '~/constants/posthogActionNames';
import useLogout from '~/hooks/useLogout';
import { usePostHogEvents } from '~/hooks/usePostHogEvents';
import useTranslate from '~/hooks/useTranslate';
import { useHistory } from 'react-router-dom';
import { useSepaDirectDebit } from '~/hooks/useSepaDirectDebit';

const AccountMenu = () => {
  const t = useTranslate();
  const logout = useLogout();
  const { capture } = usePostHogEvents();
  const history = useHistory();
  const [menuOpened, setMenuOpened] = useState(false);
  const { showPaymentTab } = useSepaDirectDebit();

  const handleLogout = () => {
    logout();
    capture({
      action: posthogActionNames.sidebar.LOG_OUT,
      sidebar: true,
    });
  };
  const handleHelpAndSupport = () => {
    history.push(`/${constVars.ROUTE_HELP_AND_SUPPORT}`);
  };

  const handleAccountOnClick = () => {
    setMenuOpened(!menuOpened);
  };

  return (
    <Menu onChange={handleAccountOnClick}>
      <Menu.Target>
        <Button
          size="md"
          leftIcon="account_circle"
          leftIconProps={{ filled: menuOpened, color: 'Icon/Default' }}
          rightIcon="expand_more"
          rightIconProps={{ color: 'Icon/Default' }}
          variant="tertiary"
        >
          <Label size="lg" color="Text/Default" px={3}>
            {t('Account.NavTitleAccount')}
          </Label>
        </Button>
      </Menu.Target>
      <Menu.Dropdown w={199}>
        <Menu.Item
          onClick={() =>
            history.push({
              pathname: `/${constVars.ROUTE_ACCOUNT}`,
              search: '?tab=profile',
            })
          }
          icon="Edit"
        >
          {t('Account.NavLinkAccountDetails')}
        </Menu.Item>
        {showPaymentTab && (
          <Menu.Item
            onClick={() =>
              history.push({
                pathname: `/${constVars.ROUTE_ACCOUNT}`,
                search: '?tab=payment',
              })
            }
            icon="credit_card"
            color="Icon/Default"
          >
            {t('Account.NavLinkPaymentOptions')}
          </Menu.Item>
        )}
        <Menu.Item
          onClick={handleHelpAndSupport}
          icon="support"
          color="Icon/Default"
        >
          {t('HelpAndSupport.HeaderTitle')}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={handleLogout} icon="logout" iconColor="Icon/Danger">
          <Label color="Icon/Danger" size="md">
            {t('Logout')}
          </Label>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default AccountMenu;
