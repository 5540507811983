import {
  validateEmail,
  validatePersonalNumber,
} from '@qred/shared-component-library/src/validators';
import { removeNonDigits } from './formatters.helper';
import { CountryCode } from '~/enums';
import { IOnboardingFile } from '~/interfaces/Onboarding';
import { getFileExtension } from './files.helper';

interface FileValidationResult {
  isValid: boolean;
  isSizeError: boolean;
  isFormatError: boolean;
}

export const isFileSizeValid = (
  file: File | IOnboardingFile,
  maxSize: number
) => file.size <= maxSize;

const isMimeTypeValid = (
  file: File | IOnboardingFile,
  allowedTypes: string[]
) => {
  return allowedTypes.includes(file.type);
};

const isFileExtensionValid = (
  file: File | IOnboardingFile,
  allowedExtensions: string[]
) => {
  const fileType = `.${getFileExtension(file.name)}`;
  return allowedExtensions.includes(fileType);
};

const isFileFormatValid = (
  file: File | IOnboardingFile,
  allowedFileFormats: string[]
) => {
  if (file.type) {
    return isMimeTypeValid(file, allowedFileFormats);
  } else {
    return isFileExtensionValid(file, allowedFileFormats);
  }
};

export const validateFile = (
  file: File | IOnboardingFile,
  allowedFileFormats: string[],
  maxSize: number
): FileValidationResult => {
  const fileSizeIsValid = isFileSizeValid(file, maxSize);

  const fileFormatIsValid = isFileFormatValid(file, allowedFileFormats);

  return {
    isValid: fileFormatIsValid && fileSizeIsValid,
    isSizeError: !fileSizeIsValid,
    isFormatError: !fileFormatIsValid,
  };
};

interface ValidateFiles {
  hasAnyFormatIssue: boolean;
  hasAnySizeIssue: boolean;
}

export const validateFiles = (
  files: File[] | IOnboardingFile[],
  allowedFileFormats: string[],
  maxSize: number
): ValidateFiles => {
  const hasAnySizeIssue = files.some((file) => {
    return !isFileSizeValid(file, maxSize);
  });
  const hasAnyFormatIssue = files.some((file) => {
    return !isFileFormatValid(file, allowedFileFormats);
  });
  return { hasAnySizeIssue, hasAnyFormatIssue };
};

function isInvalidInput(value: string | number) {
  if (!value) return true;
  /*
  Uncomment the following lines in order to include '%' sign in the validation
  const values = value.trim().split('%');
  if (values.length > 2) return true; // User has entered more than one '%'
  if (values[1]) return true; // User has entered chars after '%'
  const n = Number(values[0]); // Empty string will be parsed to 0!
  */
  const n = Number(value); // Empty string will be parsed to 0!
  if (Number.isNaN(n)) return true; // User has entered illegal chars

  return n;
}

/**
 * Validate ownership percentage and return error message if it's invalid
 * @param value
 * @param translate
 * @returns error message in case of error and undefined if no error
 */
export const validateOwnershipPercentage = (
  value: string,
  translate: (id: string, values?: {}) => string | React.JSX.Element[]
) => {
  let message;
  if (beneficialOwnerPercentageValidator(value)) {
    message = 'ValidationErrors.BeneficialOwnerPercentage';
  }
  return message ? (translate(message) as string) : message;
};

// *************************** Field validators ******************************
// All field validator should return true if an error is found otherwise false

type FieldValidator<
  T = string | number | Array<any> | undefined | boolean | null
> = (value: T) => boolean;

export const emptyFieldValidator: FieldValidator = (value) => {
  if (!value) return true;
  if (value && String(value).length === 0) return true;
  if (value && !String(value).trim().length) return true;
  return false;
};

export const fullNameValidator: FieldValidator<string | undefined> = (
  value?: string
) => {
  if (!value) return true;
  if (value.trim().split(' ').length < 2) return true;
  return false;
};

export const emailValidator: FieldValidator = (value) => !validateEmail(value);

export const personalNumberValidator: FieldValidator = (value) => {
  const market = window.__QRED_MARKET__ || 'se';
  return !validatePersonalNumber(value, market);
};

export const accountNumberValidator: FieldValidator = (value) => {
  const market = window.__QRED_MARKET__;
  let regex = /^[0-9,-]*$/;

  if (market === CountryCode.SE) {
    regex = /^[0-9,(),-]{5,30}$/;
  }
  if (market === CountryCode.NO) {
    value = value?.toString().replaceAll(' ', '');
    regex = /^\d(?:-?\d){10}$/;
  }
  if (market === CountryCode.BE) {
    regex = /^[be][BE]([0-9,-]*)\d$/i;
  }
  if (market === CountryCode.FI) {
    regex = /^fi(\d){2}(\s)?(\d{4}\s?){3}(\d){2}$/i;
  }
  if (market === CountryCode.NL) {
    regex = /^NL[0-9]{2}[A-Z]{4}[0-9]{10}$/;
  }

  return !regex.test(String(value));
};

export const clearingNumberValidator: FieldValidator = (value) => {
  const regex = /^[0-9]{4}$/;

  return !regex.test(String(value));
};
export const percentageShareFormatValidator: FieldValidator = (value) => {
  const regex = /^\d*\.?\d*$/;
  return !regex.test(String(value));
};

export const percentageValidator: FieldValidator = (
  value:
    | string
    | number /* value is read from the event therefore we know it is string */
) => {
  const n = isInvalidInput(value);
  if (typeof n === 'boolean' && n === true) return true;
  if (n <= 0 || n > 100) return true;
  return false;
};

export const beneficialOwnerPercentageValidator: FieldValidator = (
  value: string
) => !Number(value) || Number(value) <= 25 || Number(value) > 100;

export const emptyListValidator: FieldValidator = (value) => {
  if (!value) return false;
  if (Array.isArray(value) && value.length >= 1) return false;

  return true;
};

export const turnoverValidator: FieldValidator = (value) => {
  if (!value) return false;
  const str = value.toString();
  if (!str.length) return false;
  const regex = /^(0|[1-9 ][0-9 ]{0,20})$/;

  return !regex.test(removeNonDigits(str));
};

export const zipCodeValidator: FieldValidator = (valueZipCode: string) => {
  const market = window.__QRED_MARKET__;
  let regex = /^$|s+/;
  if (market === CountryCode.NL) {
    regex = /^\d{4} ?[a-z]{2}$/i;
  }
  return !regex.test(valueZipCode);
};

export const digitValidator: FieldValidator = (value) => {
  const regex = /^\d+$/;
  return !regex.test(String(value));
};

export const validateBankAccountNumber = (
  accountNumber: string | null,
  market: CountryCode
) => {
  if (!accountNumber) return false;

  switch (market) {
    case CountryCode.SE: {
      const accountNumberFormatted = removeNonDigits(accountNumber);
      const regexAccountNumber = /^[\d(),-]{11,20}$/;
      return regexAccountNumber.test(accountNumberFormatted);
    }
    case CountryCode.NO: {
      const regexAccountNumber = /^\d{11}$/;
      const regexIban = /NO\d{13}/;
      return (
        regexAccountNumber.test(accountNumber) || regexIban.test(accountNumber)
      );
    }
    case CountryCode.DK: {
      const regexAccountNumber = /^\d{6,14}$/;
      const regexIban = /DK\d{16}/;
      return (
        regexAccountNumber.test(accountNumber) || regexIban.test(accountNumber)
      );
    }
    case CountryCode.FI: {
      const regexIban = /FI\d{16}/;
      return regexIban.test(accountNumber);
    }
    case CountryCode.BE: {
      const regexIban = /^BE\d{14}$/;
      return regexIban.test(accountNumber);
    }
    case CountryCode.NL: {
      const regexIban = /NL\d{2}[A-Z]{4}\d{10}/;
      return regexIban.test(accountNumber);
    }
    case CountryCode.DE: {
      const regexIban = /^DE\d{20}$/;
      return regexIban.test(accountNumber);
    }
    default:
      return false;
  }
};

export const validateYearOfBirth: FieldValidator<string> = (yearOfBirth) => {
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear - 18;
  const regex = /^(19|20)\d{2}$/;
  return !regex.test(yearOfBirth) || Number(yearOfBirth) > maxYear;
};
