import { Group } from '@qred/components-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import {
  setBeneficialOwners,
  updateForm,
} from '~/store/slices/cardOnboardingOffer.slice';
import { useCardOnboardingOfferSelectors } from '~/store/selectors/cardOnboardingOffer.selector';
import { isBeneficialOwner } from '~/helpers/onboarding.helper';
import AdditionalOwner from './AdditionalOwner';

const OwnersList = () => {
  const dispatch = useDispatch();
  const { owners, companyHasOtherBeneficialOwners, applicant } = useSelector(
    (state: RootState) => state.cardOnboardingOffer.form
  );

  const {
    applicantIsABeneficialOwner,
    applicantIsSoloBeneficialOwner,
  } = useCardOnboardingOfferSelectors();

  useEffect(() => {
    if (owners.length) {
      const shouldResetOwnersList =
        (applicantIsABeneficialOwner && !companyHasOtherBeneficialOwners) ||
        applicantIsSoloBeneficialOwner;

      if (applicantIsSoloBeneficialOwner) {
        dispatch(
          updateForm({
            companyHasOtherBeneficialOwners: false,
          })
        );
      }

      if (shouldResetOwnersList) {
        dispatch(setBeneficialOwners([]));
      } else {
        dispatch(
          setBeneficialOwners(
            owners.filter((owner) =>
              companyHasOtherBeneficialOwners
                ? isBeneficialOwner(owner.percentage)
                : !isBeneficialOwner(owner.percentage)
            )
          )
        );
      }
    }
  }, [
    companyHasOtherBeneficialOwners,
    applicant.percentage,
    applicantIsABeneficialOwner,
    applicantIsSoloBeneficialOwner,
  ]);

  return (
    <Group direction="column" spacing="sm">
      {owners.map((owner) => (
        <AdditionalOwner owner={owner} key={owner.id} />
      ))}
    </Group>
  );
};

export default OwnersList;
