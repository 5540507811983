import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { Accordion, Group, Icon, Label, Stack } from '@qred/components-library';
import { onChangeInputEvent, onFocusSelectEvent } from '~/types/types';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { ValidationContext } from '~/components/hoc/withValidation';
import {
  validateOfferApplicant,
  validateOfferGuarantor,
  validateOfferSigner,
} from '~/helpers/onboarding.helper';
import Signer from './Signer';
import Applicant from '../Applicant/Applicant';
import { SignerType } from '~/enums';
import { IOnboardingSigner } from '~/interfaces/Onboarding';

const Signers = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const validationContext = useContext(ValidationContext);

  const [accordionValue, setAccordionValue] = useState<string | null>(null);

  const { form, overview } = useSelector(
    (state: RootState) => state.onboardingOffer
  );

  /**
   * Get all emails from all signers, guarantors and applicant
   */
  const allEmails = [
    ...form.signers.map((s) => s.email),
    ...form.guarantors.map((g) => g.email),
    overview.signers.find((_signer) => _signer.type === SignerType.Applicant)
      ?.email,
    overview.email,
  ];

  /**
   * Get all phone numbers from all signers, guarantors and applicant
   */
  const allPhones = [
    ...form.signers.map((s) => s.phone),
    ...form.guarantors.map((g) => g.phone),
    overview.signers.find((_signer) => _signer.type === SignerType.Applicant)
      ?.phone,
    overview.phone,
  ];

  const onBlur = (event: onChangeInputEvent | onFocusSelectEvent | string) => {
    let name;
    if (typeof event === 'string') {
      name = event;
    } else {
      name = event.target.name;
    }
    dispatch(
      pushToGtmOnboardingAction({
        actionName: `${name}_change`,
      })
    );
  };

  /**
   * Check if the signer is valid and if their email or phone number
   * already exists on other signers or the applicant
   */
  const signerIsValidAndUnique = (signer: IOnboardingSigner) => {
    const signerHasRequiredData = validateOfferSigner(signer);
    const signerHasUniqueEmail =
      allEmails.filter((_email) => _email === signer.email).length === 1;
    const signerHasUniquePhone =
      allPhones.filter((_phone) => _phone === signer.phone).length === 1;

    return (
      signerHasRequiredData && signerHasUniqueEmail && signerHasUniquePhone
    );
  };

  /**
   * Check if the guarantor is valid and if their email or phone number
   * already exists on other signers or the applicant
   */
  const guarantorIsValidAndUnique = (guarantor: IOnboardingSigner) => {
    const guarantorHasRequiredData = validateOfferGuarantor(guarantor);
    const guarantorHasUniqueEmail =
      allEmails.filter((_email) => _email === guarantor.email).length === 1;
    const guarantorHasUniquePhone =
      allPhones.filter((_phone) => _phone === guarantor.phone).length === 1;

    return (
      guarantorHasRequiredData &&
      guarantorHasUniqueEmail &&
      guarantorHasUniquePhone
    );
  };

  const findFirstInvalidAccordionItem = () => {
    const applicantIsValid = validateOfferApplicant(form.applicant);
    if (!applicantIsValid) {
      return form.applicant.id;
    }
    const invalidSigner = form.signers.find(
      (signer) => !signerIsValidAndUnique(signer)
    );
    if (invalidSigner) {
      return invalidSigner.signerId.toString();
    }

    const invalidGuarantor = form.guarantors.find(
      (guarantor) => !guarantorIsValidAndUnique(guarantor)
    );
    if (invalidGuarantor) {
      return invalidGuarantor.signerId.toString();
    }

    return null;
  };

  useEffect(() => {
    const applicantHasErrors = !validateOfferApplicant(form.applicant);
    if (applicantHasErrors) {
      validationContext.addPropertyToValidationErrors('applicant');
    }

    const signersHasError = form.signers.some(
      (signer) => !signerIsValidAndUnique(signer)
    );

    if (signersHasError) {
      validationContext.addPropertyToValidationErrors('signers');
    }

    const guarantorsHasError = form.guarantors.some(
      (guarantor) => !guarantorIsValidAndUnique(guarantor)
    );

    if (guarantorsHasError) {
      validationContext.addPropertyToValidationErrors('guarantors');
    }

    const firstInvalidAccordionItem = findFirstInvalidAccordionItem();
    setAccordionValue(firstInvalidAccordionItem);

    return () => {
      validationContext.removePropertyFromValidationErrors('applicant');
      validationContext.removePropertyFromValidationErrors('signers');
      validationContext.removePropertyFromValidationErrors('guarantors');
    };
  }, [form.signers, form.guarantors, form.applicant]);

  return (
    <Accordion value={accordionValue} onChange={setAccordionValue}>
      <Stack spacing="xxl">
        <Stack>
          <Label size="lg">
            {translate('OnboardingOffer.AdditionalSigners')}
          </Label>
          <div>
            <Accordion.Item value={form.applicant.id} key={form.applicant.id}>
              <Accordion.Control>
                <Group align="center">
                  <Icon
                    src={
                      validateOfferApplicant(form.applicant)
                        ? 'CheckCircle'
                        : 'CheckCircleUnfilled'
                    }
                    size="lg"
                    iconSize={16}
                  />
                  {form.applicant.fullName} (
                  {(translate('You') as string).toLowerCase()})
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Applicant
                  key={form.applicant.id}
                  applicant={form.applicant}
                  onBlur={onBlur}
                />
              </Accordion.Panel>
            </Accordion.Item>

            {!!form.signers?.length &&
              form.signers.map((signer) => (
                <Accordion.Item
                  value={signer.signerId.toString()}
                  key={signer.signerId}
                >
                  <Accordion.Control>
                    <Group align="center">
                      <Icon
                        src={
                          signerIsValidAndUnique(signer)
                            ? 'CheckCircle'
                            : 'CheckCircleUnfilled'
                        }
                        size="lg"
                        iconSize={16}
                      />
                      {signer.name}
                    </Group>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Signer
                      key={signer.signerId}
                      signer={signer}
                      onBlur={onBlur}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
          </div>
        </Stack>

        {!!form.guarantors.length && (
          <Stack>
            <Label size="lg">
              {translate('OnboardingOffer.AdditionalGuarantors')}
            </Label>
            <div>
              {form.guarantors.map((guarantor) => (
                <Accordion.Item
                  value={guarantor.signerId.toString()}
                  key={guarantor.signerId}
                  style={{ marginTop: 0 }}
                >
                  <Accordion.Control>
                    <Group align="center">
                      <Icon
                        src={
                          guarantorIsValidAndUnique(guarantor)
                            ? 'CheckCircle'
                            : 'CheckCircleUnfilled'
                        }
                        size="lg"
                        iconSize={16}
                      />
                      {guarantor.name}
                    </Group>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Signer
                      key={guarantor.signerId}
                      signer={guarantor}
                      onBlur={onBlur}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </div>
          </Stack>
        )}
      </Stack>
    </Accordion>
  );
};

export default Signers;
