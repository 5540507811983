import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { getSignedUrl, postFile } from '~/services/api/onboarding';
import { logToSentry } from '~/helpers/loggers.helper';
import { toggleGlobalErrorOn } from '~/store/slices/globalError.slice';
import { GlobalErrorType } from '~/enums';
import { useOnboardingOfferSelectors } from '~/store/selectors/onboardingOffer.selector';
import { Body, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import useGoToNextStep from '../../hooks/useGoToNextStep';
import { IOnboardingFile } from '~/interfaces/Onboarding';

const OwnershipFooter = () => {
  const dispatch = useDispatch();
  const {
    onboardingOffer: {
      form: { ownershipFiles, clientId },
    },
    intl: { market },
  } = useSelector((state: RootState) => state);
  const translate = useTranslate();

  const onNextStepClick = useGoToNextStep();

  const { ownersDataIsFetched } = useOnboardingOfferSelectors();
  const [documentsApiStatus, setDocumentsApiStatus] = useState(ApiStatus.Idle);

  const createFilesFromObjectUrls = (documents: IOnboardingFile[]) =>
    Promise.all(
      documents.map(async (document) => {
        const res = await fetch(document.objectURL);
        const blob = await res.blob();
        return new File([blob], document.name, blob);
      })
    );

  const postFilesToS3 = async (files: File[]) => {
    const fileKeys = ownershipFiles.map((localFile) => localFile.name);
    const res = await getSignedUrl(
      fileKeys,
      clientId ? String(clientId) : '',
      market,
      true,
      'ownership'
    );
    await Promise.all(
      res.data.map(async (infoContainer: any, i: number) => {
        const form = new FormData();
        Object.keys(infoContainer.fields).forEach((key) =>
          form.append(key, infoContainer.fields[key])
        );
        form.append('file', files[i]);
        await postFile(infoContainer.url, form);
      })
    );
    return res.data.map((file) => file.fields.key);
  };

  const uploadFilesToS3 = async () => {
    const files = await createFilesFromObjectUrls(ownershipFiles);
    const filePaths = await postFilesToS3(files);
    return filePaths;
  };

  const uploadDocuments = async () => {
    setDocumentsApiStatus(ApiStatus.Started);
    try {
      await uploadFilesToS3();
      setDocumentsApiStatus(ApiStatus.Completed);
      onNextStepClick();
    } catch (err: any) {
      setDocumentsApiStatus(ApiStatus.Failed);
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
      logToSentry(err, 'uploadOwnershipDocuments', {
        product: 'onboarding',
      });
    }
  };

  return (
    <Stack spacing={'sm'} align="center">
      {ownersDataIsFetched && (
        <Body size="sm">
          {translate('OnboardingOffer.BeneficialOwnerNextButtonConfirmation')}
        </Body>
      )}
      {ownershipFiles.length ? (
        <NextButton
          label="OnboardingOffer.Next"
          isLoading={documentsApiStatus === ApiStatus.Started}
          action={uploadDocuments}
        />
      ) : (
        <NextButton label="OnboardingOffer.Next" action={onNextStepClick} />
      )}
    </Stack>
  );
};

export default OwnershipFooter;
