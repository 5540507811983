import { Stack, useWindowProperties } from '@qred/components-library';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import SkipButton from '~/components/shared/Stepper/components/SkipButton';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { StepStatus } from '~/enums';
import { patchApplication } from '~/store/actions/onboardingActions';
import { goToNextStep } from '~/store/slices/onboardingApplication.slice';

export const CheckpointFooter = () => {
  const {
    onboardingApplication: { apiStatus },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { isMobile } = useWindowProperties();
  const [buttonClicked, setButtonClicked] = useState<
    'BoostApplication' | 'CompleteLater'
  >();

  const handleBoostApplication = async () => {
    setButtonClicked('BoostApplication');
    try {
      dispatch(
        pushToGtmOnboardingAction({
          actionName: 'boost_application_button_pressed',
        })
      );
      await dispatch(patchApplication({ choseToBoostApplication: true }));
      dispatch(goToNextStep());
    } catch (_err: any) {
      dispatch(
        pushToGtmOnboardingAction({
          stepStatus: StepStatus.Error,
          errorMessage: _err.response?.data?.message,
        })
      );
    }
  };

  const onCompleteLaterClick = async () => {
    setButtonClicked('CompleteLater');
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'complete_later_button_pressed',
      })
    );
    await dispatch(patchApplication({ choseToBoostApplication: false }));
    dispatch(goToNextStep());
  };

  return (
    <Stack w={isMobile ? '100%' : 'auto'}>
      <NextButton
        label="Onboarding.BoostApplication"
        isLoading={
          buttonClicked === 'BoostApplication' &&
          apiStatus.patch === ApiStatus.Started
        }
        action={handleBoostApplication}
        rightIcon="east"
        disabled={apiStatus.patch === ApiStatus.Started}
      />
      <SkipButton
        label="Onboarding.CompleteLater"
        action={onCompleteLaterClick}
        isLoading={
          buttonClicked === 'CompleteLater' &&
          apiStatus.patch === ApiStatus.Started
        }
        disabled={apiStatus.patch === ApiStatus.Started}
      />
    </Stack>
  );
};
