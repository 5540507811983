import { baseQuery } from '../../baseQuery/baseQuery';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { getMarketBasedOnHostname } from '~/helpers/utils';
import { z } from 'zod';
import { BankConnectionResult } from '~/enums';
const market = getMarketBasedOnHostname();

export type PsuType = 'business' | 'personal';

interface AuthMethod {
  name: string;
  title: string;
  psuType: PsuType;
}

export interface ASPSP {
  name: string;
  country: string;
  logo: string;
  psuTypes: PsuType[];
  authMethods: AuthMethod[];
  maximumConsentValidity: number;
  beta: boolean;
  bic: string;
  requiredPsuHeaders: string[];
  payments: any[];
}

type GetAspspsResultType = Array<ASPSP>;
type GetAspspsQueryArg = {
  psuType: PsuType;
};

interface GetAspspsResultDTO {
  aspsps: ASPSP[];
}

interface PostAuthQueryArg {
  body: {
    aspsp: {
      name: string;
      country: string;
    };
    clientId: number;
    redirectUrl?: string; // if provided, the session endpoint will return this in the response. If not provided, the session endpoint will not return a redirect URL
    language: string;
    state?: string;
    maximumConsentValidity: number;
    psuType: PsuType;
  };
}

interface PostAuthResultType {
  authorizationUrl: string;
  psuIdHash: string;
  state: string;
}

interface PostSessionQueryArg {
  body: {
    state: string;
    code?: string;
    error?: string;
    errorDescription?: string;
  };
}

interface PostSessionResultType {
  redirectUrl: string;
  status: BankConnectionResult;
}

interface GetSessionStatusQueryArg {
  state: string;
}

interface GetSessionStatusResultType {
  status: BankConnectionResult;
}

export const fetchBaseQueryErrorSchema = z
  .object({
    data: z.object({
      redirectUrl: z.string(),
    }),
    status: z.number(),
  })
  .optional();

export const openBankingApi = createApi({
  reducerPath: 'openBankingApi',
  baseQuery: baseQuery(
    `${import.meta.env.VITE_API_OPEN_BANKING_BASE_URL}/country/${market}`,
    false
  ),
  endpoints: (build) => ({
    getAspsps: build.query<GetAspspsResultType, GetAspspsQueryArg>({
      query: ({ psuType }) => ({
        url: '/aspsps',
        params: {
          psuType,
        },
      }),
      transformResponse: (response: GetAspspsResultDTO) => response.aspsps,
    }),
    postAuth: build.mutation<PostAuthResultType, PostAuthQueryArg>({
      query: ({ body }) => ({
        method: 'POST',
        url: '/auth',
        body,
      }),
    }),
    postSession: build.query<PostSessionResultType, PostSessionQueryArg>({
      query: ({ body }) => ({
        method: 'POST',
        url: '/session',
        body,
      }),
    }),
    getSessionStatus: build.query<
      GetSessionStatusResultType,
      GetSessionStatusQueryArg
    >({
      query: ({ state }) => ({
        method: 'GET',
        url: `/session-status/${state}`,
      }),
    }),
  }),
});

export const {
  useGetAspspsQuery,
  usePostAuthMutation,
  usePostSessionQuery,
  useGetSessionStatusQuery,
} = openBankingApi;
