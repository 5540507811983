import React from 'react';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { useCardOnboardingOfferSelectors } from '~/store/selectors/cardOnboardingOffer.selector';

const OwnershipHeader = () => {
  const { ownersDataIsFetched } = useCardOnboardingOfferSelectors();

  const title = 'OnboardingOffer.Ownership';
  const description = ownersDataIsFetched
    ? 'OnboardingOffer.OwnershipFetchedOwnersDescription'
    : 'OnboardingOffer.OwnershipDescription';

  return <StepHeader title={title} description={description} />;
};

export default OwnershipHeader;
