import { ActionIcon, Button } from '@qred/components-library';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import constVars from '~/constants/constVars';
import { menuOpenButton } from '~/constants/cy/shared';
import { theme } from '~/styles/themes';
import MenuItems from './MenuItems';
import OnboardingMenuItems from './OnboardingMenuItems';
import { HamburgerIcon, MainMenuContainer } from './StyledComponentsMenu';
import useTranslate from '~/hooks/useTranslate';

interface Props {
  alignMenuRight?: boolean;
  hasSiblings?: boolean;
  showText?: boolean;
}

const MainMenu: React.FC<Props> = ({
  alignMenuRight,
  hasSiblings,
  showText = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  const onMainMenuClicked = () => {
    setIsOpen(!isOpen);
    if (!isOpen && ref && ref.current) {
      ref.current.focus();
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const isLoanOnboarding = pathname.includes(`/${constVars.ROUTE_ONBOARDING}`);
  const isCardOnboarding = pathname.includes(
    `/${constVars.ROUTE_CARD_ONBOARDING_OFFER}`
  );
  const isBankConnectionResultPage = pathname.includes(
    constVars.ROUTE_BANK_CONNECTION_RESULT
  );
  const isBankConnectionStandalonePage = pathname.includes(
    constVars.ROUTE_BANK_CONNECTION
  );
  const showOnboardingMenu =
    isLoanOnboarding ||
    isCardOnboarding ||
    isBankConnectionResultPage ||
    isBankConnectionStandalonePage;
  const t = useTranslate();

  return (
    <MainMenuContainer
      alignMenuRight={alignMenuRight}
      hasSiblings={hasSiblings}
      onBlur={closeMenu}
      ref={ref}
      tabIndex={0}
      data-cy={menuOpenButton}
    >
      {isOpen ? (
        <>
          {showText ? (
            <Button variant="tertiary" onClick={onMainMenuClicked}>
              {t('Close')}
            </Button>
          ) : (
            <ActionIcon
              onClick={onMainMenuClicked}
              icon="close"
              style={{
                background: 'none',
              }}
            />
          )}
          {showOnboardingMenu ? (
            <OnboardingMenuItems onMenuItemClick={closeMenu} />
          ) : (
            <MenuItems />
          )}
        </>
      ) : showOnboardingMenu ? (
        <>
          {showText ? (
            <Button
              variant="tertiary"
              onClick={onMainMenuClicked}
              onMouseDown={(e) => e.preventDefault()}
            >
              {t('Menu')}
            </Button>
          ) : (
            <>
              <ActionIcon
                onClick={onMainMenuClicked}
                onMouseDown={(e) => e.preventDefault()}
                icon="menu"
                style={{
                  background: 'none',
                }}
              />
            </>
          )}
        </>
      ) : (
        <HamburgerIcon onClick={onMainMenuClicked}>
          <Icon src={SvgSrc.Bars} color={theme.colors.dark} />
        </HamburgerIcon>
      )}
    </MainMenuContainer>
  );
};

export default MainMenu;
