import React from 'react';
import {
  Body,
  Button,
  H5,
  Stack,
  useModals,
  useWindowProperties,
} from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';

const WelcomeModalContent = () => {
  const t = useTranslate();
  const { isMobile } = useWindowProperties();
  const modals = useModals();

  return (
    <Stack>
      <img
        src={
          isMobile
            ? '/CardWelcomeImageMobile.png'
            : '/CardWelcomeImageDesktop.png'
        }
        alt=""
      />
      <H5>{t('OnboardingCardOffer.WelcomeModalTitle')}</H5>
      <Body size="lg">{t('OnboardingCardOffer.WelcomeModalText')}</Body>
      <Button
        onClick={() => modals.closeAll()}
        ml="auto"
        display="block"
        fullWidth={isMobile}
      >
        {t('OnboardingCardOffer.WelcomeModalButtonText')}
      </Button>
    </Stack>
  );
};

export default WelcomeModalContent;
