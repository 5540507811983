import React from 'react';
import styled, { css } from 'styled-components';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import {
  ArrangementProps,
  BaseArrangement,
  ComponentSize,
} from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import { ApplicationStep } from '~/enums';
import Stack from '~/components/shared/Layout/Stack';
import { Label } from '@qred/components-library';

const getBackgroundBar = (props: {
  stepBarAnimation: number;
  currentStep: number;
  nextStepAnimation: boolean;
  isPassed: boolean;
}) => {
  if (props.nextStepAnimation) {
    if (props.stepBarAnimation <= props.currentStep) {
      return css`linear-gradient(to right,
      ${theme.colors.primaryGreen} 50%,
      ${theme.colors.document} 0)`;
    }
  } else if (props.isPassed && props.stepBarAnimation <= props.currentStep) {
    return theme.colors.primaryGreen;
  }
  return theme.colors.document;
};

const StepIcon = styled.div<{
  isPassed: boolean;
  stepBarAnimation: number;
  currentStep: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isPassed ? theme.colors.primaryGreen : theme.colors.white};
  width: 2.6em;
  height: 2.6em;
  padding: 0.35em;
  z-index: 2;
  border: ${(props) =>
    props.isPassed && props.stepBarAnimation <= props.currentStep
      ? `0.25em solid ${theme.colors.primaryGreen}`
      : `0.25em solid ${theme.colors.document}`};
  transition: all 0.5s ease;
`;

const StepsContainer = styled.ul`
  display: flex;
`;

const StepItem = styled.li<{
  stepBarAnimation: number;
  currentStep: number;
  nextStepAnimation: boolean;
  isPassed: boolean;
}>`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 33.33%;

  &:not(:last-child) {
    &:after {
      content: '';
      position: relative;
      height: 0.4em;
      margin-top: -0.3em;
      background: ${getBackgroundBar};
      animation: makeItfadeIn 1.2s 0.5s forwards;
      background-size: 200% 100%;
      background-position: right;
      animation-iteration-count: ${(props) =>
        props.stepBarAnimation === props.currentStep ? 'infinite' : null};

      order: -1;
      width: calc(100% - 2em);
      top: calc(2em / 2 + 0.5em);
      left: calc(50% + calc(2em / 2));

      @keyframes makeItfadeIn {
        100% {
          background-position: left;
        }
      }
    }
  }
`;

const StepLabel = styled.div`
  text-align: center;
`;

interface Props {
  currentStep: ApplicationStep;
  steps: Array<string>;
  nextStepBarAnimation?: boolean;
  arrangementProps?: ArrangementProps;
}

const ProgressStepper: React.FC<Props> = (props) => {
  const isStepPassed = (step: number) => props.currentStep >= step;

  return (
    <BaseArrangement {...props.arrangementProps}>
      <StepsContainer>
        {props.steps.map((stepItem, index) => (
          <StepItem
            key={stepItem}
            currentStep={props.currentStep}
            stepBarAnimation={index + 1}
            nextStepAnimation={
              index + 1 === props.currentStep && !!props.nextStepBarAnimation
            }
            isPassed={isStepPassed(index + 2)}
          >
            <Stack spacing="sm" align="center">
              <StepIcon
                currentStep={props.currentStep}
                isPassed={isStepPassed(index + 1)}
                stepBarAnimation={index + 1}
              >
                <Icon
                  size={ComponentSize.Small}
                  src={SvgSrc.Check}
                  color={
                    isStepPassed(index + 1)
                      ? theme.colors.white
                      : theme.colors.document
                  }
                />
              </StepIcon>
              <StepLabel>
                <Label size="sm">{stepItem}</Label>
              </StepLabel>
            </Stack>
          </StepItem>
        ))}
      </StepsContainer>
    </BaseArrangement>
  );
};

export default ProgressStepper;
