import {
  Banner,
  Body,
  FieldErrors,
  Label,
  Stack,
} from '@qred/components-library';
import React from 'react';
import { isEmptyObject } from '~/helpers/utils';
import useTranslate from '~/hooks/useTranslate';

interface Props {
  errors: FieldErrors<{ message: string }>;
}
export const FormErrorBanner = (props: Props) => {
  const { errors } = props;

  const t = useTranslate();

  const getFieldNameBySchemaKey = (key: string) => {
    switch (key) {
      case 'companyName':
        return t('Onboarding.SelectCompanySearchByCompanyNameLabel');
      case 'companyAddress':
        return t('Onboarding.SelectCompanySearchByCompanyAddressLabel');
      case 'firstName':
        return t('FirstName');
      case 'lastName':
        return t('LastName');
      case 'dateOfBirth':
        return t('DateOfBirth');
      case 'mobilePhone':
        return t('MobilePhone');
      case 'email':
        return t('Email');
      case 'addressFieldValue':
        return t('Onboarding.ApplicantAddress');
      case 'companySearch':
        return t('Onboarding.SelectCompanySearchInputLabel');
      default:
        return '';
    }
  };
  return (
    <Banner
      opened={!isEmptyObject(errors)}
      message={
        <Stack spacing={'xs'}>
          <Label size="lg">{t('FormErrorBannerTitle')}</Label>
          <Body size="lg">
            <ul style={{ listStyleType: 'unset', marginLeft: '2em' }}>
              {Object.entries(errors).map(([key, value]) => (
                <li key={key}>
                  {`${getFieldNameBySchemaKey(key)}: ${
                    value?.message && t(value.message)
                  }`}
                </li>
              ))}
            </ul>
          </Body>
        </Stack>
      }
      state={'critical'}
      withCloseButton={false}
    />
  );
};
