import { CountryCode } from '@qred/shared-enums';

export const normalizeOrgNumberHelper = (
  orgNumber: string,
  market?: CountryCode
) => {
  if (market === CountryCode.DE) return orgNumber;
  const charactersToRemove = /[-]|[ ]|[,]|[.]/g;
  return orgNumber?.replace(charactersToRemove, '');
};
