/* This module is a copy of Table component and is meant to use for PaymentCalculator in offer page temorarily */
/* It should be removed removed after PeymentCalculator's new design is implemented */

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';
import CardSection from '~/components/shared/CardSection/CardSection';
import Icon from '~/components/shared/Icon/Icon';
import {
  ArrangementProps,
  BaseArrangement,
  ComponentSize,
} from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import { Body, Colors, Label, Overline } from '@qred/components-library';

const TableWrapper = styled(BaseArrangement)`
  overflow-x: scroll;
`;

const TableContainer = styled.table`
  width: 100%;
  border-top: 1px solid ${theme.colors.primaryGray};
  border-left: 1px solid ${theme.colors.primaryGray};
`;

export const TableData = styled.td<{ fullWidthMobile?: boolean }>`
  border-right: 1px solid ${theme.colors.primaryGray};
  border-bottom: 1px solid ${theme.colors.primaryGray};
  text-align: left;
  vertical-align: middle;
  @media (orientation: portrait) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.fullWidthMobile ? '100%' : '50%')};
    flex-grow: 1;
  }
`;

export const TableHeader = styled.thead`
  @media (orientation: portrait) {
    display: none;
  }
`;

export const TableHead = styled.th<{ center?: boolean }>`
  border: 1px solid ${theme.colors.primaryGray};
  width: ${(props) => (props.center ? '10em' : 'auto')};
`;

export const TableRow = styled.tr`
  :nth-child(even) {
    background-color: ${theme.colors.document};
  }

  @media (orientation: portrait) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const TableCell = styled.div<{ center?: boolean }>`
  padding: 0.7em;
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  vertical-align: middle;
  color: ${theme.colors.dark};
`;

const TotalLabelCell = styled.div<{ center?: boolean }>`
  padding: 1.2em;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

const TotalCell = styled.div<{ center?: boolean }>`
  padding: 1em;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

const HeaderCell = styled.div<{ center?: boolean }>`
  padding: 0.7em;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  min-width: ${(props) => (props.center ? '10em' : 'auto')};
  white-space: nowrap;
`;

const FullWidthCell = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
`;

const FullWidthCellTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  padding: 0.1em 0;
`;

const JSXValueWrapper = styled.div`
  margin: 0 auto;
`;

interface Header {
  value: string;
  iconSrc?: IconProp;
}

interface Footer {
  value: string;
  // This is a special solution to get the style of "total" cell right for PaymentCalculator
  isTotalLabel?: boolean;
  isEmptyCell?: boolean;
}

export interface CellData {
  value: string | number | JSX.Element | undefined;
  color?: Colors;
  iconSrc?: IconProp;
  iconColor?: string;
  isFullWidthCell?: boolean;
}

interface Props {
  data: Array<Array<CellData>>;
  headers: Array<Header>;
  footers?: Array<Footer>;
  center?: boolean;
  fullWidthMobile?: boolean;
  dataTestId?: string;
}

const Table: React.FC<Props & ArrangementProps> = (props) => (
  <TableWrapper mt={props.mt} mb={props.mb} mr={props.mr} ml={props.ml}>
    <TableContainer data-testid={props.dataTestId}>
      <TableHeader>
        <TableRow>
          {props.headers.map((header, index) => (
            <TableHead center={props.center} key={index}>
              <HeaderCell center={props.center}>
                {header.iconSrc && (
                  <Icon
                    mr={0.3}
                    size={ComponentSize.Small}
                    color={theme.colors.secondaryGray}
                    src={header.iconSrc}
                  />
                )}
                <Label size="md">{header.value}</Label>
              </HeaderCell>
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>

      <tbody>
        {props.data.map((item, index) => (
          <TableRow key={index}>
            {item.map((col: CellData, key: number) => {
              if (key >= props.headers.length) return null;
              if (window.matchMedia('(orientation:portrait)').matches) {
                if (col.isFullWidthCell) {
                  return (
                    <TableData
                      fullWidthMobile={props.fullWidthMobile}
                      key={key}
                    >
                      <FullWidthCell>
                        <FullWidthCellTitleContainer>
                          {props.headers[key].iconSrc && (
                            <IconContainer>
                              <Icon
                                size={ComponentSize.Medium}
                                src={props.headers[key].iconSrc as IconProp}
                              />
                            </IconContainer>
                          )}
                          <Overline>{props.headers[key].value}</Overline>
                        </FullWidthCellTitleContainer>
                        <Body size="lg">{col.value}</Body>
                      </FullWidthCell>
                    </TableData>
                  );
                }
                return (
                  <TableData fullWidthMobile={props.fullWidthMobile} key={key}>
                    <CardSection
                      title={props.headers[key].value}
                      iconSrc={
                        col.iconColor ? col.iconSrc : props.headers[key].iconSrc
                      }
                      iconColor={
                        col.iconColor
                          ? col.iconColor
                          : theme.colors.secondaryGray
                      }
                      value={
                        typeof col.value === 'string' ||
                        typeof col.value === 'number'
                          ? col.value
                          : ''
                      }
                      valueColor={col.color}
                    >
                      {/* If col.value is an JSX element, render as children rather than CardSection value */}
                      {typeof col.value === 'object' ? col.value : null}
                    </CardSection>
                  </TableData>
                );
              }
              return (
                <TableData key={key}>
                  <TableCell center={props.center} key={key}>
                    {col.iconSrc && (
                      <Icon
                        mr={0.3}
                        size={ComponentSize.Small}
                        color={
                          col.iconColor
                            ? col.iconColor
                            : theme.colors.primaryGreen
                        }
                        src={col.iconSrc}
                      />
                    )}
                    {typeof col.value === 'string' ||
                    typeof col.value === 'number' ? (
                      <Body size="sm">{col.value}</Body>
                    ) : null}
                    {typeof col.value === 'object' ? (
                      <JSXValueWrapper>
                        <Body>{col.value}</Body>
                      </JSXValueWrapper>
                    ) : null}
                  </TableCell>
                </TableData>
              );
            })}
          </TableRow>
        ))}
      </tbody>

      {/* Following footer solution is heavily customized to accomodate the PaymentCalculator design */}
      {props.footers && (
        <tfoot>
          <TableRow>
            {props.footers.map((footer, key) => {
              if (window.matchMedia('(orientation:portrait)').matches) {
                if (footer.isEmptyCell) return null;
                if (footer.isTotalLabel) {
                  return (
                    <TableData key={key} style={{ width: '100%' }}>
                      <TotalLabelCell center={props.center}>
                        <Body size="md">{footer.value}</Body>
                      </TotalLabelCell>
                    </TableData>
                  );
                }
                return (
                  <TableData
                    fullWidthMobile={props.fullWidthMobile}
                    key={key}
                    style={{ width: '33.3%' }}
                  >
                    <TotalCell center={props.center}>
                      <Overline display={'block'}>
                        {props.headers[key].value}
                      </Overline>
                      <Body size="sm">{footer.value}</Body>
                    </TotalCell>
                  </TableData>
                );
              }
              return (
                <TableData fullWidthMobile={props.fullWidthMobile} key={key}>
                  <TableCell center={props.center}>
                    <Overline>{footer.value}</Overline>
                  </TableCell>
                </TableData>
              );
            })}
          </TableRow>
        </tfoot>
      )}
    </TableContainer>
  </TableWrapper>
);

export default Table;
