import styled, { css } from 'styled-components';
import { BaseArrangement } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';

export const Label = styled.label<{ labelColor?: string }>`
  color: ${(props) => props.labelColor || theme.colors.dark};
  vertical-align: middle;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0.5em;
  display: block;
`;

export const additionalInputContentContainerPosition = '1em';

export const inputPadding = '0.7em';
export const inputPaddingThin = '0.5em';

export const InputWrapper = styled.div`
  position: relative;
`;

export const AdditionalInputContentContainerRight = styled.div`
  position: absolute;
  right: ${additionalInputContentContainerPosition};
  top: 50%;
  transform: translateY(-50%);
`;

export const AdditionalInputContentContainerLeft = styled.div`
  position: absolute;
  left: ${additionalInputContentContainerPosition};
  top: 50%;
  transform: translateY(-50%);
`;

export const RightText = styled(AdditionalInputContentContainerRight)`
  font-size: 12px;
  text-transform: uppercase;
  pointer-events: none;
  color: ${theme.colors.secondary};
`;

export const LeftText = styled(AdditionalInputContentContainerLeft)<{
  value: string | ReadonlyArray<string> | number | undefined;
}>`
  font-size: 16px;
  color: ${(props) => (props.value ? theme.colors.dark : theme.colors.neutral)};
`;

export const LoadingSpinnerWrapper = styled(
  AdditionalInputContentContainerRight
)`
  height: 30px;
  width: 30px;
`;

export const InputContainer = styled(BaseArrangement)`
  width: 100%;
  margin: 0.4em 0;
  position: relative;
`;

export const InputSupportContainer = styled(BaseArrangement)`
  display: grid;
`;

export const Input = styled.input<{
  disabled: boolean;
  rightText: boolean;
  isLoading: boolean;
  rightContainerWidth?: number;
  leftText: boolean;
  leftContainerWidth?: number;
  thin?: boolean;
}>`
  line-height: 1;
  background-color: ${(props) =>
    props.disabled ? theme.colors.backdrop : theme.colors.white};
  color: ${(props) =>
    props.disabled ? theme.colors.newSecondaryGray : theme.colors.midwayBlack};
  border: 0.1em solid ${theme.colors.neutral};

  &:not(:disabled) {
    &:hover {
      border-color: ${theme.colors.dark};
    }
    &:focus {
      border-color: ${theme.colors.active};
    }

    &:focus::placeholder {
      color: ${(props) =>
        props.leftText ? 'transparent' : theme.colors.neutral};
    }

    &.has-error {
      border-color: ${theme.colors.error};
    }

    &.has-info {
      border-color: ${theme.colors.accent};
      background-color: ${theme.colors.accent}0d; // 0d means 5% opacity
    }
  }

  padding: ${inputPadding};

  ${(props) =>
    props.thin &&
    css`
      padding-top: ${inputPaddingThin};
      padding-bottom: ${inputPaddingThin};
    `}

  ${(props) =>
    !!props.rightContainerWidth &&
    css`
      padding-right: calc(
        ${inputPadding} + ${additionalInputContentContainerPosition} +
          ${props.rightContainerWidth}px
      );
    `}

  ${(props) =>
    !!props.leftContainerWidth &&
    css`
      text-indent: calc(${props.leftContainerWidth}px + 4px);
    `}
  border-radius: 0.5em;
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: 16px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.colors.neutral};
  }
  :-ms-input-placeholder {
    color: ${theme.colors.neutral};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100em
      ${(props) =>
        props.disabled ? theme.colors.backdrop : theme.colors.white}
      inset !important;
  }

  outline: none;
`;
