import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import { Body, H7, Stack } from '@qred/components-library';

const OnboardingTokenExpiredError = () => {
  const translate = useTranslate();

  return (
    <Stack align="center">
      <H7>{translate('Onboarding.SessionExpiredTitle')}</H7>
      <Body size="lg">{translate('Onboarding.SessionExpiredText')}</Body>
    </Stack>
  );
};

export default OnboardingTokenExpiredError;
