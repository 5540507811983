import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  FormStatus,
  SimpleEvent,
  ValidationContext,
  ValidationType,
} from '~/components/hoc/withValidation';
import { FlexContentPlacement } from '~/enums';
import useTranslate from '~/hooks/useTranslate';
import { theme } from '~/styles/themes';
import ToolTip from '../ToolTip/ToolTip';
import { Typography } from '../Typography/Typography';

const StyledCheckedIcon = styled.svg`
  width: 1em;
`;

const CheckedIcon = () => (
  <StyledCheckedIcon
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.79289 14.5225L0.292881 8.06644C-0.0976271 7.67858 -0.0976271 7.0497 0.292881 6.66179L1.70706 5.25713C2.09757 4.86922 2.73077 4.86922 3.12128 5.25713L7.5 9.60627L16.8787 0.290904C17.2692 -0.096968 17.9024 -0.096968 18.2929 0.290904L19.7071 1.69557C20.0976 2.08344 20.0976 2.71232 19.7071 3.10024L8.20711 14.5226C7.81656 14.9105 7.1834 14.9105 6.79289 14.5225Z"
      fill="#444444"
    />
  </StyledCheckedIcon>
);

const Label = styled.label<{ disabled?: boolean }>`
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChildrenContainer = styled.div`
  margin-left: 0.8em;
  color: ${theme.colors.dark};
  line-height: 1.7em;
  font-size: 1em;
  span {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{
  checked: boolean;
  disabled?: boolean;
  alignSelf?: FlexContentPlacement;
}>`
  align-self: ${(props) => props.alignSelf || 'start'};
  border: 0.12em solid
    ${(props) => (props.disabled ? theme.colors.neutral : theme.colors.dark)};
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.5em;
  max-width: 1.5em;
  height: 1.5em;
  background-color: ${theme.colors.white};
  transition: all 150ms;
  margin-top: 0.1em;
  padding: 0.15em;

  path {
    fill: ${(props) =>
      props.disabled ? theme.colors.neutral : theme.colors.dark};
  }

  &:hover {
    border-color: ${(props) =>
      props.disabled ? undefined : theme.colors.hoverGreen};

    path {
      fill: ${(props) =>
        props.disabled ? undefined : theme.colors.hoverGreen};
    }
  }

  &:active {
    border-color: ${(props) =>
      props.disabled ? undefined : theme.colors.active};

    path {
      fill: ${(props) => (props.disabled ? undefined : theme.colors.active)};
    }
  }
`;

const ValidationErrorMessage = styled(Typography)`
  margin-top: 1em;
`;

interface Props {
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  className?: string;
  label?: string;
  validationType?: ValidationType;
  style?: any;
  dataCy?: string;
  explanation?: string;
  align?: FlexContentPlacement;
  children?: React.ReactNode;
}

const Checkbox: React.FC<Props> = ({
  checked,
  onChange,
  disabled,
  name = 'checkbox',
  className,
  label,
  validationType,
  children,
  style,
  dataCy,
  explanation,
  align,
}) => {
  const {
    formStatus,
    onChangeHOC,
    removePropertyFromValidationErrors,
    validationErrors,
  } = useContext(ValidationContext);

  const translate = useTranslate();

  const validationErrorShouldBeShown =
    formStatus === FormStatus.SUBMITTED &&
    validationType &&
    validationErrors[name];

  useEffect(() => {
    const fakeEvent: SimpleEvent = {
      target: {
        name,
        value: checked,
        dataset: { validationType },
      },
    };
    onChangeHOC(fakeEvent);

    // This clean up is needed to remove the property from errors if input field is no longer shown
    return () => {
      removePropertyFromValidationErrors(name);
    };
  }, [checked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  return (
    <div className={className} style={style}>
      {explanation && (
        <ToolTip explanation={explanation} icon="ExclamationMark" />
      )}
      <Label disabled={disabled}>
        <HiddenCheckbox
          data-cy={dataCy}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          name={name}
        />
        <CheckboxWrapper>
          <StyledCheckbox
            checked={checked}
            disabled={disabled}
            alignSelf={align}
          >
            {checked && <CheckedIcon />}
          </StyledCheckbox>
          {children && <ChildrenContainer>{children}</ChildrenContainer>}
          {label && <ChildrenContainer>{label}</ChildrenContainer>}
        </CheckboxWrapper>
      </Label>

      {validationErrorShouldBeShown && (
        <ValidationErrorMessage color={theme.colors.error} fontSize={0.8}>
          {translate(`ValidationErrors.${validationType?.split('_')[0]}`)}
        </ValidationErrorMessage>
      )}
    </div>
  );
};
export default Checkbox;
