import { Box, QredLogo, useWindowProperties } from '@qred/components-library';
import { theme } from '~/styles/themes';
import React from 'react';
import { RootState } from '~/store';
import { useSelector } from 'react-redux';
import { CountryCode } from '@qred/shared-enums';

interface SecondaryLayoutProps {
  children: React.ReactNode;
}

export const SecondaryLayout = ({ children }: SecondaryLayoutProps) => {
  const { market } = useSelector((state: RootState) => state.intl);
  const { isMobile } = useWindowProperties();

  const isQeld = market === CountryCode.NL;

  return (
    <Box
      p={'xl'}
      style={{
        backgroundColor: `${theme.colors.neutral12}`,
        height: 'var(--client-height)',
        overflow: 'auto',
      }}
    >
      <QredLogo color="primary.500" height={40} mb={'lg'} qeld={isQeld} />
      <Box bg={'neutral.2'} w={isMobile ? '100%' : 580} m="0 auto">
        {children}
      </Box>
    </Box>
  );
};

export default SecondaryLayout;
