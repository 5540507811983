import React, { useContext } from 'react';
import { ValidationContext } from '~/components/hoc/withValidation';
import {
  Button,
  ButtonProps,
  useWindowProperties,
} from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';

interface SkipButtonProps extends ButtonProps {
  label: string;
  action: () => void;
  disabled?: boolean;
}

const SkipButton = ({ label, action, ...rest }: SkipButtonProps) => {
  const translate = useTranslate();
  const validationContext = useContext(ValidationContext);
  const { isMobile } = useWindowProperties();

  return (
    <Button
      dataCy="step_footer_skip_button"
      variant="tertiary"
      onClick={action}
      disabled={!validationContext.isFormValid}
      size="md"
      fullWidth={isMobile}
      compact
      {...rest}
    >
      {translate(label)}
    </Button>
  );
};

export default SkipButton;
