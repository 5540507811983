import React from 'react';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import useSubmit from '../../Steps/useSubmit';
import { useSelector } from 'react-redux';
import { RootState } from '~/store';
import { ApiStatus } from '~/store/types/sharedTypes';
import useGoToNextStep from '../../Steps/useGoToNextStep';
import { useLoanOnboardingApplicationSelectors } from '~/store/selectors/loanOnboardingApplication.selector';

export const BankConnectionFooter = () => {
  const {
    onboardingApplication: {
      apiStatus,
      currentStep,
      form: { bankConnected },
    },
  } = useSelector((state: RootState) => state);

  const { stepConfig } = useLoanOnboardingApplicationSelectors();
  const isLastStep = stepConfig.length === currentStep;

  const onSubmit = useSubmit();
  const onNextStepClick = useGoToNextStep();

  const label = isLastStep
    ? `${bankConnected ? 'Onboarding.Submit' : 'Onboarding.SkipAndSubmit'}`
    : `${bankConnected ? 'Onboarding.Next' : 'Onboarding.Skip'}`;

  return (
    <NextButton
      label={label}
      isLoading={apiStatus.patch === ApiStatus.Started}
      action={isLastStep ? onSubmit : onNextStepClick}
      compact
    />
  );
};
