import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { accessToken } from '~/store/apis/utils/accessToken';

export const baseQuery = (
  baseUrl: string = import.meta.env.VITE_API_BASE_URL,
  requiresAuth = true
) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      if (!headers.has('Content-Type') && !headers.has('unsetHeader')) {
        headers.set('Content-Type', 'application/json');
      }

      if (requiresAuth) {
        const token = await accessToken.get();

        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      }

      return headers;
    },
  });
