import {
  GetClientDataQueryArg,
  GetClientDataResultType,
} from '~/store/apis/dtos/clientData/clientData.dto';
import { myPagesUnauthenticatedBaseApi } from '../../_baseApi/myPages.unauthenticated.base.api';

export const clientDataApi = myPagesUnauthenticatedBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientData: builder.query<
      GetClientDataResultType,
      GetClientDataQueryArg
    >({
      query: ({ market, qCode }) => ({
        url: `/client-data/qcode/${qCode}/${market}`,
      }),
    }),
  }),
});

export const { useGetClientDataQuery } = clientDataApi;
