import React, { useState } from 'react';
import { Button, Menu, Label } from '@qred/components-library';
import { getMarketLanguageLabels } from '~/constants/markets';
import { LanguageIconsType } from '@qred/components-library/dist/lib/components/MaterialIcon/MaterialIcon.types';
import { usePostHogEvents } from '~/hooks/usePostHogEvents';
import { posthogActionNames } from '~/constants/posthogActionNames';
import { useAccount } from '~/hooks/useAccount';

export const LanguageItemHeader = () => {
  const { market, language, setLanguage } = useAccount();
  const [languageSelectorOpened, setLanguageSelectorOpened] = useState(false);
  const languages = getMarketLanguageLabels(market);
  const selectedLanguageInfo = languages.find((l) => l.value === language);
  const { capture } = usePostHogEvents();
  const languageItems = languages
    .filter(({ value }) => value !== language)
    .map((_language) => (
      <Menu.Item
        key={_language.value}
        onClick={() => setLanguage(_language.value)}
        // TODO: (QIT-UI) ignored until we remove qit-ui as dependency
        // @ts-ignore
        icon={_language.icon}
      >
        {_language.label.short}
      </Menu.Item>
    ));
  const handleLanguageChange = () => {
    setLanguageSelectorOpened(!languageSelectorOpened);
    capture({
      action: posthogActionNames.sidebar.CHANGE_LANGUAGE,
      sidebar: true,
    });
  };
  return (
    <Menu onChange={handleLanguageChange} condensed>
      <Menu.Target>
        <Button
          size="md"
          variant="tertiary"
          leftIcon={selectedLanguageInfo?.icon as LanguageIconsType}
          rightIcon={languageSelectorOpened ? 'expand_less' : 'expand_more'}
          rightIconProps={{
            color: 'Icon/Default',
          }}
        >
          <Label size="lg" color="Text/Default">
            {selectedLanguageInfo?.label.short}
          </Label>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>{languageItems}</Menu.Dropdown>
    </Menu>
  );
};
