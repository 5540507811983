import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import useSubmit from '../../Steps/useSubmit';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';

export const UploadDocumentsFooter = () => {
  const {
    onboardingApplication: { form, apiStatus },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const onSubmit = useSubmit();

  const onSubmitButtonClick = () => {
    const actionName = form.bankFilesUploaded
      ? 'click_submit_application_button'
      : 'click_skip_upload_documents_and_submit_application_button';

    dispatch(pushToGtmOnboardingAction({ actionName }));

    onSubmit();
  };

  return (
    <NextButton
      label={
        form.bankFilesUploaded
          ? 'Onboarding.Submit'
          : 'Onboarding.SkipAndSubmit'
      }
      isLoading={apiStatus.patch === ApiStatus.Started}
      action={onSubmitButtonClick}
    />
  );
};
