import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import {
  processKlarnaKosmaTransactionFlow,
  updateTransactionFlow,
} from '~/store/actions/klarnaKosmaActions';
import { patchApplication } from '~/store/actions/onboardingActions';
import { RootState } from '~/store/types/sharedTypes';
import { useKlarnaFlow } from '~/hooks/useKlarnaFlow/useKlarnaFlow';
import { Button, useWindowProperties } from '@qred/components-library';
import { updateForm } from '~/store/slices/onboardingApplication.slice';

interface ButtonProps {
  buttonText: string;
  clientId: number;
  organizationNumber: string;
}

const KlarnaKosmaBankIntegration = ({
  buttonText,
  clientId,
  organizationNumber,
}: ButtonProps) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { isMobile } = useWindowProperties();

  useKlarnaFlow();

  const {
    klarnaKosma: { transactionFlow },
    onboardingApplication: {
      form: { applicationUuid },
    },
  } = useSelector((state: RootState) => state);

  const handleConnect = () => {
    if (transactionFlow.status === 'inProgress') return;
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'bank_integration_connect_pressed',
        stepActionNumber: 0.2,
      })
    );
    dispatch(
      processKlarnaKosmaTransactionFlow(
        organizationNumber.replace(/-/g, ''),
        clientId,
        applicationUuid
      )
    );
  };

  useEffect(() => {
    if (transactionFlow.status === 'completed') {
      dispatch(
        pushToGtmOnboardingAction({ actionName: 'bank_integration_connected' })
      );
      dispatch(updateForm({ bankIntegration: true, turnover: '' }));
      dispatch(patchApplication({ currentStepCompleted: false }));
      dispatch(updateTransactionFlow({ status: 'idle' }));
    }
  }, [dispatch, transactionFlow.status]);

  return (
    <Button
      variant="primary"
      onClick={handleConnect}
      disabled={transactionFlow.status === 'inProgress'}
      fullWidth={isMobile}
      size="md"
    >
      {translate(buttonText)}
    </Button>
  );
};

export default KlarnaKosmaBankIntegration;
