import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';

export const CardContentContainer = styled.div`
  min-height: calc(var(--client-height) * 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 4em 2em;
  align-items: stretch;
  gap: 4em;

  @media ${MediaQueries.smallScreenPortrait} {
    height: 100%;
    padding-bottom: 5em;
  }

  @media (max-height: 400px) {
    gap: 1em;
  }

  @media (orientation: landscape) {
    padding-left: 8em;
    padding-right: 8em;
  }

  @media ${MediaQueries.mediumScreen} {
    gap: 2.5em;
  }
`;
export const ConfirmationBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 0 2em;
  flex: 1 0;
  width: 100%;
  @media (orientation: landscape) {
    max-width: 40em;
  }

  justify-content: center;
  @media ${MediaQueries.smallScreenPortrait} {
    padding: 0;
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  align-self: center;
  width: 10em;
  margin-bottom: 2em;
  @media (max-height: 400px) {
    margin-bottom: 0.5em;
  }
`;
