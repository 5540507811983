import React from 'react';
import { H5 } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import CustomerReviewItem from './CustomerReviewItem';
import {
  CustomerReviewsWrapper,
  SectionWrapper,
  TitleWrapper,
} from './CustomerReviewsSection.styled';

const customerTestimonials = [
  {
    title: 'Onboarding.CustomerReview1Name',
    text: 'Onboarding.CustomerReview1Text',
    imgSrc: 'Onboarding.CustomerReview1Image',
  },
  {
    title: 'Onboarding.CustomerReview2Name',
    text: 'Onboarding.CustomerReview2Text',
    imgSrc: 'Onboarding.CustomerReview2Image',
  },
  {
    title: 'Onboarding.CustomerReview3Name',
    text: 'Onboarding.CustomerReview3Text',
    imgSrc: 'Onboarding.CustomerReview3Image',
  },
];

const CustomerReviewsSection = () => {
  const t = useTranslate();

  return (
    <SectionWrapper>
      <TitleWrapper>
        <H5>{t('Onboarding.CustomerReviewTitle')}</H5>
      </TitleWrapper>
      <CustomerReviewsWrapper>
        {customerTestimonials.map((testimonial) => (
          <CustomerReviewItem
            key={testimonial.title}
            title={t(testimonial.title) as string}
            text={t(testimonial.text) as string}
            imgSrc={`${t(testimonial.imgSrc)}`}
          />
        ))}
      </CustomerReviewsWrapper>
    </SectionWrapper>
  );
};

export default CustomerReviewsSection;
