import {
  Body,
  Button,
  H4,
  Stack,
  useWindowProperties,
} from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { getHomePageByMarket } from '~/constants/markets';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';

const Maintenance = () => {
  const translate = useTranslate();
  const { language, market } = useSelector((state: RootState) => state.intl);

  const { isMobile } = useWindowProperties();

  return (
    <Stack p="xxl" spacing={'xxl'} align="start">
      <Stack>
        <H4>{translate('Onboarding.MaintenanceTitle')}</H4>
        <Body size="lg">{translate('Onboarding.MaintenanceText')}</Body>
      </Stack>

      <Button
        fullWidth={isMobile}
        onClick={() =>
          window.location.assign(getHomePageByMarket(market, language))
        }
        variant="primary"
        size="md"
      >
        {translate('Onboarding.MaintenanceTextBack')}
      </Button>
    </Stack>
  );
};

export default Maintenance;
