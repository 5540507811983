import { Body } from '@qred/components-library';
import React from 'react';
import { KlarnaStatus } from '~/enums';
import useTranslate from '~/hooks/useTranslate';

interface AccountNumberHeaderProps {
  klarnaStatus: KlarnaStatus;
  hasBankAccounts: boolean;
}

const AccountNumberHeader = ({
  klarnaStatus,
  hasBankAccounts,
}: AccountNumberHeaderProps) => {
  const translate = useTranslate();

  return (
    <Body size="lg">
      {!hasBankAccounts &&
        klarnaStatus === KlarnaStatus.NOT_STARTED &&
        translate('OnboardingOffer.ConfirmBankAccountDescriptionIfNotExist')}
      {klarnaStatus === KlarnaStatus.COMPLETED &&
        translate(
          'OnboardingOffer.ConfirmBankAccountDescriptionIfKlarnaCompletedWithoutBankAccounts'
        )}
      {hasBankAccounts &&
        klarnaStatus === KlarnaStatus.NOT_STARTED &&
        translate('OnboardingOffer.ConfirmBankAccountDescriptionIfExist')}
      {klarnaStatus === KlarnaStatus.ERROR &&
        translate('OnboardingOffer.ConfirmBankAccountDescriptionIfKlarnaError')}
    </Body>
  );
};

export default AccountNumberHeader;
