import {
  AppShell as CLAppShell,
  useTheme,
  useWindowProperties,
} from '@qred/components-library';
import React, { FC } from 'react';
import OnboardingHeader from '../OnboardingHeader/OnboardingHeader';
import { MainOnboardingContainer } from '~/styles/OnboardingBaseStyledComponents';

interface AppShellProps {
  children: React.ReactNode;
  background?: React.CSSProperties['background'];
  backgroundOpacity?: React.CSSProperties['opacity'];
  backgroundColor?: React.CSSProperties['backgroundColor'];
}

const AppShell: FC<AppShellProps> = ({
  children,
  background,
  backgroundOpacity,
  backgroundColor,
}) => {
  const theme = useTheme();
  const { isMobile } = useWindowProperties();
  const defaultBackgroundColor = isMobile
    ? theme.fn.themeColor('Page/Option_1_(White)')
    : theme.fn.themeColor('Page/Option_4_(Greige)');

  return (
    <MainOnboardingContainer
      $background={background}
      $backgroundOpacity={backgroundOpacity}
      $backgroundColor={backgroundColor ?? defaultBackgroundColor}
    >
      <CLAppShell
        header={<OnboardingHeader />}
        mainProps={{
          display: 'flex',
          p: 0,
          miw: '320px',
          style: {
            backgroundColor: 'inherit',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100%',
          },
        }}
      >
        {children}
      </CLAppShell>
    </MainOnboardingContainer>
  );
};

export default AppShell;
