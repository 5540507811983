import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import {
  Body,
  Box,
  Card,
  Collapse,
  GoCardlessLogo,
  Group,
  List,
  MaterialIcon,
  Overline,
  Radio,
  SepaDirectDebitLogo,
  Stack,
  Tooltip,
  UnStyledButton,
} from '@qred/components-library';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';
import { PromotionalBadge } from './SelectPaymentMethod.styles';
import useTranslate from '~/hooks/useTranslate';
import {
  getBankAccountNumber,
  getOpenBankingAccountNumber,
} from '~/helpers/onboarding.helper';
import useDateFormatter from '~/hooks/useDateFormatter';

enum PaymentMethod {
  DIRECT_DEBIT = 'directDebit',
  INVOICE = 'invoice',
}

const SelectPaymentMethod = () => {
  const dispatch = useDispatch();
  const [showSepaDirectDebitDetails, setShowSepaDirectDebitDetails] = useState(
    false
  );
  const t = useTranslate();

  const {
    onboardingOffer: { form, overview },
    intl: { market },
  } = useSelector((state: RootState) => state);
  const formatDate = useDateFormatter();

  const currentPaymentMethod = form.isDirectDebitEnabled
    ? PaymentMethod.DIRECT_DEBIT
    : PaymentMethod.INVOICE;

  const onPaymentMethodChange = (_paymentMethod: PaymentMethod) => {
    dispatch(
      updateForm({
        isDirectDebitEnabled: _paymentMethod === PaymentMethod.DIRECT_DEBIT,
      })
    );
  };

  return (
    <Stack spacing={'xl'}>
      <Body size={'lg'}>{t('OnboardingOffer.SelectPaymentMethodTitle')}</Body>
      <Radio.Group
        onChange={onPaymentMethodChange}
        value={currentPaymentMethod}
        direction="column"
      >
        <Card
          borderColor={form.isDirectDebitEnabled ? 'success.700' : 'neutral.16'}
          pos={'relative'}
          onClick={() => onPaymentMethodChange(PaymentMethod.DIRECT_DEBIT)}
          style={{ cursor: 'pointer' }}
        >
          <PromotionalBadge color="primary.500">
            <Group align="center" spacing={10}>
              <MaterialIcon src="verified" color="textLight.50" />
              <Body size="sm" color="textLight.50">
                {t('OnboardingOffer.SelectPaymentMethodPromotionBadge')}
              </Body>
            </Group>
          </PromotionalBadge>
          <Overline size={'sm'} ml="28px" mb="sm">
            {t(
              'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionEyebrowLabel'
            )}
          </Overline>
          <Radio
            value="directDebit"
            label={
              <Group justify="space-between">
                {t(
                  'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionLabel'
                )}
                <SepaDirectDebitLogo h="24px" />
              </Group>
            }
            labelWrapperProps={{ w: '100%' }}
          />
          <Stack spacing={0} ml={'28px'}>
            <Body size="md" color="textDark.200">
              {t(
                'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionHint',
                {
                  todaysDate: formatDate(new Date(), {
                    dayjsFormat: 'Do',
                  }),
                }
              )}
            </Body>
            <Group spacing={'xs'} align="center">
              <Body size="sm" color="textDark.200">
                {t(
                  'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionGoCardless',
                  {
                    goCardlessLogo: <GoCardlessLogo h="12px" />,
                  }
                )}
              </Body>
              <Tooltip
                maxWidth={'250px'}
                label={t(
                  'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionGoCardlessTooltip'
                )}
              >
                <MaterialIcon src="help" />
              </Tooltip>
            </Group>
            <Box mt={'md'}>
              <UnStyledButton
                onClick={() =>
                  setShowSepaDirectDebitDetails(!showSepaDirectDebitDetails)
                }
              >
                <Group
                  display={'flex'}
                  direction="row"
                  spacing={'sm'}
                  align="center"
                >
                  <Body size="md">
                    {t(
                      'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionGoCardlessDetailsTitle'
                    )}
                  </Body>
                  <MaterialIcon src="expand_more" />
                </Group>
              </UnStyledButton>
            </Box>
            <Collapse opened={showSepaDirectDebitDetails}>
              <Stack spacing={'md'}>
                <Card bg="neutral.10" border={false} p={'md'}>
                  <List type="ordered" spacing={'xxl'}>
                    <List.Item icon="credit_score">
                      <Stack spacing={0}>
                        <Body size="sm">
                          {t(
                            'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionGoCardlessDetailsCreditorAddress'
                          )}
                        </Body>
                        <Body size="sm">
                          {t(
                            'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionGoCardlessDetailsCreditorId'
                          )}
                        </Body>
                      </Stack>
                    </List.Item>
                    <List.Item icon="receipt">
                      <Stack spacing={0}>
                        <Body size="sm">
                          {t(
                            'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionGoCardlessDetailsPayerCompanyName',
                            {
                              companyName: overview.lenderName,
                            }
                          )}
                        </Body>
                        <Body size="sm">
                          {t(
                            'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionGoCardlessDetailsPayerBankAccountNumber',
                            {
                              bankAccountNumber:
                                form.accountNumber ||
                                getBankAccountNumber(form.account, market) ||
                                (form.openBankingAccount &&
                                  getOpenBankingAccountNumber(
                                    form.openBankingAccount
                                  )),
                            }
                          )}
                        </Body>
                      </Stack>
                    </List.Item>
                    <List.Item icon="info">
                      <Body size="sm">
                        {t(
                          'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionGoCardlessDetailsInfo'
                        )}
                      </Body>
                    </List.Item>
                  </List>
                </Card>
                <Body size={'sm'} color="textDark.200">
                  {t(
                    'OnboardingOffer.SelectPaymentMethodSepaDirectDebitOptionGoCardlessDetailsFinePrint'
                  )}
                </Body>
              </Stack>
            </Collapse>
          </Stack>
        </Card>
        <Card
          borderColor={
            !form.isDirectDebitEnabled ? 'success.700' : 'neutral.16'
          }
          onClick={() => onPaymentMethodChange(PaymentMethod.INVOICE)}
          style={{ cursor: 'pointer' }}
        >
          <Radio
            value="invoice"
            label={t(
              'OnboardingOffer.SelectPaymentMethodMonthlyInvoiceOptionLabel'
            )}
          />
          <Body size="md" color="textDark.200" ml={'28px'}>
            {t('OnboardingOffer.SelectPaymentMethodMonthlyInvoiceOptionHint', {
              todaysDate: formatDate(new Date(), {
                dayjsFormat: 'Do',
              }),
            })}
          </Body>
        </Card>
      </Radio.Group>
    </Stack>
  );
};

export default SelectPaymentMethod;
