// Onboarding
export enum FlowTypes {
  Manual = 'MANUAL',
  Authenticated = 'AUTHENTICATED',
}
export enum ApplicationStatus {
  None = 'NONE',
  ApplicationFinished = 'APPLICATION_FINISHED',
  ApplicationUnderReview = 'APPLICATION_UNDER_REVIEW',
  OfferApproved = 'OFFER_APPROVED',
  OfferNotApproved = 'OFFER_NOT_APPROVED',
  OfferSigned = 'OFFER_SIGNED',
}
export enum GlobalErrorType {
  API_FAILURE = 50,
  USER_HAS_NO_ACCOUNT,
  USER_SIGNED_OFFER_AND_NO_ACCOUNT,
  ONBOARDING_TOKEN_EXPIRED,
  CUSTOM_ERROR,
}
export enum ClientType {
  RLC = 'RLC',
  NLC = 'NLC',
  Unknown = 'UNKNOWN',
}

export enum ProductType {
  LOAN = 'loan',
  CARD = 'card',
}

export enum OTPErrorType {
  SIGNER_ALREADY_SIGNED = 'Signer already signed', // 400
  INVALID_OTP = 'Incorrect OTP', // 401
  EXPIRED_OTP = 'Expired', // 403
  TOO_MANY_REGENERATIONS = 'Too many regenerations', // 403
  UNKNOWN = '',
}

export enum OwnerTypeEnum {
  MoreThan75 = 'OWN_MORE_THAN_75', // Applicant owns more than 75%, including 100%. BO explanation: "Own more than 75%".
  MorePersonsOwn25 = 'MORE_PERSONS_OWN_25', // At least 1 owner owns between 25% and 75%, including 25 and 75. BO explanation: "One or several persons with more than 25%". (they mean 25% or more).
  NonOneOwn25 = 'NON_ONE_OWN_25', // No owner owns 25% or more. BO explanation: "No one owns more than 25%".
}

export enum Auth0ConnectionKey {
  SE_BANKID,
  DK_MITID,
  FI_FTN,
  BE_EMAIL,
  NL_EMAIL,
  NO_BANKID,
  BE_ITSME,
  BE_BEID,
  NL_IDIN,
  DE_EMAIL,
}

// Analytics
export enum StepStatus {
  Started = 'Started',
  Finished = 'Finished',
  Edit = 'Edit',
  Error = 'Error',
  Submitted = 'Submitted',
}

export enum OnboardingAuthType {
  AuthenticatedFlow = 'authenticatedFlow',
  ManualSubmit = 'manualSubmit',
  Logout = 'logout',
  Reauthenticate = 'reauthenticate',
}

export enum BankConnectionResult {
  Success = 'success',
  Error = 'error',
  Cancelled = 'cancelled',
}

// CSS
export enum FlexContentPlacement {
  FlexStart = 'flex-start',
  FlexEnd = 'flex-end',
  Center = 'center',
  SpaceAround = 'space-around',
  SpaceBetween = 'space-between',
}

export enum ApplicationStep {
  Apply = 1,
  Review = 2,
  Offer = 3,
  Payout = 4,
}

export enum InvoiceStatuses {
  activated = 'ACTIVATED',
  paused = 'PAUSED',
  closed = 'CLOSED',
  disputed = 'DISPUTED',
  stopped = 'STOPPED',
  paid = 'PAID',
  created = 'CREATED',
  pendingAuthorization = 'PENDING_AUTHORIZATION',
  pending = 'PENDING',
  deniedFinancing = 'DENIED_FINANCING',
  archived = 'ARCHIVED',
}

export enum LoanPurposes {
  Marketing = 'MARKETING',
  Hiring = 'EMPLOY_PERSONNEL',
  Equipment = 'BUY_EQUIPMENT',
  Expansion = 'EXPANSION',
  RepayOtherLoan = 'REPAY_OTHER_LOAN',
  Inventory = 'INVENTORY',
  Working = 'WORKING_CAPITAL',
  Other = 'OTHER',
}

export enum CampaignType {
  FirstCommissionFree = 1,
  FirstMonthFree,
  CustomCommissionDiscount,
  AmortizationFreeMonths,
  MonthlyInterestRateDiscount,
}

export enum KlarnaStatus {
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export enum SignerType {
  Signer = 'Signer',
  Guarantor = 'Guarantor',
  Applicant = 'Applicant',
}

export enum KycQuestionnaireQuestionNames {
  handleCashQuestion = 'Do you handle cash within your organization? For example: receiving cash as payment for products/services or paying salaries in cash.',
  handleCashToWhatExtentQuestion = 'To what extent do you handle cash?',
  handleCashQuestionNew = 'Does the company accept cash for transactions involving goods or services, or for paying salaries?',
  handleCashPercentageTurnoverQuestion = "What percentage of the company's turnover is generated from cash-related transactions?",
}

export enum CardPurposeType {
  Inventory = 'Inventory',
  ToolsAndEquipment = 'ToolsAndEquipment',
  ComputerSoftware = 'ComputerSoftware',
  OfficeExpenses = 'OfficeExpenses',
  SalariesConsultancy = 'SalariesConsultancy',
  Marketing = 'Marketing',
  Travel = 'Traveling',
  MealsAndDrinks = 'MealsAndDrinks',
  OtherPurpose = 'OtherPurpose',
}

export enum CountryCode {
  SE = 'SE',
  DK = 'DK',
  FI = 'FI',
  BE = 'BE',
  NL = 'NL',
  NO = 'NO',
  DE = 'DE',
}

export enum NewInvoiceStatusType {
  new = 'Open_Active',
  late = 'Open_Late',
  paid = 'Paid',
  collection = 'Open_In_Collection',
}

export enum ContractStatus {
  OpenActive = 'Open_Active',
  OpenLate = 'Open_Late',
  OpenInCollection = 'Open_In_Collection',
  ClosedCreditLimitChange = 'Closed_Credit_Limit_Change',
  ClosedPaidNormal = 'Closed_Paid_Normal',
  ClosedPaidEarly = 'Closed_Paid_Early',
  ClosedSold = 'Closed_Sold',
  ClosedWriteOff = 'Closed_Write_Off',
  ClosedChangePaymentPlan = 'Closed_Change_Payment_Plan',
}

export enum InvoicesSelectType {
  UPCOMING = 'upcoming',
  PAID = 'paid',
  FINALINVOICE = 'finalInvoice',
}

export enum Statement {
  ACCOUNT = 'account-statement',
  ANNUAL = 'annual-statement',
  BALANCE = 'balance-statement',
  CLIENT = 'client-statement',
  CONTRACT = 'loan-statement',
  CREDIT = 'credit-agreement',
  GENERAL = 'general-terms-and-conditions',
  PAYMENT = 'payment-plan',
}

export enum DocumentUploadSelectOptions {
  BANK_STATEMENT = 'BANK_STATEMENT',
  INTERIM_FINANCIAL_STATEMENT = 'INTERIM_FINANCIAL_STATEMENT',
  PERSONAL_TAX_STATEMENT = 'PERSONAL_TAX_STATEMENT',
  PROFIT_AND_LOSS_REPORT = 'PROFIT_AND_LOSS_REPORT',
  SHAREHOLDER_REGISTER = 'SHAREHOLDER_REGISTER',
  ANNUAL_REPORT = 'ANNUAL_REPORT',
  ULTIMATE_BENEFICIAL_OWNER_REPORT = 'ULTIMATE_BENEFICIAL_OWNER_REPORT',
  BUSINESS_PLAN = 'BUSINESS_PLAN',
  DOCUMENTATION_BANK_ACCOUNT = 'DOCUMENTATION_BANK_ACCOUNT',
  PAY_SLIPS = 'PAY_SLIPS',
  SALES_OF_SHAREHOLDER_AGREEMENT = 'SALES_OF_SHAREHOLDER_AGREEMENT',
  DOCUMENTATION_OF_ADDRESS = 'DOCUMENTATION_OF_ADDRESS',
  MT940_STATEMENT = 'MT940_STATEMENT',
  ANNUAL_ACCOUNTS_OF_HOLDING_COMPANIES = 'ANNUAL_ACCOUNTS_OF_HOLDING_COMPANIES',
  OTHER = 'OTHER',
}

export enum ApplicationInfoLevel {
  PRE_BASIC = 'PRE_BASIC',
  BASIC = 'BASIC',
  SUPER = 'SUPER',
}
