import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { useKlarnaFlow } from '~/hooks/useKlarnaFlow/useKlarnaFlow';
import useTranslate from '~/hooks/useTranslate';
import { Body, Stack } from '@qred/components-library';
import { KlarnaStatus } from '~/enums';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { ValidationContext } from '~/components/hoc/withValidation';
import BankAccountList from './BankAccountList';
import KlarnaKosmaButton from './KlarnaKosmaButton';
import AccountNumberHeader from './AccountNumberHeader';
import AccountNumberInput from './AccountNumberInput';
import { isEmptyObject } from '~/helpers/utils';

const AccountNumber = () => {
  const [klarnaStatus, setKlarnaStatus] = useState(KlarnaStatus.NOT_STARTED);
  const [showKlarna, setShowKlarna] = useState(false);
  const validationContext = useContext(ValidationContext);

  const {
    onboardingOffer: { overview, form },
  } = useSelector((state: RootState) => state);

  useKlarnaFlow();
  const translate = useTranslate();
  const {
    marketShouldShowAccountNumberInputFieldByDefault,
  } = useOnboardingMarketConfig();

  const hasBankAccounts = !!overview.bankAccounts.length;

  useEffect(() => {
    if (isEmptyObject(form.account) && !form.accountNumber) {
      validationContext.addPropertyToValidationErrors(
        'MissingAccountAndAccountNumber'
      );
    }

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'MissingAccountAndAccountNumber'
      );
    };
  }, [form.account, form.accountNumber]);

  return (
    <>
      <AccountNumberHeader
        klarnaStatus={klarnaStatus}
        hasBankAccounts={hasBankAccounts || false}
      />

      {marketShouldShowAccountNumberInputFieldByDefault && !hasBankAccounts ? (
        <>
          <AccountNumberInput />
          <Body size="lg">
            {translate('OnboardingOffer.TextAboveKlarnaKosmaButton')}
          </Body>
          <KlarnaKosmaButton setKlarnaStatus={setKlarnaStatus} />
        </>
      ) : (
        <>
          {!form.accountNumber &&
          hasBankAccounts &&
          klarnaStatus !== KlarnaStatus.ERROR ? (
            <>
              <BankAccountList setShowKlarna={setShowKlarna} />
              {showKlarna && (
                <KlarnaKosmaButton setKlarnaStatus={setKlarnaStatus} />
              )}
            </>
          ) : (
            <Stack spacing={'xxl'}>
              {(klarnaStatus === KlarnaStatus.ERROR || form.accountNumber) && (
                <AccountNumberInput />
              )}
              <Stack spacing={'md'}>
                <Body size="lg">
                  {translate('OnboardingOffer.TextAboveKlarnaKosmaButton')}
                </Body>
                <KlarnaKosmaButton setKlarnaStatus={setKlarnaStatus} />
              </Stack>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default AccountNumber;
