import { Stack } from '@qred/components-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { CountryCode, FlowTypes } from '~/enums';
import { areSameCompanies } from '~/helpers/utils';
import { setCompanyIsNew } from '~/store/slices/onboardingApplication.slice';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import CompanySearch from './components/CompanySearch';
import { CompanySearchGermany } from './components/CompanySearchGermany';
import CompanySelectorRadioButtons from './components/CompanySelectorRadioButtons';

const CompanySelector = () => {
  const dispatch = useDispatch();

  const {
    form,
    apiStatus,
    overview,
    companyIsNew,
    flowType,
    userId,
  } = useSelector((state: RootState) => state.onboardingApplication);
  const { market } = useSelector((state: RootState) => state.intl);

  // IMPORTANT TODO: we need to set companyIsNew to true if user in auth flow has
  // new company and reloads page and goes back to company selector
  useEffect(() => {
    const isAuthenticatedNlc =
      flowType === FlowTypes.Authenticated &&
      form.organizationNumber &&
      apiStatus.clients === ApiStatus.Completed &&
      !overview.clients.some((client) =>
        areSameCompanies(
          client.company_no || client.connect_id,
          form.organizationNumber || form.connectId
        )
      );

    if (flowType === FlowTypes.Manual || !userId || isAuthenticatedNlc) {
      dispatch(setCompanyIsNew(true));
    }
  }, []);

  return (
    <>
      {apiStatus.clients !== ApiStatus.Started ? (
        <Stack spacing="xxl">
          {apiStatus.clients === ApiStatus.Completed &&
            !!overview.clients?.length && (
              <Stack spacing="xs">
                <CompanySelectorRadioButtons clients={overview.clients} />
              </Stack>
            )}

          {companyIsNew && (
            <>
              {market === CountryCode.DE ? (
                <CompanySearchGermany />
              ) : (
                <CompanySearch />
              )}
            </>
          )}
        </Stack>
      ) : (
        <SpinnerCircular color={theme.colors.secondaryGray} />
      )}
    </>
  );
};
export default CompanySelector;
