import React from 'react';
import { useSelector } from 'react-redux';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { RootState } from '~/store/types/sharedTypes';

const BankConnectionStepHeader = () => {
  const { bankConnected } = useSelector(
    (state: RootState) => state.onboardingApplication.form
  );

  const title = 'Onboarding.BankConnectionTitle';
  const description = 'Onboarding.BankConnectionDescription';
  const overline = 'Onboarding.BankConnectionTitleOverline';

  if (bankConnected) return null;

  return (
    <StepHeader title={title} description={description} overline={overline} />
  );
};

export default BankConnectionStepHeader;
