import React, { useEffect, useState } from 'react';
import {
  Body,
  Button,
  H7,
  Link,
  Loader,
  Stack,
  useModals,
  useWindowProperties,
} from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { useGetSessionStatusQuery } from '~/store/apis/endpoints/openBankingApi/openBanking.api';
import { BankConnectionResult } from '~/enums';

interface BankConnectionStatusModalProps {
  id: string;
  sessionState: string;
  authorizationUrl: string;
  onResult: (result: BankConnectionResult) => void;
}

const BankConnectionStatusModal = ({
  id,
  sessionState,
  authorizationUrl,
  onResult,
}: BankConnectionStatusModalProps) => {
  const t = useTranslate();
  const { isMobile } = useWindowProperties();
  const modals = useModals();
  const { data: sessionData } = useGetSessionStatusQuery(
    {
      state: sessionState,
    },
    {
      pollingInterval: 1000,
    }
  );
  const [showTitle, setShowTitle] = useState(true);
  const [showManualRedirectLink, setShowManualRedirectLink] = useState(false);

  useEffect(() => {
    if (sessionData?.status) {
      onResult(sessionData.status);
      modals.closeModal(id);
    }
  }, [sessionData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTitle(false);
      setShowManualRedirectLink(true);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleCancel = () => {
    onResult(BankConnectionResult.Cancelled);
    modals.closeModal(id);
  };

  return (
    <Stack align="center">
      {showTitle && <H7 data-record>{t('BankConnection.StatusModalTitle')}</H7>}
      {showManualRedirectLink && (
        <Body size="lg" data-record>
          {t('BankConnection.StatusModalRedirectionQuestion', {
            statusModalRedirectButton: (
              <a href={authorizationUrl} target="_blank">
                <Link size="lg" data-record>
                  {t('BankConnection.StatusModalRedirectButtonText')}
                </Link>
              </a>
            ),
          })}
        </Body>
      )}
      <Loader size={'lg'} />
      <Button onClick={handleCancel} fullWidth={isMobile} variant="tertiary">
        {t('BankConnection.StatusModalCancelButtonText')}
      </Button>
    </Stack>
  );
};

export default BankConnectionStatusModal;
