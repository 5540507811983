import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import flatten from 'flat';
import englishMessages from '~/languages/default_EN.json';
import { TranslationsKeys } from '~/languages/translations.d';
import { NestedObjects } from '~/declarations/nested-objects.d';
import { CampaignType } from '~/enums';

const flatDefaultMessages = flatten(englishMessages);

const useTranslate = () => {
  const { formatMessage } = useIntl();

  return (id: NestedObjects<TranslationsKeys>, values = {}) => {
    const defaultMessage = (flatDefaultMessages as any)[id];
    const message = formatMessage({ id, defaultMessage }, values);
    return Array.isArray(message)
      ? message.map((tag, i) => <Fragment key={`${id}-${i}`}>{tag}</Fragment>)
      : message;
  };
};

export const useCampaignTypeTranslate = (
  campaignType: CampaignType | null,
  campaignPrincipalFreeMonths?: number | null,
  campaignInterestRateDiscount?: number | null
) => {
  const translate = useTranslate();

  switch (campaignType) {
    case CampaignType.FirstCommissionFree:
      return translate('PaymentCalculator.CampaignType1');

    case CampaignType.FirstMonthFree:
      return translate('PaymentCalculator.CampaignType2');

    case CampaignType.CustomCommissionDiscount:
      return translate('PaymentCalculator.CampaignType3');

    case CampaignType.AmortizationFreeMonths:
      if (campaignPrincipalFreeMonths) {
        return translate('PaymentCalculator.CampaignType4', {
          itemCount: campaignPrincipalFreeMonths,
        });
      }
      return null;

    case CampaignType.MonthlyInterestRateDiscount:
      if (campaignPrincipalFreeMonths && campaignInterestRateDiscount) {
        return translate('PaymentCalculator.CampaignType5', {
          itemCount: campaignPrincipalFreeMonths,
          discount: campaignInterestRateDiscount,
        });
      }
      return null;
    default:
      return null;
  }
};

export default useTranslate;
