import React, { useContext, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { theme } from '~/styles/themes';
import {
  FormStatus,
  SimpleEvent,
  ValidationContext,
  ValidationType,
} from '~/components/hoc/withValidation';
import useTranslate from '~/hooks/useTranslate';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import Stack from '~/components/shared/Layout/Stack';

interface Props {
  name: string;
  fullWidth?: boolean;
  validationType?: ValidationType;
}

interface OptionValue {
  label: string;
  value: string;
}

const Select = <
  Option extends unknown = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Omit<StateManagerProps<Option, IsMulti, Group>, 'name'> & Props
) => {
  const validationContext = useContext(ValidationContext);
  const { validationErrors } = validationContext;
  const [touched, setTouched] = useState(false);
  const t = useTranslate();

  const validationErrorShouldBeShown =
    (touched || validationContext.formStatus === FormStatus.SUBMITTED) &&
    props.validationType &&
    validationErrors[props.name];

  const getBorderColor = (menuIsOpen: boolean) => {
    if (validationErrorShouldBeShown) {
      return theme.colors.error;
    }

    return menuIsOpen ? theme.colors.active : theme.colors.neutral;
  };

  const customStyles: StylesConfig<Option, IsMulti, Group> = {
    container: (provided) => ({
      ...provided,
      width: props.fullWidth ? '100%' : undefined,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: state.menuIsOpen ? '0.5em 0.5em 0em 0em' : '0.5em',
      padding: '0.4em 0.3em',
      borderColor: getBorderColor(state.menuIsOpen),
      borderWidth: '0.1em',
      boxShadow: state.isFocused ? theme.colors.active : 'none',
      color: theme.colors.dark,

      ':hover': {
        borderColor: validationErrorShouldBeShown
          ? theme.colors.error
          : theme.colors.active,
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      color: theme.colors.dark,
      fontWeight: state.isSelected ? 700 : 500,
      padding: '1em 0.8em',
      ':hover': {
        backgroundColor: theme.colors.backdrop,
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0em 0em 0.5em 0.5em',
      margin: '0',
    }),
    menuList: (provided) => ({
      ...provided,
      boxShadow: '-1px 17px 13px -7px rgba(0,0,0,0.1) inset;',
    }),
    input: (provided) => ({
      ...provided,
      padding: '0px',
      fontSize: '16px',
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: '4px 2px',
    }),
  };

  useEffect(() => {
    const fakeEvent: SimpleEvent = {
      target: {
        name: props.name,
        value:
          (props.value as OptionValue)?.value ||
          (props.defaultValue as OptionValue)?.value,
        dataset: { validationType: props.validationType },
      },
    };

    validationContext.onChangeHOC(fakeEvent);
  }, [JSON.stringify(props.value), JSON.stringify(props.defaultValue)]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur && props.onBlur(e);
    setTouched(true);
  };

  return (
    <Stack spacing="sm">
      <ReactSelect
        {...props}
        onBlur={handleBlur}
        styles={customStyles}
        data-validation-type={props.validationType}
        className={validationErrorShouldBeShown ? 'has-error' : ''}
      />
      {validationErrorShouldBeShown && (
        <ValidationErrorMessage>
          {t(`ValidationErrors.${props.validationType?.split('_')[0]}`)}
        </ValidationErrorMessage>
      )}
    </Stack>
  );
};

export default Select;
