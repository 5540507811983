import React from 'react';
import { useSelector } from 'react-redux';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { RootState } from '~/store/types/sharedTypes';
import { getGuarantorContentByMarket } from '~/helpers/onboarding.helper';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { Body, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';

const SelectGuarantorHeader = () => {
  const {
    onboardingApplication: { form },
    intl: { market },
  } = useSelector((state: RootState) => state);
  const t = useTranslate();

  const { marketHasGuarantorSubheader } = useOnboardingMarketConfig();

  const title = t('Onboarding.AddAdditionalGuarantors') as string;
  let descriptionList = [
    `Onboarding.${getGuarantorContentByMarket(form, market)}`,
  ];
  if (marketHasGuarantorSubheader) {
    descriptionList = [
      ...descriptionList,
      'Onboarding.AddAdditionalGuarantorsSubheader',
    ];
  }
  const overline = t(
    'Onboarding.AddAdditionalGuarantorsTitleOverline'
  ) as string;

  const description = (
    <Stack>
      {descriptionList.map((text) => (
        <Body key={text} size="lg">
          {t(text)}
        </Body>
      ))}
    </Stack>
  );

  return (
    <StepHeader
      title={title}
      description={description}
      overline={overline}
      shouldTranslate={false}
    />
  );
};

export default SelectGuarantorHeader;
