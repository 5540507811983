import React, { useRef, useState } from 'react';
import withValidation from '~/components/hoc/withValidation';
import Stack from '~/components/shared/Layout/Stack';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import { useDispatch, useSelector } from 'react-redux';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { ModalFooter } from '~/components/shared/Modal/Modal';
import { Body, Box } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { getSignedUrl, postFile } from '~/services/api/onboarding';
import { logToSentry } from '~/helpers/loggers.helper';
import { updateForm } from '~/store/slices/onboardingApplication.slice';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { IOnboardingFile } from '~/interfaces/Onboarding';
import DocumentUpload from '~/components/shared/DocumentUpload/DocumentUpload';
import { validateFiles } from '~/helpers/validators.helper';
import DocumentList from '~/components/shared/DocumentUpload/DocumentList';
import DocumentErrorMessage, {
  FileUploadErrors,
} from '~/components/shared/DocumentUpload/DocumentErrorMessage';
import { filterOutIncorrectFiles } from '~/helpers/files.helper';
import useSubmit from '../../Steps/useSubmit';

const maxFiles = 4;
const allowedFileExtensionsDisplayed = ['.swi', '.STA', '.940', '.txt'];
const allowedFileFormats = ['text/plain', ...allowedFileExtensionsDisplayed];
const maxSize = 15000000;

const UploadDocumentsModalBody = () => {
  const [documentsApiStatus, setDocumentsApiStatus] = useState(ApiStatus.Idle);
  const [localFiles, setLocalFiles] = useState<IOnboardingFile[]>([]);
  const [
    localFileUploadErrors,
    setLocalFileUploadErrors,
  ] = useState<FileUploadErrors>({
    alreadyAdded: false,
    invalidType: false,
    tooLarge: false,
    maxLimit: false,
  });
  const [
    lastLocalUploadHadMultipleFiles,
    setLastLocalUploadHadMultipleFiles,
  ] = useState(false);
  const fileListRef = useRef<HTMLDivElement>(null);
  const onSubmit = useSubmit();
  const dispatch = useDispatch();
  const {
    onboardingApplication: {
      apiStatus,
      form: { applicantAuthenticated },
      clientId,
    },
    intl: { market },
  } = useSelector((state: RootState) => state);

  const t = useTranslate();

  const setErrors = (filesToCheck: IOnboardingFile[]) => {
    const { hasAnyFormatIssue, hasAnySizeIssue } = validateFiles(
      filesToCheck,
      allowedFileFormats,
      maxSize
    );

    const fileIsAlreadyAdded = filesToCheck.some(
      (file) => filesToCheck.filter((f) => f.name === file.name).length > 1
    );

    setLocalFileUploadErrors({
      alreadyAdded: fileIsAlreadyAdded,
      maxLimit: filesToCheck.length > maxFiles,
      invalidType: hasAnyFormatIssue,
      tooLarge: hasAnySizeIssue,
    });
  };

  const createFilesFromObjectUrls = (documents: IOnboardingFile[]) =>
    Promise.all(
      documents.map(async (document) => {
        const res = await fetch(document.objectURL);
        const blob = await res.blob();
        return new File([blob], document.name, blob);
      })
    );

  const postFilesToS3 = async (files: File[]) => {
    const fileKeys = localFiles.map((localFile) => localFile.name);
    const res = await getSignedUrl(
      fileKeys,
      clientId ? String(clientId) : '',
      market,
      applicantAuthenticated,
      'mt940'
    );
    await Promise.all(
      res.data.map(async (infoContainer: any, i: number) => {
        const form = new FormData();
        Object.keys(infoContainer.fields).forEach((key) =>
          form.append(key, infoContainer.fields[key])
        );
        form.append('file', files[i]);
        await postFile(infoContainer.url, form);
      })
    );
    return res.data.map((file) => file.fields.key);
  };

  const uploadFilesToS3 = async () => {
    const files = await createFilesFromObjectUrls(localFiles);
    const filePaths = await postFilesToS3(files);
    return filePaths;
  };

  const uploadDocuments = async () => {
    setDocumentsApiStatus(ApiStatus.Started);
    try {
      const filePaths = await uploadFilesToS3();
      setDocumentsApiStatus(ApiStatus.Completed);
      dispatch(
        updateForm({
          bankFilesUploaded: true,
          bankFilesPaths: filePaths,
        })
      );
    } catch (err: any) {
      setDocumentsApiStatus(ApiStatus.Failed);
      logToSentry(err, 'uploadMT940Documents', {
        product: 'onboarding',
      });
    }
  };

  const onSubmitButtonClick = async () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'click_upload_documents_and_submit_button',
      })
    );

    await uploadDocuments();
    onSubmit();
  };

  const onSkipAndSubmitButtonClick = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'click_skip_upload_documents_and_submit_application_button',
      })
    );

    onSubmit();
  };

  const onLocalUploadFiles = (acceptedFiles: File[]) => {
    setLastLocalUploadHadMultipleFiles(acceptedFiles.length > 1);
    setDocumentsApiStatus(ApiStatus.Idle);

    const acceptedFilesToOnboardingFiles = acceptedFiles.map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      timestamp: file.lastModified,
      objectURL: URL.createObjectURL(file),
    }));

    setErrors(localFiles.concat(acceptedFilesToOnboardingFiles));
    setLocalFiles((currentFiles) =>
      filterOutIncorrectFiles(
        acceptedFilesToOnboardingFiles.concat(currentFiles),
        allowedFileFormats,
        maxSize,
        maxFiles
      )
    );

    fileListRef.current?.scrollIntoView();
  };

  const handleRemoveFile = (file: IOnboardingFile) => {
    setDocumentsApiStatus(ApiStatus.Idle);
    URL.revokeObjectURL(file.objectURL);
    const removedObjArray = localFiles.filter(
      (localFile) => localFile.objectURL !== file.objectURL
    );
    setLocalFiles(removedObjArray);
    setErrors(removedObjArray);
  };

  const onDeleteAll = () => {
    setDocumentsApiStatus(ApiStatus.Idle);
    localFiles.forEach((localFile) => {
      URL.revokeObjectURL(localFile.objectURL);
    });
    setLocalFiles([]);
    setErrors([]);
  };

  const maxFilesUploaded = localFiles.length >= maxFiles;

  return (
    <Stack spacing="md">
      <Body size="lg">{t('Onboarding.UploadFilesModalDescription')}</Body>
      {!maxFilesUploaded && (
        <DocumentUpload
          allowedFileExtensionsDisplayed={allowedFileExtensionsDisplayed}
          maxFiles={maxFiles}
          maxSize={maxSize}
          onUploadFiles={onLocalUploadFiles}
        />
      )}
      <Stack spacing="lg">
        <DocumentErrorMessage
          allowedFileExtensionsDisplayed={allowedFileExtensionsDisplayed}
          documentsApiStatus={documentsApiStatus}
          fileUploadErrors={localFileUploadErrors}
          lastLocalUploadHadMultipleFiles={lastLocalUploadHadMultipleFiles}
          maxFiles={maxFiles}
          maxSize={maxSize}
        />
        {!!localFiles.length && (
          <DocumentList
            fileListRef={fileListRef}
            files={localFiles}
            handleRemoveFile={handleRemoveFile}
            onDeleteAll={onDeleteAll}
          />
        )}
      </Stack>
      <ModalFooter fixedHeaderAndFooter filledBackground>
        <Box>
          <NextButton
            action={
              localFiles.length
                ? onSubmitButtonClick
                : onSkipAndSubmitButtonClick
            }
            label={
              localFiles.length
                ? 'Onboarding.Submit'
                : 'Onboarding.SkipAndSubmit'
            }
            isLoading={
              documentsApiStatus === ApiStatus.Started ||
              apiStatus.patch === ApiStatus.Started
            }
          />
        </Box>
      </ModalFooter>
    </Stack>
  );
};

export default withValidation(UploadDocumentsModalBody);
