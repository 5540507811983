import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory, createMemoryHistory } from 'history';
import * as Sentry from '@sentry/react';
import { HubSpotConversations, PostcodeNl } from '~/Interfaces';
import { XS2AConfig } from '~/hooks/useKlarnaFlow/types';
import rootReducer from './reducers/rootReducer';
import initialState from './initialState';
import { listenerMiddleware } from './middlewares/listener.middleware';
import { myPagesBaseApi } from '~/store/apis/endpoints/_baseApi/myPages.base.api';
import { sentryNotifierMiddleware } from '~/store/middlewares/sentryNotifier/sentryNotifier.middleware';
import { rtkErrorNotifierMiddleware } from '~/store/middlewares/rtkErrorNotifier/rtkErrorNotifier.middleware';
import { myQredApi } from './apis/endpoints/_baseApi/myQredApi.base.api';
import { onboardingUnauthenticatedApi } from './apis/endpoints/_baseApi/onboarding.unauthenticated.base.api';
import { openBankingApi } from './apis/endpoints/openBankingApi/openBanking.api';
import { myPagesUnauthenticatedBaseApi } from './apis/endpoints/_baseApi/myPages.unauthenticated.base.api';
import { onboardingApi } from './apis/endpoints/onboardingApi/onboarding.api';

declare global {
  interface Window {
    HubSpotConversations?: HubSpotConversations;
    hsConversationsOnReady?: { (): void }[];
    hsConversationsSettings?: {
      loadImmediately?: boolean; // default: true
      inlineEmbedSelector?: string; // default: ''
      enableWidgetCookieBanner?: boolean; // default: false
      disableAttachment?: boolean; // default: false
    };
    __QRED_MARKET__: string;
    dataLayer: object[];
    qred?: any;
    Cypress?: any;
    authService?: any;
    Trustpilot?: any;
    onXS2AReady: (XS2A: any) => void;
    XS2A: {
      startFlow: (token: string, config?: XS2AConfig) => unknown;
      close: () => unknown;
      configure: (config: XS2AConfig) => unknown;
    };
    PostcodeNl?: PostcodeNl;
    OneTrust?: any;
    OnetrustActiveGroups?: string;
    posthogCapture: (eventName: string, data: object) => void;
  }
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      listenerMiddleware.middleware,
      myPagesBaseApi.middleware,
      myPagesUnauthenticatedBaseApi.middleware,
      myQredApi.middleware,
      onboardingUnauthenticatedApi.middleware,
      openBankingApi.middleware,
      onboardingApi.middleware,
      sentryNotifierMiddleware,
      rtkErrorNotifierMiddleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export const history =
  typeof window !== 'undefined'
    ? createBrowserHistory()
    : createMemoryHistory();
