import { styledPolymorphic } from '@qred/components-library';

export const Container = styledPolymorphic<
  'div',
  {
    $stickyFooter: boolean;
    $fullHeight: boolean;
  }
>()(
  (styled) => styled`
  overflow-y: auto;

  ${(props) =>
    props.$fullHeight ?? true
      ? `
        height: 100%;
      `
      : `
        margin: auto;
  `}

    ${(props) =>
      (props.$stickyFooter ?? true) &&
      `
        display: flex;
        flex-direction: column;
    `}

     ${({ theme }) =>
       `${theme.fn.smallerThan('tablet')} {
        display: flex;
        flex: 1;
        margin: unset;
      }
    `}
  `
);

export const StepFooterWrapper = styledPolymorphic<
  'div',
  { $fixedFooter?: boolean }
>()(
  (styled) => styled`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em 0;
  background-color: ${({ theme }) =>
    theme.fn.themeColor('Container/Option_2_(White)')};

  ${({ theme, $fixedFooter }) =>
    ($fixedFooter ?? true) &&
    `
      background-color: ${theme.fn.themeColor('Main/Secondary/Greige/100')};
      border-top: 1px solid ${theme.fn.themeColor('Border/Disabled')};
      z-index: 1;
  `}

  ${({ theme }) =>
    `${theme.fn.smallerThan('tablet')} {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  `}
`
);

export const StepContent = styledPolymorphic<
  'div',
  {
    $lockStep?: boolean;
    $footerHeight: number;
  }
>()(
  (styled) => styled`
    background-color: ${({ theme }) =>
      theme.fn.themeColor('Container/Option_2_(White)')};
    pointer-events: ${(props) => (props.$lockStep ? 'none' : 'initial')};
    padding: 32px;
    gap: 24px;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
    ${({ $footerHeight, theme }) =>
      `${theme.fn.smallerThan('tablet')} {
          padding: 24px 16px ${$footerHeight + 20}px;
      }
    `}
  `
);
