import { Body, H6 } from '@qred/components-library';
import React from 'react';
import { ApplicationDescriptionItemWrapper } from './ApplicationDescriptionItem.styled';

interface ApplicationDescriptionItemProps {
  title: string;
  text: string;
}

const ApplicationDescriptionItem = ({
  title,
  text,
}: ApplicationDescriptionItemProps) => (
  <ApplicationDescriptionItemWrapper>
    <H6>{title}</H6>
    <Body size="lg">{text}</Body>
  </ApplicationDescriptionItemWrapper>
);

export default ApplicationDescriptionItem;
