import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { CompanyTitles } from '~/constants/markets';
import MeansOfSupport from '../../MeansOfSupport/MeansOfSupport';
import { Body, H7, Stack } from '@qred/components-library';

interface Props {
  shouldShowOnboardingLink: boolean;
}

const NoAccountError = ({ shouldShowOnboardingLink }: Props) => {
  const market = useSelector((state: RootState) => state.intl.market);
  const translate = useTranslate();

  return (
    <Stack>
      <H7>
        {translate('NoAccountError.ErrorMessageTitle', {
          title: CompanyTitles[market],
        })}
      </H7>
      <Body size="lg">{translate('NoAccountError.ErrorMessageText1')}</Body>
      <Body size="md">{translate('ContactNeedHelp')}</Body>
      <MeansOfSupport />
      {!shouldShowOnboardingLink && (
        <Body size="lg">{translate('NoAccountError.NewLoanText')}</Body>
      )}
    </Stack>
  );
};

export default NoAccountError;
