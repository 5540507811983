import { CountryCode } from '@qred/shared-enums';
import { SignerType } from '~/enums';

export interface IOnboardingPerson extends IOnboardingAMLData {
  fullName?: string | null;
  name?: string | null;
  personalNumber?: string | null;
  dateOfBirth?: Date | string | null;
  yearOfBirth?: string | null;
}

export interface IOnboardingAddressFields {
  city?: string;
  houseNumber?: string;
  houseNumberAddition?: string;
  streetName?: string;
  zipCode?: string;
}

export interface IKlarnaAccount {
  id?: string;
  alias?: string;
  account_number?: string;
  iban?: string;
  holder_name?: string;
  holder_address?: {
    street_address?: string;
    street_address2?: string;
    postalcode?: string;
    city?: string;
    region?: string;
    country?: string;
  };
  bank_code?: string;
  bic?: string;
  national_branch_code?: string;
  bank_name?: string;
  bank_address?: {
    street_address?: string;
    street_address2?: string;
    postalcode?: string;
    city?: string;
    region?: string;
    country?: string;
  };
  transfer_type?: 'NONE' | 'DOMESTIC' | 'FULL' | 'REFERENCE' | 'RESTRICTED';
  account_type?: 'DEFAULT' | 'SAVING' | 'CREDITCARD' | 'DEPOT';
  balance?: {
    amount: number;
    currency: string;
  };
  capabilities?: {
    account_details?: {
      available?: boolean;
    };
    balances?: {
      available?: boolean;
    };
    transactions?: {
      available?: boolean;
    };
    transfer?: {
      available?: boolean;
    };
  };
}

interface IOpenBankingAccountBase {
  details: string | null;
  name: string | null;
  isNewlyAdded?: boolean;
}

export interface IOpenBankingAccountBBAN extends IOpenBankingAccountBase {
  market: CountryCode.SE | CountryCode.DK | CountryCode.NO;
  bban: string;
  iban: string | null;
}

export interface IOpenBankingAccountIBAN extends IOpenBankingAccountBase {
  market: CountryCode.FI | CountryCode.BE | CountryCode.NL | CountryCode.DE;
  iban: string;
  bban: string | null;
}

export type IOpenBankingAccount =
  | IOpenBankingAccountBBAN
  | IOpenBankingAccountIBAN;

export interface IOnboardingKYCQuestionnaireQuestionWithAnswers {
  answerType: number;
  answers: {
    id: number;
    name: string;
    sequenceNumber: number;
  }[];
  id: number;
  name: string;
  notes: string | null;
  sequenceNumber: number;
  type: {
    id: number;
    name: string;
    sequenceNumber: number;
  };
}

export interface IOnboardingKYCQuestionnaireAnswer {
  answers: {
    answer: string;
    answerId: number;
  }[];
  questionId: number;
}

export interface IOnboardingAMLData {
  countryOfResidence?: string | null;
  countryOfTaxResidence?: string | null;
  isPEP?: boolean;
}

export interface IOnboardingSigner extends IOnboardingAMLData {
  email?: string | null;
  name: string;
  firstName: string;
  lastName: string;
  phone?: string | null;
  signedDate?: Date;
  signerId: number;
  type: SignerType;
}

export interface IOnboardingFile {
  name: string;
  type: string;
  objectURL: string;
  size: number;
  timestamp: number;
}

export interface IOnboardingBeneficialOwner extends IOnboardingAMLData {
  fullName: string;
  personalNumber?: string | null;
  dateOfBirth?: Date | null;
  yearOfBirth?: string | null;
  percentage: string;
  id: string;
  address?: IOnboardingAddressFields;
  isAlternativeBeneficialOwner?: boolean;
}

export enum CompanyTypeCategory {
  SOLE_TRADER = 'sole trader',
  LIMITED_LIABILITY = 'limited liability',
  OTHER = 'other',
  DO_NOT_EXIST = 'do not exist anymore',
}
export interface ICompanySearchResult {
  companyNumber: string;
  companyName: string;
  companyType?: string;
}
