import { MaterialIcon } from '@qred/components-library';
import React, { Fragment } from 'react';
import {
  Bullet,
  Container,
  IconWrapper,
  ItemWrapper,
  Line,
} from './Timeline.styled';

interface Props {
  items: React.JSX.Element[];
  completedIndex: number;
}

export const Timeline = (props: Props) => {
  const { items, completedIndex } = props;

  return (
    <Container>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;
        const isCompleted = index < completedIndex;

        return (
          <Fragment key={item.key}>
            <IconWrapper>
              {getIcon(isLastItem, isCompleted)}
              {!isLastItem && <Line $isCompleted={isCompleted} />}
            </IconWrapper>
            <ItemWrapper>{item}</ItemWrapper>
          </Fragment>
        );
      })}
    </Container>
  );
};

const getIcon = (
  isLastItem: boolean,
  isCompleted: boolean
): React.JSX.Element => {
  if (isLastItem) {
    return (
      <MaterialIcon
        src="new_releases"
        color={isCompleted ? 'Main/Primary/800' : 'Main/Grey/800'}
        size={24}
      />
    );
  }

  if (isCompleted) {
    return (
      <MaterialIcon
        src="check_circle"
        color="Main/Primary/800"
        filled
        size={24}
      />
    );
  }

  return <Bullet bg="Text/Secondary" />;
};
