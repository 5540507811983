import React from 'react';
import {
  Body,
  Box,
  Group,
  H3,
  H7,
  Loader,
  Stack,
  useTheme,
  useWindowProperties,
} from '@qred/components-library';
import useQueryParams from '~/hooks/useQueryParams';
import {
  fetchBaseQueryErrorSchema,
  usePostSessionQuery,
} from '~/store/apis/endpoints/openBankingApi/openBanking.api';
import { OnboardingStepsContainer } from '~/styles/OnboardingBaseStyledComponents';
import { Redirect } from 'react-router-dom';
import AppShell from '~/components/shared/OnboardingAppShell/OnboardingAppShell';
import useTranslate from '~/hooks/useTranslate';
import { BankConnectionResult as BankConnectionResultEnum } from '~/enums';

const BankConnectionResult = () => {
  const queryParams = useQueryParams();
  const t = useTranslate();
  const { isMobile } = useWindowProperties();
  const theme = useTheme();

  const stateParam = queryParams.get('state');
  const codeParam = queryParams.get('code') ?? undefined;
  const errorParam = queryParams.get('error') ?? undefined;
  const errorDescriptionParam =
    queryParams.get('error_description') ?? undefined;

  if (!stateParam) {
    throw new Error('No state parameter found');
  }

  const {
    data: postSessionData,
    error: apiError,
    isFetching,
    isSuccess,
  } = usePostSessionQuery({
    body: {
      state: stateParam,
      code: codeParam,
      error: errorParam,
      errorDescription: errorDescriptionParam,
    },
  });

  const { data: fetchErrorData } = fetchBaseQueryErrorSchema.safeParse(
    apiError
  );

  let redirectUrl =
    postSessionData?.redirectUrl ?? fetchErrorData?.data.redirectUrl;

  if (isSuccess) {
    if (redirectUrl) {
      const url = new URL(redirectUrl);
      if (url.origin !== location.origin) {
        throw new Error('Redirect URL is not internal');
      }

      return <Redirect to={`${url.pathname}${url.search}`} />;
    } else {
      window.close();
    }
  }
  const backgroundColor = theme.fn.themeColor('Main/Primary/300');

  if (isFetching) {
    return (
      <AppShell background={backgroundColor}>
        <Loader
          size="lg"
          pos="absolute"
          top="50%"
          left="50%"
          style={{
            transform: 'translate(-50%, -50%)',
          }}
        />
      </AppShell>
    );
  }

  const hasAnyError =
    errorParam ??
    apiError ??
    postSessionData?.status === BankConnectionResultEnum.Error;

  return (
    <AppShell
      background={
        !isMobile
          ? `url(/qrayon2_zoomed.png) no-repeat bottom / cover ${backgroundColor}`
          : undefined
      }
    >
      <OnboardingStepsContainer>
        <Stack
          justify="center"
          align="center"
          spacing={'xxl'}
          style={{
            flexGrow: 0.5,
          }}
        >
          {hasAnyError && (
            <>
              <H3 data-record>
                {t('BankConnectionResult.ConnectionFailedTitle')}
              </H3>
              <Body size="xl" data-record>
                {t('BankConnectionResult.ConnectionFailedDescription')}
              </Body>
            </>
          )}

          {/* We should never get here unless the browser fails to close the window/tab programmatically after
            a successful session request without a redirectUrl in the response */}
          {postSessionData?.status === BankConnectionResultEnum.Success && (
            <>
              <H3 data-record>{t('BankConnectionResult.SuccessTitle')}</H3>
              <Body size="xl" data-record>
                {t('BankConnectionResult.SuccessDescription')}
              </Body>
              <Stack align="start">
                <Group align="center">
                  <Box
                    bg={'Main/Grey/Alpha/OnLight/10%'}
                    style={{
                      borderRadius: '100px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    w={'32px'}
                    h={'32px'}
                    display={'flex'}
                  >
                    <H7 data-record>1</H7>
                  </Box>
                  <Body size="lg" data-record>
                    {t('BankConnectionResult.SuccessDescriptionStep1')}
                  </Body>
                </Group>
                <Group align="center">
                  <Box
                    bg={'Main/Grey/Alpha/OnLight/10%'}
                    style={{
                      borderRadius: '100px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    w={'32px'}
                    h={'32px'}
                    display={'flex'}
                  >
                    <H7 data-record>2</H7>
                  </Box>
                  <Body size="lg" data-record>
                    {t('BankConnectionResult.SuccessDescriptionStep2')}
                  </Body>
                </Group>
              </Stack>
            </>
          )}
        </Stack>
      </OnboardingStepsContainer>
    </AppShell>
  );
};

export default BankConnectionResult;
