import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { RootState } from '~/store/types/sharedTypes';

const CompanySelectorHeader = () => {
  const {
    onboardingApplication: { overview },
  } = useSelector((state: RootState) => state);

  const { title, description } = useMemo(() => {
    if (overview.clients?.length > 0) {
      return {
        title: 'Onboarding.SelectCompanySelectorTitle',
        description: 'Onboarding.SelectCompanySelectorDescriptionRlc',
      };
    }

    if (!overview.clients?.length) {
      return {
        title: 'Onboarding.SelectCompanySelectorTitle',
        // TODO: Update this in translation sheet to "Search by your company name or enter the full organisation number."
        description: 'Onboarding.SelectCompanySelectorDescriptionNlcAndManual',
      };
    }

    return {
      title: 'Onboarding.SelectCompanySelectorTitle',
      description: '',
    };
  }, [overview.clients]);

  return <StepHeader title={title} description={description} />;
};

export default CompanySelectorHeader;
