import {
  Body,
  Link,
  Stack,
  useModals,
  useWindowProperties,
} from '@qred/components-library';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PDFViewer from '~/components/shared/PDFViewer/PDFViewer';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import {
  companyCreditCheckAgencies,
  getMarketHasCompanyCreditCheck,
} from '~/constants/markets';
import { StepStatus } from '~/enums';
import { areSameCompanies } from '~/helpers/utils';
import useTranslate from '~/hooks/useTranslate';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import {
  fetchClientApplication,
  patchApplication,
  postApplication,
} from '~/store/actions/onboardingActions';
import { goToNextStep } from '~/store/slices/onboardingApplication.slice';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';

const CompanySelectorFooter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatList } = useIntl();

  const {
    onboardingApplication: {
      form,
      apiStatus: applicationApiStatus,
      currentApplicationOrganizationIdentifier,
      overview,
    },
    intl: { market },
  } = useSelector((state: RootState) => state);
  const { isMobile } = useWindowProperties();

  const t = useTranslate();
  const modal = useModals();

  const applicationApiRequestInProgress = [
    applicationApiStatus.application,
    applicationApiStatus.patch,
    applicationApiStatus.post,
  ].some((apiStatus) => apiStatus === ApiStatus.Started);

  const onNextStepClick = useCallback(async () => {
    const selectedCompany = overview.clients.find((client) =>
      areSameCompanies(
        form.organizationNumber || form.connectId,
        client.company_no || client.connect_id
      )
    );

    try {
      dispatch(
        pushToGtmOnboardingAction({ actionName: 'next_button_pressed' })
      );
      // TODO: if user comes back to step 1 and doesnt change company and clicks next, we should PATCH not GET
      if (selectedCompany?.has_offer_to_sign) {
        dispatch(
          pushToGtmOnboardingAction({ actionName: 'redirect_to_offer_page' })
        );
        history.push(
          selectedCompany.application_uuid
            ? `/onboarding-offer/${selectedCompany.application_uuid}`
            : `/offer/${selectedCompany.client_id}`
        );
        return;
      }

      const companyHasChanged = !areSameCompanies(
        form.organizationNumber || form.connectId,
        currentApplicationOrganizationIdentifier
      );

      if (
        selectedCompany?.application_uuid &&
        selectedCompany?.has_inprogress_application &&
        companyHasChanged
      ) {
        dispatch(
          pushToGtmOnboardingAction({
            actionName: 'active_application_continue',
          })
        );
        const currentStepFinished = true;
        dispatch(
          fetchClientApplication(
            selectedCompany.application_uuid,
            currentStepFinished
          )
        );
      } else {
        if (companyHasChanged) {
          await dispatch(postApplication());
        } else {
          await dispatch(patchApplication());
        }
        dispatch(goToNextStep());
      }
    } catch (_err: any) {
      console.log(_err);

      dispatch(
        pushToGtmOnboardingAction({
          stepStatus: StepStatus.Error,
          errorMessage: _err.response?.data?.message,
        })
      );
    }
  }, [
    currentApplicationOrganizationIdentifier,
    dispatch,
    form.organizationNumber,
    form.connectId,
    history,
    overview.clients,
  ]);

  const openPdfViewerModal = (fileSrc: string) => {
    modal.openModal({
      size: isMobile ? 'md' : 'xl',
      children: <PDFViewer file={fileSrc} />,
    });
  };

  const agencies = companyCreditCheckAgencies[market];

  return (
    <Stack w={isMobile ? '100%' : 'auto'}>
      {getMarketHasCompanyCreditCheck(market) && (
        <Body
          data-testId="company-selector-footer-disclaimer-container"
          color="textDark.200"
          size="sm"
          data-record
        >
          {t('Onboarding.CompanyCreditConsent', {
            agencies: formatList(
              agencies.map((agency) => {
                return (
                  <Link
                    data-record
                    key={agency.id}
                    onClick={() => openPdfViewerModal(agency.pdfPath)}
                    size="sm"
                  >
                    {agency.label}
                  </Link>
                );
              })
            ),
            next: `"${t('Onboarding.Next')}"`,
          })}
        </Body>
      )}

      <NextButton
        label="Onboarding.Next"
        isLoading={applicationApiRequestInProgress}
        action={onNextStepClick}
      />
    </Stack>
  );
};

export default CompanySelectorFooter;
