import { Body, Link } from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import Popup from '~/components/shared/Popup/Popup';
import {
  ContactEmails,
  ContactNumbers,
  ContactNumbersFormatted,
} from '~/constants/markets';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';

const MissingParamsModel = () => {
  const market = useSelector((state: RootState) => state.intl.market);
  const translate = useTranslate();

  return (
    <Popup
      isOpen
      onOkButtonClick={() => window.location.assign('https://www.qred.com')}
    >
      <Body size="lg">{translate('MissingParamsModel.Text1')}</Body>
      <Body size="lg">
        <Icon
          src={SvgSrc.Phone}
          size={ComponentSize.Small}
          mr={0.5}
          color={theme.colors.dark}
        />
        <a href={`tel:${ContactNumbers[market]}`}>
          <Link>{ContactNumbersFormatted[market]}</Link>
        </a>
      </Body>
      <Body size="lg">
        <Icon
          src={SvgSrc.Mail}
          size={ComponentSize.Small}
          mr={0.5}
          color={theme.colors.dark}
        />
        <a href={`mailto:${ContactEmails[market]}`}>
          <Link>{ContactEmails[market]}</Link>
        </a>
      </Body>
    </Popup>
  );
};

export default MissingParamsModel;
