import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { Button, H7, Stack } from '@qred/components-library';
import PaymentCalculatorModal from '~/pages/OnboardingOffer/components/PaymentCalculator/PaymentCalculatorModal';
import useModals from '~/hooks/useModals';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { useOnboardingOfferSelectors } from '~/store/selectors/onboardingOffer.selector';
import PaymentDetails from './PaymentDetails';
import LoanOfferAdjustmentForm from './LoanOfferAdjustmentForm';

const LoanOfferAdjustment = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const modals = useModals();

  const { monthlyFee, loanAmount } = useOnboardingOfferSelectors();

  const {
    onboardingOffer: { overview, form },
    matchedMedia,
  } = useSelector((state: RootState) => state);

  const handleOpenPaymentScheduleModal = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'loan_offer_payment_plan_open',
      })
    );

    modals.openModal({
      header: <H7>{translate('PaymentCalculator.PaymentSchedule')}</H7>,
      children: (
        <PaymentCalculatorModal
          amount={loanAmount}
          loanPeriod={form.loanPeriod}
          monthlyFee={monthlyFee}
          campaignType={overview.campaignType}
          campaignDiscount={overview.campaignDiscount}
          campaignPrincipalFreeMonths={overview.campaignPrincipalFreeMonths}
          campaignInterestRateDiscount={overview.campaignInterestRateDiscount}
        />
      ),
      footer: (
        <Stack align="center">
          <Button variant="secondary" size="md" onClick={modals.closeAll}>
            {translate('Ok')}
          </Button>
        </Stack>
      ),
      fullScreen: matchedMedia.smallScreenPortrait,
      fixedHeaderAndFooter: matchedMedia.smallScreenPortrait,
      filledBackground: true,
      size: 'xl',
    });
  };

  return (
    <Stack spacing="xxl">
      <LoanOfferAdjustmentForm />
      <PaymentDetails />
      <Button
        size="md"
        variant="secondary"
        onClick={() => handleOpenPaymentScheduleModal()}
      >
        {translate('ViewPaymentSchedule')}
      </Button>
    </Stack>
  );
};

export default LoanOfferAdjustment;
