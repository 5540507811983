import React from 'react';
import { useSelector } from 'react-redux';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import SkipButton from '~/components/shared/Stepper/components/SkipButton';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import KlarnaKosmaBankIntegration from '../../KlarnaKosmaBankIntegration/KlarnaKosmaBankIntegration';
import useSkip from '../../Steps/useSkip';
import useGoToNextStep from '../../Steps/useGoToNextStep';
import { Stack } from '@qred/components-library';

const ConnectToBankFooter = () => {
  const {
    onboardingApplication: { form, clientId, apiStatus },
    klarnaKosma: { transactionFlow },
  } = useSelector((state: RootState) => state);

  const onNextStepClick = useGoToNextStep();
  const onSkip = useSkip();

  const hideDefaultCta = !form.bankIntegration;

  return (
    <>
      {hideDefaultCta ? (
        <Stack spacing={'xs'} align="center">
          <SkipButton
            label="Onboarding.Skip"
            action={onSkip}
            disabled={transactionFlow.status === 'inProgress'}
          />
          {clientId && form.organizationNumber && (
            <KlarnaKosmaBankIntegration
              buttonText="Onboarding.ConnectToBank"
              organizationNumber={form.organizationNumber}
              clientId={clientId}
            />
          )}
        </Stack>
      ) : (
        <NextButton
          label="Onboarding.Next"
          isLoading={apiStatus.patch === ApiStatus.Started}
          action={onNextStepClick}
        />
      )}
    </>
  );
};

export default ConnectToBankFooter;
