import z from 'zod';
import { klarnaAccountSchema } from './KlarnaKosma';
import { SignerType } from '~/enums';

const onboardingGetAddressResDTOSchema = z.strictObject({
  city: z.string(),
  houseNumber: z.number(),
  houseNumberAdditions: z.array(z.string()),
  postcode: z.string(),
  street: z.string(),
});

const onboardingGetKlarnaKosmaAccountsInfoResDTOSchema = z.strictObject({
  accounts: z.array(klarnaAccountSchema),
  message: z.string(),
});

const onboardingGetKYCQuestionsAndAnswersResDTOSchema = z.strictObject({
  questions: z.array(
    z.strictObject({
      isactive: z.boolean().optional(),
      answerType: z.number(),
      answers: z.array(
        z.strictObject({
          id: z.number(),
          name: z.string(),
          sequenceNumber: z.number(),
        })
      ),
      id: z.number(),
      name: z.string(),
      notes: z.string().nullable(),
      sequenceNumber: z.number(),
      type: z.strictObject({
        id: z.number(),
        name: z.string(),
        sequenceNumber: z.number(),
      }),
    })
  ),
});

const onboardingPostKYCAnswersResDTOSchema = z.strictObject({
  answers: z.array(
    z.strictObject({
      answer: z.string(),
      answerId: z.number().nullable(),
      questionId: z.number(),
    })
  ),
});

const onboardingPostKYCAnswersReqDTOSchema = z.strictObject({
  questionsAndAnswers: z.array(
    z.strictObject({
      answers: z.array(
        z.strictObject({
          answer: z.string(),
          answerId: z.number(),
        })
      ),
      questionId: z.number(),
    })
  ),
});

const onboardingGetAdditionalSignerLoanApplicationDataResDTOSchema = z.strictObject(
  {
    amount: z.string(),
    bank_account: z.string(),
    company_name: z.string(),
    company_no: z.string(),
    first_name: z.string(),
    landing_url: z.string(),
    language: z.string(),
    last_name: z.string(),
    monthly_fee: z.string(),
    monthly_payment: z.string(),
    payment_due: z.string(),
    personal_id: z.string(),
    signer_type: z.nativeEnum(SignerType),
    term: z.number(),
    granter: z.enum(['qred', 'nordiska']).or(z.literal('')),
  }
);

const onboardingGetAdditionalSignerCardApplicationDataResDTOSchema = z.strictObject(
  {
    cardApplicationUuid: z.string(),
    companyName: z.string(),
    hasAcceptedPersonalGuarantee: z.boolean(),
    hasAcceptedTerms: z.boolean(),
    interest: z.number(),
    offeredCreditLimit: z.number(),
    signerUuid: z.string(),
  }
);

const onboardingPostInitiateOTPResDTOSchema = z.strictObject({
  otpExpiryTimestamp: z.number(),
  message: z.string(),
});

const onboardingPostRegenerateOTPResDTOSchema = z.strictObject({
  otpExpiryTimestamp: z.number(),
  message: z.string(),
});

const onboardingPostVerifyOTPResDTOSchema = z.strictObject({
  accessToken: z.string().optional(),
  message: z.string(),
  otpExpiryTimestamp: z.number().optional(),
});

const onboardingPostIdentifyResDTOSchema = z.strictObject({
  identified: z.boolean(),
  redirectUrl: z.string().optional(),
});

export const onboardingDTOSchemas = {
  getAddressRes: onboardingGetAddressResDTOSchema,
  getKlarnaKosmaAccountsInfoRes: onboardingGetKlarnaKosmaAccountsInfoResDTOSchema,
  getKYCQuestionsAndAnswersRes: onboardingGetKYCQuestionsAndAnswersResDTOSchema,
  getAdditionalSignerLoanApplicationDataRes: onboardingGetAdditionalSignerLoanApplicationDataResDTOSchema,
  getAdditionalSignerCardApplicationDataRes: onboardingGetAdditionalSignerCardApplicationDataResDTOSchema,
  postKYCAnswersReq: onboardingPostKYCAnswersReqDTOSchema,
  postKYCAnswersRes: onboardingPostKYCAnswersResDTOSchema,
  postInitiateOTPRes: onboardingPostInitiateOTPResDTOSchema,
  postRegenerateOTPRes: onboardingPostRegenerateOTPResDTOSchema,
  postVerifyOTPRes: onboardingPostVerifyOTPResDTOSchema,
  postIdentifyRes: onboardingPostIdentifyResDTOSchema,
};
