import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonType } from '~/components/shared/Button/button';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import Stack from '~/components/shared/Layout/Stack';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { Stack as CLStack, Body, H7 } from '@qred/components-library';
import { useKlarnaFlow } from '~/hooks/useKlarnaFlow/useKlarnaFlow';
import useModals from '~/hooks/useModals';
import useTranslate from '~/hooks/useTranslate';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import { CountryCode } from '~/enums';
import { Content } from '../ConnectToBank/ConnectToBank.styled';
import KlarnaKosmaBankIntegration from '../KlarnaKosmaBankIntegration/KlarnaKosmaBankIntegration';
import UploadDocumentsModalBody from './components/UploadDocumentsModalBody';

export const UploadDocuments = () => {
  const dispatch = useDispatch();
  const {
    intl: { market },
    klarnaKosma: { transactionFlow },
    onboardingApplication: {
      form: { bankIntegration, organizationNumber, bankFilesUploaded },
      clientId,
    },
    matchedMedia,
  } = useSelector((state: RootState) => state);

  useKlarnaFlow();
  const t = useTranslate();
  const modals = useModals();

  const handleOpenUploadDocumentsModal = () => {
    modals.openModal({
      id: 'uploadDocuments-modal',
      header: <H7>{t('Onboarding.UploadFilesModalTitle')}</H7>,
      children: <UploadDocumentsModalBody />,
      size: 'md',
      fullScreen: matchedMedia.smallScreenPortrait,
      fixedHeaderAndFooter: true,
      hasOwnFooter: true,
      filledBackground: true,
      showBackButton: true,
    });

    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'open_upload_documents_modal',
      })
    );
  };

  useEffect(() => {
    if (bankFilesUploaded) {
      handleOpenUploadDocumentsModal();
    }

    return () => {
      modals.closeModal('uploadDocuments-modal');
    };
  }, []);

  return (
    <>
      {!bankIntegration && (
        <Stack
          alignContent="center"
          wrap={!matchedMedia.mobile ? 'wrap' : undefined}
        >
          {transactionFlow.status === 'inProgress' ? (
            <Content>
              <Body size="lg">{t('Onboarding.ConnectingToKlarna')}</Body>
              <SpinnerCircular color={theme.colors.secondaryGray} />
            </Content>
          ) : (
            <>
              <Button
                type={ButtonType.SecondaryInverted}
                fullWidth
                onClick={handleOpenUploadDocumentsModal}
                textColor={theme.colors.dark}
                dataCy="onboarding_upload_documents_button"
              >
                <Icon src={SvgSrc.FileUpload} mr={0.5} />
                {t('Onboarding.UploadBankTransactions')}
              </Button>
              {market !== CountryCode.NL && clientId && organizationNumber && (
                <>
                  <CLStack align="center">
                    <Body size="lg">{t('Or')}</Body>
                  </CLStack>
                  <KlarnaKosmaBankIntegration
                    buttonText="Onboarding.ConnectToYourBank"
                    clientId={clientId}
                    organizationNumber={organizationNumber}
                  />
                </>
              )}
            </>
          )}
        </Stack>
      )}
    </>
  );
};
