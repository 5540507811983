import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import Stack from '../../Layout/Stack';
import { Body, H7 } from '@qred/components-library';

interface CustomGlobalErrorMessageProps {
  title: string;
  description?: string;
}

const CustomGlobalError = ({
  title,
  description,
}: CustomGlobalErrorMessageProps) => {
  const translate = useTranslate();

  return (
    <Stack align="center">
      <H7>{translate(title)}</H7>
      {description && <Body security="lg">{translate(description)}</Body>}
    </Stack>
  );
};

export default CustomGlobalError;
