import { styledPolymorphic } from '@qred/components-library';

export const MainOnboardingContainer = styledPolymorphic<
  'div',
  {
    $background?: React.CSSProperties['background'];
    $backgroundOpacity?: React.CSSProperties['opacity'];
    $backgroundColor?: React.CSSProperties['backgroundColor'];
  }
>()(
  (styled) => styled`
  display: flex;
  flex-direction: column;
  min-height: var(--client-height);
  font-size: 16px;

  ${({ $backgroundColor }) => {
    if ($backgroundColor) {
      return `
        background-color: ${$backgroundColor};
      `;
    }
    return '';
  }}

  ${({ $background, $backgroundOpacity }) => {
    if ($background && $backgroundOpacity) {
      return `
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          background: ${$background};
          opacity: ${$backgroundOpacity};
        }
      `;
    } else if ($background) {
      return `
        background: ${$background};
      `;
    }
    return '';
  }}

  & > div {
    position: relative;
    z-index: 2;
  }
`
);

export const OnboardingStepsContainer = styledPolymorphic<
  'div',
  {
    $justify?: React.CSSProperties['justifyContent'];
  }
>()(
  (styled) => styled`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.$justify ?? 'flex-start'};
  align-items: center;
  align-self: center;
  padding: 0 2em;
  width: 100%;
  padding: 0;
  height: calc(
    var(--client-height) - (var(--onboarding-navbar-height) * 2)
  ); // total screen height - 2x navbar height

    ${({ theme }) =>
      `${theme.fn.largerThan('tablet')} {
          max-width: 40em;
          height: calc(
            var(--client-height) - (var(--onboarding-navbar-height) * 4)
          ); // total screen height - 4x navbar height
          margin-top: 18px;
        }
      `}
      }
`
);
