import { TLoanOnboardingApplicationDTO } from '~/types/DTO/loanOnboardingApplication';
import { CountryCode } from '@qred/shared-enums';
import { onboardingUnauthenticatedApi } from '../../_baseApi/onboarding.unauthenticated.base.api';

const onboardingCompanySearchApi = onboardingUnauthenticatedApi.injectEndpoints(
  {
    endpoints: (builder) => ({
      getCompanyInfoBySearch: builder.query<
        TLoanOnboardingApplicationDTO['getCompanySearchRes'],
        { searchInput: string; market: CountryCode; address?: string }
      >({
        query: ({ searchInput, market, address }) => ({
          url: `/country/${market}/company-search/${encodeURIComponent(
            searchInput
          )}`,
          params: { address },
        }),
      }),
    }),
  }
);

export const {
  useLazyGetCompanyInfoBySearchQuery,
} = onboardingCompanySearchApi;
