import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { StepStatus } from '~/enums';
import { LaunchViewTopWrapper } from './LaunchView.styled';
import LaunchViewForm from './components/LaunchViewForm/LaunchViewForm';
import Slideshow from './components/Slideshow/Slideshow';
import OnboardingNavbar from '../OnboardingNavbar/OnboardingNavbar';
import TrustpilotWidget from './components/TrustpilotWidget/TrustpilotWidget';
import ApplicationDescriptionSection from './components/ApplicationDescriptionSection/ApplicationDescriptionSection';
import CustomerReviewsSection from './components/CustomerReviewsSection/CustomerReviewsSection';
import HelpSection from './components/HelpSection/HelpSection';
import { LaunchViewContainer } from './components/LaunchViewForm/LaunchViewForm.styled';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';

const LaunchView = () => {
  const dispatch = useDispatch();

  const { matchedMedia } = useSelector((state: RootState) => state);
  const { marketHasTrustpilot } = useOnboardingMarketConfig();

  useEffect(() => {
    dispatch(
      pushToGtmOnboardingAction({
        stepStatus: StepStatus.Started,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const script = document.createElement('script');
    document.body.appendChild(script);
    script.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
  }, []);

  return (
    <LaunchViewContainer>
      <OnboardingNavbar absolutePosition showLogo isLaunchView />
      <LaunchViewTopWrapper>
        <Slideshow />
        {!matchedMedia.desktop && marketHasTrustpilot && <TrustpilotWidget />}
        <LaunchViewForm />
      </LaunchViewTopWrapper>
      {matchedMedia.desktop && marketHasTrustpilot && <TrustpilotWidget />}
      <ApplicationDescriptionSection />
      <CustomerReviewsSection />
      <HelpSection />
    </LaunchViewContainer>
  );
};

export default LaunchView;
