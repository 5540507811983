import { z } from 'zod';
import { ApplicationInfoLevel, ClientType } from '~/enums';
import { klarnaAccountSchema } from './KlarnaKosma';
import { CompanyTypeCategory } from '~/interfaces/Onboarding';

const loanOnboardingApplicationPatchApplicationResDTOSchema = z.strictObject({
  message: z.string(),
  companyTypeCategory: z.nativeEnum(CompanyTypeCategory).optional(),
  userCode: z.string().optional(),
  infoLevel: z.nativeEnum(ApplicationInfoLevel),
});

const loanOnboardingApplicationPostApplicationResDTOSchema = z.strictObject({
  accessToken: z.string().optional(),
  applicationId: z.number(),
  applicationUuid: z.string(),
  clientId: z.number().optional(),
  message: z.string(),
  qCode: z.string(),
  userCode: z.string().optional(),
  infoLevel: z.nativeEnum(ApplicationInfoLevel),
});

const addressFieldsSchema = z.strictObject({
  city: z.string().min(1).optional(),
  houseNumber: z.string().min(1).optional(),
  houseNumberAddition: z.string().optional(),
  streetName: z.string().min(1).optional(),
  zipCode: z.string().min(1).optional(),
});

const loanOnboardingApplicationGetApplicationResDTOSchema = z.strictObject({
  amount: z.number(),
  applicant: z
    .object({
      firstName: z.string().optional(),
      lastName: z.string().optional(),
      address: z
        .object({
          city: z.string(),
          street: z.string(), // streetName + houseNumber + houseNumberAddition
          zipCode: z.string(),
        })
        .optional(),
      addressFields: addressFieldsSchema.optional(),
      dateOfBirth: z.string().optional(),
      email: z.string().optional(),
      personalNumber: z.string().optional(),
      phone: z.string().optional(),
    })
    .optional(),
  applicantAuthenticated: z.boolean(),
  applicantIdentified: z.boolean(),
  applicationId: z.number(),
  applicationUuid: z.string().optional(),
  bankFilesPaths: z.array(z.string()).optional(),
  bankFilesUploaded: z.boolean().optional(),
  bankIntegration: z.boolean(),
  bankConnected: z.boolean().optional(),
  choseToBoostApplication: z.boolean().optional(),
  clientId: z.number().optional(),
  clientIp: z.string(),
  clientType: z.nativeEnum(ClientType).optional(),
  completedStep: z.number().optional(),
  createdAt: z.string(),
  currentStep: z.number(),
  finalized: z.boolean(),
  gdprConsented: z.boolean(),
  guarantors: z
    .array(
      z.strictObject({
        guarantorId: z.string(),
        fullName: z.string().optional(),
        email: z.string().optional().nullable(),
        phone: z.string().optional().nullable(),
        latestGuarantor: z.boolean().optional(),
        maskedPersonalNumber: z.string().optional(),
        maskedDateOfBirth: z.string().optional(),
        fullAddress: z.string().optional(), // streetName + houseNumber + houseNumberAddition + city + zipCode
        addressFields: addressFieldsSchema.optional(),
        personalNumber: z.string().optional(),
        dateOfBirth: z.string().optional(),
        personalNumberManuallyAdded: z.boolean().optional(),
        dateOfBirthManuallyAdded: z.boolean().optional(),
        manuallyAdded: z.boolean().optional(),
        isChecked: z.boolean().optional(),
        hasDateOfBirthFromReport: z.boolean().optional(),
        hasAddressFromReport: z.boolean().optional(),
      })
    )
    .optional(),
  infoLevel: z.nativeEnum(ApplicationInfoLevel),
  landingUrl: z.string().optional(),
  reason: z.string().optional(),
  market: z.string(),
  organization: z.strictObject({
    email: z.string().optional(),
    organizationNumber: z.string().optional(),
    companyType: z.string().optional(),
    companyName: z.string().optional(),
    connectId: z.string().optional(),
    vatNumber: z.string().optional(),
    companyAddress: z
      .object({
        city: z.string(),
        zipCode: z.string(),
        street: z.string(),
      })
      .optional(),
  }),
  purposeOfLoan: z.string(),
  referenceUrl: z.string().optional(),
  sessionId: z.string(),
  trafficCookie: z.string().optional(),
  turnover: z.string(),
  incomeReceivedInForeignCurrencies: z.boolean().optional(),
  updatedAt: z.string().optional(),
  qCode: z.string().optional(),
  eskatConnected: z.boolean().optional(),
  accounts: z.array(klarnaAccountSchema).optional(),
  beneficialOwners: z.array(z.unknown()).optional(),
  alternativeBeneficialOwners: z.array(z.unknown()).optional(),
  companyTypeCategory: z.nativeEnum(CompanyTypeCategory).optional(),
  isCompanyTypeCategoryRequested: z.boolean().optional(),
  userCode: z.string().optional(),
});

const loanOnboardingApplicationGetClientsResDTOSchema = z.strictObject({
  clients: z.array(
    z.strictObject({
      active: z.boolean(),
      application_uuid: z.string().optional().nullable(),
      client_id: z.number(),
      company_name: z.string().nullable(),
      company_no: z.string().nullable(),
      company_type: z.string().nullable(),
      email: z.string().nullable(),
      has_inprogress_application: z.boolean().optional(),
      has_offer_to_sign: z.boolean().optional(),
      phone: z.string().nullable(),
      personal_id: z.string().nullable().optional(),
      connect_id: z.string().nullable().optional(),
      company_type_category: z.nativeEnum(CompanyTypeCategory).optional(),
      vat_number: z.string().nullable().optional(),
      city: z.string().nullable().optional(),
      street: z.string().nullable().optional(),
      zip_code: z.string().nullable().optional(),
    })
  ),
});

const loanOnboardingApplicationGetGuarantorsAndBoardMembersResDTOSchema = z.strictObject(
  {
    guarantorId: z.string(),
    fullName: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional().nullable(),
    phone: z.string().optional().nullable(),
    latestGuarantor: z.boolean().optional(),
    maskedPersonalNumber: z.string().optional(),
    maskedDateOfBirth: z.string().optional(),
    hasDateOfBirthFromReport: z.boolean().optional(),
    hasAddressFromReport: z.boolean().optional(),
    address: z
      .object({
        city: z.string().optional(),
        houseNo: z.string().optional(),
        postCode: z.string().optional(),
        street: z.string().optional(),
      })
      .optional(),
  }
);

const loanOnboardingApplicationPostPatchApplicationReqDTOGuarantorSchema = z.strictObject(
  {
    guarantorId: z.string(),
    fullName: z.string().optional(),
    email: z.string().optional().nullable(),
    phone: z.string().optional().nullable(),
    latestGuarantor: z.boolean().optional(),
    maskedPersonalNumber: z.string().optional(),
    maskedDateOfBirth: z.string().optional(),
    address: z
      .object({
        city: z.string().optional(),
        houseNo: z.string().optional(),
        postCode: z.string().optional(),
        street: z.string().optional(),
      })
      .optional(),
    addressFields: addressFieldsSchema.optional(),
    personalNumber: z.string().optional(),
    dateOfBirth: z.string().optional(),
    personalNumberManuallyAdded: z.boolean().optional(),
    dateOfBirthManuallyAdded: z.boolean().optional(),
    manuallyAdded: z.boolean().optional(),
    isChecked: z.boolean().optional(),
    fullAddress: z.string().optional(), // streetName + houseNumber + houseNumberAddition + city + zipCode
  }
);

const loanOnboardingApplicationPostPatchApplicationReqDTOSchema = z.strictObject(
  {
    applicant: z
      .object({
        firstName: z.string().optional(),
        lastName: z.string().optional(),
        address: z
          .object({
            city: z.string(),
            street: z.string(), // streetName + houseNumber + houseNumberAddition
            zipCode: z.string(),
          })
          .optional(),
        addressFields: addressFieldsSchema.optional(),
        dateOfBirth: z.string().optional(),
        email: z.string().optional(),
        personalNumber: z.string().optional(),
        phone: z.string().optional(),
      })
      .optional(),
    choseToBoostApplication: z.boolean().optional(),
    clientType: z.nativeEnum(ClientType).optional(),
    guarantors: z
      .array(loanOnboardingApplicationPostPatchApplicationReqDTOGuarantorSchema)
      .optional(),
    amount: z.number().min(1),
    applicantAuthenticated: z.boolean(),
    applicantIdentified: z.boolean(),
    applicationUuid: z.string().optional(),
    clientId: z.number().optional(),
    completedStep: z.number().optional(),
    currentStep: z.number().optional(),
    finalized: z.boolean(),
    landingUrl: z.string().optional(),
    loanPurposeManual: z.string().optional(),
    market: z.string(),
    organization: z.strictObject({
      companyType: z.string().optional(),
      organizationNumber: z.string().optional(),
      companyName: z.string().optional(),
      vatNumber: z.string().optional(),
      connectId: z.string().optional(),
      companyAddress: z
        .object({
          city: z.string(),
          zipCode: z.string(),
          street: z.string(),
        })
        .optional(),
    }),
    purposeOfLoan: z.string(),
    referenceUrl: z.string().optional(),
    sessionId: z.string(),
    trafficCookie: z.string().optional(),
    turnover: z.string(),
    qCode: z.string().optional(),
    eskatConnected: z.boolean().optional(),
    incomeReceivedInForeignCurrencies: z.boolean().optional(),
    reason: z.string().optional(),
    bankIntegration: z.boolean().optional(),
    bankConnected: z.boolean().optional(),
    applicationId: z.number().optional(),
    bankFilesUploaded: z.boolean().optional(),
    bankFilesPaths: z.array(z.string()).optional(),
    companyTypeCategory: z.nativeEnum(CompanyTypeCategory).optional(),
    isCompanyTypeCategoryRequested: z.boolean().optional(),
    userCode: z.string().optional(),
  }
);

export const loanOnboardingApplicationGetCompanySearchResDTOCompanySchema = z.strictObject(
  {
    companyName: z.string(),
    companyNumber: z.string().optional(),
    companyType: z.string().optional(),
    address: z
      .object({
        city: z.string(),
        postcode: z.string(),
        street: z.string(),
      })
      .optional(),
    companyTypeCategory: z.nativeEnum(CompanyTypeCategory).optional(),
    connectId: z.string().optional(),
    vatNumber: z.string().optional(),
  }
);

const loanOnboardingApplicationGetCompanySearchResDTOSchema = z.array(
  loanOnboardingApplicationGetCompanySearchResDTOCompanySchema
);

export const loanOnboardingApplicationDTOSchemas = {
  patchApplicationRes: loanOnboardingApplicationPatchApplicationResDTOSchema,
  postApplicationRes: loanOnboardingApplicationPostApplicationResDTOSchema,
  getApplicationRes: loanOnboardingApplicationGetApplicationResDTOSchema,
  getClientsRes: loanOnboardingApplicationGetClientsResDTOSchema,
  getGuarantorsAndBoardMembersRes: loanOnboardingApplicationGetGuarantorsAndBoardMembersResDTOSchema,
  postPatchApplicationReq: loanOnboardingApplicationPostPatchApplicationReqDTOSchema,
  postPatchApplicationReqGuarantor: loanOnboardingApplicationPostPatchApplicationReqDTOGuarantorSchema,
  getCompanySearchRes: loanOnboardingApplicationGetCompanySearchResDTOSchema,
};
