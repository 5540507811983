import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import MeansOfSupport from '../../MeansOfSupport/MeansOfSupport';
import { Body, H7, Stack } from '@qred/components-library';

const ApiFailureError = () => {
  const translate = useTranslate();
  const { globalErrorPersistent } = useSelector(
    (state: RootState) => state.globalError
  );

  return (
    <Stack>
      <Stack align="center">
        <H7>{translate('ApiFailureError.ErrorMessageTitle')}</H7>
        <Body size="lg">{translate('ApiFailureError.ErrorMessageText')}</Body>
      </Stack>
      <MeansOfSupport closePopupsOnChatOpen={!globalErrorPersistent} />
    </Stack>
  );
};

export default ApiFailureError;
