import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import Stack from '~/components/shared/Layout/Stack';
import { digitValidator, zipCodeValidator } from '~/helpers/validators.helper';
import { logToSentry } from '~/helpers/loggers.helper';
import { apiGetAddress } from '~/services/api/onboarding';
import { formatZipCodeNL, removeWhitespace } from '~/helpers/formatters.helper';
import { houseNumberAdditionsDropdownValuesHelperV2 } from '~/helpers/onboarding.helper';
import { Group, Loader, Select, TextInput } from '@qred/components-library';
import { useAuth0 } from '@auth0/auth0-react';
import { Controller, useFormContext } from 'react-hook-form';
import debounce from 'lodash.debounce';
import { onChangeInputEvent } from '~/types/types';
import { IOnboardingAddressFields } from '~/interfaces/Onboarding';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';

interface AddressProps {
  trackFieldChange: (fieldName: string) => void;
}

const Address = ({ trackFieldChange }: AddressProps) => {
  const [houseNumberAdditions, setHouseNumberAdditions] = useState<string[]>(
    []
  );
  const [addressApiStatus, setAddressApiStatus] = useState(ApiStatus.Idle);

  const t = useTranslate();
  const {
    intl: { market },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);
  const { isAuthenticated } = useAuth0();
  const { register, setValue, formState, getValues, control } = useFormContext<{
    address: IOnboardingAddressFields;
  }>();

  const fetchAddress = async () => {
    const zipCode = getValues('address.zipCode');
    const houseNumber = getValues('address.houseNumber');

    setAddressApiStatus(ApiStatus.Started);
    try {
      const addressData = await apiGetAddress(
        zipCode && removeWhitespace(zipCode),
        houseNumber,
        isAuthenticated,
        market
      );
      const houseNumberAdditionResponseData = houseNumberAdditionsDropdownValuesHelperV2(
        addressData.houseNumberAdditions
      );

      setHouseNumberAdditions(houseNumberAdditionResponseData);
      setValue('address.city', addressData.city);
      setValue('address.streetName', addressData.street);
      setAddressApiStatus(ApiStatus.Completed);
    } catch (err: any) {
      if (err?.response?.status !== 404) {
        logToSentry(err, 'apiGetAddress');
      }
      setAddressApiStatus(ApiStatus.Failed);
    }
  };

  const debouncedSearch = useRef(debounce(fetchAddress, 500)).current;

  useEffect(() => {
    const initialZipCode = getValues('address.zipCode');
    const initialHouseNumber = getValues('address.houseNumber');
    if (initialZipCode && initialHouseNumber) {
      fetchAddress();
    }
  }, []);

  useEffect(
    () => () => {
      debouncedSearch.cancel();
    },
    [debouncedSearch]
  );

  const resetApiData = () => {
    setAddressApiStatus(ApiStatus.Idle);
    setHouseNumberAdditions([]);
    setValue('address.city', '');
    setValue('address.streetName', '');
    setValue('address.houseNumberAddition', '');
  };

  const onZipOrStreetChange = () => {
    resetApiData();
    const validZipCode = !zipCodeValidator(getValues('address.zipCode'));
    const validHouseNumber = !digitValidator(getValues('address.houseNumber'));

    if (validZipCode && validHouseNumber) {
      debouncedSearch();
    } else {
      debouncedSearch.cancel();
    }
  };

  const streetName = register('address.streetName', { required: true });
  const city = register('address.city', { required: true });

  return (
    <>
      <Stack spacing="sm">
        <Group justify={mobile ? 'space-between' : ''}>
          <TextInput
            dataCy="address_input_zip_code"
            label={t('AddressFields.PostCode')}
            placeholder="XXXX GE"
            {...register('address.zipCode', {
              required: t('ValidationErrors.Required') as string,
              validate: (value) =>
                !zipCodeValidator(value) ||
                (t('ValidationErrors.Zip') as string),
              onBlur: trackFieldChange,
              onChange: (e: onChangeInputEvent) => {
                const newVal = e.target.value;
                const valueIsValid = !zipCodeValidator(newVal.trim());
                setValue(
                  'address.zipCode',
                  valueIsValid ? formatZipCodeNL(newVal) : newVal
                );
                onZipOrStreetChange();
              },
              deps: ['address.streetName', 'address.city'],
            })}
            error={formState.errors.address?.zipCode?.message}
          />
          <TextInput
            dataCy="address_input_house_number"
            label={t('AddressFields.HouseNumber')}
            inputMode="numeric"
            placeholder="XXX"
            {...register('address.houseNumber', {
              required: t('ValidationErrors.Required') as string,
              validate: (value) =>
                !digitValidator(value) ||
                (t('ValidationErrors.HouseNumber') as string),
              onBlur: trackFieldChange,
              onChange: (e: onChangeInputEvent) => {
                setValue('address.houseNumber', e.target.value);
                onZipOrStreetChange();
              },
              deps: ['address.streetName', 'address.city'],
            })}
            error={formState.errors.address?.houseNumber?.message}
          />
        </Group>
        {addressApiStatus === ApiStatus.Started && <Loader />}

        {addressApiStatus === ApiStatus.Failed && (
          <ValidationErrorMessage>
            {t('ValidationErrors.NoAddressFound')}
          </ValidationErrorMessage>
        )}
        {addressApiStatus === ApiStatus.Completed && (
          <>
            {!!houseNumberAdditions.length && (
              <Controller
                name="address.houseNumberAddition"
                control={control}
                rules={{ required: t('ValidationErrors.Required') as string }}
                render={({ field, fieldState }) => (
                  <Select
                    label={t('AddressFields.HouseNumberAddition')}
                    onChange={(value) => {
                      trackFieldChange(field.name);
                      field.onChange(value?.value || '');
                    }}
                    options={houseNumberAdditions.map(
                      (houseNumberAddition) => ({
                        label:
                          houseNumberAddition === 'No additional house number'
                            ? (t(
                                'AddressFields.NoAdditionalHouseNumber'
                              ) as string)
                            : houseNumberAddition,
                        value: houseNumberAddition,
                      })
                    )}
                    value={
                      field.value
                        ? {
                            value: field.value,
                            label:
                              field.value === 'No additional house number'
                                ? (t(
                                    'AddressFields.NoAdditionalHouseNumber'
                                  ) as string)
                                : field.value,
                          }
                        : undefined
                    }
                    error={fieldState.error?.message}
                  />
                )}
              />
            )}
            <TextInput
              name={streetName.name}
              dataCy="personal_information_step_street"
              label={t('AddressFields.Street')}
              readOnly
              ref={streetName.ref}
            />
            <TextInput
              name={city.name}
              dataCy="personal_information_step_city"
              label={t('AddressFields.City')}
              readOnly
              ref={city.ref}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default Address;
