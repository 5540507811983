import React from 'react';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import constVars from '~/constants/constVars';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ModalProvider } from '~/components/shared/Modal/ModalProvider';
import * as Sentry from '@sentry/react';
import { history, store } from '~/store';
import IntlProviderHOC from './components/hoc/IntlProviderHOC';
import Main from './Main';
import auth0Config from '~/helpers/auth0Config';
import PostHogProvider from '~/contexts/PostHogProvider/PostHogProvider';
import posthog from 'posthog-js';
import './react-pdf';
import { getProductFromUrl } from './helpers/loggers.helper';

const EnhancedIntlProvider = IntlProviderHOC(IntlProvider);

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn:
      'https://37cdcd5ee4e94ecb8c748666a244bfd9@o294555.ingest.sentry.io/5523729',
    integrations: [
      new posthog.SentryIntegration(
        posthog,
        'qred',
        import.meta.env.VITE_APP_PUBLIC_SENTRY_PROJECT_ID
      ),
    ],
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_CURRENT_VERSION,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
    normalizeDepth: 10,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'xhr') {
        breadcrumb.data = {
          ...breadcrumb.data,
          response: JSON.parse((hint?.xhr as XMLHttpRequest).response),
        };
      }
      return breadcrumb;
    },
    beforeSend(event) {
      try {
        if (!event.tags?.product) {
          event.tags = {
            ...event.tags,
            // teamtag
            product: getProductFromUrl(),
          };
        }
        return event;
      } catch (error) {
        console.error('Error in beforeSend', error);
        return event;
      }
    },
  });
}

const App = () => {
  const url = new URL(window.location.href);
  const errorParam = url.searchParams.get('error');
  if (errorParam) {
    if (errorParam === 'login_required') {
      localStorage.removeItem('isAuthenticated');
    }
    const isBankConnectionResultPage = location.pathname.includes(
      constVars.ROUTE_BANK_CONNECTION_RESULT
    );

    // we do not want to remove these search params on the bank connection result page
    // because we assume they are coming from open banking and we need them. On all other
    // pages we want to remove them because we assume they are coming from Auth0 and we don't do
    // anything with them.
    if (!isBankConnectionResultPage) {
      url.searchParams.delete('error');
      url.searchParams.delete('error_description');
      url.searchParams.delete('state');
      history.replace({ search: url.search });
    }
  }

  const ssLandingUrl = sessionStorage.getItem('landingUrl');
  if (!ssLandingUrl) {
    sessionStorage.setItem('landingUrl', window.location.href);
  }

  const ssReferrerUrl = sessionStorage.getItem('referrerUrl');
  // To prevent overwriting sessionStorage after redirecting from auth0
  if (!ssReferrerUrl && document.referrer) {
    sessionStorage.setItem('referrerUrl', document.referrer);

    /**
     * This logic is relevant to login page only.
     * We want to move it to the login component, but have not found a way yet.
     * The reason for this logic is to "reset" local storage market values when coming
     * from a new document referrer.
     */
    if (window.location.pathname === `/${constVars.ROUTE_LOGIN}`) {
      localStorage.removeItem('qredMarket');
      localStorage.removeItem('qredLoginLanguage');
    }
  }

  return (
    <PostHogProvider>
      <Auth0Provider {...auth0Config}>
        <Provider store={store}>
          <EnhancedIntlProvider>
            <ModalProvider>
              <Router history={history}>
                <Main />
              </Router>
            </ModalProvider>
          </EnhancedIntlProvider>
        </Provider>
      </Auth0Provider>
    </PostHogProvider>
  );
};

export default App;
export { store, history };
