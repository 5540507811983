import React from 'react';
import { Badge, Group, Radio, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { getOpenBankingAccountNumber } from '~/helpers/onboarding.helper';
import { useGetBankAccountsQuery } from '~/store/apis/endpoints/onboardingApi/onboarding.api';

interface OpenBankingAccountListProps {
  showAddNewAccount: boolean;
  handleAccountSelected: (value: string) => void;
  handleAddNewAccount: () => void;
}

const OpenBankingAccountList = ({
  showAddNewAccount,
  handleAccountSelected,
  handleAddNewAccount,
}: OpenBankingAccountListProps) => {
  const {
    onboardingOffer: {
      overview,
      form: { openBankingAccount },
    },
  } = useSelector((state: RootState) => state);

  const { data: openBankingAccounts } = useGetBankAccountsQuery(
    { clientId: overview.clientId },
    {
      skip: !overview.clientId,
    }
  );

  const translate = useTranslate();

  let radioValue = '';
  if (openBankingAccount) {
    radioValue = getOpenBankingAccountNumber(openBankingAccount);
  } else if (showAddNewAccount) {
    radioValue = 'new';
  }

  const handleSelect = (value: string) => {
    if (value === 'new') {
      handleAddNewAccount();
    } else {
      handleAccountSelected(value);
    }
  };

  return (
    <Stack>
      <Radio.Group
        direction="column"
        onChange={handleSelect}
        value={radioValue}
      >
        {openBankingAccounts?.map((bankAccount) => (
          <Radio
            name="bankAccount"
            key={getOpenBankingAccountNumber(bankAccount)}
            value={getOpenBankingAccountNumber(bankAccount)}
            labelWrapperProps={{ style: { flexGrow: 1 } }}
            label={
              <Group justify="space-between">
                {bankAccount.details ??
                  getOpenBankingAccountNumber(bankAccount)}
                {bankAccount.isNewlyAdded && (
                  <Badge color="Main/Primary/400" size="sm">
                    {translate('OnboardingOffer.BankConnectionNewAccountBadge')}
                  </Badge>
                )}
              </Group>
            }
            hint={
              bankAccount.details && getOpenBankingAccountNumber(bankAccount)
            }
          />
        ))}
        <Radio
          name="bankAccount"
          value="new"
          label={translate(
            'OnboardingOffer.VerifyBankAccountOptionInRadioGroup'
          )}
        />
      </Radio.Group>
    </Stack>
  );
};

export default OpenBankingAccountList;
