import { Badge, BadgeProps, styledPolymorphic } from '@qred/components-library';

export const PromotionalBadge = styledPolymorphic<'div', BadgeProps>(Badge)(
  (_styled) => _styled`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-10%, -50%);
`
);
