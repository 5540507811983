import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { RootState } from '~/store/types/sharedTypes';

export const UploadDocumentsHeader = () => {
  const {
    onboardingApplication: { form },
    klarnaKosma: { transactionFlow },
  } = useSelector((state: RootState) => state);

  const { title, description } = useMemo(() => {
    if (form.bankIntegration || transactionFlow.status === 'inProgress') {
      return {};
    }

    return {
      title: 'Onboarding.ConnectToBankOrUploadDocumentsTitle',
      description: 'Onboarding.ConnectToBankOrUploadDocumentsDescription',
    };
  }, [form.bankIntegration, transactionFlow.status]);

  return <StepHeader title={title} description={description} />;
};
