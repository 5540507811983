import { GetAccountResponseDto } from '~/store/apis/dtos/accounts/accounts.dto';
import { Account, Company } from '~/models/accounts/accounts';
import { isNull } from 'lodash';

export const initSelectedCompany = (companies: Company[]) => {
  const defaultCompany = companies[0];
  const selectedCompanyIdLocalStorage = localStorage.getItem(
    '@qred/selectedCompanyId'
  );

  if (isNull(selectedCompanyIdLocalStorage)) {
    return defaultCompany;
  }

  for (const company of companies) {
    if (company.id === +selectedCompanyIdLocalStorage) {
      return company;
    }
  }

  return defaultCompany;
};

export const getAccountMapper = (response: GetAccountResponseDto): Account => {
  return {
    user: {
      id: response.user.id,
      email: response.user.email,
      personalNumber: response.user.personalNumber,
      phone: response.user.phone,
      firstName: response.user.firstName,
      lastName: response.user.lastName,
      address: {
        street: response.user.address.street,
        city: response.user.address.city,
        country: response.user.address.country,
        zip: response.user.address.zip,
      },
    },
    companies: response.companies.map((company) => ({
      id: company.id,
      name: company.name,
      number: company.number,
      connectId: company.connectId,
      bankAccountNumber: company.bankAccountNumber,
    })),
    selectedCompany: initSelectedCompany(response.companies),
  };
};

export const fileExtensionsMapper = (response: string[]): string => {
  const fileExtensions = response.map((extension) => extension).join(', ');
  return fileExtensions;
};
