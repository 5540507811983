import {
  Body,
  Group,
  H4,
  H6,
  Label,
  Stack,
  useWindowProperties,
} from '@qred/components-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import constVars from '~/constants/constVars';
import { formatAmount } from '~/helpers/formatters.helper';
import { getFullName } from '~/helpers/onboarding.helper';
import useDateFormatter from '~/hooks/useDateFormatter';
import useTranslate from '~/hooks/useTranslate';
import { fetchAdditionalSignerLoanApplication } from '~/store/actions/additionalSignerActions';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import LoanAdditionalSignerFooter from './LoanAdditionalSignerFooter';

const LoanAdditionalSigner = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    intl: { market, languageIsSet },
    additionalSigner: { additionalSignerLoanApplicationOffer, apiStatus },
  } = useSelector((state: RootState) => state);

  const { isMobile } = useWindowProperties();
  const formatDate = useDateFormatter();
  const translate = useTranslate();

  useEffect(() => {
    dispatch(fetchAdditionalSignerLoanApplication(market));
  }, []);

  useEffect(() => {
    if (apiStatus.fetchAdditionalSignerLoanApplication === ApiStatus.Failed) {
      history.push(constVars.ROUTE_SIGNER_ERROR);
    }
  }, [apiStatus.fetchAdditionalSignerLoanApplication]);

  const loanDetailsTypographyAndValue = [
    {
      typography: translate('LoanAmount') as string,
      value: formatAmount(Number(additionalSignerLoanApplicationOffer.amount)),
    },
    {
      typography: translate('MonthlyFee') as string,
      value: formatAmount(
        Number(additionalSignerLoanApplicationOffer.monthly_fee)
      ),
    },
    {
      typography: translate('LoanPeriod') as string,
      value: `${additionalSignerLoanApplicationOffer.term}  ${translate(
        'Months'
      )}`,
    },
    {
      typography: translate('DueDate') as string,
      value: additionalSignerLoanApplicationOffer
        ? formatDate(additionalSignerLoanApplicationOffer.payment_due, {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })
        : '',
    },
  ];

  const loanBorrowerDetailsTypographyAndValue = [
    {
      typography: translate('LoanRecipient') as string,
      value: additionalSignerLoanApplicationOffer.company_name,
    },
    {
      typography: translate('OrganisationNumber') as string,
      value: additionalSignerLoanApplicationOffer.company_no,
    },
    {
      typography: translate('CompanyRepresentative') as string,
      value: getFullName(
        additionalSignerLoanApplicationOffer.first_name || '',
        additionalSignerLoanApplicationOffer.last_name || ''
      ),
    },
    {
      typography: translate('RecipientBankAccount') as string,
      value: additionalSignerLoanApplicationOffer.bank_account,
    },
  ];

  return apiStatus.fetchAdditionalSignerLoanApplication !== ApiStatus.Started &&
    languageIsSet ? (
    <>
      <Stack p={'xxl'} spacing={'xxl'}>
        <Stack spacing={'sm'}>
          <H4>{translate('VerifyCreditAgreementsCard.Title')}</H4>
          <Body size="lg">
            {translate('VerifyCreditAgreementsCard.Text1', {
              companyName: additionalSignerLoanApplicationOffer.company_name,
              amount: (
                <Body size="lg" weight={600}>
                  {formatAmount(
                    Number(additionalSignerLoanApplicationOffer.amount)
                  )}
                </Body>
              ),
            })}{' '}
            {translate('VerifyCreditAgreementsCard.Text2')}
          </Body>
        </Stack>

        <Stack spacing={'sm'}>
          <H6>{translate('LoanDetailsCard_signer.Title')}</H6>
          {loanDetailsTypographyAndValue.map((loanDetail) => (
            <Group
              key={loanDetail.typography}
              justify="space-between"
              direction={isMobile ? 'column' : 'row'}
              spacing={'xs'}
            >
              <Body size="lg">{loanDetail.typography}</Body>
              <Label size="lg">{loanDetail.value}</Label>
            </Group>
          ))}
        </Stack>

        <Stack spacing={'sm'}>
          <H6>{translate('RecipientDetailsCard.Title')}</H6>
          {loanBorrowerDetailsTypographyAndValue.map((loanBorrowerDetail) => (
            <Group
              key={loanBorrowerDetail.typography}
              justify="space-between"
              direction={isMobile ? 'column' : 'row'}
              spacing={'xs'}
            >
              <Body size="lg">{loanBorrowerDetail.typography}</Body>
              <Label size="lg">{loanBorrowerDetail.value}</Label>
            </Group>
          ))}
        </Stack>
        <Stack spacing={'sm'}>
          <H6>{translate('LoanSummeryCard.Title')}</H6>
          <Body size="lg">
            {translate('LoanSummeryCard.Text', {
              loanPeriod: (
                <Body weight={600}>
                  {additionalSignerLoanApplicationOffer.term}
                </Body>
              ),
            })}
          </Body>
        </Stack>
      </Stack>
      <LoanAdditionalSignerFooter />
    </>
  ) : (
    <SpinnerCircular />
  );
};

export default LoanAdditionalSigner;
