import { Group } from '@qred/components-library';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VerificationInput from 'react-verification-input';
import { OTPErrorType } from '~/enums';
import { setOTP, setOTPError } from '~/store/slices/additionalSigner.slice';
import { RootState } from '~/store/types/sharedTypes';

const AdditionalSignerOTPInput = () => {
  const {
    additionalSigner: { otpError, otp },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const onChange = (value: string) => {
    if (otpError === OTPErrorType.INVALID_OTP) {
      dispatch(setOTPError(null));
    }
    dispatch(setOTP(value));
  };

  return (
    <Group>
      <VerificationInput
        placeholder=""
        inputProps={{
          autoComplete: 'one-time-code',
          outline: 'none',
          pointerEvents: 'none',
        }}
        containerProps={{ outline: 'none', pointerEvents: 'fill' }}
        autoFocus
        validChars="0-9"
        classNames={{
          character:
            otpError === OTPErrorType.INVALID_OTP
              ? 'verification-character--error'
              : 'verification-character',
          characterSelected:
            otpError === OTPErrorType.INVALID_OTP
              ? 'verification-character--selected--error'
              : 'verification-character--selected',
          container: 'container',
        }}
        onChange={onChange}
        value={otp}
      />
    </Group>
  );
};

export default AdditionalSignerOTPInput;
