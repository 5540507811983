import { baseQuery } from '../../baseQuery/baseQuery';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { getMarketBasedOnHostname } from '~/helpers/utils';
import {
  IOpenBankingAccount,
  IOpenBankingAccountBBAN,
  IOpenBankingAccountIBAN,
} from '~/interfaces/Onboarding';
import { CountryCode } from '@qred/shared-enums';
const market = getMarketBasedOnHostname();
type GetBankAccountsResultTypeDTO = {
  iban: string | null;
  bban: string | null;
  name: string | null;
  details: string | null;
}[];

type GetBankAccountsResultType = IOpenBankingAccount[];
interface GetBankAccountsQueryArg {
  clientId: number;
}

export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: baseQuery(
    `${import.meta.env.VITE_API_SVC_ONBOARDING_BASE_URL}/country/${market}`
  ),
  endpoints: (build) => ({
    getBankAccounts: build.query<
      GetBankAccountsResultType,
      GetBankAccountsQueryArg
    >({
      query: ({ clientId }) => ({
        url: `/bank-accounts/${clientId}`,
      }),
      transformResponse: (response: GetBankAccountsResultTypeDTO) => {
        if (market) {
          let accounts;
          switch (market) {
            case CountryCode.SE:
            case CountryCode.DK:
            case CountryCode.NO:
              accounts = response
                .filter((item) => item.bban)
                .map(
                  (item): IOpenBankingAccountBBAN => ({
                    ...item,
                    bban: item.bban!,
                    market,
                  })
                );
              break;
            case CountryCode.FI:
            case CountryCode.BE:
            case CountryCode.NL:
            case CountryCode.DE:
              accounts = response
                .filter((item) => item.iban)
                .map(
                  (item): IOpenBankingAccountIBAN => ({
                    ...item,
                    iban: item.iban!,
                    market,
                  })
                );
              break;
          }
          return accounts;
        }

        throw new Error('Market could not be determined');
      },
      merge: (currentCacheData, responseData) => {
        const existingAccountIds = new Set(
          currentCacheData.map((item) => item.iban ?? item.bban)
        );

        return responseData.map(
          (item): IOpenBankingAccount => ({
            ...item,
            isNewlyAdded: !existingAccountIds.has(item.iban ?? item.bban),
          })
        );
      },
    }),
  }),
});

export const { useGetBankAccountsQuery } = onboardingApi;
