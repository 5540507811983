import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import {
  ApplicationInfoLevel,
  ApplicationStatus,
  ClientType,
  FlowTypes,
} from '~/enums';
import { onboardingApplicationInitialState } from '../initialState';
import { IOnboardingAddressFields } from '~/interfaces/Onboarding';
import {
  ILoanOnboardingApplicationApplicant,
  ILoanOnboardingApplicationForm,
  ILoanOnboardingApplicationOverview,
  ILoanOnboardingApplicationState,
} from '~/interfaces/LoanOnboardingApplication';
import { listenerMiddleware } from '../middlewares/listener.middleware';
import { buildStreetValue } from '~/helpers/onboarding.helper';
import { removeWhitespace } from '~/helpers/formatters.helper';

const onboardingApplicationSlice = createSlice({
  name: 'onboardingApplication',
  initialState: onboardingApplicationInitialState,
  reducers: {
    updateCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    goToNextStep: (state) => {
      state.currentStep += 1;
    },
    goToPreviousStep: (state) => {
      state.currentStep -= 1;
    },
    updateFlowType: (state, action: PayloadAction<FlowTypes>) => {
      state.flowType = action.payload;
    },
    setCompanyIsNew: (state, action: PayloadAction<boolean>) => {
      state.companyIsNew = action.payload;
    },
    updateForm: (
      state,
      action: PayloadAction<Partial<ILoanOnboardingApplicationForm>>
    ) => {
      state.form = { ...state.form, ...action.payload };
    },
    resetCompanyInForm: (state) => {
      state.form.companyName = undefined;
      state.form.companyAddress = undefined;
      state.form.organizationNumber = undefined;
      state.form.vatNumber = undefined;
      state.form.companyTypeCategory = undefined;
      state.form.connectId = undefined;
      state.form.clientType = ClientType.NLC;
    },
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },
    setClientId: (state, action: PayloadAction<number>) => {
      state.clientId = action.payload;
    },
    updateOverview: (
      state,
      action: PayloadAction<ILoanOnboardingApplicationOverview>
    ) => {
      state.overview = { ...state.overview, ...action.payload };
    },
    setApiStatus: (
      state,
      action: PayloadAction<
        Partial<ILoanOnboardingApplicationState['apiStatus']>
      >
    ) => {
      state.apiStatus = {
        ...state.apiStatus,
        ...action.payload,
      };
    },
    setStepStartTimestamp: (state) => {
      state.stepStartTimestamp = new Date().toString();
    },
    updateApplicationStatus: (
      state,
      action: PayloadAction<ApplicationStatus>
    ) => {
      state.applicationStatus = action.payload;
    },
    updateCurrentOrganizationIdentifier: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.currentApplicationOrganizationIdentifier = action.payload || '';
    },
    setIsCompanyTypeCategoryRequested: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCompanyTypeCategoryRequested = action.payload;
    },
    clearOnboardingState: () => ({
      ...onboardingApplicationInitialState,
      cleared: true,
    }),
    removeClearedState: (state) => {
      state.cleared = false;
    },
    updateApplicantData: (
      state,
      action: PayloadAction<ILoanOnboardingApplicationApplicant>
    ) => {
      state.form.applicant = {
        ...state.form.applicant,
        ...action.payload,
      };
    },
    updateApplicantAddress: (
      state,
      action: PayloadAction<IOnboardingAddressFields>
    ) => {
      state.form.applicant.addressFields = {
        ...state.form.applicant.addressFields,
        ...action.payload,
      };

      const defaultStreetValue = state.form.applicant.address?.street ?? '';
      const defaultZipCodeValue = state.form.applicant.address?.zipCode ?? '';
      const defaultCityValue = state.form.applicant.address?.city ?? '';

      state.form.applicant.address = {
        street:
          action.payload.streetName ||
          action.payload.houseNumber ||
          action.payload.houseNumberAddition
            ? buildStreetValue({
                ...state.form.applicant.addressFields,
                ...action.payload,
              })
            : defaultStreetValue,
        zipCode: action.payload.zipCode
          ? removeWhitespace(action.payload.zipCode)
          : defaultZipCodeValue,
        city: action.payload.city ?? defaultCityValue,
      };
    },
    setBankConnectionError: (state, action: PayloadAction<boolean>) => {
      state.bankConnectionError = action.payload;
    },
    setInfoLevel: (state, action: PayloadAction<ApplicationInfoLevel>) => {
      state.infoLevel = action.payload;
    },
  },
});

export const {
  updateCurrentStep,
  goToNextStep,
  goToPreviousStep,
  updateFlowType,
  setCompanyIsNew,
  updateForm,
  resetCompanyInForm,
  setUserId,
  setClientId,
  updateOverview,
  setApiStatus,
  setStepStartTimestamp,
  updateApplicationStatus,
  updateCurrentOrganizationIdentifier,
  setIsCompanyTypeCategoryRequested,
  clearOnboardingState,
  removeClearedState,
  updateApplicantData,
  updateApplicantAddress,
  setBankConnectionError,
  setInfoLevel,
} = onboardingApplicationSlice.actions;

export default onboardingApplicationSlice.reducer;

listenerMiddleware.startListening({
  matcher: isAnyOf(updateCurrentStep, goToNextStep, goToPreviousStep),
  effect: async (_action, listenerApi) => {
    listenerApi.dispatch(setStepStartTimestamp());
  },
});
