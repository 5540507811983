import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepStatus } from '~/enums';
import { RootState } from '~/store';

import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { patchApplication } from '~/store/actions/onboardingActions';
import { useLoanOnboardingApplicationSelectors } from '~/store/selectors/loanOnboardingApplication.selector';
import {
  goToNextStep,
  updateCurrentStep,
} from '~/store/slices/onboardingApplication.slice';

const useGoToNextStep = () => {
  const dispatch = useDispatch();
  const { stepConfig } = useLoanOnboardingApplicationSelectors();
  const { currentStep } = useSelector(
    (state: RootState) => state.onboardingApplication
  );

  const onNextStepClick = useCallback(async () => {
    try {
      dispatch(
        pushToGtmOnboardingAction({ actionName: 'next_button_pressed' })
      );
      await dispatch(patchApplication());

      // next step is checkpoint step
      if (stepConfig[currentStep].name === 'checkpoint_step') {
        dispatch(updateCurrentStep(1));
      } else {
        dispatch(goToNextStep());
      }
    } catch (_err: any) {
      dispatch(
        pushToGtmOnboardingAction({
          stepStatus: StepStatus.Error,
          errorMessage: _err.response?.data?.message,
        })
      );
    }
  }, [dispatch, currentStep, stepConfig]);

  return onNextStepClick;
};

export default useGoToNextStep;
