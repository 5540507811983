import {
  Button,
  Divider,
  Group,
  QredLogo,
  Stack,
  useWindowProperties,
} from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import MainMenu from '~/components/shared/Menu/MainMenu';
import ProgressStepperDots from '~/components/shared/ProgressStepperDots/ProgressStepperDots';
import { RootState } from '~/store/types/sharedTypes';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import {
  BackButton,
  CenterGroup,
  LeftGroup,
  OnboardingNavbarContainer,
  RightGroup,
} from './OnboardingNavbar.styled';
import useTranslate from '~/hooks/useTranslate';
import { CountryCode } from '@qred/shared-enums';
import {
  onboardingNavbarDividerThickness,
  onboardingNavbarHeight,
} from '~/styles/index.css';
import { StepConfigItem } from '~/Interfaces';

interface OnboardingNavbarProps {
  absolutePosition?: boolean;
  fixedPosition?: boolean;
  showBackButton?: boolean;
  onBackClick?: () => void;
  progressStepperDots?: {
    steps: Pick<StepConfigItem, 'name' | 'hideInProgressStepper'>[];
    currentStep: number;
  };
  filledBackground?: boolean;
  showLogo?: boolean;
  isLaunchView?: boolean;
}

const OnboardingNavbar = ({
  absolutePosition,
  fixedPosition,
  filledBackground,
  showLogo,
  showBackButton,
  onBackClick,
  progressStepperDots,
  isLaunchView = false,
}: OnboardingNavbarProps) => {
  const {
    intl: { market },
  } = useSelector((state: RootState) => state);

  const { isMobile } = useWindowProperties();
  const t = useTranslate();

  const isQeld = market === CountryCode.NL;

  return isLaunchView ? (
    <OnboardingNavbarContainer
      absolutePosition={absolutePosition}
      fixedPosition={fixedPosition}
      filledBackground={filledBackground}
    >
      <LeftGroup>
        {showLogo && <QredLogo height={40} color="primary.500" qeld={isQeld} />}

        {showBackButton && onBackClick && (
          <BackButton
            data-cy="navbar_back_button"
            data-testid="navbar_back_button"
            onClick={onBackClick}
          >
            <Icon
              color={theme.colors.secondaryBlack}
              src={SvgSrc.ChevronLeft}
              size={ComponentSize.Medium}
            />
          </BackButton>
        )}
      </LeftGroup>
      <CenterGroup>
        {progressStepperDots && (
          <ProgressStepperDots
            steps={progressStepperDots.steps}
            currentStep={progressStepperDots.currentStep}
          />
        )}
      </CenterGroup>
      <RightGroup>
        <MainMenu showText={!isMobile} />
      </RightGroup>
    </OnboardingNavbarContainer>
  ) : (
    <Stack
      spacing={0}
      w="100%"
      h={onboardingNavbarHeight}
      justify="center"
      bg={isMobile ? 'textLight.1' : 'transparent'}
    >
      {isMobile && (
        <Divider
          w={'100%'}
          thickness={onboardingNavbarDividerThickness}
          color="Border/Minimal"
        />
      )}
      <Group
        pos="relative"
        align="center"
        justify="center"
        style={{
          flexGrow: 1,
        }}
      >
        <Group pos="absolute" left="8px">
          {showLogo && (
            <QredLogo height={40} color="primary.500" qeld={isQeld} />
          )}
          {showBackButton && onBackClick && (
            <Button
              leftIcon="arrow_back_ios"
              data-cy="navbar_back_button"
              data-testid="navbar_back_button"
              onClick={onBackClick}
              variant="tertiary"
            >
              {!isMobile && t('Back')}
            </Button>
          )}
        </Group>
        {progressStepperDots && (
          <ProgressStepperDots
            steps={progressStepperDots.steps}
            currentStep={progressStepperDots.currentStep}
          />
        )}
      </Group>
    </Stack>
  );
};

export default OnboardingNavbar;
