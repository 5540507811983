import axios from 'axios';
import { Language } from '~/store/types/sharedTypes';
import { normalizeOrgNumberHelper } from '~/helpers/normalizeOrgNumber.helper';
import { logToSentry } from '~/helpers/loggers.helper';
import { onboardingDTOSchemas } from '~/schemas/DTO/Onboarding';
import { TOnboardingDTO } from '~/types/DTO/onboarding';
import { CountryCode } from '~/enums';

export const apiInitKlarnaKosmaTransactions = (
  countryCode: CountryCode,
  language: Language,
  clientId: number
) => {
  const languageParam = (language === Language.DutchBE
    ? Language.Dutch
    : language
  ).toLowerCase();
  return axios.post(
    `${
      import.meta.env.VITE_API_KOSMA_BASE_URL
    }/country/${countryCode.toLowerCase()}/accounts/initflow?language=${languageParam}&clientId=${clientId}`,
    {}
  );
};

export const apiStoreKlarnaKosmaTransactions = (
  countryCode: CountryCode,
  sessionId: string,
  flowId: string,
  companyNumber: string,
  clientId: number,
  applicationUuid?: string
) => {
  const params = new URLSearchParams([
    ['sessionId', sessionId],
    ['flowId', flowId],
    ['clientId', clientId.toString()],
  ]);
  if (applicationUuid) {
    params.append('applicationUUID', applicationUuid);
  }

  return axios.put(
    `${
      import.meta.env.VITE_API_KOSMA_BASE_URL
    }/country/${countryCode}/companyNumber/${normalizeOrgNumberHelper(
      companyNumber,
      countryCode
    )}/transactions?${params.toString()}`,
    {}
  );
};

export const apiGetKlarnaKosmaAccountsInfo = async (
  countryCode: CountryCode,
  sessionId: string,
  flowId: string,
  applicationUuid: string
) => {
  const params = new URLSearchParams([
    ['sessionId', sessionId],
    ['flowId', flowId],
    ['applicationUUID', applicationUuid],
  ]);

  const response = await axios.get<
    TOnboardingDTO['getKlarnaKosmaAccountsInfoRes']
  >(
    `${
      import.meta.env.VITE_API_KOSMA_BASE_URL
    }/country/${countryCode}/accountInfo?${params.toString()}`
  );

  const parseResponseResult = onboardingDTOSchemas.getKlarnaKosmaAccountsInfoRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetKlarnaKosmaAccountsInfo parseResponseResult'
    );
  }
  return response.data;
};
