import React, { useContext } from 'react';
import { FormStatus, ValidationContext } from '~/components/hoc/withValidation';
import useTranslate from '~/hooks/useTranslate';
import {
  Button,
  ButtonProps,
  useWindowProperties,
} from '@qred/components-library';

interface NextButtonProps extends ButtonProps {
  label: string;
  action: () => void;
}

const NextButton = ({ label, action, ...rest }: NextButtonProps) => {
  const t = useTranslate();
  const validationContext = useContext(ValidationContext);
  const { isMobile } = useWindowProperties();

  const handleNextStepClick = () => {
    validationContext.setFormStatus(FormStatus.SUBMITTING);
    action();
  };

  return (
    <Button
      dataCy="onboarding_next_or_submit_button"
      onClick={handleNextStepClick}
      disabled={!validationContext.isFormValid}
      size="md"
      fullWidth={isMobile}
      compact
      {...rest}
    >
      {t(label)}
    </Button>
  );
};

export default NextButton;
