import React, { useCallback } from 'react';
import {
  Divider,
  Navbar,
  QredLogo,
  Stack,
  useWindowProperties,
} from '@qred/components-library';
import { useLocation, useHistory } from 'react-router-dom';
import useLogout from '~/hooks/useLogout';
import { openBecomeTesterPage, openQredCardPage } from '~/services/links';
import useTranslate from '~/hooks/useTranslate';
import { SwitchCompanyButton } from './SwitchCompanyButton';
import { LanguageItemSideBar } from './LanguageItemSideBar';
import { CountryCode } from '~/enums';
import constVars from '~/constants/constVars';
import { posthogActionNames } from '~/constants/posthogActionNames';
import { usePostHogEvents } from '~/hooks/usePostHogEvents';
import { useAccount } from '~/hooks/useAccount';
import { useSepaDirectDebit } from '~/hooks/useSepaDirectDebit';
const SideBar = () => {
  const { language, market, companies } = useAccount();
  const { isMobile, isTablet } = useWindowProperties();
  const { capture } = usePostHogEvents();
  const tabletSpacing = isTablet ? 25 : 28;
  const { showPaymentTab } = useSepaDirectDebit();

  const showQredCard = market === CountryCode.SE || market === CountryCode.FI;

  const isQeld = market === CountryCode.NL;

  const t = useTranslate();
  const logout = useLogout();
  const { pathname, search } = useLocation();
  const history = useHistory();

  const handleLogout = () => {
    logout();
    capture({
      action: posthogActionNames.sidebar.LOG_OUT,
      sidebar: true,
    });
  };
  const onOpenPage = useCallback(
    (name: string) => {
      switch (name) {
        case 'home':
          history.push('/');
          break;
        case 'qred-visa':
          openQredCardPage(market, language);
          break;
        case 'become-a-tester':
          openBecomeTesterPage(market, language);
          break;
        default:
          break;
      }
    },
    [market, language]
  );

  const handleOnClickBecomeTester = () => {
    onOpenPage('become-a-tester');
    capture({
      action: posthogActionNames.sidebar.BECOME_A_TESTER,
      sidebar: true,
    });
  };

  const handleOnClickQredVisa = () => {
    onOpenPage('qred-visa');
    capture({
      action: posthogActionNames.sidebar.QRED_VISA,
      sidebar: true,
    });
  };
  const queryParams = new URLSearchParams(search);
  const isProfileTab = queryParams.get('tab') === 'profile';
  const isPaymentTab = queryParams.get('tab') === 'payment';

  const mobileNavBarSection = () => {
    return (
      <>
        <Navbar.Section grow>
          <Navbar.Title>{t('Account.NavTitleAccount')}</Navbar.Title>
          <Navbar.Link
            leftIcon="Edit"
            onClick={() =>
              history.push({
                pathname: `/${constVars.ROUTE_ACCOUNT}`,
                search: '?tab=profile',
              })
            }
            active={pathname === `/${constVars.ROUTE_ACCOUNT}` && isProfileTab}
          >
            {t('Account.NavLinkAccountDetails')}
          </Navbar.Link>
          {showPaymentTab && (
            <Navbar.Link
              leftIcon="credit_card"
              onClick={() =>
                history.push({
                  pathname: `/${constVars.ROUTE_ACCOUNT}`,
                  search: '?tab=payment',
                })
              }
              active={
                pathname === `/${constVars.ROUTE_ACCOUNT}` && isPaymentTab
              }
            >
              {t('Account.NavLinkPaymentOptions')}
            </Navbar.Link>
          )}
          <Navbar.Link
            leftIcon="support"
            onClick={() => history.push(`/${constVars.ROUTE_HELP_AND_SUPPORT}`)}
            active={pathname === `/${constVars.ROUTE_HELP_AND_SUPPORT}`}
          >
            {t('HelpAndSupport.HeaderTitle')}
          </Navbar.Link>
        </Navbar.Section>
        <Navbar.Section grow>
          {showQredCard && (
            <>
              <Navbar.Title>{t('Dashboard.Explore')}</Navbar.Title>
              <Navbar.Link
                onClick={handleOnClickQredVisa}
                rightIcon="open_in_new"
              >
                {t('QredCard')}
              </Navbar.Link>
            </>
          )}
          <Navbar.Link
            onClick={handleOnClickBecomeTester}
            rightIcon="open_in_new"
          >
            {t('Dashboard.BecomeATester')}
          </Navbar.Link>
        </Navbar.Section>
        <Navbar.Section mb="xl">
          {companies.length > 1 && <SwitchCompanyButton />}
          <Divider color="Main/Grey/Alpha/OnLight/20%" my="1em" />
          <Navbar.Link leftIcon="logout" onClick={handleLogout}>
            {t('Logout')}
          </Navbar.Link>
        </Navbar.Section>
      </>
    );
  };
  const nonMobileNavBarSection = () => {
    return (
      <>
        <Navbar.Section>
          {companies.length > 1 && <SwitchCompanyButton />}
        </Navbar.Section>
        <Divider color="neutral.16" my="1.5em" />
        <Navbar.Section pb="1.73em">
          {showQredCard && (
            <Navbar.Link
              onClick={handleOnClickQredVisa}
              rightIcon="open_in_new"
              rightIconProps={{ color: 'Icon/Default' }}
            >
              {t('QredCard')}
            </Navbar.Link>
          )}
          <Navbar.Link
            onClick={handleOnClickBecomeTester}
            rightIcon="open_in_new"
            rightIconProps={{ color: 'Icon/Default' }}
          >
            {t('Dashboard.BecomeATester')}
          </Navbar.Link>
        </Navbar.Section>
      </>
    );
  };

  return (
    <Navbar
      width={247}
      collapseTooltipLabels={{
        open: `${t('Dashboard.OpenSidebar')}`,
        close: `${t('Dashboard.CloseSidebar')}`,
      }}
    >
      <Navbar.Section pl={32} pt={tabletSpacing} pb={40}>
        <QredLogo width={90} qeld={isQeld} onClick={() => history.push('/')} />
      </Navbar.Section>
      <Navbar.Section grow>
        <Navbar.Section mb="xl">
          {!isQeld && isMobile && (
            <Stack
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                marginRight: 30,
              }}
            >
              <LanguageItemSideBar />
            </Stack>
          )}
        </Navbar.Section>
        <Navbar.Link
          active={pathname === `/`}
          onClick={() => history.push(`/`)}
          leftIcon="home"
          leftIconProps={{ filled: pathname === `/`, color: 'Icon/Default' }}
          color="Icon/Default"
        >
          {t('Home')}
        </Navbar.Link>
        <Navbar.Link
          active={pathname === `/${constVars.ROUTE_DOCUMENTS}`}
          onClick={() => history.push(`/${constVars.ROUTE_DOCUMENTS}`)}
          leftIcon="description"
          leftIconProps={{
            filled: pathname === `/${constVars.ROUTE_DOCUMENTS}`,
            color: 'Icon/Default',
          }}
          color="Icon/Default"
        >
          {t('NewDashboard.DocumentsTitle')}
        </Navbar.Link>
        <Navbar.Link
          active={pathname === `/${constVars.ROUTE_INVOICES}`}
          onClick={() => history.push(`/${constVars.ROUTE_INVOICES}`)}
          leftIcon="receipt"
          leftIconProps={{
            filled: pathname === `/${constVars.ROUTE_INVOICES}`,
            color: 'Icon/Default',
          }}
          color="Icon/Default"
        >
          {t('Dashboard.Invoices')}
        </Navbar.Link>
      </Navbar.Section>
      {isMobile ? mobileNavBarSection() : nonMobileNavBarSection()}
    </Navbar>
  );
};

export default SideBar;
