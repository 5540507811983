import React from 'react';
import {
  Stack,
  H4,
  Body,
  useWindowProperties,
  Overline,
} from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { StepperHeaderProps } from '~/Interfaces';

interface StepHeaderProps extends StepperHeaderProps {
  shouldTranslate?: boolean;
}

const StepHeader = ({
  title,
  description,
  overline,
  shouldTranslate = true,
}: StepHeaderProps) => {
  const translate = useTranslate();
  const { isMobile } = useWindowProperties();

  return (
    <>
      {(title || description) && (
        <Stack spacing={isMobile ? 'md' : 'xl'}>
          <Stack spacing="md">
            {!!overline && (
              <Overline data-testid="title-overline" data-record>
                {shouldTranslate ? translate(overline) : overline}
              </Overline>
            )}
            {!!title && (
              <H4 data-testid="title" data-record>
                {shouldTranslate ? translate(title) : title}
              </H4>
            )}
          </Stack>
          {!!description && (
            <>
              {typeof description === 'string' ? (
                <Body size="lg" data-testid="description">
                  {shouldTranslate ? translate(description) : description}
                </Body>
              ) : (
                description
              )}
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default StepHeader;
