import styled, { css } from 'styled-components';
import { MediaQueries } from '~/constants/constVars';
import { theme } from '~/styles/themes';

export const BackButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 0.75em;
  padding: 0;
`;

export const CenterGroup = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const LeftGroup = styled.div`
  display: flex;
  flex: 1;
`;

export const OnboardingNavbarContainer = styled.div<{
  absolutePosition?: boolean;
  fixedPosition?: boolean;
  filledBackground?: boolean;
}>`
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-items: center;
  padding: 2em 3.5em;
  @media ${MediaQueries.smallScreenPortrait} {
    padding: 1.5em;
  }

  ${(props) =>
    props.absolutePosition &&
    css`
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    `}
  ${(props) =>
    props.fixedPosition &&
    css`
      width: 100%;
      top: 0;
      left: 0;
      position: fixed;
      align-items: center;
    `}
  ${(props) =>
    props.filledBackground &&
    css`
      @media ${MediaQueries.smallScreenPortrait} {
        background-color: ${theme.colors.white};
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
      }
    `};
`;

export const RightGroup = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;
`;
