import {
  Container,
  Input,
  Loader,
  MaterialIcon,
  Stack,
} from '@qred/components-library';
import React, { useEffect, useRef } from 'react';
import { PostcodeAddress, usePostcode } from '~/hooks/usePostcode';

interface Props {
  label: React.ReactNode;
  handleResult: (addressResult: PostcodeAddress | null) => void;
  error?: React.ReactNode;
  onBlur?: () => void;
  onChange: (value: string) => void;
  initialValue?: string;
}

export const AddressSearch = (props: Props) => {
  const { label, handleResult, error, onBlur, onChange, initialValue } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { addressResult, autocompleteIsLoading, currentValue } = usePostcode({
    inputElement: inputRef.current,
    menuElement: menuRef.current,
  });

  useEffect(() => {
    handleResult(addressResult);
  }, [addressResult]);

  useEffect(() => {
    if (currentValue) {
      onChange(currentValue);
    }
  }, [currentValue]);

  return (
    <Container pos="relative" w="100%">
      <link rel="stylesheet" href="/autocomplete-address.css" />
      <Stack pos="absolute" right="1em" top="1.15em">
        {autocompleteIsLoading && <Loader size="sm" />}
        {!addressResult && !autocompleteIsLoading && (
          <MaterialIcon src="expand_more" />
        )}
      </Stack>

      <div
        ref={menuRef}
        onMouseDown={(event) => {
          /**
           * event.preventDefault() is needed to prevent the onBlur of the input field to fire when user selects
           * an address from the autocomplete menu.
           */
          event.preventDefault();
        }}
        role="menu"
        tabIndex={-1}
      />
      <Input
        label={label}
        type="text"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={onBlur}
        error={error}
        ref={inputRef}
        defaultValue={initialValue}
      />
    </Container>
  );
};
