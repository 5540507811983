import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Banner,
  Body,
  Label,
  List,
  Stack,
  TextInput,
} from '@qred/components-library';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';
import { ValidationContext } from '~/components/hoc/withValidation';
import { onChangeInputEvent } from '~/types/types';
import { RootState } from '~/store/types/sharedTypes';
import useTranslate from '~/hooks/useTranslate';
import { validateBankAccountNumber } from '~/helpers/validators.helper';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';

const AccountNumberInput = () => {
  const dispatch = useDispatch();
  const {
    onboardingOffer: { form, overview },
    intl: { market },
  } = useSelector((state: RootState) => state);
  const validationContext = useContext(ValidationContext);
  const [isTouched, setIsTouched] = useState(false);
  const translate = useTranslate();
  const { marketOnlyAllowsIban } = useOnboardingMarketConfig();

  const accountNumberIsValid = validateBankAccountNumber(
    form.accountNumber,
    market
  );
  const showError = isTouched && !accountNumberIsValid;

  useEffect(() => {
    if (!accountNumberIsValid) {
      validationContext.addPropertyToValidationErrors('InvalidAccountNumber');
    }

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'InvalidAccountNumber'
      );
    };
  }, [accountNumberIsValid]);

  const onAccountNumberChange = (event: onChangeInputEvent) => {
    let { value } = event.target;

    if (marketOnlyAllowsIban && !value.startsWith(market)) {
      value = `${market}${value.replace(/[^0-9]/g, '')}`;
    }

    dispatch(updateForm({ accountNumber: value, account: {} }));
  };

  return (
    <Stack spacing={'xs'}>
      <TextInput
        value={form.accountNumber || (marketOnlyAllowsIban ? market : '')}
        onChange={onAccountNumberChange}
        onBlur={() => setIsTouched(true)}
        label={translate('OnboardingOffer.BankAccountInputLabel')}
        error={
          showError &&
          (form.accountNumber?.replace(/[^0-9]/g, '').length
            ? translate('ValidationErrors.IncorrectFormat')
            : translate('ValidationErrors.Required'))
        }
        autoFocus={!!overview.bankAccounts.length}
      />

      {showError && (
        <Banner
          state="critical"
          message={
            <Stack spacing={'sm'}>
              <Label size={'lg'} data-record>
                {translate('FormErrorBannerTitle')}
              </Label>
              <List>
                <List.Item>
                  <Body size={'lg'} data-record>
                    {translate('OnboardingOffer.BankAccountInputErrorDetails')}
                  </Body>
                </List.Item>
              </List>
            </Stack>
          }
          opened
          withCloseButton={false}
        />
      )}
    </Stack>
  );
};

export default AccountNumberInput;
