import React, { useState } from 'react';
import {
  MaterialIconProps,
  Menu,
  Label,
  Button,
} from '@qred/components-library';
import { getMarketLanguageLabels } from '~/constants/markets';
import { useAccount } from '~/hooks/useAccount';

export const LanguageItemSideBar = () => {
  const { market, language, setLanguage } = useAccount();

  const [languageSelectorOpened, setLanguageSelectorOpened] = useState(false);
  const languages = getMarketLanguageLabels(market);
  const selectedLanguageInfo = languages.find((l) => l.value === language);

  const languageItems = languages
    .filter(({ value }) => value !== language)
    .map((_language) => (
      <Menu.Item
        key={_language.value}
        onClick={() => setLanguage(_language.value)}
        // TODO: (QIT-UI) ignored until we remove qit-ui as dependency
        // @ts-ignore
        icon={_language.icon}
      >
        {_language.label.short}
      </Menu.Item>
    ));

  return (
    <Menu onChange={setLanguageSelectorOpened} position="bottom-start">
      <Menu.Target>
        <Button
          size="md"
          leftIcon={selectedLanguageInfo?.icon as MaterialIconProps['src']}
          leftIconProps={{ filled: languageSelectorOpened, pr: 3 }}
          rightIcon="expand_more"
          rightIconProps={{ pl: 3 }}
          variant="tertiary"
          color="Icon/Default"
        >
          <Label size="lg">{selectedLanguageInfo?.label.short}</Label>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>{languageItems}</Menu.Dropdown>
    </Menu>
  );
};
