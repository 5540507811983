import React, { useEffect, useState } from 'react';
import OnboardingAppShell from '~/components/shared/OnboardingAppShell/OnboardingAppShell';
import useQueryParams from '~/hooks/useQueryParams';
import BankConnection from '~/components/shared/BankConnection/BankConnection';
import { useHistory } from 'react-router-dom';
import { OnboardingStepsContainer } from '~/styles/OnboardingBaseStyledComponents';
import {
  Body,
  Button,
  Card,
  Container,
  H3,
  H4,
  Loader,
  MaterialIcon,
  Stack,
  useWindowProperties,
} from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { parseLanguage } from '~/helpers/market.helper';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '~/store/slices/intl.slice';
import { RootState } from '~/store';
import { useGetClientDataQuery } from '~/store/apis/endpoints/myPagesUnauthenticatedApi/clientData/clientData.api';
import { BankConnectionResult, GlobalErrorType } from '~/enums';
import { logToSentry } from '~/helpers/loggers.helper';
import { toggleGlobalErrorOn } from '~/store/slices/globalError.slice';

const BANK_CONNECTION_RESULT_QUERY_STRING_KEY = 'bank_connection_result';

const BankConnectionStandalone = () => {
  const { market, languageIsSet } = useSelector(
    (state: RootState) => state.intl
  );
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const qCodeParam = queryParams.get('qcode') ?? '';
  const bankConnectionResultParam = queryParams.get(
    BANK_CONNECTION_RESULT_QUERY_STRING_KEY
  );
  const languageParam = queryParams.get('language');
  const history = useHistory();
  const { isMobile } = useWindowProperties();
  const translate = useTranslate();
  const [error, setError] = useState<boolean>(
    bankConnectionResultParam === BankConnectionResult.Error ||
      bankConnectionResultParam === BankConnectionResult.Cancelled
  );
  const [connectedSuccessfully] = useState<boolean>(
    bankConnectionResultParam === BankConnectionResult.Success
  );
  const parsedLanguageParam = parseLanguage(languageParam);
  const {
    data: clientData,
    isFetching: isFetchingClientData,
    isError,
  } = useGetClientDataQuery(
    {
      qCode: qCodeParam,
      market,
    },
    {
      skip: connectedSuccessfully || !qCodeParam,
    }
  );

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('language');
    history.replace({ search: currentUrl.search });
    if (parsedLanguageParam) {
      dispatch(setLanguage(parsedLanguageParam));
    }
  }, []);

  useEffect(() => {
    if (!qCodeParam) {
      logToSentry(
        new Error('qCodeParam is required'),
        'Missing qCodeParam in BankConnectionStandalone'
      );
    }

    if (isError || !qCodeParam) {
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    }
  }, [isError]);

  const onCheckApplicationStatusButtonClick = () => {
    history.push('/');
  };

  if (!qCodeParam || isError) {
    return (
      <OnboardingAppShell>
        <></>
      </OnboardingAppShell>
    );
  }

  if (!languageIsSet || isFetchingClientData) {
    return (
      <OnboardingAppShell>
        <Loader
          size="lg"
          pos="absolute"
          top="50%"
          left="50%"
          style={{
            transform: 'translate(-50%, -50%)',
          }}
        />
      </OnboardingAppShell>
    );
  }

  return (
    <OnboardingAppShell>
      <OnboardingStepsContainer>
        <Card
          bg="miscellaneous.white"
          mt={isMobile ? 0 : 60}
          p={0}
          display="flex"
          style={{ overflow: 'auto', flexGrow: 1 }}
        >
          {connectedSuccessfully && (
            <Stack
              spacing={0}
              style={{
                backgroundImage: 'url(/swoosh.png)',
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: '90%',
                backgroundSize: 'contain',
              }}
            >
              <Stack
                spacing="xxl"
                align="center"
                justify="center"
                p="1.5em"
                style={{
                  flexGrow: 0.5,
                }}
              >
                <MaterialIcon
                  src="verified"
                  filled
                  size="48"
                  color="Icon/Success"
                />
                <H3 data-record>
                  {translate(
                    'BankConnectionStandalone.SuccessfullyConnectedTitle'
                  )}
                </H3>
                <Body size="lg" data-record>
                  {translate(
                    'BankConnectionStandalone.SuccessfullyConnectedDescription'
                  )}
                </Body>
                <Container
                  display={'flex'}
                  pos={isMobile ? 'fixed' : 'static'}
                  bottom="0"
                  p={isMobile ? 'lg' : 0}
                  w="100%"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    size="md"
                    onClick={onCheckApplicationStatusButtonClick}
                    fullWidth={isMobile}
                  >
                    <span data-record>
                      {translate(
                        'BankConnectionStandalone.CheckApplicationStatus'
                      )}
                    </span>
                  </Button>
                </Container>
              </Stack>
            </Stack>
          )}

          {!connectedSuccessfully && clientData && (
            <Stack spacing={isMobile ? 'md' : 'xl'} p="1.5em">
              <H4 data-testid="title" data-record>
                {translate('BankConnectionStandalone.Title')}
              </H4>
              <Body size="lg" data-testid="description" data-record>
                {translate('BankConnectionStandalone.Description')}
              </Body>
              <BankConnection
                clientId={clientData.clientId}
                hasError={error}
                setError={setError}
                finalRedirectUrl={location.href}
                allowCancel={false}
              />
            </Stack>
          )}
        </Card>
      </OnboardingStepsContainer>
    </OnboardingAppShell>
  );
};

export default BankConnectionStandalone;
