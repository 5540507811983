import React from 'react';
import Card from '~/components/shared/Card/Card';
import { Body, H7 } from '@qred/components-library';
import ProgressStepper from '~/components/shared/ProgressStepper/ProgressStepper';
import { ApplicationStatus, FlexContentPlacement } from '~/enums';
import Image from '~/components/shared/Image/Image';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import Stack from '~/components/shared/Layout/Stack';
import Button, { ButtonType } from '~/components/shared/Button/button';
import { useHistory } from 'react-router-dom';
import useLogout from '~/hooks/useLogout';
import { getHomePageByMarket } from '~/constants/markets';
import {
  CardContentContainer,
  ImageContainer,
} from './ConfirmationPage.styled';
import { clearOnboardingState } from '~/store/slices/onboardingApplication.slice';

interface OfferInfoCardProps {
  step: number;
  errorMessage?: string;
  barStepAnimation: boolean;
}

const OfferInfoCard = ({
  step,
  errorMessage,
  barStepAnimation,
}: OfferInfoCardProps) => {
  const t = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const logout = useLogout();

  const {
    onboardingApplication: {
      applicationStatus = ApplicationStatus.None,
      userId,
    },
    matchedMedia,
    intl: { market, language },
  } = useSelector((state: RootState) => state);

  const heading = {
    [ApplicationStatus.None]: '',
    [ApplicationStatus.OfferSigned]: '',
    [ApplicationStatus.ApplicationFinished]: 'Onboarding.ApplicationSentTitle',
    [ApplicationStatus.ApplicationUnderReview]:
      'Onboarding.ApplicationUnderReviewTitle',
    [ApplicationStatus.OfferNotApproved]:
      'Onboarding.ApplicationUnderReviewTitle',
    [ApplicationStatus.OfferApproved]: 'Onboarding.ApplicationApprovedTitle',
  };

  const subText = {
    [ApplicationStatus.None]: '',
    [ApplicationStatus.OfferSigned]: '',
    [ApplicationStatus.ApplicationFinished]: '',
    [ApplicationStatus.ApplicationUnderReview]:
      'Onboarding.ApplicationUnderReviewDescription',
    [ApplicationStatus.OfferApproved]: '',
    [ApplicationStatus.OfferNotApproved]:
      'Onboarding.OfferNotApprovedDescription',
  };

  const handleButtonClick = () => {
    if (userId) {
      dispatch(clearOnboardingState());
      history.push('/');
    } else {
      logout(getHomePageByMarket(market, language));
    }
  };

  return (
    <Card
      mt={matchedMedia.smallScreenPortrait ? 0 : 2}
      mb={matchedMedia.smallScreenPortrait ? 0 : 2}
      justifyContent={FlexContentPlacement.Center}
      isOnboarding
    >
      <CardContentContainer>
        {!matchedMedia.smallScreenPortrait && (
          <ImageContainer>
            <Image src="launch.png" />
          </ImageContainer>
        )}
        {!errorMessage && matchedMedia.smallScreenPortrait && (
          <H7>{heading[applicationStatus] && t(heading[applicationStatus])}</H7>
        )}
        <ProgressStepper
          steps={[
            t('Apply') as string,
            t('Review') as string,
            t('Offer') as string,
          ]}
          nextStepBarAnimation={barStepAnimation}
          currentStep={step}
        />

        {errorMessage ? (
          <H7>{errorMessage}</H7>
        ) : (
          <Stack spacing="xxl">
            <Stack spacing="sm">
              {!matchedMedia.smallScreenPortrait &&
                heading[applicationStatus] && (
                  <H7>{t(heading[applicationStatus])}</H7>
                )}
              {subText[applicationStatus] && t(subText[applicationStatus]) && (
                <Body size="lg">{t(subText[applicationStatus])}</Body>
              )}
            </Stack>
            {applicationStatus === ApplicationStatus.OfferNotApproved && (
              <Button
                dataCy="onboarding_ok_button"
                type={ButtonType.Secondary}
                onClick={handleButtonClick}
                wide
              >
                {t(
                  `Onboarding.${
                    userId ? 'GoToMyQredButtonText' : 'GoToQredButtonText'
                  }`
                )}
              </Button>
            )}
          </Stack>
        )}
      </CardContentContainer>
    </Card>
  );
};

export default OfferInfoCard;
