import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Divider,
  Group,
  Icon,
  IconKey,
  Menu,
  Navbar,
  QredLogo,
  Stack,
  Body,
} from '@qred/components-library';
import { RootState } from '~/store/types/sharedTypes';
import {
  ContactEmails,
  ContactNumbersFormatted,
  getMarketLanguageLabels,
} from '~/constants/markets';
import useTranslate from '~/hooks/useTranslate';
import { LanguageItem } from '~/components/shared/Sidebar/LanguageItem';

import useHubspotChat from '~/hooks/useHubspotChat';

import {
  sidebarHelpButton,
  sidebarHomeButton,
  sidebarProfileButton,
} from '~/constants/cy/components/sidebar';
import { capitalizeFirstLetter } from '~/helpers/formatters.helper';
import { openQredHomePage } from '~/services/links';
import styled from 'styled-components';

const StyledQredLogo = styled(QredLogo)`
  cursor: pointer;
`;
const StyledIcon = styled(Icon)`
  width: 1em;
`;
const SidebarContentLoggedOut = () => {
  const t = useTranslate();
  const [languageSelectorOpened, setLanguageSelectorOpened] = useState(false);
  const { market, language } = useSelector((state: RootState) => state.intl);
  const { openChat } = useHubspotChat();

  const languages = getMarketLanguageLabels(market);
  const selectedLanguageInfo = languages.find((l) => l.value === language);

  // const showQeldLogo = market === CountryCode.NL; // TODO: add logo for NL

  const onPhoneClick = () => {
    window.open(`tel:${ContactNumbersFormatted[market]}`, '_self');
  };

  const onEmailClick = () => {
    window.open(`mailto:${ContactEmails[market]}`, '_self');
  };

  const onOpenChat = () => {
    openChat();
  };

  return (
    <Navbar width={235} showCollapseButton={false}>
      <Navbar.Section pl="1.7em" pt="1.8em" pb="3em">
        <StyledQredLogo
          width={80}
          onClick={() => {
            openQredHomePage(market, language);
          }}
        />
      </Navbar.Section>
      <Navbar.Section grow>
        <Stack spacing="sm">
          <Navbar.Title>{t('Onboarding.ContactUs')}</Navbar.Title>
          <Navbar.Link onClick={onPhoneClick} dataCy={sidebarHomeButton}>
            {ContactNumbersFormatted[market]}
          </Navbar.Link>
          <Navbar.Link onClick={onEmailClick} dataCy={sidebarProfileButton}>
            {ContactEmails[market]}
          </Navbar.Link>
          <Navbar.Link onClick={onOpenChat} dataCy={sidebarHelpButton}>
            {capitalizeFirstLetter(`${t('Dashboard.Chat')}`)}
          </Navbar.Link>
        </Stack>
      </Navbar.Section>

      {languages.length > 1 && selectedLanguageInfo && (
        <>
          <Divider color="neutral.16" my={24} />
          <Navbar.Section>
            <Menu
              onChange={() =>
                setLanguageSelectorOpened(!languageSelectorOpened)
              }
            >
              <Menu.Target>
                <Group spacing="sm" w="100%" pl={24} mb={48} align="center">
                  <StyledIcon src={selectedLanguageInfo?.icon as IconKey} />
                  <Body color="textLight.1">
                    {selectedLanguageInfo?.label.long}
                  </Body>
                  <Icon
                    src={languageSelectorOpened ? 'ChevronUp' : 'ChevronDown'}
                    size="sm"
                    color="textLight.1"
                  />
                </Group>
              </Menu.Target>

              <Menu.Dropdown>
                {languages
                  .filter(({ value }) => value !== language)
                  .map((_language) => (
                    <LanguageItem
                      {..._language}
                      key={_language.value}
                      newStyle
                      loggedOut
                    />
                  ))}
              </Menu.Dropdown>
            </Menu>
          </Navbar.Section>
        </>
      )}
    </Navbar>
  );
};

export default SidebarContentLoggedOut;
