import { CountryCode } from '~/enums';
import { LoanAmountDefaultValues } from '~/constants/markets';

export const loanAmountRounderHelper = (
  amount: string,
  market: CountryCode,
  step?: number
): string => {
  const maxValue = LoanAmountDefaultValues[market].max;
  const minValue = LoanAmountDefaultValues[market].min;
  step = step || LoanAmountDefaultValues[market].step;
  const replaced = amount.replace(/\D+/g, '');
  let newAmount = parseInt(replaced) || step;

  if (newAmount < minValue) {
    return minValue.toString();
  }

  if (newAmount > maxValue) {
    return maxValue.toString();
  }
  if (newAmount > 0) {
    newAmount = Math.ceil(newAmount / step) * step;
    return newAmount.toString();
  }

  return step.toString();
};
