import React from 'react';
import { useSelector } from 'react-redux';
import { Body, Group, H7, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { useOnboardingOfferSelectors } from '~/store/selectors/onboardingOffer.selector';
import { useCurrencyFormatter } from '~/hooks/formatter/useCurrencyFormatter';

const PaymentDetails = () => {
  const translate = useTranslate();
  const formatCurrency = useCurrencyFormatter();

  const {
    onboardingOffer: { form, overview },
  } = useSelector((state: RootState) => state);

  const {
    monthlyAmortization,
    monthlyFee,
    monthlyPayment,
    loanAmount,
  } = useOnboardingOfferSelectors();

  return (
    <Stack spacing={'xxl'}>
      {overview.type === 'CREDIT_LIMIT_CHANGE' && (
        <Stack spacing={'sm'}>
          <Group direction="row" justify="space-between">
            <H7 data-testid="total-loan-label">{translate('TotalLoan')}</H7>
            <H7 data-testid="total-loan-value">
              {formatCurrency.format(loanAmount)}
            </H7>
          </Group>
          <Stack spacing={'xs'} data-testid="existing-loan-info-section">
            <Group direction="row" justify="space-between">
              <Body size="lg" data-testid="payout-amount-label">
                {translate('OnboardingOffer.PayoutAmount')}
              </Body>
              <Body size="lg" data-testid="payout-amount-value">
                {formatCurrency.format(form.payoutAmount)}
              </Body>
            </Group>
            <Group direction="row" justify="space-between">
              <Body size="lg" data-testid="existing-loan-label">
                {translate('ExistingLoan')}
              </Body>
              <Body size="lg" data-testid="existing-loan-value">
                {formatCurrency.format(overview.currentLoanAmountLeft ?? 0)}
              </Body>
            </Group>
          </Stack>
        </Stack>
      )}

      <Stack spacing={'sm'}>
        <Group direction="row" justify="space-between">
          <H7 data-testid="total-per-month-label">
            {overview.type === 'CREDIT_LIMIT_CHANGE'
              ? translate('OnboardingOffer.NewTotalPerMonth')
              : translate('OnboardingOffer.TotalPerMonth')}
          </H7>
          <H7 data-testid="total-per-month-value">
            {formatCurrency.format(monthlyPayment)}
          </H7>
        </Group>
        <Stack spacing={'xs'} data-testid="existing-loan-info-section">
          <Group direction="row" justify="space-between">
            <Body size="lg" data-testid="monthly-fee-label">
              {translate('MonthlyFee')}
            </Body>
            <Body size="lg" data-testid="monthly-fee-value">
              {formatCurrency.format(monthlyFee)}
            </Body>
          </Group>
          <Group direction="row" justify="space-between">
            <Body size="lg" data-testid="monthly-amortization-label">
              {translate('MonthlyAmortization')}
            </Body>
            <Body size="lg" data-testid="monthly-amortization-value">
              {formatCurrency.format(monthlyAmortization)}
            </Body>
          </Group>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PaymentDetails;
