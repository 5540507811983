import React from 'react';
import { RootState } from '~/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBankConnectionError,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import BankConnection from '~/components/shared/BankConnection/BankConnection';
import {
  Body,
  Button,
  H3,
  MaterialIcon,
  Stack,
} from '@qred/components-library';
import { patchApplication } from '~/store/actions/onboardingActions';
import useTranslate from '~/hooks/useTranslate';

const BankConnectionStep = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const {
    onboardingApplication: {
      clientId,
      bankConnectionError,
      form: { bankConnected },
    },
  } = useSelector((state: RootState) => state);

  if (!clientId) {
    return null;
  }

  return (
    <>
      {bankConnected ? (
        <Stack
          spacing={0}
          style={{
            flex: 1,
            backgroundImage: 'url(/swoosh.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: '90%',
            backgroundSize: 'contain',
          }}
        >
          <Stack
            spacing="xxl"
            align="center"
            justify="center"
            style={{
              flexGrow: 0.5,
            }}
          >
            <MaterialIcon
              src="verified"
              filled
              size="48"
              color="Icon/Success"
            />
            <H3 data-record>
              {translate('Onboarding.BankConnectionSuccessfullyConnectedTitle')}
            </H3>
            <Body size="lg" data-record>
              {translate(
                'Onboarding.BankConnectionSuccessfullyConnectedDescription'
              )}
              {/* TODO: remove before final release */}
              {['development', 'test'].includes(import.meta.env.MODE) && (
                <Button
                  onClick={() => {
                    dispatch(updateForm({ bankConnected: false }));
                    dispatch(patchApplication({ currentStepCompleted: false }));
                  }}
                  variant="tertiary"
                >
                  Revert (for testing purposes)
                </Button>
              )}
            </Body>
          </Stack>
        </Stack>
      ) : (
        <BankConnection
          clientId={clientId}
          hasError={bankConnectionError}
          finalRedirectUrl={location.href}
          setError={(error) => dispatch(setBankConnectionError(error))}
        />
      )}
    </>
  );
};

export default BankConnectionStep;
