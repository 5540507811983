import React, { useEffect } from 'react';
import { Body, H4, Link, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { getSupportUrl } from '~/constants/markets';
import { CountryCode } from '~/enums';

const AdditionalSignerError = () => {
  const {
    intl: { language, market },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();

  useEffect(() => {
    document.cookie = `additionalSignerId=; Max-Age=0`;
    document.cookie = `additionalSignerProductType=; Max-Age=0`;
    document.cookie = `additionalSignerHashCode=; Max-Age=0`;
    document.cookie = `additionalSignerAccessToken=; Max-Age=0`;
  }, []);

  const contactUsOnClick = () => {
    if (!language) return;
    const supportUrl = getSupportUrl(market as CountryCode, language);
    window.open(supportUrl, '_blank');
  };

  return (
    <Stack p={'xxl'}>
      <H4>
        {translate('LoanAdditionalSigner.LoanAdditionalSignerErrorTitle')}
      </H4>
      <Body size="lg">
        {translate('LoanAdditionalSigner.LoanAdditionalSignerErrorSubheader')}
      </Body>
      <Body size="lg">
        {translate(
          'LoanAdditionalSigner.LoanAdditionalSignerSuccessContactUs',
          {
            contactUs: (
              <Link size="lg" onClick={contactUsOnClick}>
                {translate('Onboarding.ContactUs').toString().toLowerCase()}
              </Link>
            ),
          }
        )}
      </Body>
    </Stack>
  );
};

export default AdditionalSignerError;
