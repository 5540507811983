import {
  formatNumber,
  formatNumberWithCurrency,
  getCurrency,
} from '@qred/shared-component-library/src/currency';
import { Language } from '~/store/types/sharedTypes';
import { format } from 'date-fns';
import se from 'date-fns/locale/sv';
import da from 'date-fns/locale/da';
import nb from 'date-fns/locale/nb';
import fr from 'date-fns/locale/fr';
import fi from 'date-fns/locale/fi';
import nlBE from 'date-fns/locale/nl-BE';
import nl from 'date-fns/locale/nl';
import enGB from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import { CountryCode } from '~/enums';
import { formatNameHelper } from './formatName.helper';
import { personalNumberValidator } from './validators.helper';
import { parseMarket } from './market.helper';

const languageLocales: { [key in Language]: Locale | undefined } = {
  [Language.Swedish]: se,
  [Language.Danish]: da,
  [Language.Norwegian]: nb,
  [Language.French]: fr,
  [Language.DutchBE]: nlBE,
  [Language.Finnish]: fi,
  [Language.English]: enGB,
  [Language.Dutch]: nl,
  [Language.German]: de,
};

export const capitalizeFirstLetter = (s: string): string => {
  if (!s) return '';
  const firstLetter = s.charAt(0).toUpperCase();
  const rest = s.substring(1).toLocaleLowerCase();
  return firstLetter + rest;
};

export const formatAmount = (amount: number): string => {
  // TODO: market is now read from Auth0 token, what to do for public pages?
  // Default value is given so that public pages don't crash in the meanwhile.
  const market = window.__QRED_MARKET__ || 'se';
  return formatNumberWithCurrency(market, amount);
};

export const formatAmountWithoutCurrency = (
  amount: number | string
): string => {
  // TODO: market is now read from Auth0 token, what to do for public pages?
  // Default value is given so that public pages don't crash in the meanwhile.

  const market = window.__QRED_MARKET__ || 'se';
  return amount ? formatNumber(market, amount) : '';
};

export const removeNonDigits = (value: string): string =>
  value.replace(/\D/g, '');

export const formatAmountNumbersOnly = (amount: string): string =>
  formatAmountWithoutCurrency(Number(removeNonDigits(amount)));

export const removeWhitespace = (value: string): string =>
  value.replace(/\s/g, '');

export const formatValueNumbersOnly = (value: string): string => {
  let val = value;
  if (val[0] === '0' && val.length > 1) {
    val = val.replace('0', '');
  }
  return removeNonDigits(val);
};

export const getMarketCurrency = (): { currency: string; unit: string } => {
  const market = window.__QRED_MARKET__ || 'se';
  return getCurrency(market);
};

export const formatDate = (
  date: Date | string,
  language: Language,
  dateFormat = 'MMM d, y'
): string => {
  const market = window.__QRED_MARKET__ || 'se';

  if (market === 'NL') {
    return formatNameHelper(
      format(new Date(date), 'd MMMM y', {
        locale: languageLocales[language],
      })
    ).toLowerCase();
  }

  return format(new Date(date), dateFormat, {
    locale: languageLocales[language],
  });
};

export const formatPersonalNumber = (
  personalNumber: string,
  market: CountryCode
) => {
  const personalNumberIsValid = personalNumberValidator(personalNumber);
  if (!personalNumber || !market || !personalNumberIsValid)
    return personalNumber;

  switch (market.toUpperCase()) {
    case CountryCode.BE:
      return personalNumber.replace(
        /(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})/,
        '$1.$2.$3-$4.$5'
      );

    case CountryCode.DK:
      // Sometimes the PN is already formatted, like when loggin into the internal-prod environment.
      // Format it only if it is not already formatted.

      if (personalNumber.indexOf('-') > 0) {
        return personalNumber;
      }
      return personalNumber.replace(/(\d{6})/, '$1-');

    default:
      return personalNumber;
  }
};

export const removeSpecialCharacters = (str: string) =>
  str.replace(/[^a-zA-Z0-9]/g, '');

export const formatBankAccountNumber = (
  accountNumber: string | null,
  market: CountryCode
) => {
  if (!accountNumber || !market) return '';
  switch (market.toUpperCase()) {
    case CountryCode.FI:
      return `FI${removeNonDigits(accountNumber)}`;
    case CountryCode.SE:
      return removeNonDigits(accountNumber);
    default:
      return accountNumber;
  }
};

export const formatZipCodeNL = (zipCode: string | undefined) => {
  if (!zipCode) return '';

  return zipCode
    .replace(/[^a-zA-Z0-9]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
    .toUpperCase();
};

export const formatSize = (bytes: number, decimals = 1) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
};

export const formatOrganizationNumber = (organizationNumber: string) => {
  const market = parseMarket(window.__QRED_MARKET__) || CountryCode.SE;
  if (!organizationNumber) return '';

  if ([CountryCode.BE].includes(market)) {
    return organizationNumber.replace(/^(\d{4})(\d{3})(\d{3})$/, '$1.$2.$3');
  }
  return organizationNumber;
};
