import React from 'react';
import {
  FontWeights,
  Typography,
} from '~/components/shared/Typography/Typography';
import styled from 'styled-components';
import useTranslate from '~/hooks/useTranslate';
import Image from '~/components/shared/Image/Image';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { CountryCode } from '~/enums';
import {
  openAppStore,
  openGooglePlay,
  openMailToQredSupport,
  openQredAppPage,
} from '~/services/links';
import {
  ContactEmails,
  LanguageValues,
  MarketValues,
} from '~/constants/markets';
import { MediaQueries } from '~/constants/constVars';
import { Link } from '@qred/components-library';

const AppStoreBadges: LanguageValues = {
  SV: 'app-store-badge-swedish.svg',
  EN: 'app-store-badge-english.svg',
  FI: 'app-store-badge-finnish.svg',
  DA: 'app-store-badge-english.svg',
  NO: 'app-store-badge-english.svg',
  FR: 'app-store-badge-english.svg',
  NL_BE: 'app-store-badge-english.svg',
  NL: 'app-store-badge-english.svg',
  // TODO: Add German badge
  DE: 'app-store-badge-english.svg',
};

const GooglePlayStoreBadges: LanguageValues = {
  SV: 'google-play-badge-swedish.png',
  EN: 'google-play-badge-english.png',
  FI: 'google-play-badge-english.png',
  DA: 'google-play-badge-english.png',
  NO: 'google-play-badge-english.png',
  FR: 'google-play-badge-english.png',
  NL_BE: 'google-play-badge-english.png',
  NL: 'google-play-badge-english.png',
  // TODO: Add German badge
  DE: 'google-play-badge-english.png',
};

const TitleContainer = styled.div`
  padding: 0 1em;
  margin: auto;
`;

const TextContainer = styled.div`
  padding: 1em;
`;

const Separator = styled.div`
  height: 1em;
`;

const BadgeContainer = styled.div`
  padding: 1em;
  @media ${MediaQueries.mediumScreen} {
    display: flex;
    justify-content: center;
  }
`;

const ImageContainer = styled.div`
  width: 10em;
  cursor: pointer;
  margin: 0 1em;
  @media ${MediaQueries.smallScreen} {
    margin: 1em auto;
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 30em;
  @media (orientation: landscape) {
    height: 50em;
  }
`;

const ThankYouCardContentSetPinCode = () => {
  const translate = useTranslate();
  const { language, market } = useSelector((state: RootState) => state.intl);

  const ClickHereTag = (
    <Link onClick={() => openMailToQredSupport(market)}>
      {ContactEmails[market.toUpperCase() as keyof MarketValues<string>]}
    </Link>
  );

  const QredAppTag = (
    <Link onClick={() => openQredAppPage(market, language)}>Qred app</Link>
  );

  const QredAppStoreBadge = () => (
    <ImageContainer onClick={() => openAppStore(language)}>
      <Image src={AppStoreBadges[language]} />
    </ImageContainer>
  );

  const QredAppGooglePlayStoreBadge = () => (
    <ImageContainer onClick={() => openGooglePlay()}>
      <Image src={GooglePlayStoreBadges[language]} />
    </ImageContainer>
  );

  const getReferralFactoryCodeBasedOnMarket = () => {
    switch (market) {
      case CountryCode.SE:
        return 'd7K5UQ';
      case CountryCode.FI:
        return 'HMigKe';
      default:
        return '';
    }
  };

  return (
    <>
      <TitleContainer>
        <Typography
          centerAlign
          lineHeight={1.5}
          fontSize={1.2}
          fontWeight={FontWeights.Thick}
        >
          {translate('ThankYouCardContentSetPinCode.Title')}
        </Typography>
      </TitleContainer>
      <TextContainer>
        <Typography centerAlign lineHeight={2} fontSize={0.8} element="p">
          {translate('ThankYouCardContentSetPinCode.Text1')}
        </Typography>
        <Separator />
        <Typography centerAlign lineHeight={2} fontSize={0.8} element="p">
          {translate('ThankYouCardContentSetPinCode.Text2', {
            link: QredAppTag,
          })}
        </Typography>
        <Separator />
        <Typography centerAlign lineHeight={2} fontSize={0.8} element="p">
          {translate('ThankYouCardContentSetPinCode.Text3', {
            link: ClickHereTag,
          })}
        </Typography>
      </TextContainer>
      <BadgeContainer>
        <QredAppGooglePlayStoreBadge />
        <QredAppStoreBadge />
      </BadgeContainer>
      {(market === CountryCode.SE || market === CountryCode.FI) && (
        <Iframe
          title="Referral factory"
          src={`https://embed.referral-factory.com/${getReferralFactoryCodeBasedOnMarket()}/join`}
        />
      )}
    </>
  );
};

export default ThankYouCardContentSetPinCode;
