import React, { useContext, useEffect } from 'react';
import { Radio, Stack, notifications } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationContext } from '~/components/hoc/withValidation';
import { RootState } from '~/store/types/sharedTypes';
import { validateBankAccountNumber } from '~/helpers/validators.helper';
import { IKlarnaAccount } from '~/interfaces/Onboarding';
import { getBankAccountNumber } from '~/helpers/onboarding.helper';
import { isEmptyObject } from '~/helpers/utils';

interface KlarnaKosmaBankAccountListProps {
  setShowAddNewAccount: (showAddNewAccount: boolean) => void;
  showAddNewAccount: boolean;
}

const KlarnaKosmaBankAccountList = ({
  setShowAddNewAccount,
  showAddNewAccount,
}: KlarnaKosmaBankAccountListProps) => {
  const validationContext = useContext(ValidationContext);

  const dispatch = useDispatch();
  const {
    onboardingOffer: { form, overview },
    intl: { market },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();

  const findIndexByIban = (
    list?: IKlarnaAccount[],
    ibanNumber?: string
  ): number | undefined => {
    if (!ibanNumber) return undefined;
    return list?.findIndex((item) => item.iban === ibanNumber);
  };

  const indexOfSelectedAccount = findIndexByIban(
    overview?.bankAccounts,
    form?.account?.iban
  );

  useEffect(() => {
    const hasSelectedAccount = !isEmptyObject(form.account);
    const manuallyEnteredAccountNumber = form.accountNumber;

    if (!hasSelectedAccount && !manuallyEnteredAccountNumber) {
      validationContext.addPropertyToValidationErrors('MissingAccount');
    }

    return () => {
      validationContext.removePropertyFromValidationErrors('MissingAccount');
    };
  }, [form.account]);

  const onHandleBankAccountChange = (value: string) => {
    const valueAsNumber = Number(value);
    if (valueAsNumber === overview.bankAccounts?.length) {
      setShowAddNewAccount(true);
      dispatch(
        updateForm({
          account: {},
          accountNumber: null,
        })
      );
    } else {
      setShowAddNewAccount(false);
      dispatch(
        updateForm({
          account: overview.bankAccounts?.[valueAsNumber],
          accountNumber: null,
        })
      );
    }
  };

  const validateBankAccount = (bankAccount: IKlarnaAccount) => {
    const accountNumber = getBankAccountNumber(bankAccount, market);
    return validateBankAccountNumber(accountNumber, market);
  };

  useEffect(() => {
    if (overview.bankAccounts) {
      const numberOfBankAccounts = overview.bankAccounts.length;
      const invalidBankAccounts = overview.bankAccounts.filter(
        (bankAccount) => !validateBankAccount(bankAccount)
      );
      const numberOfInvalidBankAccounts = invalidBankAccounts.length;

      // no bank accounts are valid
      if (numberOfInvalidBankAccounts === numberOfBankAccounts) {
        notifications.show({
          message: translate('OnboardingOffer.BankAccountNotificationError'),
          state: 'critical',
          autoClose: true,
        });
        return;
      }
      // one or more bank accounts are invalid, one or more bank accounts are valid
      if (
        numberOfInvalidBankAccounts > 0 &&
        numberOfBankAccounts > numberOfInvalidBankAccounts
      ) {
        notifications.show({
          message: translate('OnboardingOffer.BankAccountNotificationInfo'),
          state: 'informative',
          autoClose: true,
        });
      }
    }
  }, [overview.bankAccounts]);

  return (
    <Stack>
      <Radio.Group
        direction="column"
        onChange={onHandleBankAccountChange}
        defaultValue={indexOfSelectedAccount?.toString()}
      >
        {overview.bankAccounts?.map((bankAccount, index) => (
          <Radio
            key={bankAccount.id}
            name="bankAccount"
            value={String(index)}
            label={bankAccount.alias}
            hint={getBankAccountNumber(bankAccount, market)}
            checked={index === Number(indexOfSelectedAccount)}
            disabled={!validateBankAccount(bankAccount)}
          />
        ))}
        <Radio
          name="bankAccount"
          value={String(overview?.bankAccounts?.length)}
          label={translate(
            'OnboardingOffer.VerifyBankAccountOptionInRadioGroup'
          )}
          checked={showAddNewAccount}
        />
      </Radio.Group>
    </Stack>
  );
};

export default KlarnaKosmaBankAccountList;
