import styled, { css } from 'styled-components';
import { theme } from '~/styles/themes';

interface DividerProps {
  orientation?: 'horizontal' | 'vertical';
}

const orientation: Record<
  NonNullable<DividerProps['orientation']>,
  ReturnType<typeof css>
> = {
  horizontal: css`
    border: 0;
    border-top-width: 1px;
    border-top-color: ${theme.colors.backdrop};
    border-top-style: solid;
    margin: 0;
  `,
  vertical: css`
    border: 0;
    align-self: stretch;
    height: 100%;
    border-left-width: 1px;
    border-left-color: ${theme.colors.backdrop};
    border-left-style: solid;
  `,
};

const Divider = styled.div<DividerProps>`
  ${(props) => orientation[props.orientation ?? 'horizontal']}
`;

export default Divider;
