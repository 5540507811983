import { CountryCode } from '~/enums';
import { Language, LanguageLabel } from '~/store/types/sharedTypes';
import { PartialRecord } from '~/types/types';
import { IValueDefaults } from '~/Interfaces';
import { IconKey } from '@qred/qit-ui';

export type MarketValues<T> = Record<CountryCode, T>;
export type LanguageValues = Record<Language, string>;
export type MarketLanguagePartial = Record<
  CountryCode,
  Partial<Record<Language, string>>
>;

export const countryNames: MarketLanguagePartial = {
  [CountryCode.BE]: {
    [Language.French]: 'Belgique',
    [Language.DutchBE]: 'België',
    [Language.English]: 'Belgium',
  },
  [CountryCode.DK]: {
    [Language.Danish]: 'Danmark',
    [Language.English]: 'Denmark',
  },
  [CountryCode.FI]: {
    [Language.Finnish]: 'Suomi',
    [Language.English]: 'Finland',
  },
  [CountryCode.SE]: {
    [Language.Swedish]: 'Sverige',
    [Language.English]: 'Sweden',
  },
  [CountryCode.NO]: {
    [Language.Norwegian]: 'Norge',
    [Language.English]: 'Norway',
  },
  [CountryCode.NL]: {
    [Language.Dutch]: 'Nederland',
    [Language.English]: 'Netherlands',
  },
  [CountryCode.DE]: {
    [Language.German]: 'Deutschland',
    [Language.English]: 'Germany',
  },
};

const validLanguagesBE: Array<Language> = [
  Language.DutchBE,
  Language.French,
  Language.English,
];
const validLanguagesDK: Array<Language> = [Language.Danish, Language.English];
const validLanguagesSE: Array<Language> = [Language.Swedish, Language.English];
const validLanguagesNO: Array<Language> = [
  Language.Norwegian,
  Language.English,
];
const validLanguagesFI: Array<Language> = [Language.Finnish, Language.English];
const validLanguagesNL: Array<Language> = [Language.Dutch];
const validLanguagesDE: Array<Language> = [Language.German, Language.English];

export const ValidLanguagesPerMarket: Record<CountryCode, Array<Language>> = {
  [CountryCode.BE]: validLanguagesBE,
  [CountryCode.DK]: validLanguagesDK,
  [CountryCode.SE]: validLanguagesSE,
  [CountryCode.NO]: validLanguagesNO,
  [CountryCode.FI]: validLanguagesFI,
  [CountryCode.NL]: validLanguagesNL,
  [CountryCode.DE]: validLanguagesDE,
};

export const languageLabels: Record<Language, LanguageLabel> = {
  [Language.DutchBE]: {
    long: 'Nederlands',
    short: 'NL',
  },
  [Language.Dutch]: {
    long: 'Nederlands',
    short: 'NL',
  },
  [Language.French]: {
    long: 'Français',
    short: 'FR',
  },
  [Language.Danish]: {
    long: 'Dansk',
    short: 'DA',
  },
  [Language.Swedish]: {
    long: 'Svenska',
    short: 'SV',
  },
  [Language.Norwegian]: {
    long: 'Norsk',
    short: 'NO',
  },
  [Language.Finnish]: {
    long: 'Suomi',
    short: 'FI',
  },
  [Language.English]: {
    long: 'English',
    short: 'EN',
  },
  [Language.German]: {
    long: 'German',
    short: 'DE',
  },
};

export const languageIcons: Record<Language, IconKey> = {
  [Language.DutchBE]: 'FlagBelgium',
  [Language.Dutch]: 'FlagNetherlands',
  [Language.French]: 'FlagFrance',
  [Language.Danish]: 'FlagDenmark',
  [Language.Swedish]: 'FlagSweden',
  [Language.Norwegian]: 'FlagNorway',
  [Language.Finnish]: 'FlagFinland',
  [Language.English]: 'FlagUnitedKingdom',
  [Language.German]: 'FlagGermany',
};

export const getMarketLanguageLabels = (market: CountryCode) =>
  Object.values(ValidLanguagesPerMarket[market]).map((validLang: Language) => ({
    label: languageLabels[validLang],
    value: validLang,
    icon: languageIcons[validLang],
  }));

export const ContactNumbers: MarketValues<string> = {
  [CountryCode.DK]: '89871006',
  [CountryCode.FI]: '0942450399',
  [CountryCode.NL]: '0208086084',
  [CountryCode.SE]: '020150333',
  [CountryCode.BE]: '025880983',
  [CountryCode.NO]: '32993641',
  [CountryCode.DE]: '040228999808',
};

export const ContactNumbersFormatted: MarketValues<string> = {
  [CountryCode.DK]: '89 87 10 06',
  [CountryCode.FI]: '09 424 503 99 (pvm/mpm)',
  [CountryCode.NL]: '020 - 808 6084',
  [CountryCode.SE]: '020-150 333',
  [CountryCode.BE]: '02 588 09 83',
  [CountryCode.NO]: '32 99 36 41',
  [CountryCode.DE]: '040 228999808',
};

export const ContactEmails: MarketValues<string> = {
  [CountryCode.DK]: 'support@qred.dk',
  [CountryCode.FI]: 'asiakaspalvelu@qred.com',
  [CountryCode.NL]: 'support@qeld.nl',
  [CountryCode.SE]: 'support@qred.se',
  [CountryCode.BE]: 'support@qred.be',
  [CountryCode.NO]: 'support@qred.no',
  [CountryCode.DE]: 'support@qred.de',
};

// Todo: Find a way to use interface MarketLanguageValues here
export const PersonalNumberPlaceholders: MarketLanguagePartial = {
  [CountryCode.BE]: {
    [Language.English]: 'XX.XX.XX-XXX.XX',
    [Language.French]: 'XX.XX.XX-XXX.XX',
    [Language.DutchBE]: 'XX.XX.XX-XXX.XX',
  },
  [CountryCode.DK]: {
    [Language.Danish]: 'DDMMÅÅ-NNNN',
    [Language.English]: 'DDMMYY-NNNN',
  },
  [CountryCode.FI]: {
    [Language.English]: 'DDMMYYCZZZQ',
    [Language.Finnish]: 'PPKKVVXNNNT',
  },
  [CountryCode.SE]: {
    [Language.English]: 'YYYYMMDD-XXXX',
    [Language.Swedish]: 'ÅÅÅÅMMDD-XXXX',
  },
  [CountryCode.NO]: {
    [Language.English]: 'DDMMYYXXXZZ',
    [Language.Norwegian]: 'DDMMÅÅXXXZZ',
  },
  [CountryCode.NL]: {
    [Language.English]: 'DDMMYYXXXZZ',
    [Language.Dutch]: 'DDMMÅÅXXXZZ',
  },
  // TODO: (DE) German personal number placeholder
  [CountryCode.DE]: {
    [Language.English]: 'DDMMYYXXXZZ',
    [Language.German]: 'DDMMÅÅXXXZZ',
  },
};

export const getPersonalNumberPlaceholder = (
  market: CountryCode,
  language: Language
) => PersonalNumberPlaceholders[market][language];

const DatePlaceholders: MarketLanguagePartial = {
  [CountryCode.BE]: {
    [Language.English]: 'DD/MM/YYYY',
    [Language.French]: 'DD/MM/AAAA',
    [Language.DutchBE]: 'DD/MM/JJJJ',
  },
  [CountryCode.DK]: {
    [Language.Danish]: 'DD/MM/YYYY',
    [Language.English]: 'DD/MM/YYYY',
  },
  [CountryCode.FI]: {
    [Language.English]: 'DD/MM/YYYY',
    [Language.Finnish]: 'DD/MM/YYYY',
  },
  [CountryCode.SE]: {
    [Language.English]: 'DD/MM/YYYY',
    [Language.Swedish]: 'DD/MM/ÅÅÅÅ',
  },
  [CountryCode.NO]: {
    [Language.English]: 'DD/MM/YYYY',
    [Language.Norwegian]: 'DD/MM/YYYY',
  },
  [CountryCode.NL]: {
    [Language.English]: 'DD-MM-YYYY',
    [Language.Dutch]: 'DD-MM-JJJJ',
  },
  // TODO: (DE) German date placeholder
  [CountryCode.DE]: {
    [Language.English]: 'DD-MM-YYYY',
    [Language.German]: 'DD-MM-JJJJ',
  },
};

export const getDatePlaceholder = (market: CountryCode, language: Language) =>
  DatePlaceholders[market][language];

const QredOrgs: MarketLanguagePartial = {
  [CountryCode.BE]: {
    [Language.English]: 'Qred, affiliate of Qred AB',
    [Language.French]: 'Qred, filiale de Qred AB',
    [Language.DutchBE]: 'Qred, filiaal van Qred AB',
  },
  [CountryCode.DK]: {
    [Language.Danish]: 'Qred Erhvervslån, filial af Qred AB, Sverige',
    [Language.English]: 'Qred Erhvervslån, a filial of Qred AB',
  },
  [CountryCode.FI]: {
    [Language.English]: '',
    [Language.Finnish]: '',
  },
  [CountryCode.SE]: {
    [Language.English]: 'Qred AB',
    [Language.Swedish]: 'Qred AB',
  },
  [CountryCode.NO]: {
    [Language.English]: 'Qred AB NUF',
    [Language.Norwegian]: 'Qred AB NUF',
  },
  [CountryCode.NL]: {
    [Language.Dutch]: '',
    [Language.English]: '',
  },
  // TODO: (DE) German Qred org
  [CountryCode.DE]: {
    [Language.German]: '',
    [Language.English]: '',
  },
};

// Todo: Find a way to use interface MarketLanguageValues here
export const SigningMethods: MarketLanguagePartial = {
  [CountryCode.BE]: {
    [Language.English]: 'be-ID',
    [Language.French]: 'be-ID',
    [Language.DutchBE]: 'be-ID',
  },
  [CountryCode.DK]: {
    [Language.Danish]: 'MitID',
    [Language.English]: 'MitID',
  },
  [CountryCode.FI]: {
    [Language.English]: 'online bank ID',
    [Language.Finnish]: 'verkkopankkitunnuksilla',
  },
  [CountryCode.SE]: {
    [Language.English]: 'Mobile Bank ID',
    [Language.Swedish]: 'Mobilt Bank ID',
  },
  [CountryCode.NO]: {
    [Language.English]: 'BankID',
    [Language.Norwegian]: 'BankID',
  },
  [CountryCode.NL]: {
    [Language.Dutch]: '',
    [Language.English]: '',
  },
  // TODO: (DE) German signing method
  [CountryCode.DE]: {
    [Language.German]: '',
    [Language.English]: '',
  },
};

export const getSigningMethod = (market: CountryCode, language: Language) =>
  SigningMethods[market][language];

// Todo: Find a way to use interface MarketLanguageValues here
export const policyTermsUrl: MarketLanguagePartial = {
  [CountryCode.BE]: {
    [Language.English]: 'https://en.qred.be/info/privacyverklaring-be',
    [Language.French]: 'https://fr.qred.be/info/Politique-de-confidentialite',
    [Language.DutchBE]: 'https://www.qred.be/info/privacyverklaring-be',
  },
  [CountryCode.DK]: {
    [Language.English]: 'https://en.qred.dk/info/privatlivspolitik',
    [Language.Danish]: 'https://www.qred.dk/info/privatlivspolitik',
  },
  [CountryCode.FI]: {
    [Language.English]: 'https://en.qred.fi/info/yksityisyyden-suoja',
    [Language.Finnish]: 'https://www.qred.fi/info/yksityisyyden-suoja',
  },
  [CountryCode.SE]: {
    [Language.English]: 'https://en.qred.se/info/integrity',
    [Language.Swedish]: 'https://www.qred.se/info/integritet',
  },
  [CountryCode.NO]: {
    [Language.English]: 'https://en.qred.no/info/integritet',
    [Language.Norwegian]: 'https://www.qred.no/info/integritet',
  },
  [CountryCode.NL]: {
    [Language.Dutch]: 'https://www.qeld.com/info/qeld-privacybeleid',
  },
  [CountryCode.DE]: {
    [Language.English]: 'https://www.qred.de/en/documents/privacy-policy',
    [Language.German]: 'https://www.qred.de/documents/datenschutzerklarung',
  },
};

export const getPolicyTermsUrl = (market: CountryCode, language: Language) =>
  policyTermsUrl[market][language];

export const moneyLaunderingUrl: MarketLanguagePartial = {
  [CountryCode.BE]: {
    [Language.English]: 'https://en.qred.be/info/witwassen-be',
    [Language.French]: 'https://fr.qred.be/info/Anti-blanchiment-dargent',
    [Language.DutchBE]: 'https://www.qred.be/info/witwassen-be',
  },
  [CountryCode.DK]: {
    [Language.English]: 'https://en.qred.dk/info/hvidvask',
    [Language.Danish]: 'https://www.qred.dk/info/hvidvask',
  },
  [CountryCode.FI]: {
    [Language.English]: 'https://en.qred.fi/info/rahanpesu',
    [Language.Finnish]: 'https://www.qred.fi/info/rahanpesu',
  },
  [CountryCode.SE]: {
    [Language.English]: 'https://en.qred.se/info/moneylaundering',
    [Language.Swedish]: 'https://www.qred.se/info/penningtvatt',
  },
  [CountryCode.NO]: {
    [Language.English]: 'https://en.qred.no/info/hvitvasking',
    [Language.Norwegian]: 'https://www.qred.no/info/hvitvasking',
  },
  [CountryCode.NL]: {
    [Language.Dutch]: 'https://www.qeld.com/info/witwassen-nl',
  },
  [CountryCode.DE]: {
    [Language.English]:
      'https://www.qred.de/en/info/money-laundering-and-terrorist-financing',
    [Language.German]:
      'https://www.qred.de/info/geldwasche-und-terrorismusfinanzierung',
  },
};

export const supportUrl: MarketLanguagePartial = {
  [CountryCode.BE]: {
    [Language.English]: 'https://en.qred.be/support',
    [Language.French]: 'https://fr.qred.be/support',
    [Language.DutchBE]: 'https://www.qred.be/support',
  },
  [CountryCode.DK]: {
    [Language.Danish]: 'https://www.qred.dk/hjaelp',
    [Language.English]: 'https://en.qred.dk/support',
  },
  [CountryCode.FI]: {
    [Language.English]: 'https://en.qred.fi/support',
    [Language.Finnish]: 'https://www.qred.fi/asiakaspalvelu',
  },
  [CountryCode.SE]: {
    [Language.English]: 'https://en.qred.se/support',
    [Language.Swedish]: 'https://www.qred.se/support',
  },
  [CountryCode.NO]: {
    [Language.English]: 'https://en.qred.no/support',
    [Language.Norwegian]: 'https://www.qred.no/support',
  },
  [CountryCode.NL]: {
    [Language.DutchBE]: 'https://www.qeld.com/support',
  },
  [CountryCode.DE]: {
    [Language.English]: 'https://www.qred.de/en/support',
    [Language.German]: 'https://www.qred.de/support',
  },
};

export const getSupportUrl = (market: CountryCode, language: Language) =>
  supportUrl[market][language];

const buildNewLoanUrl = (
  baseUrl: string,
  market: CountryCode,
  language: Exclude<Language, Language.DutchBE> // NL_BE is not a real language
) => {
  const url = new URL(baseUrl);
  const isQeld = market === CountryCode.NL;
  const utmSource = isQeld ? 'myqeld' : 'myqred';
  const utmContent = isQeld ? 'my-qeld' : 'my-qred';
  const utmCampaignPrefix = isQeld ? 'myqe' : 'myqr';

  url.searchParams.append('utm_medium', 'login');
  url.searchParams.append('utm_term', 'login');
  url.searchParams.append('utm_source', utmSource);
  url.searchParams.append('utm_content', utmContent);

  url.searchParams.append(
    'utm_campaign',
    `${utmCampaignPrefix}_log_${market}_${language}_none_ln_all_all_`
  );

  return url.href.toLowerCase();
};

export const newLoanUrl: MarketLanguagePartial = {
  [CountryCode.SE]: {
    [Language.Swedish]: buildNewLoanUrl(
      'https://www.qred.se/foretagslan',
      CountryCode.SE,
      Language.Swedish
    ),
    [Language.English]: buildNewLoanUrl(
      'https://en.qred.se/businessloan',
      CountryCode.SE,
      Language.English
    ),
  },
  [CountryCode.DK]: {
    [Language.Danish]: buildNewLoanUrl(
      'https://www.qred.dk/erhvervslan',
      CountryCode.DK,
      Language.Danish
    ),
    [Language.English]: buildNewLoanUrl(
      'https://en.qred.dk/businessloan',
      CountryCode.DK,
      Language.English
    ),
  },
  [CountryCode.FI]: {
    [Language.Finnish]: buildNewLoanUrl(
      'https://www.qred.fi/yrityslaina',
      CountryCode.FI,
      Language.Finnish
    ),
    [Language.English]: buildNewLoanUrl(
      'https://www.qred.fi/en/business-loan',
      CountryCode.FI,
      Language.English
    ),
  },
  [CountryCode.BE]: {
    [Language.DutchBE]: buildNewLoanUrl(
      'https://www.qred.be/zakelijke-lening',
      CountryCode.BE,
      Language.Dutch
    ),
    [Language.French]: buildNewLoanUrl(
      'https://fr.qred.be/pret-professionnel',
      CountryCode.BE,
      Language.French
    ),
    [Language.English]: buildNewLoanUrl(
      'https://en.qred.be/zakelijke-lening',
      CountryCode.BE,
      Language.English
    ),
  },
  [CountryCode.NO]: {
    [Language.Norwegian]: buildNewLoanUrl(
      'https://www.qred.no/bedriftslan',
      CountryCode.NO,
      Language.Norwegian
    ),
    [Language.English]: buildNewLoanUrl(
      'https://en.qred.no/bedriftslan',
      CountryCode.NO,
      Language.English
    ),
  },
  [CountryCode.NL]: {
    [Language.Dutch]: buildNewLoanUrl(
      'https://www.qeld.com/zakelijke-lening',
      CountryCode.NL,
      Language.Dutch
    ),
  },
  [CountryCode.DE]: {
    [Language.German]: buildNewLoanUrl(
      'https://www.qred.de/firmenkredit',
      CountryCode.DE,
      Language.German
    ),
    [Language.English]: buildNewLoanUrl(
      'https://www.qred.de/en/business-loan',
      CountryCode.DE,
      Language.English
    ),
  },
};

export const PhoneNumberPlaceholders: MarketValues<string> = {
  [CountryCode.BE]: '+32-XXXXXXXXX',
  [CountryCode.DK]: 'XXXXXXXXX',
  [CountryCode.FI]: '+358 xx xxx xxxx',
  [CountryCode.NL]: 'XXXXXXXXXX',
  [CountryCode.SE]: '+46 XXX XXX XXX',
  [CountryCode.NO]: 'XXX-XX-XXX',
  // TODO: (DE) German phone number placeholder
  [CountryCode.DE]: '',
};
export const OrgNumberPlaceholders: MarketValues<string> = {
  [CountryCode.BE]: 'XXXXXXXXXX',
  [CountryCode.DK]: 'XXXXXXXX',
  [CountryCode.FI]: 'XXXXXXX-X',
  [CountryCode.NL]: 'XXXXXXXX',
  [CountryCode.SE]: 'XXXXXX-XXXX',
  [CountryCode.NO]: 'XXXXXXXXX',
  // TODO: (DE) German org number placeholder
  [CountryCode.DE]: '',
};

export const CompanyTitles: MarketValues<string> = {
  [CountryCode.BE]: 'Qred',
  [CountryCode.DK]: 'Qred',
  [CountryCode.FI]: 'Qred',
  [CountryCode.NL]: 'Qeld',
  [CountryCode.SE]: 'Qred',
  [CountryCode.NO]: 'Qred',
  [CountryCode.DE]: 'Qred',
};

export const ProductNames: MarketValues<string> = {
  [CountryCode.BE]: 'My Qred',
  [CountryCode.DK]: 'My Qred',
  [CountryCode.FI]: 'My Qred',
  [CountryCode.NL]: 'My Qeld',
  [CountryCode.SE]: 'My Qred',
  [CountryCode.NO]: 'My Qred',
  [CountryCode.DE]: 'My Qred',
};

export const FeedbackUrls: MarketValues<string> = {
  [CountryCode.BE]: '',
  [CountryCode.DK]: 'https://share.hsforms.com/1UJMEWOBgTB-PtsygMnHtIg227xd',
  [CountryCode.FI]: '',
  [CountryCode.NL]: '',
  [CountryCode.SE]: 'https://share.hsforms.com/12OP__sJPShCOvZutjxWgHA227xd',
  [CountryCode.NO]: 'https://share.hsforms.com/12OP__sJPShCOvZutjxWgHA227xd', // should norway have this link? (its in swedish)
  // TODO: (DE) German feedback URL
  [CountryCode.DE]: '',
};

const soleTraderPerMarket: Record<CountryCode, string[]> = {
  [CountryCode.SE]: ['EF', 'Enskild firma'],
  [CountryCode.FI]: [
    'TMI',
    'YEH',
    'YE',
    'Yksityinen elinkeinonharjoittaja',
    'TOIM',
    'Toiminimi',
  ],
  [CountryCode.NO]: ['ENK', 'Enkeltpersonsforetak'],
  [CountryCode.BE]: ['EZ'],
  [CountryCode.NL]: ['EZ'],
  [CountryCode.DK]: [
    'ENK',
    'Enkeltmandsvirksomhed',
    'PMV',
    'Personligt ejet mindre virksomhed',
    'EV',
    'PMV',
  ],
  // TODO: (DE) German sole trader types
  [CountryCode.DE]: [''],
};

export const companyIsSoleTrader = (
  market: CountryCode,
  companyType: string | null
) =>
  !!companyType &&
  soleTraderPerMarket[market].some(
    (type) => type.toUpperCase() === companyType.toUpperCase()
  );

const stockCompanyTypesPerMarket: Record<CountryCode, string[]> = {
  [CountryCode.SE]: ['ab'],
  [CountryCode.FI]: ['oy'],
  [CountryCode.NO]: ['as'],
  [CountryCode.BE]: ['bv', 'bvba', 'srl', 'sprl'],
  [CountryCode.NL]: ['bv'],
  [CountryCode.DK]: ['aps'],
  // TODO: (DE) German stock company types
  [CountryCode.DE]: [''],
};

export const companyIsStockCompany = (
  market: CountryCode,
  companyType: string | null
) =>
  !!companyType &&
  stockCompanyTypesPerMarket[market].some(
    (type) => type.toUpperCase() === companyType.toUpperCase()
  );

const marketsWithOnlyDigitPersonalNumbers: CountryCode[] = [CountryCode.SE];

export const personalNumberIsOnlyNumbers = (market: CountryCode) =>
  marketsWithOnlyDigitPersonalNumbers.includes(market);

const marketsWithOnlyDigitOrgNumbers: CountryCode[] = [
  CountryCode.DK,
  CountryCode.NO,
  CountryCode.NL,
  CountryCode.BE,
];

export const orgNumberIsOnlyNumbers = (market: CountryCode) =>
  marketsWithOnlyDigitOrgNumbers.includes(market);

export const trustPilotBusinessIdPerMarket: Record<CountryCode, string> = {
  SE: '55c351d80000ff000581d886',
  FI: '5a5dbad77e4c580001eb26e1',
  NL: '5ef08c0bc762fd00019a09a1',
  NO: '62668430b5f1fbd1ead9f157',
  DK: '59b2a60e0000ff0005aab3de',
  BE: '601a6f428668050001e90792',
  // TODO: (DE) German Trustpilot ID
  DE: '',
};

const marketsWithPersonalNumber: CountryCode[] = [
  CountryCode.SE,
  CountryCode.BE,
  CountryCode.DK,
  CountryCode.FI,
  CountryCode.NO,
];

export const getMarketHasPersonalNumber = (market: CountryCode) =>
  marketsWithPersonalNumber.includes(market);

const marketsWithAddress: CountryCode[] = [CountryCode.NL, CountryCode.DE];

export const getMarketHasAddress = (market: CountryCode) =>
  marketsWithAddress.includes(market);

const marketsHasAddGuarantorButtonNonFooter: Array<CountryCode> = [
  CountryCode.SE,
  CountryCode.BE,
  CountryCode.DK,
  CountryCode.FI,
  CountryCode.NO,
];

export const getMarketsHasAddGuarantorButtonNonFooter = (market: CountryCode) =>
  marketsHasAddGuarantorButtonNonFooter.includes(market);

const marketWithAddGuarantorButtonCopy: CountryCode[] = [CountryCode.NL];

export const getMarketHasAddGuarantorButtonCopy = (market: CountryCode) =>
  marketWithAddGuarantorButtonCopy.includes(market);

const marketOnlyAllowsBoardMemberAsGuarantor: CountryCode[] = [
  CountryCode.NL,
  CountryCode.DE,
];

export const getMarketOnlyAllowsBoardMemberAsGuarantor = (
  market: CountryCode
) => marketOnlyAllowsBoardMemberAsGuarantor.includes(market);

const marketOnlyHasEmailLogin: CountryCode[] = [CountryCode.NL];

export const getMarketOnlyHasEmailLogin = (market: CountryCode) =>
  marketOnlyHasEmailLogin.includes(market);

const marketShouldAskApplicantForFullName: CountryCode[] = [
  CountryCode.NL,
  CountryCode.BE,
];

export const getMarketShouldAskApplicantForFullName = (market: CountryCode) =>
  marketShouldAskApplicantForFullName.includes(market);

const marketsHaveMandatoryTurnover: CountryCode[] = [CountryCode.NL];

export const getMarketHasMandatoryTurnover = (market: CountryCode) =>
  marketsHaveMandatoryTurnover.includes(market);

const marketRequiresLoginBeforeSubmittingApplication: Array<CountryCode> = [];

export const getMarketRequiresLoginBeforeSubmittingApplication = (
  market: CountryCode
) => marketRequiresLoginBeforeSubmittingApplication.includes(market);

const homePageByMarket: Record<CountryCode, PartialRecord<Language, string>> = {
  [CountryCode.SE]: {
    [Language.Swedish]: 'https://www.qred.se',
    [Language.English]: 'https://en.qred.se',
  },
  [CountryCode.FI]: {
    [Language.Finnish]: 'https://www.qred.fi',
    [Language.English]: 'https://en.qred.fi',
  },
  [CountryCode.NO]: {
    [Language.Norwegian]: 'https://www.qred.no',
    [Language.English]: 'https://en.qred.no',
  },
  [CountryCode.BE]: {
    [Language.DutchBE]: 'https://www.qred.be',
    [Language.French]: 'https://fr.qred.be',
    [Language.English]: 'https://en.qred.be',
  },
  [CountryCode.DK]: {
    [Language.Danish]: 'https://www.qred.dk',
    [Language.English]: 'https://en.qred.dk',
  },
  [CountryCode.NL]: {
    [Language.Dutch]: 'https://www.qeld.com',
  },
  [CountryCode.DE]: {
    [Language.German]: 'https://www.qred.de',
    [Language.English]: 'https://en.qred.de',
  },
};

const marketShouldShowCreditCheckCopyTooltip: Array<CountryCode> = [
  CountryCode.SE,
  CountryCode.NO,
];

export const getMarketShouldShowCreditCheckCopyTooltip = (
  market: CountryCode
) => marketShouldShowCreditCheckCopyTooltip.includes(market);

export const getHomePageByMarket = (market: CountryCode, language: Language) =>
  homePageByMarket?.[market]?.[language] || 'https://www.qred.com';

export const LoanAmountDefaultValues: Record<CountryCode, IValueDefaults> = {
  [CountryCode.SE]: {
    clcMin: 10000,
    min: 50000,
    max: 5000000,
    step: 10000,
  },
  [CountryCode.NO]: {
    clcMin: 10000,
    min: 50000,
    max: 5000000,
    step: 10000,
  },
  [CountryCode.NL]: {
    clcMin: 1000,
    min: 5000,
    max: 500000,
    step: 1000,
  },
  [CountryCode.FI]: {
    clcMin: 1000,
    min: 5000,
    max: 500000,
    step: 1000,
  },
  [CountryCode.DK]: {
    clcMin: 10000,
    min: 10000,
    max: 3000000,
    step: 10000,
  },
  [CountryCode.BE]: {
    clcMin: 1000,
    min: 1000,
    max: 500000,
    step: 1000,
  },
  [CountryCode.DE]: {
    clcMin: 5000,
    min: 5000,
    max: 500000,
    step: 1000,
  },
};

const marketHasGuarantorSubheader = [CountryCode.BE];
export const getMarketHasGuarantorSubheader = (market: CountryCode) =>
  marketHasGuarantorSubheader.includes(market);

const marketShouldShowAccountNumberInputFieldByDefault: Array<CountryCode> = [
  CountryCode.BE,
  CountryCode.DK,
  CountryCode.FI,
  CountryCode.NO,
  CountryCode.NL,
  CountryCode.DE,
];

export const getMarketShouldShowAccountNumberInputFieldByDefault = (
  market: CountryCode
) => marketShouldShowAccountNumberInputFieldByDefault.includes(market);

const marketHasRoaring: CountryCode[] = [
  CountryCode.SE,
  CountryCode.DK,
  CountryCode.NO,
  CountryCode.FI,
];

export const getMarketHasRoaring = (market: CountryCode) =>
  marketHasRoaring.includes(market);

const marketShouldFetchBeneficialOwners: CountryCode[] = [CountryCode.DE];
export const getMarketShouldFetchBeneficialOwners = (market: CountryCode) =>
  marketShouldFetchBeneficialOwners.includes(market);

const marketRequiresPersonalNumberForFetchedOwners: CountryCode[] = [
  CountryCode.SE,
];

export const getMarketRequiresPersonalNumberForFetchedOwners = (
  market: CountryCode
) => marketRequiresPersonalNumberForFetchedOwners.includes(market);

const marketRequiresDateOfBirthForFetchedOwners: CountryCode[] = [
  CountryCode.FI,
  CountryCode.DE,
];

export const getMarketRequiresDateOfBirthForFetchedOwners = (
  market: CountryCode
) => marketRequiresDateOfBirthForFetchedOwners.includes(market);

const marketRequiresYearOfBirthForFetchedOwners: CountryCode[] = [
  CountryCode.NO,
];

export const getMarketRequiresYearOfBirthForFetchedOwners = (
  market: CountryCode
) => marketRequiresYearOfBirthForFetchedOwners.includes(market);

const marketRequiresCountryOfResidenceForFetchedOwners: CountryCode[] = [
  CountryCode.SE,
  CountryCode.DK,
  CountryCode.FI,
  CountryCode.DE,
];

export const getMarketRequiresCountryOfResidenceForFetchedOwners = (
  market: CountryCode
) => marketRequiresCountryOfResidenceForFetchedOwners.includes(market);

const marketHasFetchedOwnershipSharesAsInteger: CountryCode[] = [
  CountryCode.DK,
  CountryCode.NO,
  CountryCode.FI,
  CountryCode.DE,
];

export const getMarketHasFetchedOwnershipSharesAsInteger = (
  market: CountryCode
) => marketHasFetchedOwnershipSharesAsInteger.includes(market);

const marketHasFetchedOwnershipSharesAsRanges = [CountryCode.SE];

export const getMarketHasFetchedOwnershipSharesAsRanges = (
  market: CountryCode
) => marketHasFetchedOwnershipSharesAsRanges.includes(market);

const marketHasMultipleProducts = [CountryCode.SE, CountryCode.FI];

export const getMarketHasMultipleProducts = (market: CountryCode) =>
  marketHasMultipleProducts.includes(market);

const marketHasTrustpilot = [
  CountryCode.BE,
  CountryCode.DK,
  CountryCode.FI,
  CountryCode.NO,
  CountryCode.NL,
  CountryCode.SE,
];

export const getMarketHasTrustpilot = (market: CountryCode) =>
  marketHasTrustpilot.includes(market);

export const termsAndConditionPdfSources: MarketValues<{
  qred: string;
  nordiska: string;
}> = {
  [CountryCode.NL]: {
    qred: '/nl_loan_toc_qeld.pdf',
    nordiska: '/nl_loan_toc_nordiska.pdf',
  },
  [CountryCode.SE]: {
    qred: '/se_loan_toc_qred.pdf',
    nordiska: '/se_loan_toc_nordiska.pdf',
  },
  [CountryCode.FI]: {
    qred: '/fi_loan_toc_qred.pdf',
    nordiska: '/fi_loan_toc_nordiska.pdf',
  },
  [CountryCode.NO]: {
    qred: '/no_loan_toc_qred.pdf',
    nordiska: '/no_loan_toc_nordiska.pdf',
  },
  [CountryCode.DK]: {
    qred: '/dk_loan_toc_qred.pdf',
    nordiska: '/dk_loan_toc_nordiska.pdf',
  },
  [CountryCode.BE]: {
    qred: '/be_loan_toc_qred.pdf',
    nordiska: '',
  },
  [CountryCode.DE]: {
    qred: '/de_loan_toc_qred.pdf',
    nordiska: '',
  },
};

export const marketIso3Code: MarketValues<string> = {
  [CountryCode.SE]: 'SWE',
  [CountryCode.FI]: 'FIN',
  [CountryCode.NO]: 'NOR',
  [CountryCode.DK]: 'DNK',
  [CountryCode.BE]: 'BEL',
  [CountryCode.NL]: 'NLD',
  [CountryCode.DE]: 'DEU',
};

const marketHasCompanyCreditCheck = [CountryCode.DE];

export const getMarketHasCompanyCreditCheck = (market: CountryCode) =>
  marketHasCompanyCreditCheck.includes(market);

export const companyCreditCheckAgencies: MarketValues<
  { label: string; pdfPath: string; id: string }[]
> = {
  [CountryCode.DE]: [
    {
      id: 'schufa',
      label: 'SCHUFA Holding AG',
      pdfPath: '/company_credit_check_schufa_de.pdf',
    },
    {
      id: 'crif',
      label: 'CRIF GmbH',
      pdfPath: '/company_credit_check_crif_de.pdf',
    },
  ],
  [CountryCode.BE]: [],
  [CountryCode.DK]: [],
  [CountryCode.FI]: [],
  [CountryCode.NL]: [],
  [CountryCode.NO]: [],
  [CountryCode.SE]: [],
};

const marketCanSearchCompanyByName = [
  CountryCode.SE,
  CountryCode.FI,
  CountryCode.DK,
  CountryCode.NL,
  CountryCode.BE,
  CountryCode.DE,
];
export const getMarketCanSearchCompanyByName = (market: CountryCode) =>
  marketCanSearchCompanyByName.includes(market);

const marketOnlyAllowsIbanAccountNumbers: CountryCode[] = [
  CountryCode.BE,
  CountryCode.NL,
  CountryCode.FI,
  CountryCode.DE,
];

export const getMarketOnlyAllowsIbanAccountNumbers = (market: CountryCode) =>
  marketOnlyAllowsIbanAccountNumbers.includes(market);

export const connectBankTermsAndConditionsLink: MarketLanguagePartial = {
  [CountryCode.SE]: {
    [Language.Swedish]: 'https://www.qred.se/info/konto-information-tjanst',
    [Language.English]: 'https://en.qred.se/info/konto-information-tjanst',
  },
  [CountryCode.FI]: {
    [Language.Finnish]: 'https://www.qred.fi/info/tilitietopalvelu',
    [Language.English]: 'https://en.qred.fi/info/tilitietopalvelu',
  },
  [CountryCode.NO]: {
    [Language.Norwegian]: 'https://www.qred.no/info/kontoinformasjonstjeneste',
    [Language.English]: 'https://en.qred.no/info/kontoinformasjonstjeneste',
  },
  [CountryCode.DK]: {
    [Language.Danish]: 'https://www.qred.dk/info/konto-information-service',
    [Language.English]: 'https://en.qred.dk/info/konto-information-service',
  },
  [CountryCode.BE]: {
    [Language.DutchBE]: 'https://www.qred.be/info/account-informatie-service',
    [Language.English]: 'https://en.qred.be/info/account-informatie-service',
    [Language.French]: 'https://fr.qred.be/info/account-informatie-service',
  },
  [CountryCode.NL]: {
    [Language.Dutch]: 'https://www.qeld.com/info/account-informatie-service',
  },
  [CountryCode.DE]: {
    [Language.German]: 'https://www.qred.de/info/kontoinformationsdienst',
    [Language.English]: 'https://www.qred.de/en/info/kontoinformationsdienst',
  },
};

const marketHasEskat = [CountryCode.DK];
export const getMarketHasEskat = (market: CountryCode) =>
  marketHasEskat.includes(market);

export const marketHasCheckpointStep: Record<CountryCode, boolean> = {
  [CountryCode.NL]: true,
  [CountryCode.SE]: false,
  [CountryCode.FI]: false,
  [CountryCode.NO]: false,
  [CountryCode.DK]: false,
  [CountryCode.BE]: false,
  [CountryCode.DE]: false,
};
