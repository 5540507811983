import { CountryCode } from '~/enums';
import { StepConfigItem } from '~/Interfaces';
import ContractSummary from './ContractSummary/ContractSummary';
import LoanOfferAdjustment from './LoanOfferAdjustment/LoanOfferAdjustment';
import Ownership from './Ownership/Ownership';
import AccountNumber from './AccountNumber/AccountNumber';
import Signers from './Signers/Signers';
import OwnershipFooter from './Ownership/OwnershipFooter';
import KYCQuestionnaire from './KYCQuestionnaire/KYCQuestionnaire';
import LoanPurpose from './LoanPurpose/LoanPurpose';
import LoanOfferAdjustmentHeader from './LoanOfferAdjustment/LoanOfferAdjustmentHeader';
import BankInformation from './BankInformation/BankInformation';
import BankInformationHeader from './BankInformation/components/BankInformationHeader';

const LoanOfferAdjustmentStep: StepConfigItem = {
  stepContent: [LoanOfferAdjustment],
  customHeader: LoanOfferAdjustmentHeader,
  name: 'adjustment_step',
};

const AccountNumberStep: StepConfigItem = {
  stepContent: [AccountNumber],
  header: {
    title: 'OnboardingOffer.ConfirmBankAccountTitle',
  },
  name: 'account_number_step',
};

const BankInformationStep: StepConfigItem = {
  stepContent: [BankInformation],
  customHeader: BankInformationHeader,
  name: 'bank_information_step',
};

const OwnershipStep: StepConfigItem = {
  stepContent: [Ownership],
  header: {
    title: 'OnboardingOffer.Ownership',
  },
  customFooter: OwnershipFooter,
  name: 'ownership_step',
};

const ContractSummaryStep: StepConfigItem = {
  stepContent: [ContractSummary],
  name: 'contract_summary_step',
};

const LoanPurposeStep: StepConfigItem = {
  stepContent: [LoanPurpose],
  header: {
    title: 'Onboarding.SelectPurposeTitle',
    description: 'Onboarding.SelectPurposeDescription',
  },
  name: 'loan_purpose_step',
};

const KYCQuestionnaireStep: StepConfigItem = {
  header: {
    title: 'OnboardingOffer.KYCQuestionnaire',
    description: 'OnboardingOffer.KYCQuestionnaireDescription',
  },
  stepContent: [KYCQuestionnaire],
  name: 'kyc_questionnaire_step',
};

const SignersStep: StepConfigItem = {
  stepContent: [Signers],
  header: {
    title: 'OnboardingOffer.AdditionalSignersAndGuarantorsTitle',
    description: 'OnboardingOffer.AdditionalSignersAndGuarantorsDescription',
  },
  name: 'additional_signers_and_guarantors_step',
};

export const stepConfigPerMarket: Record<CountryCode, StepConfigItem[]> = {
  [CountryCode.SE]: [
    LoanOfferAdjustmentStep,
    LoanPurposeStep,
    BankInformationStep,
    AccountNumberStep,
    OwnershipStep,
    KYCQuestionnaireStep,
    SignersStep,
    ContractSummaryStep,
  ],
  [CountryCode.FI]: [
    LoanOfferAdjustmentStep,
    LoanPurposeStep,
    BankInformationStep,
    AccountNumberStep,
    OwnershipStep,
    KYCQuestionnaireStep,
    SignersStep,
    ContractSummaryStep,
  ],
  [CountryCode.NO]: [
    LoanOfferAdjustmentStep,
    LoanPurposeStep,
    BankInformationStep,
    AccountNumberStep,
    OwnershipStep,
    KYCQuestionnaireStep,
    SignersStep,
    ContractSummaryStep,
  ],
  [CountryCode.DK]: [
    LoanOfferAdjustmentStep,
    LoanPurposeStep,
    BankInformationStep,
    AccountNumberStep,
    OwnershipStep,
    KYCQuestionnaireStep,
    SignersStep,
    ContractSummaryStep,
  ],
  [CountryCode.BE]: [
    LoanOfferAdjustmentStep,
    LoanPurposeStep,
    BankInformationStep,
    OwnershipStep,
    KYCQuestionnaireStep,
    SignersStep,
    ContractSummaryStep,
  ],
  [CountryCode.NL]: [
    LoanOfferAdjustmentStep,
    LoanPurposeStep,
    BankInformationStep,
    AccountNumberStep,
    OwnershipStep,
    KYCQuestionnaireStep,
    SignersStep,
    ContractSummaryStep,
  ],
  [CountryCode.DE]: [
    LoanOfferAdjustmentStep,
    BankInformationStep,
    LoanPurposeStep,
    OwnershipStep,
    SignersStep,
    KYCQuestionnaireStep,
    ContractSummaryStep,
  ],
};
