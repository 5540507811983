import React, { useMemo, useState } from 'react';
import {
  PostHogContext,
  PostHogEvent,
} from '~/contexts/PostHogProvider/posthog.context';
import posthog from 'posthog-js';
import { PostHogProvider as NativePostHogProvider } from 'posthog-js/react';
import { getMarketBasedOnHostname } from '~/helpers/utils';
import { logToSentry } from '~/helpers/loggers.helper';

interface PostHogProviderProps {
  children: React.ReactNode;
}

posthog.init(import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
  capture_pageview: true,
  capture_pageleave: true,
  capture_heatmaps: true,
  enable_heatmaps: true,
  autocapture: true,
  disable_session_recording: import.meta.env.MODE !== 'production',
  session_recording: {
    maskAllInputs: true,
    maskInputFn: (text, element) => {
      if (element?.dataset['record'] === 'true') {
        return text;
      }
      return '*'.repeat(text.length);
    },
    maskTextSelector: '*',
    maskTextFn: (text, element) => {
      try {
        const elementIsMaterialSymbol = element?.className?.includes?.(
          'material-symbols'
        );
        const elementIsMarkedForRecording =
          element?.dataset?.['record'] === 'true';

        if (elementIsMarkedForRecording || elementIsMaterialSymbol) {
          return text;
        }

        const maskingCharacterRepeatCount = text?.length || 1;
        return '*'.repeat(maskingCharacterRepeatCount);
      } catch (error) {
        logToSentry(error, 'Error masking text in session recording');
        return '*';
      }
    },
  },
  loaded: () => {
    const marketFromDomain = getMarketBasedOnHostname();
    if (marketFromDomain) {
      posthog.setPersonProperties({
        market: marketFromDomain,
      });
    }
    posthog.setPersonProperties({
      isAuthenticated: false,
    });
  },
});

const PostHogProvider = ({ children }: PostHogProviderProps) => {
  const [event, setEvent] = useState<PostHogEvent>(null as any);
  const memoizedValue = useMemo(() => ({ event, setEvent }), [event, setEvent]);

  return (
    <NativePostHogProvider client={posthog}>
      <PostHogContext.Provider value={memoizedValue}>
        {children}
      </PostHogContext.Provider>
    </NativePostHogProvider>
  );
};

export default PostHogProvider;
