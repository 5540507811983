import {
  Group,
  Stack,
  MaterialIcon,
  H8,
  Body,
  Link,
} from '@qred/components-library';
import React, { Ref } from 'react';
import { formatSize } from '~/helpers/formatters.helper';
import useTranslate from '~/hooks/useTranslate';
import { IOnboardingFile } from '~/interfaces/Onboarding';

interface IDocumentList {
  files: IOnboardingFile[];
  fileListRef: Ref<HTMLDivElement>;
  onDeleteAll: () => void;
  handleRemoveFile: (File: IOnboardingFile) => void;
}
const DocumentList: React.FC<IDocumentList> = ({
  files,
  fileListRef,
  onDeleteAll,
  handleRemoveFile,
}) => {
  const t = useTranslate();

  return (
    <Stack>
      <Group justify="space-between">
        <H8>{`${t('Onboarding.UploadedFilesListTitle')} (${files.length})`}</H8>
        {files.length > 1 && (
          <Link onClick={onDeleteAll} size="sm">
            {t('Onboarding.RemoveAllFiles')}
          </Link>
        )}
      </Group>
      <Group spacing={'md'} ref={fileListRef} direction="column">
        {files.map((localFile: IOnboardingFile, i, arr) => (
          <Group
            key={localFile.objectURL}
            align="center"
            nowrap
            justify="space-between"
            display="flex"
          >
            <Body size="lg" align="left">
              {localFile.name}
            </Body>

            <Group direction="row" align="center" nowrap>
              <Body size="lg" whiteSpace="nowrap">
                {formatSize(localFile.size)}
              </Body>
              <MaterialIcon
                src="delete"
                size="24"
                onClick={() => handleRemoveFile(localFile)}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Group>
          </Group>
        ))}
      </Group>
    </Stack>
  );
};

export default DocumentList;
