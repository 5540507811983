import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { CompanyTitles } from '~/constants/markets';
import MeansOfSupport from '../../MeansOfSupport/MeansOfSupport';
import { Body, H7, Stack } from '@qred/components-library';

const SignedOfferNoAccountError = () => {
  const market = useSelector((state: RootState) => state.intl.market);
  const translate = useTranslate();

  return (
    <Stack>
      <H7>
        {translate('SignedOfferNoAccountError.ErrorMessageTitle', {
          title: CompanyTitles[market],
        })}
      </H7>
      <Body size="lg">
        {translate('SignedOfferNoAccountError.ErrorMessageText1')}
      </Body>
      <Body size="lg">
        {translate('SignedOfferNoAccountError.ErrorMessageText2')}
      </Body>
      <Body size="md">{translate('ContactNeedHelp')}</Body>
      <MeansOfSupport />
    </Stack>
  );
};

export default SignedOfferNoAccountError;
