import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Group, Icon, Stack } from '@qred/components-library';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import useTranslate from '~/hooks/useTranslate';
import { ValidationContext } from '~/components/hoc/withValidation';
import { useCardOnboardingOfferSelectors } from '~/store/selectors/cardOnboardingOffer.selector';
import { updateForm } from '~/store/slices/cardOnboardingOffer.slice';
import { isBeneficialOwner } from '~/helpers/onboarding.helper';
import OwnershipPercentagePanel from './components/OwnershipPercentagePanel/OwnershipPercentagePanel';
import OwnershipAdditionalOwnersPanel from './components/OwnershipAdditionalOwnersPanel/OwnershipAdditionalOwnersPanel';
import OwnershipUploadDocumentationPanel from './components/OwnershipUploadDocumentationPanel/OwnershipUploadDocumentationPanel';
import FetchedBeneficialOwners from './components/FetchedBeneficialOwners/FetchedBeneficialOwners';

interface OwnershipAccordionItems {
  'your-ownership-item': boolean;
  'additional-owners-item': boolean;
  'upload-documentation-item': boolean;
}

interface OwnershipErrors {
  totalOwnershipSharesMoreThan100: boolean;
  applicantOwnershipPercentageMoreThan100: boolean;
  additionalBeneficialOwnersRequired: boolean;
  alternativeBeneficialOwnersRequired: boolean;
  applicantOwns100PercentOfSharesQuestionRequired: boolean;
  applicantOwnershipPercentageRequired: boolean;
}
interface AccordionItem {
  id: keyof OwnershipAccordionItems;
  label: string;
  content: React.ReactNode;
  disabled?: boolean;
  isCompleted: boolean;
}

const Ownership = () => {
  const [ownershipErrors, setOwnershipErrors] = useState<OwnershipErrors>({
    totalOwnershipSharesMoreThan100: false,
    applicantOwnershipPercentageMoreThan100: false,
    additionalBeneficialOwnersRequired: false,
    alternativeBeneficialOwnersRequired: false,
    applicantOwns100PercentOfSharesQuestionRequired: false,
    applicantOwnershipPercentageRequired: false,
  });
  const validationContext = useContext(ValidationContext);

  const dispatch = useDispatch();
  const {
    applicant,
    owners,
    companyHasOtherBeneficialOwners,
    ownershipFiles,
  } = useSelector((state: RootState) => state.cardOnboardingOffer.form);

  const t = useTranslate();
  const {
    totalAmountOfShares,
    applicantIsSoloBeneficialOwner,
    applicantIsABeneficialOwner,
    applicantHasAnsweredBeneficialOwnersQuestion,
    ownersDataIsFetched,
    companyIsStockCompany: show100PercentOwnershipQuestion,
    applicantHasAnswered100PercentOwnershipQuestion,
  } = useCardOnboardingOfferSelectors();

  const [
    openedAccordionItems,
    setOpenedAccordionItems,
  ] = useState<OwnershipAccordionItems>({
    'your-ownership-item': true,
    'additional-owners-item':
      !!owners.length || applicantHasAnsweredBeneficialOwnersQuestion,
    'upload-documentation-item': !!ownershipFiles.length,
  });

  const openItem = (itemId: keyof OwnershipAccordionItems) => {
    if (!openedAccordionItems[itemId]) {
      setOpenedAccordionItems((prev) => ({ ...prev, [itemId]: true }));
    }
  };

  const onSharesChange = (percentage: string) => {
    if (Number(percentage) >= 75 && companyHasOtherBeneficialOwners) {
      dispatch(updateForm({ companyHasOtherBeneficialOwners: false }));
    }

    const shouldOpenUpAdditionalOwnersItem =
      percentage && Number(percentage) < 75;

    if (shouldOpenUpAdditionalOwnersItem) {
      openItem('additional-owners-item');
    }
  };

  const applicantOwnershipPercentageIsValid =
    !!applicant.percentage &&
    !ownershipErrors.applicantOwnershipPercentageMoreThan100;

  const yourOwnerShipPanelIsCompleted = applicantOwnershipPercentageIsValid;

  // Check if the total ownership shares do not exceed 100%
  const totalOwnershipSharesIsValid = !ownershipErrors.totalOwnershipSharesMoreThan100;

  // Check if the additional beneficial owners are not required
  const additionalBeneficialOwnersAreNotRequired = !ownershipErrors.additionalBeneficialOwnersRequired;

  // Check if the alternative beneficial owners are not required
  const alternativeBeneficialOwnersAreNotRequired = !ownershipErrors.alternativeBeneficialOwnersRequired;

  // Combine the above conditions to check if the additional owners are valid
  const additionalOwnersAreValid =
    totalOwnershipSharesIsValid &&
    additionalBeneficialOwnersAreNotRequired &&
    alternativeBeneficialOwnersAreNotRequired;

  // Check if there are any owners or if the applicant has answered the beneficial owners question
  // and the company does not have other beneficial owners and the applicant is a beneficial owner
  const ownersExistOrApplicantIsBeneficialOwner =
    !!owners.length ||
    (applicantHasAnsweredBeneficialOwnersQuestion &&
      !companyHasOtherBeneficialOwners &&
      applicantIsABeneficialOwner);

  // Combine the above conditions to check if the additional owners panel is completed
  const additionalOwnersPanelIsCompleted =
    additionalOwnersAreValid && ownersExistOrApplicantIsBeneficialOwner;

  const uploadDocumentationPanelIsCompleted = !!ownershipFiles.length;

  const accordionItems: AccordionItem[] = [
    {
      id: 'your-ownership-item',
      label: t('OnboardingOffer.YourOwnershipPanelLabel') as string,
      content: <OwnershipPercentagePanel onSharesChange={onSharesChange} />,
      isCompleted: yourOwnerShipPanelIsCompleted,
    },
    {
      id: 'additional-owners-item',
      label: t('OnboardingOffer.AdditionalOwnersPanelLabel') as string,
      content: <OwnershipAdditionalOwnersPanel />,
      disabled:
        !yourOwnerShipPanelIsCompleted || Number(applicant.percentage) >= 75,
      isCompleted: additionalOwnersPanelIsCompleted,
    },
    {
      id: 'upload-documentation-item',
      label: t('OnboardingOffer.UploadDocumentationPanelLabel') as string,
      content: <OwnershipUploadDocumentationPanel />,
      disabled:
        !companyHasOtherBeneficialOwners && !applicantIsABeneficialOwner,
      isCompleted: uploadDocumentationPanelIsCompleted,
    },
  ];

  const activeItemIds = accordionItems
    .filter((item) => openedAccordionItems[item.id] && !item.disabled)
    .map((item) => item.id);

  useEffect(() => {
    const listHasNoBeneficialOwners = owners.every(
      (owner) => !isBeneficialOwner(owner.percentage)
    );
    const listHasNoAlternativeOwners = !owners.length;

    setOwnershipErrors({
      totalOwnershipSharesMoreThan100: totalAmountOfShares > 100,
      applicantOwnershipPercentageMoreThan100:
        !!applicant.percentage && Number(applicant.percentage) > 100,
      additionalBeneficialOwnersRequired:
        !!companyHasOtherBeneficialOwners && listHasNoBeneficialOwners,
      alternativeBeneficialOwnersRequired:
        !!applicant.percentage &&
        !applicantIsSoloBeneficialOwner &&
        !companyHasOtherBeneficialOwners &&
        listHasNoAlternativeOwners,
      applicantOwns100PercentOfSharesQuestionRequired:
        !ownersDataIsFetched &&
        show100PercentOwnershipQuestion &&
        !applicantHasAnswered100PercentOwnershipQuestion,
      applicantOwnershipPercentageRequired:
        !ownersDataIsFetched && !applicant.percentage,
    });
  }, [
    owners,
    totalAmountOfShares,
    applicant.percentage,
    companyHasOtherBeneficialOwners,
    applicantIsSoloBeneficialOwner,
    ownersDataIsFetched,
    show100PercentOwnershipQuestion,
    applicantHasAnswered100PercentOwnershipQuestion,
  ]);

  useEffect(() => {
    Object.keys(ownershipErrors).forEach((key) => {
      if (ownershipErrors[key as keyof OwnershipErrors]) {
        validationContext.addPropertyToValidationErrors(key);
      }
    });

    return () => {
      Object.keys(ownershipErrors).forEach((key) => {
        validationContext.removePropertyFromValidationErrors(key);
      });
    };
  }, [ownershipErrors]);

  const onAccordionChange = (value: (keyof OwnershipAccordionItems)[]) => {
    setOpenedAccordionItems({
      'your-ownership-item': value.includes('your-ownership-item'),
      'additional-owners-item': value.includes('additional-owners-item'),
      'upload-documentation-item': value.includes('upload-documentation-item'),
    });
  };

  return (
    <Stack spacing={'xl'}>
      {ownersDataIsFetched ? (
        <FetchedBeneficialOwners />
      ) : (
        <Accordion multiple value={activeItemIds} onChange={onAccordionChange}>
          {accordionItems.map((item) => (
            <Accordion.Item value={item.id} key={item.id}>
              <Accordion.Control
                disabled={item.disabled}
                style={{
                  ...(item.disabled && {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                  }),
                }}
              >
                <Group align="center">
                  <Icon
                    src={
                      item.isCompleted ? 'CheckCircle' : 'CheckCircleUnfilled'
                    }
                    size="lg"
                    iconSize={16}
                  />
                  {item.label}
                </Group>
              </Accordion.Control>
              <Accordion.Panel>{item.content}</Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </Stack>
  );
};

export default Ownership;
