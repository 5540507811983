import React from 'react';
import { Stack, Button, Group, Link, Body } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import { useSelector } from 'react-redux';
import { getSupportUrl } from '~/constants/markets';
import { CountryCode } from '~/enums';

interface AdditionalSignerOTPErrorModalProps {
  onClose: () => void;
}

const AdditionalSignerOTPErrorModal = ({
  onClose,
}: AdditionalSignerOTPErrorModalProps) => {
  const translate = useTranslate();

  const {
    intl: { language, market },
  } = useSelector((state: RootState) => state);

  const contactUsOnClick = () => {
    if (!language) return;
    const supportUrl = getSupportUrl(market as CountryCode, language);
    window.open(supportUrl, '_blank');
  };

  return (
    <Stack>
      <Body size="lg">
        {translate('AdditionalSigner.ErrorBody', {
          support: (
            <Link size="lg" onClick={contactUsOnClick}>
              {translate('AdditionalSigner.SupportTeam')}
            </Link>
          ),
        })}
      </Body>

      <Group justify="flex-end" mt={10}>
        <Button onClick={onClose}>
          {translate('AdditionalSigner.ErrorClose')}
        </Button>
      </Group>
    </Stack>
  );
};

export default AdditionalSignerOTPErrorModal;
