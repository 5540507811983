import React from 'react';
import { useSelector } from 'react-redux';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { Stack, H7 } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import { Content } from './ConnectToBank.styled';

const ConnectToBank = () => {
  const {
    klarnaKosma: { transactionFlow },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();

  return (
    <Content>
      {transactionFlow.status === 'inProgress' && (
        <Stack>
          <H7>{translate('Onboarding.ConnectingToKlarna')}</H7>
          <SpinnerCircular color={theme.colors.secondaryGray} />
        </Stack>
      )}
    </Content>
  );
};

export default ConnectToBank;
