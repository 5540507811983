import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { CountryCode } from '~/enums';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { accessToken } from '~/store/apis/utils/accessToken';
import { useAuth0 } from '@auth0/auth0-react';
import Routes from './routes';
import GlobalErrorPopup from './components/shared/GlobalErrorPopup/GlobalErrorPopup';
import ErrorFallback from './components/shared/ErrorFallback/ErrorFallback';
import ScrollToTop from './components/shared/ScrolToTop/ScrolToTop';
import HubspotChat from './components/shared/HubspotChat/HubspotChat';
import HelpPopup from './components/shared/HelpPopup/HelpPopup';
import GlobalStyle from './styles/index.css';
import { updateMatchedMedia } from './store/slices/matchedMedia.slice';
import { ErrorBoundary } from 'react-error-boundary';
import posthog from 'posthog-js';
import { ComponentsLibraryProvider } from '@qred/components-library';

const Main: React.FC<RouteComponentProps<any>> = () => {
  const { market, marketIsSet } = useSelector((state: RootState) => state.intl);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const onError = (error: Error) => {
    console.error(error);
  };

  useEffect(() => {
    if (market === CountryCode.NL) {
      document.title = 'Qeld | My Qeld';
    }

    return () => {
      document.title = 'Qred | My Qred';
    };
  }, [market]);

  useEffect(() => {
    if (market && marketIsSet) {
      posthog.setPersonProperties({
        market,
      });
      posthog.setPersonPropertiesForFlags({
        market,
      });
    }
  }, [market, marketIsSet]);

  useEffect(() => {
    if (!isLoading) {
      posthog.setPersonProperties({
        isAuthenticated,
      });
    }
  }, [isAuthenticated, isLoading]);

  const handleResize = () => {
    dispatch(updateMatchedMedia());
    document.documentElement.style.setProperty(
      '--client-height',
      `${window.innerHeight}px`
    );
  };

  useEffect(() => {
    dispatch(updateMatchedMedia());
    window.addEventListener('resize', handleResize);
    document.documentElement.style.setProperty(
      '--client-height',
      `${window.innerHeight}px`
    );
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    accessToken.set(getAccessTokenSilently);
  }, []);

  return (
    <React.StrictMode>
      <ErrorBoundary onError={onError} fallback={<ErrorFallback />}>
        <GlobalStyle />
        <ScrollToTop />
        <ComponentsLibraryProvider>
          <GlobalErrorPopup />
        </ComponentsLibraryProvider>
        <HelpPopup />
        <HubspotChat />
        <Routes />
      </ErrorBoundary>
    </React.StrictMode>
  );
};

export default withRouter(Main);
