import { PaginationResponse } from '~/store/apis/types/Pagination';
import {
  GetActiveLoanResponseDto,
  GetLoanInvoicesResponseDto,
  GetLoanStatementResponseDto,
  InvoiceStatus,
} from '~/store/apis/dtos/loan/loans.dto';
import { CountryCode } from '~/enums';
import {
  formatValueWithLocalisedCurrency,
  formatValueWithLocalisedDate,
} from '@qred/fe-cp-formatting-helpers';
import { Invoice } from '~/models/loans/invoice';
import { Statement } from '~/models/loans/statement';
import { Loan } from '~/models/loans/loan';
import dayjs from 'dayjs';

export const getLoanActiveMapped = (
  response: GetActiveLoanResponseDto,
  market: CountryCode
): Loan => ({
  id: response.id,
  contractNumber: response.contractNumber,
  status: response.status,
  amount: formatValueWithLocalisedCurrency(response.amount, market),
  term: response.term,
  startDate: formatValueWithLocalisedDate(response.startDate, market),
  closedDate: formatValueWithLocalisedDate(response.closedDate, market),
  amountPaid: formatValueWithLocalisedCurrency(response.amountPaid, market),
  amountLeft: {
    label: formatValueWithLocalisedCurrency(response.amountLeft, market),
    value: response.amountLeft,
  },
  monthsProlonged: response.monthsProlonged,
  grantedByExternal: response.grantedByExternal,
  percentagePaid: Math.round(
    (response.amountPaid / (response.amountPaid + response.amountLeft)) * 100
  ),
  totalAmount: formatValueWithLocalisedCurrency(
    response.amountPaid + response.amountLeft,
    market
  ),
  isOpen: response.status === 'Open_Active' || response.status === 'Open_Late',
  isLate: response.status === 'Open_Late',
  isCollection: response.status === 'Open_In_Collection',
  amoFreeMonthsEligible: response.amoFreeMonthsEligible,
  terminationLetterSent: response.terminationLetterSent,
  paidOut: response.paidOut,
  directDebitEnabled: response.directDebitEnabled,
  signer: response.signer,
});

export const getLoanCurrentInvoiceMapped = (
  response: PaginationResponse<GetLoanInvoicesResponseDto>,
  market: CountryCode
): Invoice | undefined => {
  const invoice = response.content.filter((i) =>
    (['NEW', 'OVERDUE', 'COLLECTION', 'PENDING'] as InvoiceStatus[]).includes(
      i.status
    )
  )[0];

  if (!invoice) {
    return undefined;
  }

  return {
    id: invoice.id,
    totalAmount: formatValueWithLocalisedCurrency(invoice.totalAmount, market),
    status: invoice.status,
    paidDate: formatValueWithLocalisedDate(invoice.paidDate, market),
    dueDate: formatValueWithLocalisedDate(invoice.dueDate, market),
    sentDate: invoice?.sentDate
      ? formatValueWithLocalisedDate(invoice.sentDate, market)
      : null,
    amortization: formatValueWithLocalisedCurrency(
      invoice.amortization,
      market
    ),
    monthlyFee: formatValueWithLocalisedCurrency(invoice.monthlyFee, market),
    downloadable: invoice.status !== 'UPCOMING' && invoice.sentDate !== null,
    lateDays: dayjs().diff(dayjs(invoice.dueDate), 'days'),
    lateFee:
      invoice.lateFee > 0
        ? formatValueWithLocalisedCurrency(invoice.lateFee, market)
        : undefined,
    latePenaltyFee:
      invoice.latePenaltyFee > 0
        ? formatValueWithLocalisedCurrency(invoice.latePenaltyFee, market)
        : undefined,
    amortizationOpen:
      invoice.amortizationOpen > 0
        ? formatValueWithLocalisedCurrency(invoice.amortizationOpen, market)
        : undefined,
    monthlyFeeOpen:
      invoice.monthlyFeeOpen > 0
        ? formatValueWithLocalisedCurrency(invoice.monthlyFeeOpen, market)
        : undefined,
    paperInvoiceFee:
      invoice.paperInvoiceFee > 0
        ? formatValueWithLocalisedCurrency(invoice.paperInvoiceFee, market)
        : undefined,
    reactivationFee: invoice.reactivationFee
      ? formatValueWithLocalisedCurrency(invoice.reactivationFee, market)
      : undefined,
    feeDiscount:
      invoice.feeDiscount > 0
        ? formatValueWithLocalisedCurrency(invoice.feeDiscount, market)
        : undefined,
    nextSEPADDMonth:
      invoice.status === 'NEW'
        ? dayjs(invoice?.dueDate).add(1, 'month').toDate()
        : null,
  };
};

export const getLoanInvoicesMapped = (
  response: PaginationResponse<GetLoanInvoicesResponseDto>,
  market: CountryCode
): PaginationResponse<Invoice> => ({
  ...response,
  content: response.content.map((invoice) => ({
    id: invoice.id,
    totalAmount: formatValueWithLocalisedCurrency(invoice.totalAmount, market),
    status: invoice.status,
    dueDate: formatValueWithLocalisedDate(invoice.dueDate, market),
    paidDate: formatValueWithLocalisedDate(invoice.paidDate, market),
    sentDate: invoice?.sentDate
      ? formatValueWithLocalisedDate(invoice.sentDate, market)
      : null,
    amortization: formatValueWithLocalisedCurrency(
      invoice.amortization,
      market
    ),
    monthlyFee: formatValueWithLocalisedCurrency(invoice.monthlyFee, market),
    downloadable: invoice.status !== 'UPCOMING' && invoice.sentDate !== null,
    lateFee:
      invoice.lateFee > 0
        ? formatValueWithLocalisedCurrency(invoice.lateFee, market)
        : undefined,
    latePenaltyFee:
      invoice.latePenaltyFee > 0
        ? formatValueWithLocalisedCurrency(invoice.latePenaltyFee, market)
        : undefined,
    amortizationOpen:
      invoice.amortizationOpen > 0
        ? formatValueWithLocalisedCurrency(invoice.amortizationOpen, market)
        : undefined,
    monthlyFeeOpen:
      invoice.monthlyFeeOpen > 0
        ? formatValueWithLocalisedCurrency(invoice.monthlyFeeOpen, market)
        : undefined,
    paperInvoiceFee:
      invoice.paperInvoiceFee > 0
        ? formatValueWithLocalisedCurrency(invoice.paperInvoiceFee, market)
        : undefined,
    reactivationFee:
      invoice.reactivationFee > 0
        ? formatValueWithLocalisedCurrency(invoice.reactivationFee, market)
        : undefined,
    feeDiscount:
      invoice.feeDiscount > 0
        ? formatValueWithLocalisedCurrency(invoice.feeDiscount, market)
        : undefined,
    nextSEPADDMonth: (() => {
      if (['NEW'].includes(invoice.status)) {
        return dayjs(invoice.dueDate).add(1, 'month').toDate();
      } else if (['UPCOMING'].includes(invoice.status)) {
        return dayjs(invoice.dueDate).toDate();
      } else {
        return null;
      }
    })(),
  })),
});

export const getLoanStatementMapped = (
  response: GetLoanStatementResponseDto,
  market: CountryCode
): Statement => ({
  ...response,
  earlyRepaymentAmount: formatValueWithLocalisedCurrency(
    response.earlyRepaymentAmount,
    market
  ),
});
