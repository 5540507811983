import {
  Body,
  Box,
  Button,
  Center,
  H5,
  Link,
  Stack,
  useWindowProperties,
} from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSupportUrl } from '~/constants/markets';
import { CountryCode } from '~/enums';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import useTranslate from '~/hooks/useTranslate';
import { openQredHomePage } from '~/services/links';
import { RootState } from '~/store/types/sharedTypes';

const LoanAdditionalSignerSuccess = () => {
  const {
    intl: { language, market },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();
  const { marketHasMultipleProducts } = useOnboardingMarketConfig();

  const contactUsOnClick = () => {
    if (!language) return;
    const supportUrl = getSupportUrl(market as CountryCode, language);
    window.open(supportUrl, '_blank');
  };

  const { isMobile } = useWindowProperties();

  return (
    <Box bg={'neutral.2'} w={isMobile ? 396 : 580}>
      <Stack p={'xxl'} spacing={'xxl'}>
        <Stack spacing={'sm'}>
          <H5>
            {translate('LoanAdditionalSigner.LoanAdditionalSignerSuccessTitle')}
          </H5>
          <Body size="lg">
            {translate(
              'LoanAdditionalSigner.LoanAdditionalSignerSuccessSubheader'
            )}
          </Body>
        </Stack>
        <Body size="lg">
          {marketHasMultipleProducts ? (
            <>
              {translate(
                'LoanAdditionalSigner.LoanAdditionalSignerSuccessDiscoverOtherProducts'
              )}
            </>
          ) : (
            translate(
              'LoanAdditionalSigner.LoanAdditionalSignerSuccessContactUs',
              {
                contactUs: (
                  <Link size="lg" onClick={contactUsOnClick}>
                    {translate('Onboarding.ContactUs').toString().toLowerCase()}
                  </Link>
                ),
              }
            )
          )}
        </Body>
        {marketHasMultipleProducts && (
          <Center>
            <Button
              fullWidth={isMobile}
              onClick={() => openQredHomePage(market, language)}
              variant="primary"
              size="md"
            >
              {translate(
                'LoanAdditionalSigner.LoanAdditionalSignerSuccessButtonText'
              )}
            </Button>
          </Center>
        )}
      </Stack>
    </Box>
  );
};

export default LoanAdditionalSignerSuccess;
