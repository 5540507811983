import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Select } from '@qred/components-library/next';
import { ComboboxData } from '@qred/components-library/dist/lib/next/components/Combobox/Combobox.types';
import {
  Banner,
  Body,
  Button,
  Group,
  Link,
  Loader,
  MaterialIcon,
  Stack,
  useWindowProperties,
  Highlight,
  useModals,
  Radio,
  Label,
} from '@qred/components-library';
import { RootState } from '~/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASPSP,
  PsuType,
  useGetAspspsQuery,
  usePostAuthMutation,
} from '~/store/apis/endpoints/openBankingApi/openBanking.api';
import useTranslate from '~/hooks/useTranslate';
import { getAspspByName } from './BankConnection.utils';
import { ValidationContext } from '~/components/hoc/withValidation';
import { toggleGlobalErrorOn } from '~/store/slices/globalError.slice';
import { BankConnectionResult, GlobalErrorType } from '~/enums';
import { logToSentry } from '~/helpers/loggers.helper';
import BankConnectionStatusModal from './components/BankConnectionStatusModal';
import { connectBankTermsAndConditionsLink } from '~/constants/markets';
import { openBankingMaxAspspsResultsToShow } from '~/constants/constVars';

/**
 * If {finalRedirectUrl} is provided, the flow will redirect the user to the specified URL after
 * successfully connecting, cancelling, or encountering an error. The result will be appended as a parameter to the URL.
 *
 * Note: The {onResult} prop cannot be provided if {finalRedirectUrl} is specified.
 */
interface RedirectFlowProps {
  finalRedirectUrl: string;
  onResult?: never;
}

/**
 * If {onResult} is provided, the flow will open a new popup window (tab if popup is blocked) and
 * show the BankConnectionStatusModal component. The result will be passed to the onResult callback.
 *
 * Note: The {finalRedirectUrl} prop can not be provided if onResult is provided.
 */
interface NewPageFlowProps {
  finalRedirectUrl?: never;
  onResult: (result: BankConnectionResult) => void;
}

interface BankConnectionBaseProps {
  clientId: number;
  hasError?: boolean;
  allowCancel?: boolean;
  setError: (error: boolean) => void;
  showPsuTypeLabel?: boolean;
}

type BankConnectionProps = BankConnectionBaseProps &
  (RedirectFlowProps | NewPageFlowProps);

const BankConnection = ({
  clientId,
  hasError,
  finalRedirectUrl,
  allowCancel = true,
  setError,
  onResult,
  showPsuTypeLabel,
}: BankConnectionProps) => {
  const shouldStayInSameTab = Boolean(finalRedirectUrl);
  const [selectedASPSP, setSelectedASPSP] = useState<ASPSP>();
  const [selectedPSU, setSelectedPSU] = useState<PsuType>();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { isMobile } = useWindowProperties();
  const startAuthenticationContainerRef = useRef<HTMLDivElement>(null);
  const aspspsSelectRef = useRef<HTMLInputElement>(null);
  const {
    intl: { language, market },
  } = useSelector((state: RootState) => state);
  const {
    data: aspsps = [],
    isFetching: getAspspsIsFetching,
    isError: getAspspsIsError,
  } = useGetAspspsQuery(
    { psuType: selectedPSU! },
    {
      skip: !selectedPSU,
    }
  );
  const [
    postAuth,
    { isLoading: isAuthLoading, isError: isPostAuthError },
  ] = usePostAuthMutation();
  const [searchValue, setSearchValue] = useState<string>('');
  const validationContext = useContext(ValidationContext);
  const modals = useModals();

  const openBankingLocale = language.split('_')[0];
  const psuTypeOptions = [
    {
      value: 'business',
      label: translate('BankConnection.PsuTypeBusiness') as string,
    },
    {
      value: 'personal',
      label: translate('BankConnection.PsuTypePersonal') as string,
    },
  ];

  useEffect(() => {
    const startAuthenticationContainerElement =
      startAuthenticationContainerRef?.current;
    if (startAuthenticationContainerElement) {
      startAuthenticationContainerElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [selectedASPSP]);

  useEffect(() => {
    const aspspsSelectElement = aspspsSelectRef?.current;
    if (aspspsSelectElement) {
      aspspsSelectElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [aspspsSelectRef]);

  useEffect(() => {
    if (selectedASPSP) {
      validationContext.addPropertyToValidationErrors(
        'BankSelectedButNotConnected'
      );
    }

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'BankSelectedButNotConnected'
      );
    };
  }, [selectedASPSP]);

  useEffect(() => {
    setSelectedASPSP(undefined);
  }, [getAspspsIsFetching]);

  useEffect(() => {
    if (getAspspsIsError || isPostAuthError) {
      onResult && onResult(BankConnectionResult.Error);
    }
  }, [getAspspsIsError, isPostAuthError]);

  const resetHasError = () => {
    setError(false);
  };

  const handlePsuTypeSelect = (psuType: PsuType) => {
    setSelectedPSU(psuType);
    resetInputField();

    if (hasError) {
      resetHasError();
    }
  };

  const handleASPSPSelect = (aspspName: string) => {
    const aspsp = getAspspByName(aspsps, aspspName);
    setSelectedASPSP(aspsp);

    if (hasError) {
      resetHasError();
    }
  };

  const handleConnectionResult = (result: BankConnectionResult) => {
    onResult && onResult(result);
    resetInputField();
    if (
      result === BankConnectionResult.Error ||
      result === BankConnectionResult.Cancelled
    ) {
      setError(true);
    }
  };

  const handleStartAuthorization = async () => {
    if (!clientId || !selectedASPSP || !selectedPSU) {
      logToSentry(
        new Error('BankConnection: clientId or selectedASPSP is missing'),
        'handleStartAuthorization'
      );
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
      return;
    }

    try {
      const { authorizationUrl, state: sessionState } = await postAuth({
        body: {
          aspsp: {
            name: selectedASPSP.name,
            country: selectedASPSP.country,
          },
          clientId,
          redirectUrl: finalRedirectUrl,
          language: openBankingLocale,
          maximumConsentValidity: selectedASPSP.maximumConsentValidity,
          psuType: selectedPSU,
        },
      }).unwrap();

      if (shouldStayInSameTab) {
        location.assign(authorizationUrl);
      } else {
        const popupWindow = window.open(authorizationUrl, '_blank', 'popup');

        if (!popupWindow) {
          console.log(
            'Window.open for a popup blocked, opening new tab instead'
          );
          const newTab = window.open(authorizationUrl, '_blank');

          if (!newTab) {
            console.log(
              'Window.open for a new tab blocked, opening tab with a faked click event instead'
            );
            const link = document.createElement('a');
            link.href = authorizationUrl;
            link.target = '_blank';
            link.click();
          }
        }

        modals.openModal({
          id: 'bankConnectionStatusModal',
          children: (
            <BankConnectionStatusModal
              id="bankConnectionStatusModal"
              sessionState={sessionState}
              onResult={handleConnectionResult}
              authorizationUrl={authorizationUrl}
            />
          ),
          withCloseButton: false,
          closeOnClickOutside: false,
          closeOnEscape: false,
        });
      }
    } catch (error) {
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    }
  };

  const resetInputField = () => {
    setSearchValue('');
    setSelectedASPSP(undefined);
  };

  const options: ComboboxData = useMemo(
    () =>
      aspsps.map(({ name }) => ({
        value: name,
        label: name,
      })),
    [aspsps]
  );

  const shouldNotDisplayAspspsOptionsWithoutSearch =
    options.length > openBankingMaxAspspsResultsToShow;

  return (
    <Stack spacing="xxl">
      <Stack spacing={'sm'}>
        {showPsuTypeLabel && (
          <Label data-record>{translate('BankConnection.PsuTypeLabel')}</Label>
        )}
        <Radio.Group onChange={handlePsuTypeSelect} value={selectedPSU}>
          {psuTypeOptions.map((psuType) => (
            <Radio
              key={psuType.value}
              label={<span data-record>{psuType.label}</span>}
              value={psuType.value}
            />
          ))}
        </Radio.Group>
      </Stack>
      {selectedPSU && (
        <>
          <Select
            ref={aspspsSelectRef}
            data={
              getAspspsIsFetching ||
              (shouldNotDisplayAspspsOptionsWithoutSearch &&
                !searchValue.trim())
                ? []
                : options
            }
            limit={openBankingMaxAspspsResultsToShow}
            label={
              <span data-record>
                {translate(
                  searchValue
                    ? 'BankConnection.SearchLabel'
                    : 'BankConnection.SearchPlaceholder'
                )}
              </span>
            }
            searchable
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            value={selectedASPSP?.name ?? ''}
            onChange={handleASPSPSelect}
            allowDeselect={false}
            nothingFoundMessage={
              <>
                {getAspspsIsFetching ? (
                  <Loader />
                ) : (
                  <span data-record>
                    {searchValue.trim()
                      ? translate('BankConnection.SearchNoBanksFound')
                      : translate('BankConnection.SearchPlaceholder')}
                  </span>
                )}
              </>
            }
            leftSection={<MaterialIcon src="search" />}
            rightSection={
              selectedASPSP ? (
                <img
                  src={`${selectedASPSP.logo}-/resize/x16/`}
                  alt={`${selectedASPSP.name} logo`}
                  height="16"
                />
              ) : (
                <></>
              )
            }
            renderOption={({ option }) => {
              const aspsp = getAspspByName(aspsps, option.value);

              return (
                <Group align="center" justify="space-between">
                  <Highlight highlight={searchValue} data-record>
                    {aspsp.name}
                  </Highlight>
                  <img
                    src={`${aspsp.logo}-/resize/x16/`}
                    alt={`${aspsp.name} logo`}
                    height={14}
                    loading="lazy"
                  />
                </Group>
              );
            }}
          />

          {selectedASPSP && (
            <Stack spacing="xl" ref={startAuthenticationContainerRef}>
              <Stack spacing="lg" align={isMobile ? 'stretch' : 'start'}>
                <Group>
                  <Button
                    onClick={handleStartAuthorization}
                    leftIcon="lock"
                    isLoading={isAuthLoading}
                    size="md"
                    fullWidth={isMobile}
                  >
                    <span data-record>
                      {translate(
                        'BankConnection.StartAuthenticationButtonText'
                      )}
                    </span>
                  </Button>
                  {allowCancel && (
                    <Button
                      variant="tertiary"
                      size="md"
                      onClick={resetInputField}
                      fullWidth={isMobile}
                    >
                      <span data-record>{translate('Cancel')}</span>
                    </Button>
                  )}
                </Group>
                <Body size="sm" data-record>
                  {translate('BankConnection.ConnectionExplanation')}
                </Body>
                <Body size="sm" data-record>
                  {translate('BankConnection.ConsentText', {
                    startAuthenticationButtonText: translate(
                      'BankConnection.StartAuthenticationButtonText'
                    ),
                    termsLinkText: (
                      <a
                        href={
                          connectBankTermsAndConditionsLink[market][language]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Link size="sm" data-record>
                          {translate('BankConnection.TermsLinkText')}
                        </Link>
                      </a>
                    ),
                  })}{' '}
                  {allowCancel &&
                    translate('BankConnection.CancelText', {
                      cancel: translate('Cancel'),
                    })}
                </Body>
              </Stack>
            </Stack>
          )}
        </>
      )}
      {hasError && (
        <Banner
          opened
          withCloseButton={false}
          message={
            <span data-record>
              {translate('BankConnection.AuthenticationFlowErrorOrCancelled')}
            </span>
          }
          state="informative"
        />
      )}
    </Stack>
  );
};

export default BankConnection;
