import { CountryCode, GlobalErrorType } from '~/enums';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKlarnaFlow } from '~/hooks/useKlarnaFlow/useKlarnaFlow';
import useQueryParams from '~/hooks/useQueryParams';
import {
  processKlarnaKosmaTransactionFlow,
  updateTransactionFlow,
} from '~/store/actions/klarnaKosmaActions';
import { Language, RootState } from '~/store/types/sharedTypes';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import {
  Stack,
  H5,
  Body,
  Button,
  Center,
  MaterialIcon,
} from '@qred/components-library';
import { theme } from '~/styles/themes';
import useTranslate from '~/hooks/useTranslate';
import { MainOnboardingContainer } from '~/styles/OnboardingBaseStyledComponents';
import { apiGetClientDataFromQcode } from '~/services/api';
import { logToSentry } from '~/helpers/loggers.helper';
import { pushToGtmAction } from '~/store/actions/gtmActions';
import {
  setGlobalErrorPersistent,
  toggleGlobalErrorOn,
} from '~/store/slices/globalError.slice';
import { setLanguage, setMarket } from '~/store/slices/intl.slice';
import { parseLanguage, parseMarket } from '~/helpers/market.helper';
import { Content } from '../Onboarding/components/ConnectToBank/ConnectToBank.styled';

const BankIntegrationPublic = () => {
  const dispatch = useDispatch();
  const t = useTranslate();

  useKlarnaFlow();

  const {
    klarnaKosma: { transactionFlow },
  } = useSelector((state: RootState) => state);
  const [clientData, setClientData] = useState({
    clientId: 0,
    orgNumber: '',
    applicationUuid: '',
  });

  const qCodeParam = useQueryParams().get('qcode') as string;
  const localeParam = useQueryParams().get('locale') as string;
  const languageParam = parseLanguage(localeParam?.split('-')[0]);
  const marketParam = parseMarket(localeParam?.split('-')[1]);

  const startKlarnaFlow = (
    clientId: number,
    orgNumber: string,
    applicationUuid: string
  ) => {
    dispatch(
      processKlarnaKosmaTransactionFlow(orgNumber, clientId, applicationUuid)
    );
  };

  const startInitialFlow = async (market: CountryCode) => {
    dispatch(
      pushToGtmAction({
        eventName: 'bank_integration_public',
        actionName: 'bank_integration_automatic_start',
      })
    );
    dispatch(updateTransactionFlow({ status: 'inProgress' }));
    try {
      const { data } = await apiGetClientDataFromQcode(qCodeParam, market);
      if (!data?.clientId || !data?.orgNumber) {
        dispatch(updateTransactionFlow({ status: 'error' }));
        dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
        dispatch(setGlobalErrorPersistent(true));
        logToSentry(new Error('No client data'), 'apiGetClientDataFromQcode', {
          product: 'onboarding',
        });
      } else {
        setClientData({
          clientId: data.clientId,
          orgNumber: data.orgNumber,
          applicationUuid: data.applicationUuid,
        });
        startKlarnaFlow(data.clientId, data.orgNumber, data.applicationUuid);
      }
    } catch (error) {
      dispatch(updateTransactionFlow({ status: 'idle' }));
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
      logToSentry(error, 'apiGetClientDataFromQcode', {
        product: 'onboarding',
      });
    }
  };

  useEffect(() => {
    if (transactionFlow.status === 'completed') {
      dispatch(
        pushToGtmAction({
          eventName: 'bank_integration_public',
          actionName: 'bank_integration_connected',
        })
      );
    } else if (transactionFlow.status === 'error') {
      dispatch(
        pushToGtmAction({
          eventName: 'bank_integration_public',
          actionName: 'bank_integration_failed',
          errorMessage: transactionFlow.error?.message || 'unknown error',
        })
      );
      dispatch(updateTransactionFlow({ status: 'idle' }));
    }
  }, [transactionFlow.status]);

  useEffect(() => {
    if (!qCodeParam || !marketParam || !languageParam) {
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
      dispatch(updateTransactionFlow({ status: 'error' }));
      dispatch(setGlobalErrorPersistent(true));
      logToSentry(
        Error('Error in fetching query params from url'),
        'BankIntegrationPublic',
        { product: 'onboarding' }
      );
      return;
    }

    if (marketParam === CountryCode.BE && languageParam === Language.Dutch) {
      dispatch(setLanguage(Language.DutchBE));
    } else {
      dispatch(setLanguage(languageParam));
    }

    dispatch(setMarket(marketParam));
    startInitialFlow(marketParam);
  }, []);

  const handleManualStart = () => {
    dispatch(
      pushToGtmAction({
        eventName: 'bank_integration_public',
        actionName: 'bank_integration_manual_start',
      })
    );
    startKlarnaFlow(
      clientData.clientId,
      clientData.orgNumber,
      clientData.applicationUuid
    );
  };

  return (
    <MainOnboardingContainer>
      {transactionFlow.status === 'inProgress' && (
        <Content>
          <H5>{t('PublicBankIntegration.ConnectingToKlarna')}</H5>
          <SpinnerCircular color={theme.colors.secondaryGray} />
        </Content>
      )}
      {transactionFlow.status === 'idle' && (
        <Content>
          <Stack spacing={'xxl'}>
            <Stack>
              <H5>{t('PublicBankIntegration.BankIntegrationTitle')}</H5>
              <Body size="lg">
                {t('PublicBankIntegration.BankIntegrationText')}
              </Body>
            </Stack>
            <Center>
              <Button
                onClick={handleManualStart}
                leftIcon="arrow_right_alt"
                size={'lg'}
              >
                {t('PublicBankIntegration.ConnectToBank')}
              </Button>
            </Center>
          </Stack>
        </Content>
      )}
      {transactionFlow.status === 'completed' && (
        <Content>
          <Stack align="center">
            <MaterialIcon src="check_box" size={'48'} />
            <H5>{t('PublicBankIntegration.BankIntegrationSuccess')}</H5>
          </Stack>
        </Content>
      )}
    </MainOnboardingContainer>
  );
};

export default BankIntegrationPublic;
