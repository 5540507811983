import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { getCurrency } from '@qred/shared-component-library/src/currency';
import { updateForm } from '~/store/slices/onboardingApplication.slice';
import {
  Button,
  Group,
  Stack,
  Select,
  Body,
  Label,
  LabelValueOption,
} from '@qred/components-library';
import { ValidationContext } from '~/components/hoc/withValidation';
import { numberFormatter } from '@qred/fe-cp-formatting-helpers';
import { removeWhitespace } from '~/helpers/formatters.helper';

const turnoverRangesNonEuro = [
  { min: 0, max: 10000 },
  { min: 10001, max: 20000 },
  { min: 20001, max: 30000 },
  { min: 30001, max: 50000 },
  { min: 50001, max: 75000 },
  { min: 75001, max: 125000 },
  { min: 125001, max: 250000 },
  { min: 250001, max: 1000000 },
  { min: 1000001, max: 50000000 },
  { min: 50000000 },
];

const turnoverRangesEuro = [
  { min: 0, max: 1000 },
  { min: 1001, max: 2000 },
  { min: 2001, max: 3000 },
  { min: 3001, max: 5000 },
  { min: 5001, max: 7500 },
  { min: 7501, max: 12500 },
  { min: 12501, max: 25000 },
  { min: 25001, max: 100000 },
  { min: 100001, max: 5000000 },
  { min: 5000000 },
];

type TurnoverOption = LabelValueOption<string>;

const FinancialInformation = () => {
  const validationContext = useContext(ValidationContext);

  const dispatch = useDispatch();

  const {
    onboardingApplication: { form },
    intl: { market },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();

  const formatter = numberFormatter(market);

  const formatTurnoverRange = ({
    min,
    max,
  }: {
    min: number;
    max?: number;
  }): TurnoverOption => {
    let label;
    if (max) {
      label = `${formatter(min)} - ${formatter(max)}`;
    } else {
      label = `> ${formatter(min)}`;
    }
    return { value: removeWhitespace(label), label };
  };

  const currencyOptions =
    getCurrency(market).unit === '€'
      ? turnoverRangesEuro
      : turnoverRangesNonEuro;

  const formattedTurnoverOptions = currencyOptions.map(formatTurnoverRange);

  useEffect(() => {
    const hasAnsweredForeignCurrenciesQuestion =
      typeof form.incomeReceivedInForeignCurrencies === 'boolean';
    if (!hasAnsweredForeignCurrenciesQuestion || !form.turnover) {
      validationContext.manuallyInvalidateForm(true);
    }

    return () => {
      validationContext.manuallyInvalidateForm(false);
    };
  }, [form.incomeReceivedInForeignCurrencies, form.turnover]);

  const onTurnoverChange = (selectedOption: TurnoverOption) => {
    if (selectedOption) {
      dispatch(
        updateForm({
          turnover: selectedOption.value,
        })
      );
    }
  };

  const onClick = (answer: boolean) => {
    dispatch(
      updateForm({
        incomeReceivedInForeignCurrencies: answer,
      })
    );

    dispatch(
      pushToGtmOnboardingAction({
        actionName: `incomeReceivedInForeignCurrencies: ${answer}`,
      })
    );

    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'financial_information_change',
        stepActionNumber: 0.1,
      })
    );
  };

  return (
    <Stack spacing={'xl'}>
      <Select
        className="turnover_step_amount"
        options={formattedTurnoverOptions}
        onChange={onTurnoverChange}
        value={formattedTurnoverOptions.find(
          (option) => option.value === form.turnover
        )}
        label={
          <Label size="md">
            {translate('Onboarding.TurnoverLabel')} {getCurrency(market).unit}
          </Label>
        }
        placeholder={translate('Onboarding.TurnoverSelectInterval') as string}
        isSearchable={false}
      />
      <Stack spacing={'md'}>
        <Body size="lg">
          {translate('Onboarding.TurnoverForeignCurrencyQuestion')}
        </Body>
        <Group direction="row">
          <Button
            data-cy="foreign_currency_question_button"
            size="md"
            onClick={() => onClick(true)}
            variant="secondary"
            style={{ flexGrow: mobile ? 1 : 0 }}
            data-active={form.incomeReceivedInForeignCurrencies === true}
          >
            {translate('Yes')}
          </Button>
          <Button
            data-cy="foreign_currency_question_button"
            size="md"
            onClick={() => onClick(false)}
            variant="secondary"
            style={{ flexGrow: mobile ? 1 : 0 }}
            data-active={form.incomeReceivedInForeignCurrencies === false}
          >
            {translate('No')}
          </Button>
        </Group>
      </Stack>
    </Stack>
  );
};

export default FinancialInformation;
