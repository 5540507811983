import { createSelector } from '@reduxjs/toolkit';
import {
  companyIsSoleTrader,
  companyIsStockCompany,
} from '~/constants/markets';
import {
  isBeneficialOwner,
  personsAreTheSame,
} from '~/helpers/onboarding.helper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { CampaignType, SignerType } from '~/enums';
import { RootState } from '../types/sharedTypes';
import { stepConfigPerMarket } from '~/pages/OnboardingOffer/components/stepConfigs';
import { StepConfigItem } from '~/Interfaces';
import { CountryCode } from '@qred/shared-enums';
import posthog from 'posthog-js';

const selectApplicantHasAnsweredBeneficialOwnersQuestion = createSelector(
  [
    (state: RootState) =>
      state.onboardingOffer.form.companyHasOtherBeneficialOwners,
  ],
  (companyHasOtherBeneficialOwners) =>
    typeof companyHasOtherBeneficialOwners === 'boolean'
);

const selectApplicantHasAnswered100PercentOwnershipQuestion = createSelector(
  [
    (state: RootState) =>
      state.onboardingOffer.form.applicantOwns100PercentOfShares,
  ],
  (applicantOwns100PercentOfShares) =>
    typeof applicantOwns100PercentOfShares === 'boolean'
);

const selectCompanyIsStockCompany = createSelector(
  [
    (state: RootState) => state.onboardingOffer.overview.companyType,
    (state: RootState) => state.intl.market,
  ],
  (companyType, market) => companyIsStockCompany(market, companyType)
);

const selectApplicantIsABeneficialOwner = createSelector(
  [(state: RootState) => state.onboardingOffer.form.applicant.percentage],
  (applicantOwnerPercentage) => isBeneficialOwner(applicantOwnerPercentage)
);

const selectApplicantIsSoloBeneficialOwner = createSelector(
  [
    (state: RootState) => state.onboardingOffer.form.applicant.percentage,
    (state: RootState) =>
      state.onboardingOffer.form.companyHasOtherBeneficialOwners,
  ],
  (applicantOwnerPercentage, companyHasOtherBeneficialOwners) =>
    isBeneficialOwner(applicantOwnerPercentage) &&
    (companyHasOtherBeneficialOwners === false ||
      Number(applicantOwnerPercentage) >= 75)
);

const selectOwnersDataIsFetched = createSelector(
  [(state: RootState) => state.onboardingOffer.fetchedBeneficialOwners],
  (fetchedBeneficialOwners) => !!fetchedBeneficialOwners.length
);

const selectTotalAmountOfShares = createSelector(
  [
    (state: RootState) => state.onboardingOffer.form.applicant.percentage,
    (state: RootState) => state.onboardingOffer.form.owners,
    selectOwnersDataIsFetched,
  ],
  (applicantOwnerPercentage, owners, ownersDataIsFetched) => {
    if (ownersDataIsFetched) {
      return owners
        .filter((owner) => !owner.isAlternativeBeneficialOwner)
        .reduce((acc, owner) => acc + Number(owner.percentage), 0);
    }

    return owners
      .filter((owner) => !owner.isAlternativeBeneficialOwner)
      .reduce(
        (acc, owner) => acc + Number(owner.percentage),
        Number(applicantOwnerPercentage)
      );
  }
);

const selectFetchedOwnersHaveBeenChanged = createSelector(
  [
    (state: RootState) => state.onboardingOffer.form.owners,
    (state: RootState) => state.onboardingOffer.fetchedBeneficialOwners,
  ],
  (owners, fetchedBeneficialOwners) => {
    if (!fetchedBeneficialOwners.length) {
      return false;
    }

    const hasChanged =
      fetchedBeneficialOwners.length !== owners.length ||
      fetchedBeneficialOwners.some((fetchedOwner) => {
        const owner = owners.find((o) => o.id === fetchedOwner.id);

        if (
          !owner ||
          owner.fullName !== fetchedOwner.fullName ||
          owner.personalNumber !== fetchedOwner.personalNumber ||
          owner.percentage !== fetchedOwner.percentage ||
          owner.countryOfResidence !== fetchedOwner.countryOfResidence ||
          (fetchedOwner.dateOfBirth &&
            moment(owner.dateOfBirth).format('YYYY-MM-DD') !==
              moment(fetchedOwner.dateOfBirth).format('YYYY-MM-DD')) ||
          owner.yearOfBirth !== fetchedOwner.yearOfBirth
        ) {
          return true;
        }

        return false;
      });

    return hasChanged;
  }
);

export const selectLoanAmount = createSelector(
  [
    (state: RootState) => state.onboardingOffer.form.payoutAmount,
    (state: RootState) => state.onboardingOffer.overview.currentLoanAmountLeft,
  ],
  (amount, currentLoanAmountLeft) => amount + (currentLoanAmountLeft ?? 0)
);

const selectMonthlyAmortization = createSelector(
  [
    (state: RootState) => state.onboardingOffer.form.loanPeriod,
    selectLoanAmount,
    (state: RootState) => state.onboardingOffer.overview.campaignType,
    (state: RootState) =>
      state.onboardingOffer.overview.campaignPrincipalFreeMonths,
  ],
  (loanPeriod, loanAmount, campaignType, campaignPrincipalFreeMonths) => {
    let loanPeriodWithAmortization = loanPeriod;
    if (campaignType === CampaignType.AmortizationFreeMonths) {
      loanPeriodWithAmortization -= campaignPrincipalFreeMonths;
    } else if (campaignType === CampaignType.FirstMonthFree) {
      loanPeriodWithAmortization -= 1;
    }

    const monthlyAmortization = loanAmount / loanPeriodWithAmortization;
    return monthlyAmortization ?? 0;
  }
);

const selectMonthlyFee = createSelector(
  [
    selectLoanAmount,
    (state: RootState) => state.onboardingOffer.overview.interestPercent,
  ],
  (loanAmount, interestPercent) => {
    const monthlyFee = (loanAmount * interestPercent) / 100 || 0;
    return monthlyFee;
  }
);

const selectMonthlyPayment = createSelector(
  [selectMonthlyAmortization, selectMonthlyFee],
  (monthlyFee, monthlyAmortization) => {
    const monthlyPayment = monthlyAmortization + monthlyFee;
    return monthlyPayment;
  }
);

const selectAlternativeOwnerManuallyAdded = createSelector(
  [(state: RootState) => state.onboardingOffer.form.owners],
  (owners) => {
    return owners.some((owner) => owner.isAlternativeBeneficialOwner);
  }
);

export const selectHasSepaDirectDebitFeature = createSelector(
  [(state: RootState) => state.intl.market],
  (market) => {
    switch (market) {
      case CountryCode.BE:
      case CountryCode.DE:
        return true;
      default:
        return false;
    }
  }
);

export const selectStepConfig = createSelector(
  [
    (state: RootState) => state.onboardingOffer.form.owners,
    (state: RootState) => state.onboardingOffer.form.applicant,
    (state: RootState) => state.onboardingOffer.overview.companyType,
    (state: RootState) => state.onboardingOffer.overview.loanPurpose,
    (state: RootState) => state.onboardingOffer.overview.signers,
    (state: RootState) => state.intl.market,
    selectHasSepaDirectDebitFeature,
  ],
  (
    owners,
    applicant,
    companyType,
    loanPurpose,
    signers,
    market,
    hasSepaDirectDebitFeature
  ) => {
    console.group('Offer flow');
    const isKlarnaKosmaDead = Boolean(
      posthog.isFeatureEnabled('Onboarding_IsKlarnaKosmaDead')
    );
    const marketHasEnableBankingFeature = Boolean(
      posthog.isFeatureEnabled('Onboarding_MarketHasEnableBankingFeature')
    );
    console.log('isKlarnaKosmaDead', isKlarnaKosmaDead);
    console.log('marketHasEnableBankingFeature', marketHasEnableBankingFeature);

    const stepConfig = stepConfigPerMarket[market].filter(
      (step: StepConfigItem) => {
        let hideStep = false;

        switch (step.name) {
          case 'ownership_step': // hide ownership step if company is sole trader
            hideStep = companyIsSoleTrader(market, companyType);
            break;
          case 'loan_purpose_step': // hide loan purpose step if we already have a loan purpose
            hideStep = !!loanPurpose;
            break;
          case 'additional_signers_and_guarantors_step': {
            // hide step if there are no additional signers and the applicant is an owner.
            // If the applicant is an owner, it means that the AML/KYC data will be added to the applicant in the owners step.
            const hasAdditionalSigners = signers.some(
              (signer) =>
                signer.type === SignerType.Signer ||
                signer.type === SignerType.Guarantor
            );
            const applicantExistsInListOfOwners = !!owners.find((owner) =>
              personsAreTheSame(owner, applicant)
            );
            hideStep = !hasAdditionalSigners && applicantExistsInListOfOwners;
            break;
          }
          case 'account_number_step': {
            hideStep =
              isKlarnaKosmaDead ||
              marketHasEnableBankingFeature ||
              hasSepaDirectDebitFeature;
            console.log('account_number_step is shown', !hideStep);

            break;
          }
          case 'bank_information_step': {
            hideStep =
              !isKlarnaKosmaDead &&
              !marketHasEnableBankingFeature &&
              !hasSepaDirectDebitFeature;
            console.log('bank_information_step is shown', !hideStep);
            if (!hideStep) {
              console.log(
                'enable banking shown',
                marketHasEnableBankingFeature
              );
            }
            break;
          }
          default:
            break;
        }

        return !hideStep;
      }
    );
    console.groupEnd();
    return stepConfig;
  }
);

export const useOnboardingOfferSelectors = () => ({
  applicantHasAnsweredBeneficialOwnersQuestion: useSelector(
    selectApplicantHasAnsweredBeneficialOwnersQuestion
  ),
  applicantHasAnswered100PercentOwnershipQuestion: useSelector(
    selectApplicantHasAnswered100PercentOwnershipQuestion
  ),
  companyIsStockCompany: useSelector(selectCompanyIsStockCompany),
  applicantIsABeneficialOwner: useSelector(selectApplicantIsABeneficialOwner),
  applicantIsSoloBeneficialOwner: useSelector(
    selectApplicantIsSoloBeneficialOwner
  ),
  totalAmountOfShares: useSelector(selectTotalAmountOfShares),
  ownersDataIsFetched: useSelector(selectOwnersDataIsFetched),
  fetchedOwnersHaveBeenChanged: useSelector(selectFetchedOwnersHaveBeenChanged),
  monthlyAmortization: useSelector(selectMonthlyAmortization),
  monthlyFee: useSelector(selectMonthlyFee),
  monthlyPayment: useSelector(selectMonthlyPayment),
  loanAmount: useSelector(selectLoanAmount),
  alternativeOwnerManuallyAdded: useSelector(
    selectAlternativeOwnerManuallyAdded
  ),
  stepConfig: useSelector(selectStepConfig),
  hasSepaDirectDebitFeature: useSelector(selectHasSepaDirectDebitFeature),
});
