import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { Group, H7, MaterialIcon } from '@qred/components-library';

const ConnectToEskat = () => {
  const t = useTranslate();
  const {
    onboardingApplication: {
      form: { eskatConnected },
    },
  } = useSelector((state: RootState) => state);

  return (
    <>
      {eskatConnected && (
        <Group align="center" spacing={'sm'}>
          <MaterialIcon src="check_circle" />
          <H7>{t('Onboarding.EskatSuccessText')}</H7>
        </Group>
      )}
    </>
  );
};

export default ConnectToEskat;
