import { Body, Stack } from '@qred/components-library';
import React from 'react';
import styled from 'styled-components';

interface Item {
  label: string;
  value: string;
}

interface Props {
  items: Item[];
  isMobile?: boolean;
}

export const LabelValueGrid = (props: Props) => {
  const { items, isMobile } = props;

  return (
    <Stack spacing={'sm'}>
      {items.map((item) => (
        <Grid key={item.label} isMobile={isMobile}>
          <Body size="lg">{item.label}</Body>
          <Body size="lg" weight={600} align={isMobile ? 'left' : 'right'}>
            {item.value}
          </Body>
        </Grid>
      ))}
    </Stack>
  );
};

const Grid = styled('div')<{ isMobile?: boolean }>`
  display: grid;
  ${({ isMobile }) =>
    !isMobile &&
    `
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
  `}
`;
