import React, { useContext, useEffect } from 'react';
import useTranslate from '~/hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '~/components/shared/Input/InputField';
import { RootState } from '~/store/types/sharedTypes';
import {
  FormStatus,
  SimpleEvent,
  ValidationContext,
  ValidationType,
} from '~/components/hoc/withValidation';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import { updateForm } from '~/store/slices/cardOnboardingOffer.slice';
import { Checkbox, Stack } from '@qred/components-library';
import { CardPurposeType } from '~/enums';

const CardPurpose: React.FC = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const validationContext = useContext(ValidationContext);
  const { validationErrors } = validationContext;
  const otherPurposeReasonTextMaxCharLimit = 200;

  const {
    cardOnboardingOffer: { overview, form },
  } = useSelector((state: RootState) => state);

  const { applicationReasons } = form;
  const { cardOfferAlreadySigned } = overview;

  const validationErrorShouldBeShown =
    (validationContext.formStatus === FormStatus.SUBMITTED &&
      validationErrors.applicationReasons) ||
    validationErrors.ApplicationReasonOther;

  useEffect(() => {
    const fakeEvent: SimpleEvent = {
      target: {
        name: 'applicationReasons',
        value: applicationReasons,
        dataset: { validationType: 'CardPurpose' },
      },
    };
    validationContext.onChangeHOC(fakeEvent);

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'applicationReasons'
      );
    };
  }, [applicationReasons]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (cardOfferAlreadySigned) return;
    if (e.target.type === 'checkbox') {
      validationContext.onChangeHOC({
        target: {
          name: 'applicationReasons',
          value: applicationReasons,
          dataset: {
            validationType: 'CardPurpose' as ValidationType,
          },
        },
      });
      if (applicationReasons.find((r) => r.reason === e.target.name)) {
        dispatch(
          updateForm({
            ...form,
            applicationReasons: applicationReasons.filter(
              (r) => r.reason !== e.target.name
            ),
          })
        );
      } else {
        dispatch(
          updateForm({
            ...form,
            applicationReasons: [
              ...applicationReasons,
              {
                reason: e.target.name as CardPurposeType,
                reasonText: null,
              },
            ],
          })
        );
      }
    } else {
      validationContext.onChangeHOC({
        target: {
          name: 'specifyCardPurpose',
          value: e.target.value,
          dataset: {
            validationType: 'ApplicationReasonOther' as ValidationType,
          },
        },
      });
      dispatch(
        updateForm({
          ...form,
          applicationReasons: [
            ...applicationReasons.map((r) => {
              if (r.reason === CardPurposeType.OtherPurpose) {
                return { ...r, reasonText: e.target.value };
              }
              return r;
            }),
          ],
        })
      );
    }
  };

  const otherLoanPurposeReason = applicationReasons.find(
    (r) => r.reason === CardPurposeType.OtherPurpose
  )?.reasonText;

  return (
    <Stack spacing={'md'}>
      {Object.values(CardPurposeType).map((option: string, index: number) => (
        <Checkbox
          key={`${option}`}
          checked={applicationReasons.some((r) => r.reason === option)}
          disabled={
            applicationReasons.some((r) => r.reason === option) &&
            cardOfferAlreadySigned
          }
          onChange={handleOnChange}
          name={option}
          className={validationErrorShouldBeShown ? 'has-error' : ''}
          label={translate(
            `CreditCardPurposeCard.Option${index + 1}` as string
          )}
        />
      ))}

      {applicationReasons &&
        applicationReasons.some(
          (r) => r.reason === CardPurposeType.OtherPurpose
        ) && (
          <InputField
            label="Specify purpose"
            name="specifyCardPurpose"
            onChange={handleOnChange}
            disabled={cardOfferAlreadySigned}
            validationType="ApplicationReasonOther"
            placeholder={
              translate('CreditCardPurposeCard.Option9Specify') as string
            }
            maxLength={otherPurposeReasonTextMaxCharLimit}
            characterCounter
            value={otherLoanPurposeReason || ''}
          />
        )}
      {validationErrorShouldBeShown && (
        <ValidationErrorMessage>
          {translate('ValidationErrors.CardPurpose')}
        </ValidationErrorMessage>
      )}
    </Stack>
  );
};

export default CardPurpose;
