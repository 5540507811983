import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Typography } from '~/components/shared/Typography/Typography';
import constVars from '~/constants/constVars';
import { sortOverduePayments } from '~/helpers/sorters.helper';
import { toggleNotificationsStatus } from '~/store/actions/loansActions';
import { RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import pushToGtmHelper from '~/helpers/pushToGtm.helper';
import { getNextPaymentInDays } from '~/helpers/payments.helper';
import EmptyNotificationItem from './EmptyNotificationItem';
import NotificationItem from './NotificationItem';

const NotificationMenuContainer = styled.div`
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 0;
  z-index: 10;
  @media (orientation: landscape) {
    width: auto;
  }
`;

const BellIcon = styled.div.attrs(() => ({
  'data-testid': 'notification-menu',
}))`
  display: inline-block;
  border-radius: 0.5em 0.5em 0 0;
  cursor: pointer;
  position: relative;
`;

const BellIconOpen = styled(BellIcon)`
  background-color: ${theme.colors.white};
`;

const NotificationItemsContainer = styled.div`
  width: 23em;
  outline: none;
  display: flex;
  flex-direction: column;
  margin-top: -0.8em;
  border-radius: 0.5em 0 0.5em 0.5em;
  position: absolute;
  top: 5em;
  right: 1.5em;
  box-shadow: 0px 0px 20px -10px #000000;
`;

const RedDot = styled.span`
  position: absolute;
  top: 0.1em;
  right: -0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.error};
  color: ${theme.colors.white};
  border-radius: 50%;
  width: 1em;
  height: 1em;
`;

export interface Notification {
  type: Action;
  textKey: string;
  textKeyOptions?: object;
  subTextKey: string;
  subTextKeyOptions?: object;
  redirect: string;
  icon: JSX.Element;
  dueDate?: Date;
  onClick?: () => void;
  companyName?: string;
}

export enum Placement {
  Top,
  Middle,
  Buttom,
  Single,
}

const PaymentOverdueAction = 'A_OVERDUE_PAYMENT';
const TerminationAction = 'B_TREMINATION';
const NewLoanOfferAction = 'C_NEW_LOAN_OFFER';
const NewCardOfferAction = 'D_NEW_CARD_OFFER';
const NewFeatureAction = 'E_NEW_FEATURE';
const QredCardAction = 'F_QRED_CARD';

type Action =
  | typeof PaymentOverdueAction
  | typeof TerminationAction
  | typeof NewLoanOfferAction
  | typeof NewCardOfferAction
  | typeof NewFeatureAction
  | typeof QredCardAction;

const notificationSorter = (a: Notification, b: Notification): number => {
  if (a.type > b.type) return 1;
  if (a.type < b.type) return -1;
  return 0;
};

// OBS! This is a "React" reducer, don't get this confused with a "Redux" reducer.
const reducer = (
  state: Notification[],
  action: { type: Action; payload: Notification }
) => {
  switch (action.type) {
    case PaymentOverdueAction:
    case TerminationAction:
    case NewLoanOfferAction:
    case NewCardOfferAction:
    case NewFeatureAction:
    case QredCardAction:
      return [...state, action.payload].sort(notificationSorter);
    default:
      throw new Error('Invalid Action');
  }
};

const NotificationMenu: React.FC = () => {
  /* States */
  const [isOpen, setIsOpen] = useState(false);

  /* Selectors */
  const loans = useSelector((state: RootState) => state.loans.overview);
  const hasLoanOffer = useSelector(
    (state: RootState) => state.loanOffer.hasOffer
  );
  const hasCardOffer = useSelector(
    (state: RootState) => state.cardOffer.hasOffer
  );
  const loanOffers = useSelector(
    (state: RootState) => state.loanOffer.overview
  );
  const cardOffers = useSelector(
    (state: RootState) => state.cardOffer.overview
  );
  const terminatedContracts = useSelector(
    (state: RootState) => state.loans.terminatedContract
  );
  const areNotificationsSeen = useSelector(
    (state: RootState) => state.loans.areNotificationsSeen
  );

  /* Other hooks */
  const [notifications, localDispatch] = useReducer(reducer, []);
  const globalDispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  /* Effect */
  useEffect(() => {
    const overduePayments = sortOverduePayments(loans);
    if (overduePayments.length > 0) {
      overduePayments.forEach((overduePayment) => {
        localDispatch({
          type: PaymentOverdueAction,
          payload: {
            type: PaymentOverdueAction,
            textKey: 'Beware',
            subTextKey: 'PaymentOverdueBy',
            subTextKeyOptions: {
              days:
                overduePayment.dueDate &&
                Math.abs(getNextPaymentInDays(overduePayment.dueDate)),
            },
            redirect: `/${constVars.ROUTE_LOANS}/${overduePayment.loanId}/${constVars.ROUTE_OVERDUE}/${constVars.ROUTE_PAYMENT}/${overduePayment.index}`,
            icon: <Icon src={SvgSrc.ExclamationTriangle} />,
            dueDate: overduePayment.dueDate,
          },
        });
      });
    }
  }, [loans.length]);

  useEffect(() => {
    if (hasLoanOffer && loanOffers.length > 0) {
      loanOffers.forEach((offer) => {
        localDispatch({
          type: NewLoanOfferAction,
          payload: {
            type: NewLoanOfferAction,
            textKey: 'NotificationMenu.NewLoanHeader',
            subTextKey: 'NotificationMenu.NewLoanText',
            subTextKeyOptions: {
              companyName: offer.companyName,
            },
            redirect: `${constVars.ROUTE_ONBOARDING_OFFER}/${offer.uuid}`,
            icon: <Icon src={SvgSrc.Smile} />,
            companyName: offer.companyName,
          },
        });
      });
    }
  }, [loanOffers, hasLoanOffer]);

  useEffect(() => {
    if (hasCardOffer && cardOffers.length > 0) {
      cardOffers.forEach((offer) => {
        if (offer.cardOfferAlreadySigned) return;
        localDispatch({
          type: NewCardOfferAction,
          payload: {
            type: NewCardOfferAction,
            textKey: 'NotificationMenu.NewCardHeader',
            subTextKey: 'NotificationMenu.NewCardText',
            subTextKeyOptions: {
              companyName: offer.companyName,
            },
            redirect: `/${constVars.ROUTE_CARD_OFFER}/${offer.companyId}`,
            icon: <Icon src={SvgSrc.Smile} />,
            companyName: offer.companyName,
          },
        });
      });
    }
  }, [cardOffers, hasCardOffer]);

  useEffect(() => {
    if (terminatedContracts) {
      terminatedContracts.forEach((termination) => {
        localDispatch({
          type: TerminationAction,
          payload: {
            type: TerminationAction,
            textKey: 'TerminationLoan',
            subTextKey: 'YourLoanWillBeTerminated',
            redirect: `/${constVars.ROUTE_LOANS}/${termination.contractId}/${constVars.ROUTE_TERMINATED_CONTRACT}`,
            icon: <Icon src={SvgSrc.ExclamationTriangle} />,
          },
        });
      });
    }
  }, [terminatedContracts.length]);

  useEffect(() => {
    if (isOpen) {
      pushToGtmHelper('q_user_a_nav_menu_open');
    }
  }, [isOpen]);

  /*
  useEffect(() => {
    if (market === CountryCode.SE)
      localDispatch({
        type: QredCardAction,
        payload: {
          type: QredCardAction,
          textKey: 'NotificationMenu.QredCardHeader',
          subTextKey: 'NotificationMenu.QredCardText',
          redirect: '',
          onClick: () => openQredCardPage(language),
          icon: <Icon src={SvgSrc.CreditCard} />,
        },
      });
  }, []);
  */

  const onNotificationMenuClicked = () => {
    globalDispatch(toggleNotificationsStatus(true));
    setIsOpen(!isOpen);
    if (!isOpen && ref && ref.current) {
      ref.current.focus();
    }
  };

  const onBlur = () => {
    setIsOpen(false);
  };

  const getItemPlacement = (index: number) => {
    if (index === 0 && notifications.length === 1) return Placement.Single;
    if (index === 0) return Placement.Top;
    if (index === notifications.length - 1) return Placement.Buttom;
    return Placement.Middle;
  };

  const filterNotificatios = (_notifications: Notification[]) =>
    _notifications.filter(
      (n) =>
        n.type !== NewFeatureAction ||
        localStorage.getItem('isBannerSeen') !== 'true'
    );

  return (
    <NotificationMenuContainer onBlur={onBlur} ref={ref} tabIndex={0}>
      {isOpen ? (
        <>
          <BellIconOpen onClick={onNotificationMenuClicked}>
            <Icon src={SvgSrc.Bell} color={theme.colors.secondary} />
          </BellIconOpen>
          <NotificationItemsContainer>
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <NotificationItem
                  key={index}
                  notification={notification}
                  placement={getItemPlacement(index)}
                />
              ))
            ) : (
              <EmptyNotificationItem />
            )}
          </NotificationItemsContainer>
        </>
      ) : (
        <BellIcon onClick={onNotificationMenuClicked}>
          <Icon src={SvgSrc.Bell} color={theme.colors.secondary} />
          {filterNotificatios(notifications).length > 0 &&
            !areNotificationsSeen && (
              <RedDot>
                <Typography
                  fontSize={0.7}
                  fontWeight={600}
                  color={theme.colors.white}
                >
                  {filterNotificatios(notifications).length}
                </Typography>
              </RedDot>
            )}
        </BellIcon>
      )}
    </NotificationMenuContainer>
  );
};

export default NotificationMenu;
