import { CountryCode } from '~/enums';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { RootState } from '~/store/types/sharedTypes';
import {
  ArrangementProps,
  BaseArrangement,
  cssLenght,
  SizeProps,
} from '~/styles/BaseStyledComponents';
import { logoContainer } from '~/constants/cy/components/logo';

const LogoContainer = styled(BaseArrangement)<SizeProps>`
  height: ${(props) => cssLenght(props, 3)};
  width: ${(props) => cssLenght(props, 3)};
`;

const Img = styled.img<{ clickable?: boolean }>`
  height: 100%;
  width: 100%;

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;

interface LogoProps {
  onClick?: () => void;
  noLink?: boolean;
}

const LogoOld: React.FC<ArrangementProps & SizeProps & LogoProps> = (props) => {
  const { market } = useSelector((state: RootState) => state.intl);
  const logoSrc =
    market === CountryCode.NL ? '/qeld-logo.png' : '/qred-logo.png';

  return (
    <LogoContainer
      mt={props.mt}
      mb={props.mb}
      size={props.size}
      data-cy={logoContainer}
    >
      {props.noLink ? (
        <Img
          src={logoSrc}
          clickable={!!props.onClick}
          onClick={() => {
            props?.onClick && props.onClick();
          }}
        />
      ) : (
        <Link to="/">
          <Img src={logoSrc} />
        </Link>
      )}
    </LogoContainer>
  );
};

export default LogoOld;
