import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '~/components/shared/CheckBox/CheckBox';
import { Body, Link, notifications } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { onChangeInputEvent } from '~/types/types';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';
import { logToSentry } from '~/helpers/loggers.helper';
import useTermsAndConditionsModal from '~/hooks/useTermsAndConditionsModal';

const TermsAndConditions = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { openTermsAndConditionsModal } = useTermsAndConditionsModal();

  const {
    onboardingOffer: {
      form,
      overview: { applicationId, granter },
    },
  } = useSelector((state: RootState) => state);

  const onChange = (event: onChangeInputEvent) => {
    const { checked } = event.target;
    dispatch(updateForm({ offerIsAgreed: checked }));
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'terms_agreement_change',
      })
    );
  };

  const openModal = () => {
    if (granter) {
      openTermsAndConditionsModal(granter);
    } else {
      notifications.show({
        state: 'critical',
        message: t('SomethingWentWrong'),
        opened: true,
      });
      logToSentry(
        new Error(`Granter is missing for application ${applicationId}`),
        'GranterIsMissingForApplication'
      );
    }
  };

  return (
    <>
      <Checkbox
        checked={form.offerIsAgreed}
        dataCy="onboarding_offer_terms_and_conditions_checkbox"
        name="offerIsAgreed"
        onChange={onChange}
        validationType="Required"
      >
        <Body size={'sm'}>
          {
            t('OnboardingOffer.TermsAndConditionsConsent', {
              termsAndConditionslinkText: (
                <Link size="sm" onClick={openModal}>
                  {t('OnboardingOffer.TermsAndConditionslinkText')}
                </Link>
              ),
            }) as string
          }
        </Body>
      </Checkbox>
    </>
  );
};

export default TermsAndConditions;
