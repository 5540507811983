import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { marketIso3Code } from '~/constants/markets';
import { CountryCode } from '~/enums';
import { PostcodeNlAutocompleteAddress } from '~/Interfaces';
import { RootState } from '~/store';

const supportedMarkets = [
  CountryCode.NL,
  CountryCode.NO,
  CountryCode.BE,
  CountryCode.DK,
  CountryCode.DE,
];

export interface PostcodeAddress {
  language: string;
  address: {
    country: string;
    locality: string;
    street: string;
    postcode: string;
    building: string;
    buildingNumber: number | null;
    buildingNumberAddition: string | null;
  };
  mailLines: string[];
  location: {
    latitude: number;
    longitude: number;
    precision: string;
  } | null;
  isPoBox: boolean;
  country: {
    name: string;
    iso3Code: string;
    iso2Code: string;
  };
  details: {};
}

interface Props {
  inputElement: HTMLInputElement | null;
  menuElement: HTMLDivElement | null;
}

/**
 * Hook to use the Postcode API for address autocomplete
 * @param inputElement - The input element to attach the autocomplete to
 * @param menuElement - The menu element to append the autocomplete to
 * @returns addressResult - The address result from the API
 * @returns autocompleteIsLoading - Whether the autocomplete is currently loading
 * @returns currentValue - The current value of the input element
 */
export const usePostcode = ({ inputElement, menuElement }: Props) => {
  const { market } = useSelector((state: RootState) => state.intl);
  const [isPostcodeScriptLoaded, setIsPostcodeScriptLoaded] = useState(false);
  const [addressResult, setAddressResult] = useState<PostcodeAddress | null>(
    null
  );
  const [autocompleteIsLoading, setAutocompleteIsLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState<string | null>(null);

  if (!supportedMarkets.includes(market)) {
    console.error(
      `Market ${market} not supported by postcode autocomplete API`
    );
  }

  const initializeAutocomplete = (
    _inputElement: HTMLInputElement,
    _menuElement: HTMLDivElement
  ) => {
    if (window.PostcodeNl) {
      const autocomplete = new window.PostcodeNl.AutocompleteAddress(
        _inputElement,
        {
          appendTo: _menuElement,
          autocompleteUrl: `${
            import.meta.env.VITE_API_AUTOCOMPLETE_URL
          }/country/${market}/autocomplete/`,
          addressDetailsUrl: `${
            import.meta.env.VITE_API_AUTOCOMPLETE_URL
          }/country/${market}/details/`,
        }
      );

      autocomplete.setCountry(marketIso3Code[market]);

      autocomplete.getSuggestions = function (context, term, response) {
        let url = `${this.options.autocompleteUrl}${encodeURIComponent(
          term
        )}?context=${encodeURIComponent(context)}`;

        return autocomplete.xhrGet(url, response);
      };

      autocomplete.getDetails = function (addressId, response) {
        let url = `${this.options.addressDetailsUrl}${encodeURIComponent(
          addressId
        )}`;

        return autocomplete.xhrGet(url, response);
      };

      _inputElement.addEventListener('autocomplete-select', function (e: any) {
        setAutocompleteIsLoading(true);
        setCurrentValue(e.detail.value);

        if (e.detail.precision === 'Address') {
          autocomplete.getDetails(
            e.detail.context,
            function (result: PostcodeAddress) {
              setAddressResult(result);
              setAutocompleteIsLoading(false);
            }
          );
        }
      });

      _inputElement.addEventListener('autocomplete-search', () => {
        setAutocompleteIsLoading(true);
      });

      _inputElement.addEventListener('autocomplete-response', () => {
        setAutocompleteIsLoading(false);
      });

      _inputElement.addEventListener('autocomplete-error', () => {
        setAutocompleteIsLoading(false);
      });

      return autocomplete;
    }

    return undefined;
  };

  useEffect(() => {
    const script = document.createElement('script');
    document.body.appendChild(script);
    script.src = '/AutocompleteAddress.min.js';
    script.async = false;

    script.onload = () => {
      setIsPostcodeScriptLoaded(true);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    let autocompleteInstance: PostcodeNlAutocompleteAddress | undefined;

    if (isPostcodeScriptLoaded && inputElement && menuElement) {
      autocompleteInstance = initializeAutocomplete(inputElement, menuElement);
    }

    return () => {
      if (autocompleteInstance) {
        autocompleteInstance.destroy();
      }
    };
  }, [isPostcodeScriptLoaded, inputElement, menuElement]);

  return {
    addressResult,
    autocompleteIsLoading,
    currentValue,
  };
};
