import React from 'react';
import { useSelector } from 'react-redux';
import { LabelValueGrid } from '~/components/shared/Layout/LabelValueGrid';
import { LoanPurposes } from '~/enums';
import { useCurrencyFormatter } from '~/hooks/formatter/useCurrencyFormatter';
import { useLoanPurposeTranslationMapper } from '~/hooks/useLoanPurposeTranslationMapper';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';

export const ApplicationOverview = () => {
  const t = useTranslate();
  const { form } = useSelector(
    (state: RootState) => state.onboardingApplication
  );
  const formatter = useCurrencyFormatter();
  const loanPurposeTranslationMapper = useLoanPurposeTranslationMapper();

  const items = [
    {
      label: t('Onboarding.ApplicationOverviewAmount') as string,
      value: formatter.format(Number(form.amount!.replace(/\D/g, ''))),
    },
    {
      label: t('Onboarding.ApplicationOverviewCompany') as string,
      value: form.companyName!,
    },
    {
      label: t('LoanPurpose') as string,
      value: loanPurposeTranslationMapper[form.purposeOfLoan as LoanPurposes],
    },
    ...(form.applicant.fullName
      ? [
          {
            label: t('FullName') as string,
            value: form.applicant.fullName,
          },
        ]
      : []),
    {
      label: t('Email') as string,
      value: form.applicant.email!,
    },
    {
      label: t('Phone') as string,
      value: form.applicant.phone!,
    },
    ...(form.applicant.personalNumber
      ? [
          {
            label: t('PersonalNumber') as string,
            value: form.applicant.personalNumber,
          },
        ]
      : []),
    ...(form.applicant.dateOfBirth
      ? [
          {
            label: t('DateOfBirth') as string,
            value: form.applicant.dateOfBirth,
          },
        ]
      : []),
    ...(form.applicant.address
      ? [
          {
            label: t('Onboarding.ApplicantAddress') as string,
            value: `${form.applicant.address.street}, ${form.applicant.address.zipCode}, ${form.applicant.address.city}`,
          },
        ]
      : []),
  ];

  return <LabelValueGrid items={items} />;
};
