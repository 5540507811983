import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { isValidDate } from '~/helpers/date.helper';
import { generateLocale } from '~/helpers/generators.helper';
import { RootState } from '~/store/types/sharedTypes';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

interface Options extends Intl.DateTimeFormatOptions {
  dayjsFormat?: string;
}

const useDateFormatter = () => {
  const { language, market } = useSelector((state: RootState) => state.intl);

  return (date: Date | string, options?: Options) => {
    const dateIsValid = isValidDate(date);
    if (!dateIsValid) return date.toString();

    const locale = generateLocale(language, market);

    if (options?.dayjsFormat) {
      const dayjsLocale = locale.split('-')[0];
      return dayjs(date).locale(dayjsLocale).format(options.dayjsFormat);
    }

    return new Intl.DateTimeFormat(locale, options).format(new Date(date));
  };
};

export default useDateFormatter;
