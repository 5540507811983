import moment from 'moment';
import { CountryCode, SignerType } from '~/enums';
import { formatPersonalNumber } from '~/helpers/formatters.helper';
import { TCardOnboardingOfferDTO } from '~/types/DTO/cardOnboardingOffer';
import { v4 as uuidv4 } from 'uuid';
import { getOwnerType, personsAreTheSame } from '~/helpers/onboarding.helper';
import {
  IOnboardingBeneficialOwner,
  IOnboardingSigner,
} from '~/interfaces/Onboarding';
import {
  ICardOnboardingOfferApplicant,
  ICardOnboardingOfferForm,
  ICardOnboardingOfferOverview,
} from '~/interfaces/CardOnboardingOffer';

const beneficialOwnersToDTO = (
  owners: IOnboardingBeneficialOwner[],
  market: CountryCode
): TCardOnboardingOfferDTO['postOfferReqBeneficialOwner'][] =>
  owners?.map(
    (owner): TCardOnboardingOfferDTO['postOfferReqBeneficialOwner'] => ({
      id: owner.id,
      isAlternativeOwner: owner.isAlternativeBeneficialOwner || false,
      ownership: owner.percentage || '0',
      fullName: owner.fullName,
      ...(!!owner.personalNumber && {
        personalNumber: formatPersonalNumber(owner.personalNumber, market),
      }),
      ...(!!owner.dateOfBirth && {
        dateOfBirth: moment(owner.dateOfBirth).format('YYYY-MM-DD'),
      }),
      ownerShare: owner.percentage || '0',
      politicallyExposed: owner.isPEP || false,
      residenceCountryIsoCode: owner.countryOfResidence || '',
      taxResidenceCountryIsoCode: owner.countryOfTaxResidence || '',
      residenceCountry: owner.countryOfResidence || '',
    })
  );

const signersToDTO = (
  signers: IOnboardingSigner[]
): TCardOnboardingOfferDTO['postOfferReqSigner'][] =>
  signers?.map((signer): TCardOnboardingOfferDTO['postOfferReqSigner'] => ({
    name: signer.name,
    firstName: signer.firstName,
    lastName: signer.lastName,
    email: signer.email || '',
    phoneNumber: signer.phone || '',
    signerId: signer.signerId,
    type: signer.type,
    residenceCountryIsoCode: signer.countryOfResidence || '',
    taxResidenceCountryIsoCode: signer.countryOfTaxResidence || '',
    politicallyExposed: signer.isPEP || false,
  }));

const offerBeneficialOwnersFromDTO = (
  owners: TCardOnboardingOfferDTO['getOfferRes']['beneficialOwners']
): IOnboardingBeneficialOwner[] =>
  owners?.map(
    (owner): IOnboardingBeneficialOwner => {
      const dateOfBirthOnlyContainsYear =
        owner.dateOfBirth?.toString().length === 4;

      return {
        fullName: owner.fullName || '',
        personalNumber: owner?.personalNumber,
        dateOfBirth:
          owner.dateOfBirth && !dateOfBirthOnlyContainsYear
            ? new Date(owner.dateOfBirth)
            : null,
        yearOfBirth: dateOfBirthOnlyContainsYear ? owner.dateOfBirth : '',
        percentage: owner.ownership?.replace(',', '.') || '', // In case API returns comma instead of period
        id: uuidv4(),
        countryOfResidence: owner.residenceCountry,
      };
    }
  );

const offerSignersFromDTO = (
  signers: TCardOnboardingOfferDTO['getOfferRes']['additionalSigners']
): IOnboardingSigner[] =>
  signers?.map(
    (signer): IOnboardingSigner => ({
      name: `${signer.firstName} ${signer.lastName}`,
      firstName: signer.firstName,
      lastName: signer.lastName,
      signerId: signer.signerId,
      email: signer.email,
      phone: signer.phoneNumber,
      type: SignerType.Signer,
    })
  );

const offerApplicantFromDTO = (
  applicant: TCardOnboardingOfferDTO['getOfferRes']['applicant']
): ICardOnboardingOfferApplicant => {
  const dateOfBirthOnlyContainsYear =
    applicant.dateOfBirth?.toString().length === 4;

  return {
    fullName: `${applicant.firstName} ${applicant.lastName}`,
    personalNumber: applicant?.personalNumber,
    dateOfBirth:
      applicant.dateOfBirth && !dateOfBirthOnlyContainsYear
        ? new Date(applicant.dateOfBirth)
        : null,
    yearOfBirth: dateOfBirthOnlyContainsYear ? applicant.dateOfBirth : '',
    percentage: '',
    id: uuidv4(),
    signerId: applicant.signerId,
    email: applicant.email,
    phoneNumber: applicant.phoneNumber,
    firstName: applicant.firstName,
    lastName: applicant.lastName,
  };
};

const offerFromDTO = (
  cardOffer: TCardOnboardingOfferDTO['getOfferRes']
): ICardOnboardingOfferOverview => ({
  additionalSigners: offerSignersFromDTO(cardOffer.additionalSigners) || [],
  applicant: offerApplicantFromDTO(cardOffer.applicant),
  applicationReasons: cardOffer.applicationReasons || [],
  applicationUuid: cardOffer.applicationUuid || '',
  beneficialOwners:
    offerBeneficialOwnersFromDTO(cardOffer.beneficialOwners) || [],
  cardOfferAlreadySigned: cardOffer.cardOfferAlreadySigned || false,
  clientId: cardOffer.clientId,
  companyName: cardOffer.companyName || '',
  companyNo: cardOffer.companyNo || '',
  companyType: cardOffer.companyType || '',
  creditLimit: cardOffer.offeredCreditLimit || 0,
  hasAcceptedPersonalGuarantee: cardOffer.hasAcceptedPersonalGuarantee || false,
  hasAcceptedTerms: cardOffer.hasAcceptedTerms || false,
  interest: cardOffer.interest || 0,
  invoiceAddress: cardOffer.invoiceAddress || '',
  invoiceDay: cardOffer.invoiceDay || 'MID_OF_MONTH',
  invoiceEmail: cardOffer.invoiceEmail || '',
  isPaperInvoiceChosen: cardOffer.isPaperInvoiceChosen || false,
  isPersonalGuaranteeRequired: !!cardOffer.isPersonalGuaranteeRequired,
  offeredCreditLimit: cardOffer.offeredCreditLimit || 0,
  shippingAddress: cardOffer.shippingAddress || '',
  alternativeBeneficialOwners: [],
});

const applicantToDTO = (
  applicant: ICardOnboardingOfferApplicant,
  market: CountryCode
): TCardOnboardingOfferDTO['postOfferReq']['applicant'] => ({
  dateOfBirth: applicant.dateOfBirth
    ? moment(applicant.dateOfBirth).format('YYYY-MM-DD')
    : null,
  email: applicant.email,
  firstName: applicant.firstName,
  lastName: applicant.lastName,
  ...(!!applicant.personalNumber && {
    personalNumber: formatPersonalNumber(applicant.personalNumber, market),
  }),
  phoneNumber: applicant.phoneNumber,
  signerId: applicant.signerId,
  residenceCountryIsoCode: applicant.countryOfResidence || '',
  taxResidenceCountryIsoCode: applicant.countryOfTaxResidence || '',
  politicallyExposed: applicant.isPEP || false,
  fullName: applicant.fullName || '',
  id: applicant.id || '',
  personalNumber: applicant.personalNumber || '',
});

const applicantToBeneficialOwnerDTO = (
  applicant: ICardOnboardingOfferApplicant,
  market: CountryCode
): TCardOnboardingOfferDTO['postOfferReqBeneficialOwner'] => ({
  id: applicant.id,
  isAlternativeOwner: applicant.isAlternativeBeneficialOwner || false,
  ownership: applicant.percentage || '0',
  fullName: applicant.fullName,
  ...(!!applicant.personalNumber && {
    personalNumber: formatPersonalNumber(applicant.personalNumber, market),
  }),
  ...(!!applicant.dateOfBirth && {
    dateOfBirth: moment(applicant.dateOfBirth).format('YYYY-MM-DD'),
  }),
  ownerShare: applicant.percentage || '0',
  politicallyExposed: applicant.isPEP || false,
  residenceCountryIsoCode: applicant.countryOfResidence || '',
  taxResidenceCountryIsoCode: applicant.countryOfTaxResidence || '',
  residenceCountry: applicant.countryOfResidence || '',
});

const formAndOverviewToPostOfferDTO = (
  details: ICardOnboardingOfferForm,
  overview: ICardOnboardingOfferOverview,
  market: CountryCode
): TCardOnboardingOfferDTO['postOfferReq'] => {
  const additionalOwners = details.owners.filter(
    (owner) => !personsAreTheSame(owner, details.applicant)
  );
  return {
    applicant: applicantToDTO(details.applicant, market),
    applicantOwnerShare: details.applicant.percentage || '0',
    ownerStructure: getOwnerType(details.applicant.percentage, details.owners),
    additionalSigners: signersToDTO(details.signers),
    beneficialOwners: [
      applicantToBeneficialOwnerDTO(details.applicant, market),
      ...beneficialOwnersToDTO(additionalOwners, market),
    ],
    applicationReasons: details.applicationReasons,
    clientId: overview.clientId,
    applicationUuid: overview.applicationUuid,
    creditLimit: overview.creditLimit,
    interest: overview.interest,
    hasAcceptedTerms: true,
    hasAcceptedPersonalGuarantee: overview.isPersonalGuaranteeRequired || false,
    isPersonalGuaranteeRequired: overview.isPersonalGuaranteeRequired,
    invoiceAddress: overview.invoiceAddress,
    invoiceEmail: overview.invoiceEmail,
    shippingAddress: null,
    offeredCreditLimit: 0,
    isPaperInvoiceChosen: false,
    companyName: overview.companyName,
    companyType: overview.companyType,
    invoiceDay: 'MID_OF_MONTH',
    companyNo: overview.companyNo,
  };
};

export const cardOnboardingOfferDTOMappers = {
  beneficialOwnersToDTO,
  signersToDTO,
  applicantToDTO,
  applicantToBeneficialOwnerDTO,
  formAndOverviewToPostOfferDTO,
  offerFromDTO,
};
