import { Stack, useWindowProperties } from '@qred/components-library';
import React, { RefObject } from 'react';
import { StepConfigItem } from '~/Interfaces';
import { StepFooterWrapper } from '../Stepper.styled';
import NextButton from './NextButton';

interface StepFooterProps {
  CTALabel: string;
  currentStepConfig: StepConfigItem;
  CTAAction: () => void;
  footerRef: RefObject<HTMLDivElement>;
  stepIsLoading?: boolean;
  extraContent?: React.ReactNode;
  fixedFooter?: boolean;
}

const StepFooter = ({
  CTALabel,
  currentStepConfig,
  CTAAction,
  footerRef,
  stepIsLoading,
  extraContent,
  fixedFooter,
}: StepFooterProps) => {
  const { isMobile } = useWindowProperties();

  return (
    <StepFooterWrapper ref={footerRef} $fixedFooter={fixedFooter}>
      <Stack
        justify={'center'}
        align={'center'}
        p={`0 ${isMobile ? '1em' : '2em'}`}
        spacing={isMobile ? 'sm' : 'xs'}
        w="100%"
      >
        {extraContent}
        {currentStepConfig.customFooter ? (
          <currentStepConfig.customFooter />
        ) : (
          <NextButton
            label={CTALabel}
            action={CTAAction}
            isLoading={stepIsLoading}
          />
        )}
      </Stack>
    </StepFooterWrapper>
  );
};

export default StepFooter;
