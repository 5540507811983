import { myPagesBaseApi } from '~/store/apis/endpoints/_baseApi/myPages.base.api';
import { myQredApi } from './_baseApi/myQredApi.base.api';
import { onboardingUnauthenticatedApi } from './_baseApi/onboarding.unauthenticated.base.api';
import { openBankingApi } from './openBankingApi/openBanking.api';
import { myPagesUnauthenticatedBaseApi } from './_baseApi/myPages.unauthenticated.base.api';
import { onboardingApi } from './onboardingApi/onboarding.api';

export const apis = {
  [myPagesBaseApi.reducerPath]: myPagesBaseApi.reducer,
  [myPagesUnauthenticatedBaseApi.reducerPath]:
    myPagesUnauthenticatedBaseApi.reducer,
  [myQredApi.reducerPath]: myQredApi.reducer,
  [onboardingUnauthenticatedApi.reducerPath]:
    onboardingUnauthenticatedApi.reducer,
  [openBankingApi.reducerPath]: openBankingApi.reducer,
  [onboardingApi.reducerPath]: onboardingApi.reducer,
};
