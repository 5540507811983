import { useAccount } from '~/hooks/useAccount';
import { useGetLoanActiveQuery } from '~/store/apis/endpoints/myQredApi/loan/loans.api';
import posthog from 'posthog-js';

export const useSepaDirectDebit = () => {
  const { selectedCompany, market } = useAccount();
  const { currentData: activeLoan } = useGetLoanActiveQuery(
    {
      companyId: selectedCompany.id,
      market,
    },
    { refetchOnMountOrArgChange: true }
  );
  const isSignerApplicant = activeLoan?.signer?.type === 'Applicant';
  const showPaymentTab =
    posthog.isFeatureEnabled('Payment_Options_SepaDirectDebit_Release') &&
    isSignerApplicant;

  return {
    activeLoan,
    isDirectDebitEnabled: activeLoan?.directDebitEnabled,
    showPaymentTab,
  };
};
