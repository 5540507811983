import { getMarketHasAddress } from '~/constants/markets';
import { ClientType, CountryCode } from '~/enums';
import {
  formatOrganizationNumber,
  formatAmountWithoutCurrency,
  removeNonDigits,
} from '~/helpers/formatters.helper';
import { normalizeOrgNumberHelper } from '~/helpers/normalizeOrgNumber.helper';
import { transformGuarantorAddress } from '~/helpers/onboarding.helper';
import { getCookieValue } from '~/helpers/utils';
import {
  ILoanOnboardingApplicationForm,
  ILoanOnboardingApplicationGuarantor,
} from '~/interfaces/LoanOnboardingApplication';
import { TLoanOnboardingApplicationDTO } from '~/types/DTO/loanOnboardingApplication';

const applicationFromDTO = (
  data: TLoanOnboardingApplicationDTO['getApplicationRes'],
  currentForm: ILoanOnboardingApplicationForm
): ILoanOnboardingApplicationForm => {
  let applicant = { ...currentForm.applicant };
  if (data.applicant) {
    const { firstName, lastName, ...restOfApplicantFromData } = data.applicant;
    const fullName = `${firstName || ''} ${lastName || ''}`.trim();
    applicant = {
      ...applicant,
      ...restOfApplicantFromData,
      ...(fullName && { fullName }),
    };
  }

  return {
    ...currentForm,
    applicant,
    applicationUuid: data.applicationUuid,
    bankIntegration: data.bankIntegration,
    bankConnected: data.bankConnected,
    organizationNumber: formatOrganizationNumber(
      data.organization.organizationNumber || ''
    ),
    connectId: data.organization.connectId,
    vatNumber: data.organization.vatNumber,
    companyName: data.organization.companyName,
    companyAddress: data.organization.companyAddress,
    companyType: data.organization.companyType || '',
    companyTypeCategory: data.companyTypeCategory,
    purposeOfLoan: data.purposeOfLoan,
    reason: data.reason || '',
    sessionId: data.sessionId,
    guarantors:
      data.guarantors?.map(
        (g): ILoanOnboardingApplicationGuarantor => ({
          ...g,
          guarantorId: g.guarantorId,
          isChecked: true,
        })
      ) || [],
    turnover: data.turnover,
    amount:
      currentForm.amount ||
      formatAmountWithoutCurrency(removeNonDigits(data.amount.toString())), // TODO: skip formatting here, and format when displayed to user instead?
    clientType:
      currentForm.clientType !== ClientType.Unknown
        ? currentForm.clientType
        : data.clientType || ClientType.Unknown,
    applicationId: data.applicationId,
    ...(!!data.bankFilesPaths?.length && {
      bankFilesPaths: data.bankFilesPaths,
    }),
    ...(!!data.bankFilesUploaded && {
      bankFilesUploaded: data.bankFilesUploaded,
    }),
    qCode: data.qCode,
    userCode: data.userCode,
    eskatConnected: data.eskatConnected,
    incomeReceivedInForeignCurrencies: data.incomeReceivedInForeignCurrencies,
    choseToBoostApplication: data.choseToBoostApplication,
  };
};

const applicationToDTO = ({
  form,
  currentStep,
  market,
  shouldPostApplication,
  finalized,
  currentStepCompleted,
  clientId,
  isCompanyTypeCategoryRequested,
}: {
  clientId?: number;
  currentStep: number;
  currentStepCompleted: boolean;
  finalized: boolean;
  form: ILoanOnboardingApplicationForm;
  market: CountryCode;
  shouldPostApplication: boolean;
  isCompanyTypeCategoryRequested?: boolean;
}): TLoanOnboardingApplicationDTO['postPatchApplicationReq'] => {
  const {
    organizationNumber,
    companyType,
    companyName,
    vatNumber,
    connectId,
    companyAddress,
    guarantors,
    amount = '',
    applicant,
    ...rest
  } = form;

  const marketHasAddress = getMarketHasAddress(market);

  const requestData: TLoanOnboardingApplicationDTO['postPatchApplicationReq'] = {
    organization: {},
    amount: Number(removeNonDigits(amount)),
    completedStep: currentStepCompleted ? currentStep : currentStep - 1,
    market,
    currentStep,
    finalized,
    isCompanyTypeCategoryRequested,
    clientId,
    ...rest,
  };

  organizationNumber &&
    (requestData.organization.organizationNumber = normalizeOrgNumberHelper(
      organizationNumber,
      market
    ));
  companyType && (requestData.organization.companyType = companyType);
  companyName && (requestData.organization.companyName = companyName);
  vatNumber && (requestData.organization.vatNumber = vatNumber);
  connectId && (requestData.organization.connectId = connectId);
  companyAddress && (requestData.organization.companyAddress = companyAddress);

  const { fullName, ...applicantRest } = applicant || {};
  requestData.applicant = applicantRest;
  if (fullName) {
    const [firstName, ...restOfName] = fullName.split(' ');
    requestData.applicant.firstName = firstName;
    requestData.applicant.lastName = restOfName.join(' ');
  }

  if (shouldPostApplication) {
    const trafficCookie = getCookieValue('_ga') || '';
    requestData.trafficCookie = trafficCookie;
  }

  if (guarantors && guarantors.length) {
    requestData.guarantors = guarantors
      .filter((guarantor) => guarantor.isChecked)
      .map(
        (
          formGuarantor
        ): TLoanOnboardingApplicationDTO['postPatchApplicationReqGuarantor'] => ({
          guarantorId: formGuarantor.guarantorId,
          fullName: formGuarantor.fullName || '',
          email: formGuarantor.email || '',
          phone: formGuarantor.phone || '',
          personalNumber: formGuarantor.personalNumber,
          maskedPersonalNumber: formGuarantor.maskedPersonalNumber,
          dateOfBirth: formGuarantor.dateOfBirth,
          maskedDateOfBirth: formGuarantor.maskedDateOfBirth,
          addressFields: formGuarantor.addressFields,
        })
      );
  }

  if (marketHasAddress) {
    if (requestData.guarantors?.length) {
      requestData.guarantors = requestData.guarantors.map(
        (
          guarantor
        ): TLoanOnboardingApplicationDTO['postPatchApplicationReqGuarantor'] => {
          if (guarantor.addressFields) {
            return {
              ...guarantor,
              fullAddress: transformGuarantorAddress({
                ...guarantor.addressFields,
                market,
              }),
            };
          }
          return guarantor;
        }
      );
    }
  }

  return requestData;
};

export const loanOnboardingApplicationDTOMappers = {
  applicationFromDTO,
  applicationToDTO,
};
