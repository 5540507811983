import { useWindowProperties } from '@qred/components-library';
import { numberFormatter } from '@qred/fe-cp-formatting-helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { LabelValueGrid } from '~/components/shared/Layout/LabelValueGrid';
import { formatAmount } from '~/helpers/formatters.helper';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';

export const CardOfferView = () => {
  const {
    intl: { market },
    cardOnboardingOffer: {
      overview: { companyName, interest, offeredCreditLimit },
    },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();
  const { isMobile } = useWindowProperties();
  const formatter = numberFormatter(market);

  const cardDetailsLabelAndValue = [
    {
      label: translate('CreditCardDetailsCard.CompanyName') as string,
      value: companyName,
    },
    {
      label: translate('CreditCardDetailsCard.ApprovedCredit') as string,
      value: formatAmount(offeredCreditLimit),
    },
    {
      label: translate('CreditCardDetailsCard.MonthlyInterest') as string,
      value: `${formatter(interest)} %`,
    },
    {
      label: translate('CreditCardDetailsCard.YearlyMonthlyFee') as string,
      value: formatAmount(0),
    },
    {
      label: translate('CreditCardDetailsCard.PlasticCardFee') as string,
      value: formatAmount(0),
    },
    {
      label: translate('OnboardingCardOffer.BenefitsCashback') as string,
      value: '1 %',
    },
  ];

  return (
    <LabelValueGrid items={cardDetailsLabelAndValue} isMobile={isMobile} />
  );
};
