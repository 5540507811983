import {
  Controller,
  DatePicker,
  Input,
  Stack,
  useForm,
  z,
} from '@qred/components-library';
import { validatePhoneNumber } from '@qred/shared-component-library/src/validators';
import dayjs from 'dayjs';
import React, { useContext, useEffect } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationContext } from '~/components/hoc/withValidation';
import { AddressSearch } from '~/components/shared/AddressSearch/AddressSearch';
import { FormErrorBanner } from '~/components/shared/FormErrorBanner/FormErrorBanner';
import { PostcodeAddress } from '~/hooks/usePostcode';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import { updateApplicantData } from '~/store/slices/onboardingApplication.slice';

const DEFAULT_DATE = new Date(
  new Date().setFullYear(new Date().getFullYear() - 18)
);

export const PersonalInformationDE = () => {
  const t = useTranslate();
  const {
    intl: { market },
    onboardingApplication: {
      form: {
        applicant: { fullName, dateOfBirth, phone, email, address },
      },
    },
  } = useSelector((state: RootState) => state);

  const initialAddressValue = address
    ? `${address.city}, ${address.street}`
    : undefined;

  const PersonalInformationSchema = z
    .object({
      firstName: z
        .string()
        .trim()
        .min(1, { message: 'ValidationErrors.Required' }),
      lastName: z
        .string()
        .trim()
        .min(1, { message: 'ValidationErrors.Required' }),
      dateOfBirth: z
        .date()
        .optional()
        .refine((value) => value !== undefined, {
          message: 'ValidationErrors.Required',
        }),
      mobilePhone: z
        .string()
        .trim()
        .min(1, { message: 'ValidationErrors.Required' })
        .refine((value) => validatePhoneNumber(value, market), {
          message: 'ValidationErrors.Phone',
        }),
      email: z
        .string()
        .trim()
        .min(1, { message: 'ValidationErrors.Required' })
        .email({ message: 'ValidationErrors.Email' }),
      addressFieldValue: z
        .string()
        .min(1, { message: 'ValidationErrors.Required' }),
      selectedAddress: z
        .object({
          city: z.string(),
          street: z.string(),
          zipCode: z.string(),
        })
        .nullable(),
    })
    .refine(
      (data) => {
        if (data.selectedAddress === null && data.addressFieldValue !== '') {
          return false;
        }

        return true;
      },
      {
        message: 'ValidationErrors.NotSelectedFromAddressSearchResults',
        path: ['addressFieldValue'],
      }
    );

  type PersonalInformationForm = z.infer<typeof PersonalInformationSchema>;
  const {
    setValue,
    watch,
    register,
    control,
    formState: { errors, isValid },
  } = useForm<PersonalInformationForm>({
    schema: PersonalInformationSchema,
    defaultValues: {
      firstName: fullName?.split(' ')[0] || '',
      lastName: fullName?.split(' ')[1] || '',
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : undefined,
      mobilePhone: phone || '+49',
      email: email || '',
      addressFieldValue: initialAddressValue || '',
      selectedAddress: address || null,
    },
    mode: 'onTouched',
  });

  const fieldValues = watch();

  const dispatch = useDispatch();
  const validationContext = useContext(ValidationContext);

  const handleSelectedAddress = (selectedAddress: PostcodeAddress | null) => {
    if (selectedAddress) {
      const {
        locality,
        postcode,
        street,
        buildingNumber,
        buildingNumberAddition,
      } = selectedAddress.address;

      const fullStreet = `${street} ${buildingNumber || ''}${
        buildingNumberAddition || ''
      }`.trim();

      setValue(
        'selectedAddress',
        {
          city: locality,
          street: fullStreet,
          zipCode: postcode,
        },
        { shouldValidate: true }
      );

      setValue('addressFieldValue', `${locality}, ${fullStreet}`, {
        shouldValidate: true,
      });
    }
  };

  const onChangeAddressFieldValue = (
    field: ControllerRenderProps<PersonalInformationForm, 'addressFieldValue'>,
    value: string
  ) => {
    setValue('selectedAddress', null);
    field.onChange(value);
  };

  useEffect(() => {
    if (isValid && fieldValues.selectedAddress !== null) {
      dispatch(
        updateApplicantData({
          fullName: `${fieldValues.firstName} ${fieldValues.lastName}`,
          dateOfBirth: dayjs(fieldValues.dateOfBirth).format('YYYY-MM-DD'),
          phone: fieldValues.mobilePhone,
          email: fieldValues.email,
          address: fieldValues.selectedAddress,
        })
      );
      validationContext.removePropertyFromValidationErrors(
        'companySelectorStep'
      );
    } else {
      validationContext.addPropertyToValidationErrors('companySelectorStep');
    }

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'companySelectorStep'
      );
    };
  }, [
    fieldValues.firstName,
    fieldValues.lastName,
    fieldValues.dateOfBirth,
    fieldValues.mobilePhone,
    fieldValues.email,
    fieldValues.addressFieldValue,
    fieldValues.selectedAddress,
    isValid,
  ]);

  return (
    <Stack spacing={'xs'}>
      <Input.Group orientation="vertical">
        <Input
          {...register('firstName')}
          label={t('FirstName')}
          error={errors?.firstName?.message && t(errors?.firstName?.message)}
        />
        <Input
          {...register('lastName')}
          label={t('LastName')}
          error={errors?.lastName?.message && t(errors?.lastName?.message)}
        />
        <Controller
          name="dateOfBirth"
          control={control}
          render={({ field, fieldState }) => (
            <DatePicker
              label={t('DateOfBirth')}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={fieldState.error?.message && t(fieldState.error?.message)}
              valueFormat="DD-MM-YYYY"
              defaultDate={DEFAULT_DATE}
              maxDate={new Date()}
            />
          )}
        />
        <Input
          {...register('mobilePhone')}
          label={t('MobilePhone')}
          value={
            fieldValues.mobilePhone.length < 3 ? '+49' : fieldValues.mobilePhone
          }
          error={
            errors?.mobilePhone?.message && t(errors?.mobilePhone?.message)
          }
        />
        <Input
          {...register('email')}
          label={t('Email')}
          error={errors?.email?.message && t(errors?.email?.message)}
        />
        <Controller
          name="addressFieldValue"
          control={control}
          render={({ field, fieldState }) => (
            <AddressSearch
              label={t('Onboarding.ApplicantAddress')}
              handleResult={handleSelectedAddress}
              onChange={(value) => onChangeAddressFieldValue(field, value)}
              onBlur={field.onBlur}
              error={fieldState.error?.message && t(fieldState.error?.message)}
              initialValue={initialAddressValue}
            />
          )}
        />
      </Input.Group>
      <FormErrorBanner errors={errors} />
    </Stack>
  );
};
