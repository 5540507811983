import {
  Body,
  Controller,
  FormProvider,
  Stack,
  useForm,
  z,
} from '@qred/components-library';
import { ComboboxData } from '@qred/components-library/dist/lib/next/components/Combobox/Combobox.types';
import { OptionsFilter } from '@qred/components-library/dist/lib/next/components/Combobox/components/OptionsDropdown/OptionsDropdown';
import { FilterOptionsInput } from '@qred/components-library/dist/lib/next/components/Combobox/components/OptionsDropdown/defaultOptionsFilter';
import { AsyncSelect, SelectProps } from '@qred/components-library/next';
import { validateOrgNumber } from '@qred/shared-component-library/src/validators';
import debounce from 'lodash.debounce';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationContext } from '~/components/hoc/withValidation';
import { FormErrorBanner } from '~/components/shared/FormErrorBanner/FormErrorBanner';
import { getMarketCanSearchCompanyByName } from '~/constants/markets';
import { logToSentry } from '~/helpers/loggers.helper';
import { normalizeOrgNumberHelper } from '~/helpers/normalizeOrgNumber.helper';
import { areSameCompanies } from '~/helpers/utils';
import useTranslate from '~/hooks/useTranslate';
import { ICompanySearchResult } from '~/interfaces/Onboarding';
import { apiGetCompanyInfoBySearch } from '~/services/api/onboarding';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import {
  setApiStatus,
  setCompanyIsNew,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';

const companySearchSchema = z.object({
  companySearch: z
    .string()
    .trim()
    .min(1, { message: 'ValidationErrors.Required' }),
  selectedCompany: z
    .string()
    .refine((value) => value.length, {
      message: 'ValidationErrors.Required',
    })
    .nullable(),
});

type CompanySearchForm = z.infer<typeof companySearchSchema>;

const CompanySearch = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const validationContext = useContext(ValidationContext);

  const { market } = useSelector((state: RootState) => state.intl);
  const {
    form,
    overview,
    apiStatus: { companySearch: companySearchApiStatus },
  } = useSelector((state: RootState) => state.onboardingApplication);

  const formMethods = useForm<CompanySearchForm>({
    schema: companySearchSchema,
    defaultValues: {
      companySearch: form.companyName ?? '',
      selectedCompany: form.organizationNumber ?? null,
    },
    mode: 'onTouched',
  });

  const {
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  const [requestHasBeenMade, setRequestHasBeenMade] = useState(false);

  const initialCompanies =
    form.companyName && form.organizationNumber
      ? [
          {
            companyName: form.companyName,
            companyNumber: form.organizationNumber,
            companyType: form.companyType,
          },
        ]
      : [];

  const [companies, setCompanies] = useState<ICompanySearchResult[]>(
    initialCompanies
  );
  const marketCanSearchByName = getMarketCanSearchCompanyByName(market);

  const debouncedCompanyFetch = useCallback(
    debounce(
      async (inputValue: string, callback: (data: ComboboxData) => void) => {
        dispatch(setApiStatus({ companySearch: ApiStatus.Started }));
        try {
          const result = await apiGetCompanyInfoBySearch(inputValue, market);

          setCompanies(
            result.map((company) => ({
              companyName: company.companyName,
              companyNumber: company.companyNumber ?? '',
              companyType: company.companyType,
            }))
          );

          callback(
            result.map((company) => ({
              label: company.companyName ?? '',
              value: company.companyNumber ?? '',
            }))
          );

          setRequestHasBeenMade(true);

          dispatch(setApiStatus({ companySearch: ApiStatus.Completed }));
        } catch (error: any) {
          setRequestHasBeenMade(true);
          if (error?.response?.status !== 404) {
            logToSentry(error, 'apiGetCompanyInfoBySearch');
          }
          dispatch(setApiStatus({ companySearch: ApiStatus.Failed }));
          setCompanies([]);
          callback([]);
        }
      },
      1000
    ),
    []
  );

  const clearSelectedCompany = () => {
    setValue('selectedCompany', null);
    setValue('companySearch', '');
    setCompanies([]);
    setRequestHasBeenMade(false);
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'other_company_search_result_cleared',
      })
    );
    dispatch(
      updateForm({ organizationNumber: '', companyType: '', companyName: '' })
    );
  };

  useEffect(() => {
    if (
      overview.clients?.some((client) =>
        areSameCompanies(
          client.company_no || client.connect_id,
          form.organizationNumber || form.connectId
        )
      )
    ) {
      dispatch(setCompanyIsNew(false));
    }
  }, [form.organizationNumber]);

  useEffect(() => {
    form.organizationNumber
      ? validationContext.removePropertyFromValidationErrors('MissingCompany')
      : validationContext.addPropertyToValidationErrors('MissingCompany');
    return () => {
      validationContext.removePropertyFromValidationErrors('MissingCompany');
    };
  }, [form.organizationNumber]);

  const handleCompanySelect = (companyNumber: string, searchValue: string) => {
    const selectedCompany = companies.find(
      (company) => company.companyNumber === companyNumber
    );

    if (!selectedCompany) {
      // TODO: Better way to do this?
      logToSentry(
        new Error(
          'No matching company found from API call to selected company'
        ),
        'companySearch'
      );
      return;
    }

    const searchedByOrgNr = areSameCompanies(
      selectedCompany.companyNumber,
      searchValue
    );

    dispatch(
      pushToGtmOnboardingAction({
        actionName: searchedByOrgNr
          ? 'other_company_search_result_selected_by_company_nr_search'
          : 'other_company_search_result_selected_by_company_name_search',
      })
    );
    dispatch(
      updateForm({
        organizationNumber: selectedCompany.companyNumber,
        companyType: selectedCompany?.companyType,
        companyName: selectedCompany.companyName,
      })
    );

    setValue('selectedCompany', selectedCompany.companyNumber);
    setValue('companySearch', selectedCompany.companyName, {
      shouldValidate: true,
    });

    setRequestHasBeenMade(false);
  };

  const getEmptyDropdownText = () => {
    if (companySearchApiStatus === ApiStatus.Started) return t('Loading');

    return requestHasBeenMade && companySearchApiStatus !== ApiStatus.Idle
      ? t('Onboarding.SelectCompanyNoSearchResultFound')
      : t('Onboarding.SelectCompanySearchValueMissingPrompt');
  };

  const onBlur = (field: ControllerRenderProps) => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: `company_selector_${field}_change`,
      })
    );
    if (!field.value) {
      clearSelectedCompany();
    }
    field.onBlur();
  };

  const optionsFilter: OptionsFilter = (input: FilterOptionsInput) => {
    return input.options;
  };

  const loadData = (
    inputValue: string,
    callback: (data: ComboboxData) => void
  ) => {
    setValue('companySearch', inputValue);
    if (inputValue.trim().length < 2) {
      setRequestHasBeenMade(false);
      clearSelectedCompany();
      return callback([]);
    }

    const normalizedInputValue = normalizeOrgNumberHelper(inputValue, market);
    const isValidOrgNumberFormat = validateOrgNumber(
      normalizedInputValue,
      market
    );

    const searchValue = isValidOrgNumberFormat
      ? normalizedInputValue
      : inputValue;

    if (!marketCanSearchByName) {
      if (isValidOrgNumberFormat) {
        debouncedCompanyFetch(searchValue, callback);
      } else if (companies.length) {
        callback([
          {
            label: companies[0].companyName,
            value: companies[0].companyNumber,
          },
        ]);
      } else {
        callback([]);
      }
    } else {
      debouncedCompanyFetch(searchValue, callback);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <Stack>
          <Controller
            name="companySearch"
            control={control}
            render={({ field }) => (
              <AsyncSelect
                label={t('Onboarding.SelectCompanySearchInputLabel')}
                defaultSearchValue={field.value}
                defaultValue={form.organizationNumber}
                defaultData={companies.map((company) => ({
                  label: company.companyName,
                  value: company.companyNumber,
                }))}
                filter={optionsFilter}
                loadData={loadData}
                onChange={(value) => {
                  if (value) {
                    handleCompanySelect(value, field.value);
                  }
                }}
                error={
                  errors.companySearch?.message &&
                  t(errors.companySearch?.message)
                }
                renderOption={renderSelectOption}
                onBlur={() => onBlur(field)}
                nothingFoundMessage={`${getEmptyDropdownText()}`}
                searchable={true}
                clearable={true}
                // If we don't have allowDeselect set to false, if they change the search
                // and click on the same result, we actually deselect it
                allowDeselect={false}
                onClear={clearSelectedCompany}
              />
            )}
          />
          <FormErrorBanner errors={errors} />
        </Stack>
      </form>
    </FormProvider>
  );
};

const renderSelectOption: SelectProps['renderOption'] = ({ option }) => (
  <Stack spacing="xs">
    <Body size="lg" color="textDark.500" data-record>
      {option.label}
    </Body>
    <Body size="lg" color="textDark.200" data-record>
      {option.value}
    </Body>
  </Stack>
);
export default CompanySearch;
