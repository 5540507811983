import { Button } from '@qred/components-library';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHomePageByMarket } from '~/constants/markets';
import { StepStatus } from '~/enums';

import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';

export const ApplicationOverviewFooter = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { language, market } = useSelector((state: RootState) => state.intl);

  const onClick = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'close_button_pressed',
      })
    );
    dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Finished }));
    window.location.assign(getHomePageByMarket(market, language));
  };

  return (
    <Button variant="tertiary" onClick={onClick}>
      {t('Close')}
    </Button>
  );
};
