import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { startAppListening } from '../middlewares/listener.middleware';
import {
  ILoanOnboardingOffer,
  ILoanOnboardingOfferApplicant,
  ILoanOnboardingOfferForm,
  ILoanOnboardingOfferState,
} from '~/interfaces/LoanOnboardingoffer';
import { loanOnboardingOfferInitialState } from '../initialState';
import { KycQuestionnaireQuestionNames, SignerType } from '~/enums';
import {
  IKlarnaAccount,
  IOnboardingBeneficialOwner,
  IOnboardingKYCQuestionnaireAnswer,
  IOnboardingSigner,
} from '~/interfaces/Onboarding';
import { syncOnboardingOfferPersons } from '~/helpers/onboarding.helper';

type IUpdatableFormFields = Omit<ILoanOnboardingOfferForm, 'owners'>;

const loanOnboardingOfferSlice = createSlice({
  name: 'loanOnboardingOffer',
  initialState: loanOnboardingOfferInitialState,
  reducers: {
    updateCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    goToNextStep: (state) => {
      state.currentStep += 1;
    },
    goToPreviousStep: (state) => {
      state.currentStep -= 1;
    },
    updateForm: (
      state,
      action: PayloadAction<Partial<IUpdatableFormFields>>
    ) => {
      state.form = { ...state.form, ...action.payload };
    },
    updateOverview: (state, action: PayloadAction<ILoanOnboardingOffer>) => {
      state.overview = { ...state.overview, ...action.payload };
    },
    setApiStatus: (
      state,
      action: PayloadAction<Partial<ILoanOnboardingOfferState['apiStatus']>>
    ) => {
      state.apiStatus = {
        ...state.apiStatus,
        ...action.payload,
      };
    },
    setStepStartTimestamp: (state) => {
      state.stepStartTimestamp = new Date().toString();
    },
    setFetchedBeneficialOwners: (
      state,
      action: PayloadAction<IOnboardingBeneficialOwner[]>
    ) => {
      state.fetchedBeneficialOwners = action.payload;
    },
    updateApplicant: (
      state,
      action: PayloadAction<Partial<ILoanOnboardingOfferApplicant>>
    ) => {
      state.form.applicant = {
        ...state.form.applicant,
        ...action.payload,
      };
    },
    updateAdditionalOwner: (
      state,
      action: PayloadAction<IOnboardingBeneficialOwner>
    ) => {
      const updatedOwners = state.form.owners.map((o) => {
        if (o.id === action.payload.id) {
          return action.payload;
        }
        return o;
      });

      state.form.owners = updatedOwners;
    },
    addAdditionalOwner: (
      state,
      action: PayloadAction<IOnboardingBeneficialOwner>
    ) => {
      state.form.owners.push(action.payload);
    },
    setBeneficialOwners: (
      state,
      action: PayloadAction<IOnboardingBeneficialOwner[]>
    ) => {
      state.form.owners = action.payload;
    },
    setHasWebIdIdentified: (state, action: PayloadAction<boolean>) => {
      state.hasWebIdIdentified = action.payload;
    },
    removeAdditionalOwnerById: (state, action: PayloadAction<string>) => {
      state.form.owners = state.form.owners.filter(
        (o) => o.id !== action.payload
      );
    },
    updateKYCAnswers: (
      state,
      action: PayloadAction<IOnboardingKYCQuestionnaireAnswer>
    ) => {
      const { kycQuestionnaireAnswers, kycQuestionnaireQuestions } = state.form;

      let updatedKYCAnswers = [...kycQuestionnaireAnswers];

      const questionHasAlreadyBeenAnswered = !!kycQuestionnaireAnswers.find(
        (item) => item.questionId === action.payload.questionId
      );
      if (questionHasAlreadyBeenAnswered) {
        updatedKYCAnswers = kycQuestionnaireAnswers.map((item) => {
          if (item.questionId === action.payload.questionId) {
            return action.payload;
          }
          return item;
        });
      } else {
        updatedKYCAnswers.push(action.payload);
      }

      const handleCashQuestionId = kycQuestionnaireQuestions.find(
        (question) =>
          question.name === KycQuestionnaireQuestionNames.handleCashQuestion ||
          question.name === KycQuestionnaireQuestionNames.handleCashQuestionNew
      )?.id;

      // to remove the answer to the question "How much cash do you handle?" in case user answered
      // "NO" to the question "Do you handle cash within your organization?"
      if (
        action.payload.questionId === handleCashQuestionId &&
        action.payload.answers[0].answer === 'NO'
      ) {
        const handleCashToWhatExtentQuestionId = kycQuestionnaireQuestions.find(
          (question) =>
            question.name ===
              KycQuestionnaireQuestionNames.handleCashToWhatExtentQuestion ||
            question.name ===
              KycQuestionnaireQuestionNames.handleCashPercentageTurnoverQuestion
        )?.id;
        updatedKYCAnswers = updatedKYCAnswers.filter(
          (item) => item.questionId !== handleCashToWhatExtentQuestionId
        );
      }

      state.form.kycQuestionnaireAnswers = updatedKYCAnswers;
    },
    appendAccountsInfo: (state, action: PayloadAction<IKlarnaAccount[]>) => {
      const { overview } = state;
      const currentAccounts = overview.bankAccounts;

      if (!currentAccounts) {
        state.overview.bankAccounts = action.payload;
      } else {
        const newAccounts = action.payload.filter(
          (account) =>
            !currentAccounts.some(
              (currentAccount) => currentAccount.iban === account.iban
            )
        );

        if (newAccounts.length > 0) {
          state.overview.bankAccounts = [...currentAccounts, ...newAccounts];
        }
      }
    },
    updateSigner: (state, action: PayloadAction<IOnboardingSigner>) => {
      const { form } = state;
      const signerKeys: Record<
        SignerType,
        keyof Pick<ILoanOnboardingOfferForm, 'signers' | 'guarantors'> | ''
      > = {
        [SignerType.Signer]: 'signers',
        [SignerType.Guarantor]: 'guarantors',
        [SignerType.Applicant]: '',
      };

      const reduxKey = signerKeys[action.payload.type];
      if (reduxKey) {
        const updatedList = form[reduxKey].map((s) => {
          if (s.signerId === action.payload.signerId) {
            return {
              ...s,
              ...action.payload,
            };
          }
          return s;
        });
        state.form[reduxKey] = updatedList;
      }
    },
  },
});

export const {
  updateCurrentStep,
  goToNextStep,
  goToPreviousStep,
  updateForm,
  updateOverview,
  setApiStatus,
  setStepStartTimestamp,
  setFetchedBeneficialOwners,
  updateApplicant,
  updateAdditionalOwner,
  addAdditionalOwner,
  removeAdditionalOwnerById,
  updateKYCAnswers,
  appendAccountsInfo,
  updateSigner,
  setBeneficialOwners,
  setHasWebIdIdentified,
} = loanOnboardingOfferSlice.actions;

export type TLoanOnboardingOfferSliceActions = typeof loanOnboardingOfferSlice.actions;

export default loanOnboardingOfferSlice.reducer;

startAppListening({
  matcher: isAnyOf(updateCurrentStep, goToNextStep, goToPreviousStep),
  effect: async (_action, listenerApi) => {
    listenerApi.dispatch(setStepStartTimestamp());
  },
});

// if signers, owners or the applicant is updated, we need to check if the same person is in the
// other lists and update it there as well
startAppListening({
  matcher: isAnyOf(
    addAdditionalOwner,
    updateAdditionalOwner,
    updateSigner,
    updateApplicant,
    setBeneficialOwners,
    removeAdditionalOwnerById
  ),
  effect: (action, listenerApi) => {
    syncOnboardingOfferPersons(
      action,
      listenerApi.getState().onboardingOffer,
      listenerApi.getOriginalState().onboardingOffer,
      listenerApi.dispatch,
      loanOnboardingOfferSlice.actions
    );
  },
});
