import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputField from '~/components/shared/Input/InputField';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import Stack from '~/components/shared/Layout/Stack';
import useDebounce from '~/hooks/useDebounce';
import { digitValidator, zipCodeValidator } from '~/helpers/validators.helper';
import DropDown from '~/components/shared/DropDown/DropDown';
import { ValidationContext } from '~/components/hoc/withValidation';
import { theme } from '~/styles/themes';
import { logToSentry } from '~/helpers/loggers.helper';
import { apiGetAddress } from '~/services/api/onboarding';
import { formatZipCodeNL, removeWhitespace } from '~/helpers/formatters.helper';
import {
  onChangeInputEvent,
  onChangeSelectEvent,
  onFocusSelectEvent,
} from '~/types/types';
import { houseNumberAdditionsDropdownValuesHelper } from '~/helpers/onboarding.helper';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import { IOnboardingAddressFields } from '~/interfaces/Onboarding';
import { Group, useWindowProperties } from '@qred/components-library';

interface AddressProps {
  addressFields?: IOnboardingAddressFields;
  onAddressFieldChange: (partialAddress: IOnboardingAddressFields) => void;
  onAddressFieldBlur?: (name: string) => void;
  applicantAuthenticated: boolean;
  showTooltipHomeAddressExplanation?: boolean;
  showValidationInfoWhenUntouched?: boolean;
}

const Address = ({
  addressFields = {},
  onAddressFieldChange,
  onAddressFieldBlur,
  applicantAuthenticated,
  showTooltipHomeAddressExplanation,
  showValidationInfoWhenUntouched,
}: AddressProps) => {
  const t = useTranslate();
  const { isMobile } = useWindowProperties();

  const [houseNumberAdditions, setHouseNumberAdditions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [addressApiStatus, setAddressApiStatus] = useState(ApiStatus.Idle);

  const validationContext = useContext(ValidationContext);

  const { market } = useSelector((state: RootState) => state.intl);

  const debouncedZipCodeValue = useDebounce(addressFields.zipCode, {
    manuallyInvalidateFormDuringDebounce: true,
  });
  const debouncedHouseNumberValue = useDebounce(addressFields.houseNumber, {
    manuallyInvalidateFormDuringDebounce: true,
  });

  const onChange = (event: onChangeInputEvent) => {
    const { name, value } = event.target;
    onAddressFieldChange({ [name]: value });
  };

  const onZipCodeChange = (event: onChangeInputEvent) => {
    const { value } = event.target;
    const valueIsValid = !zipCodeValidator(value.trim());
    onAddressFieldChange({
      zipCode: valueIsValid ? formatZipCodeNL(value) : value,
    });
  };

  const onBlur = (event: onChangeInputEvent | onFocusSelectEvent) => {
    const { name } = event.target;
    onAddressFieldBlur && onAddressFieldBlur(name);
  };

  const onDropDownChange = (event: onChangeSelectEvent) => {
    const { name, value } = event.target;

    onAddressFieldChange({ [name]: value });
  };

  const fetchAddress = useCallback(async () => {
    validationContext.manuallyInvalidateForm(true);
    setAddressApiStatus(ApiStatus.Started);
    validationContext.removePropertyFromValidationErrors('NoAddressFound');
    try {
      const addressData = await apiGetAddress(
        debouncedZipCodeValue && removeWhitespace(debouncedZipCodeValue),
        debouncedHouseNumberValue,
        applicantAuthenticated,
        market
      );

      const localHouseNumberAdditions = houseNumberAdditionsDropdownValuesHelper(
        addressData.houseNumberAdditions
      );
      setHouseNumberAdditions(
        localHouseNumberAdditions.map((value) => {
          let label = value;
          if (label === 'NoAdditionalHouseNumber') {
            label = t('AddressFields.NoAdditionalHouseNumber') as string;
          } else if (label === 'Select') {
            label = t('AddressFields.SelectAHouseNumber') as string;
          }

          return {
            label,
            value,
          };
        })
      );

      if (
        addressFields.houseNumberAddition &&
        !localHouseNumberAdditions.includes(addressFields.houseNumberAddition)
      ) {
        onAddressFieldChange({
          houseNumberAddition: '',
        });
      }

      onAddressFieldChange({
        city: addressData.city,
        streetName: addressData.street,
      });

      setAddressApiStatus(ApiStatus.Completed);
    } catch (err: any) {
      validationContext.addPropertyToValidationErrors('NoAddressFound');
      if (err?.response?.status !== 404) {
        logToSentry(err, 'apiGetAddress');
      }
      onAddressFieldChange({
        city: '',
        streetName: '',
        houseNumberAddition: '',
      });
      setHouseNumberAdditions([]);
      setAddressApiStatus(ApiStatus.Failed);
    } finally {
      validationContext.manuallyInvalidateForm(false);
    }
  }, [
    addressFields.houseNumberAddition,
    applicantAuthenticated,
    debouncedHouseNumberValue,
    debouncedZipCodeValue,
    market,
    onAddressFieldChange,
    validationContext,
  ]);

  const fetchAddressIfValid = useCallback(() => {
    const validZipCode = !zipCodeValidator(debouncedZipCodeValue);
    const validHouseNumber = !digitValidator(debouncedHouseNumberValue);
    const shouldFetchAddress = validZipCode && validHouseNumber;

    if (shouldFetchAddress) {
      fetchAddress();
    } else if (addressApiStatus !== ApiStatus.Idle) {
      setAddressApiStatus(ApiStatus.Idle);
    }
  }, [
    addressApiStatus,
    debouncedHouseNumberValue,
    debouncedZipCodeValue,
    fetchAddress,
  ]);

  useEffect(() => {
    fetchAddressIfValid();

    return () => {
      validationContext.removePropertyFromValidationErrors('NoAddressFound');
    };
  }, [debouncedZipCodeValue, debouncedHouseNumberValue]);

  return (
    <Stack spacing="md">
      <Group
        justify="space-between"
        nowrap
        direction={isMobile ? 'column' : 'row'}
      >
        <InputField
          name="zipCode"
          dataCy="address_input_zip_code"
          label={t('AddressFields.PostCode') as string}
          onChange={onZipCodeChange}
          onBlur={onBlur}
          placeholder="XXXX GE"
          value={addressFields.zipCode || ''}
          validationType="Zip"
          isLoading={addressApiStatus === ApiStatus.Started}
          showValidationInfoWhenUntouched={showValidationInfoWhenUntouched}
        />
        <InputField
          name="houseNumber"
          dataCy="address_input_house_number"
          label={t('AddressFields.HouseNumber') as string}
          explanation={
            showTooltipHomeAddressExplanation
              ? (t('AddressFields.TooltipHomeAddressExplanation') as string)
              : ''
          }
          onChange={onChange}
          onBlur={onBlur}
          inputMode="numeric"
          placeholder="XXX"
          value={addressFields.houseNumber || ''}
          validationType="HouseNumber"
          isLoading={addressApiStatus === ApiStatus.Started}
          showValidationInfoWhenUntouched={showValidationInfoWhenUntouched}
        />
      </Group>

      {addressApiStatus === ApiStatus.Failed && (
        <ValidationErrorMessage>
          {t('ValidationErrors.NoAddressFound')}
        </ValidationErrorMessage>
      )}

      {addressApiStatus === ApiStatus.Completed && (
        <>
          {houseNumberAdditions.length ? (
            <DropDown
              name="houseNumberAddition"
              label={t('AddressFields.HouseNumberAddition') as string}
              labelColor={theme.colors.dark}
              onChange={onDropDownChange}
              onBlur={onBlur}
              dropDownValues={houseNumberAdditions}
              value={addressFields.houseNumberAddition || 'Select'}
              showValidationInfoWhenUntouched
              validationType="HouseNumberAddition"
            />
          ) : null}

          <InputField
            name="street"
            dataCy="personal_information_step_street"
            label={t('AddressFields.Street') as string}
            value={addressFields.streetName || ''}
            disabled
          />
          <InputField
            name="city"
            dataCy="personal_information_step_city"
            label={t('AddressFields.City') as string}
            value={addressFields.city || ''}
            disabled
          />
        </>
      )}
    </Stack>
  );
};

export default Address;
